export default {
    country: {
        label: "Kraj zamieszkania",
        errors: {
            required: "Wymagany jest wybór"
        }
    },
    entity: {
        label: "Podmiot",
        errors: {
            required: "Wymagany jest wybór"
        }
    },
    location: {
        label: "Lokalizacja biura",
        errors: {
            required: "Wymagany jest wybór"
        }
    },
    workplace: {
        values: {
            "Dedicated Office": {
                title: "Biuro Dedykowane",
                description: "Home office ze stałą lokalizacją przeznaczoną na biuro"
            },
            "Semi-Dedicated": {
                title: "Częściowo dedykowany",
                description: "Tymczasowa lokalizacja w celu zaspokojenia potrzeb domowego środowiska pracy"
            },
            "Floating": {
                title: "Ruchomy",
                description: "Brak wydzielonej przestrzeni na domowe biuro, ale jest współdzielona z innymi lokalizacjami, takimi jak jadalnia lub salon"
            }
        },
        errors: {
            required: "Wymagany jest wybór"
        }
    },
    priority: {
        label: "Najwyższy priorytet",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Docking Station": {
                title: "Stacja dokująca"
            },
            "Keyboard/Mouse": {
                title: "Myszka klawiaturowa"
            },
            "Web Cam": {
                title: "Kamerka internetowa"
            },
            "Headset": {
                title: "Zestaw słuchawkowy"
            },
            "Office Chair": {
                title: "Krzesło biurowe"
            },
            "Desk": {
                title: "Biurko"
            },
            "Adapters": {
                title: "Adaptery"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Zasilacz bezprzerwowy (UPS)"
            },
            "Something Else": {
                title: "Coś innego"
            },
            "Nothing Needed": {
                title: "Nic nie potrzebne"
            }
        },
        errors: {
            requiredUnless: "Wymagany jest wybór",
            requiredIf: "Wymagany jest wybór",
            required: "Wymagany jest wybór"
        }
    },
    priorityDetails: {
        placeholder: "Podaj szczegóły"
    },
    secondaryPriority: {
        label: "Drugi najwyższy priorytet",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Second Monitor": {
                title: "Drugi monitor"
            },
            "Docking Station": {
                title: "Stacja dokująca"
            },
            "Keyboard/Mouse": {
                title: "Myszka klawiaturowa"
            },
            "Web Cam": {
                title: "Kamerka internetowa"
            },
            "Headset": {
                title: "Zestaw słuchawkowy"
            },
            "Office Chair": {
                title: "Krzesło biurowe"
            },
            "Desk": {
                title: "Biurko"
            },
            "Adapters": {
                title: "Adaptery"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Zasilacz bezprzerwowy (UPS)"
            },
            "Something Else": {
                title: "Coś innego"
            },
            "Nothing Needed": {
                title: "Nic nie potrzebne"
            }
        },
        errors: {
            requiredUnless: "Wymagany jest wybór",
            requiredIf: "Wymagany jest wybór",
            required: "Wymagany jest wybór"
        }
    },
    secondaryPriorityDetails: {
        placeholder: "Podaj szczegóły"
    },
    improveWorkspace: {
        label: "Jak ulepszyłbyś swoje miejsce pracy?",
        placeholder: "Opcjonalny",
    },
    improveRemoteWorking: {
        label: "Co poza sprzętem pomogłoby Ci zwiększyć efektywność pracy zdalnej?",
        placeholder: "Opcjonalny",
    },
    receivedEquipmentHeadset: {
        label: "Czy w ciągu ostatnich 24 miesięcy otrzymałeś od Firmy bezprzewodowy zestaw słuchawkowy lub zestaw głośnomówiący?",
        values: {
            Yes: {
                name: "Tak"
            },
            No: {
                name: "NIE"
            }
        }
    },
    issuesHeadset: {
        label: "Czy masz z tym problemy?",
        values: {
            Yes: {
                name: "Tak"
            },
            No: {
                name: "NIE"
            }
        }
    },
    issuesDescriptionHeadset: {
        placeholder: "Opisz swoje problemy"
    },
    isOfficeWorker: {
        label: "Pracujesz w biurze na pełny etat?",
        values: {
            Yes: {
                name: "Tak"
            },
            No: {
                name: "NIE"
            }
        }
    },
    receivedKeyboardMouse: {
        label: "Czy w ciągu ostatnich 12 miesięcy otrzymałeś od Firmy klawiaturę i mysz?",
        values: {
            Yes: {
                name: "Tak"
            },
            No: {
                name: "NIE"
            }
        }
    },
    issuesKeyboardMouse: {
        label: "Czy masz z tym problemy?",
        values: {
            Yes: {
                name: "Tak"
            },
            No: {
                name: "NIE"
            }
        }
    },
    issuesDescriptionKeyboardMouse: {
        placeholder: "Opisz swoje problemy"
    },
    needWebcam: {
        label: "Potrzebujesz do pracy zewnętrznej kamery internetowej (nie tej wbudowanej w laptopa?",
        values: {
            Yes: {
                name: "Tak"
            },
            No: {
                name: "NIE"
            }
        }
    },
    needWebcamExternal: {
        label: "Czy potrzebujesz zewnętrznej kamery internetowej USB (nie tej wbudowanej w laptopa)?",
        values: {
            Yes: {
                name: "Tak"
            },
            No: {
                name: "NIE"
            }
        }
    },
    needMonitor: {
        label: "Potrzebujesz monitora do pracy?",
        values: {
            Yes: {
                name: "Tak"
            },
            No: {
                name: "NIE"
            }
        }
    },
    receivedMonitor: {
        label: "Czy w ciągu ostatnich 4 lat otrzymałeś od firmy monitor?",
        values: {
            Yes: {
                name: "Tak"
            },
            No: {
                name: "NIE"
            }
        }
    },
    issuesMonitor: {
        label: "Czy masz z tym problemy?",
        values: {
            Yes: {
                name: "Tak"
            },
            No: {
                name: "NIE"
            }
        }
    },
    issuesDescriptionMonitor: {
        placeholder: "Opisz swoje problemy"
    },
    needUsbhub: {
        label: "Potrzebujesz zewnętrznego koncentratora USB do pracy?",
        values: {
            Yes: {
                name: "Tak"
            },
            No: {
                name: "NIE"
            }
        }
    },
    issuesDescriptionUsbhub: {
        placeholder: "Opisz swoje problemy"
    }
}