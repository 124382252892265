export default {
    title: "Rendelés a nevében",
    model: {
        email: {
            label: "Email",
            placeholder: "Kérjük, töltse ki a felhasználói e-mail-címet a megrendeléshez",
            errors: {
                myself: "Az e-mail-címnek különböznie kell az Önétől"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Beküldés",
            processing: "Beküldés"
        }
    },
    error: {
        userNotExists: "{email} nem létezik"
    }
}