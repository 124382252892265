export default {
    "India": "India",
    "United States of America": "Estados Unidos de América",
    "United Kingdom": "Reino Unido",
    "Philippines": "Filipinas",
    "Australia": "Australia",
    "Spain": "España",
    "Bulgaria": "Bulgaria",
    "Poland": "Polonia",
    "Malaysia": "Malasia",
    "Russian Federation": "Federación Rusa",
    "Ukraine": "Ucrania",
    "Germany": "Alemania",
    "Brazil": "Brasil",
    "Canada": "Canada",
    "Romania": "Rumania",
    "France": "Francia",
    "China": "Porcelana",
    "Italy": "Italia",
    "Costa Rica": "Costa Rica",
    "Mexico": "México",
    "Slovakia": "Eslovaquia",
    "Vietnam": "Vietnam",
    "Singapore": "Singapur",
    "Argentina": "Argentina",
    "Denmark": "Dinamarca",
    "Japan": "Japón",
    "Belgium": "Bélgica",
    "Czech Republic": "República Checa",
    "Netherlands": "Países Bajos",
    "Switzerland": "Suiza",
    "Sweden": "Suecia",
    "New Zealand": "Nueva Zelanda",
    "Hungary": "Hungría",
    "Egypt": "Egipto",
    "Lithuania": "Lituania",
    "United Arab Emirates": "Emiratos Árabes Unidos",
    "Ireland": "Irlanda",
    "Hong Kong": "Hong Kong",
    "Austria": "Austria",
    "Saudi Arabia": "Arabia Saudita",
    "Portugal": "Portugal",
    "Norway": "Noruega",
    "Chile": "Chile",
    "Thailand": "Tailandia",
    "Taiwan": "Taiwán",
    "Tunisia": "Túnez",
    "Israel": "Israel",
    "South Africa": "Sudáfrica",
    "Colombia": "Colombia",
    "Serbia": "Serbia",
    "Luxembourg": "luxemburgo",
    "Peru": "Perú",
    "Finland": "Finlandia",
    "Brunei Darussalam": "Brunei Darussalam",
    "Korea, Republic of": "Corea, república de",
    "Indonesia": "Indonesia",
    "Jordan": "Jordán",
    "Fiji": "Fiyi",
    "Morocco": "Marruecos",
    "Nigeria": "Nigeria",
    "Panama": "Panamá",
    "Qatar": "Katar",
    "Greece": "Grecia",
    "Turkey": "Pavo",
    "Croatia": "Croacia",
    "Kazakhstan": "Kazajistán",
    "Cyprus": "Chipre"
}