export default {
    error: "Při načítání objednávek došlo k problému. Obnovte prosím svůj prohlížeč.",
    meta: {
        default: "Objednávky",
        my_orders: "Mé objednávky",
        my_gear: "Přijato"
    },
    loadMore: "Načíst další",
    noOrders: "Žádné objednávky",
    order: {
        product: {
            title: "Objednat",
            tracking: "Sledování",
            quantity: "Množství",
            control: {
                cancel: {
                    cta: "Klikněte pro zrušení",
                    tooltip: "Kliknutím tuto položku zrušíte",
                    message: "Vaši objednávku jsme zrušili."
                },
                cancelRequest: {
                    cta: "Žádost o zrušení",
                    tooltip: "Kliknutím odešlete žádost o zrušení"
                },
                received: {
                    cta: "Označit jako přijaté",
                    tooltip: "Kliknutím potvrdíte doručení",
                    message: "Děkujeme za potvrzení, že jste produkt obdrželi."
                },
                notReceived: {
                    cta: "Označit jako nepřijaté",
                    tooltip: "Kliknutím potvrdíte nedoručení"
                },
                damaged: {
                    cta: "Označit jako poškozené/rozbité",
                    tooltip: "Kliknutím vytvoříte lístek podpory"
                },
                hide: {
                    cta: "Skrýt položku",
                    tooltip: "Kliknutím skryjete položku ze seznamu"
                }
            },
            requestToCancelInfo: "Budeme kontaktovat dodavatele s žádostí o zrušení. Upozorňujeme, že toto je pouze požadavek a zrušení nemusí být možné. Jakmile budeme moci určit, zda jej lze zrušit nebo ne, dáme vám vědět.",
            cancelOk: "OK",
            reason: "Důvod",
            orderedBy: "Objednáno někým",
            model: {
                cancel: {
                    reason: {
                        label: "Důvody zrušení",
                        placeholder: "Důvody zrušení"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Předložit",
                        processing: "Odesílání"
                    },
                    cancel: {
                        cta: "zrušení"
                    }
                }
            }
        }
    },
    help: {
        text: "<p>Máte dotazy ke své objednávce?<br/> Nápovědu naleznete v našem <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>centru podpory</b></a></p><p> Potřebujete produkt, který není uveden?<br/> Kliknutím <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>sem</b></a> pošlete návrh</p>",
        email: {
            subject: ""
        }
    }
}