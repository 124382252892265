export default {
    title: "门户调查",
    header: "@:views.survey.customer.title",
    model: {
        service_rating: {
            label: "从 1 到 10 打分，其中 1 表示非常不满意，10 表示非常满意，您如何评价这项服务？",
        },
        service_likes: {
            label: "你喜欢什么？",
            placeholder: ""
        },
        service_improvements: {
            label: "有什么可以改进的？",
            placeholder: ""
        },
        service_easy_to_follow: {
            label: "在 1-10 的等级中，1 表示完全不清楚，10 表示易于理解，您如何评价有关该站点的通信？"
        },
        item: {
            setup_rating: {
                label: "在 1 到 10 的范围内，1 表示非常困难，10 表示非常容易，安装和设置“{name}”的难度如何？",
            },
            comments: {
                label: "请对 {name} 发表任何评论？",
                placeholder: ""
            }
        }
    },
    disclaimer: "请注意，您的调查回复将与 O-I 共享。我们可能会就您的反馈跟进您。",
    button: {
        submit: {
            cta: "提交",
            processing: "提交中"
        }
    }
}