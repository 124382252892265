export default {
    user: {
        create: {
            title: "New user",
            model: {
                email: {
                    label: "Email",
                    placeholder: "Fill email"
                },
                name: {
                    label: "Name",
                    placeholder: "Fill name"
                },
                country: {
                    label: "Country",
                    placeholder: "Fill country"
                }
            },
            cta: {
                submit: {
                    text: "Submit",
                    processing: "Submitting"
                },
                cancel: {
                    text: "Cancel"
                }
            }
        }
    }
}