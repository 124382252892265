export default {
    error: "Er is een probleem opgetreden bij het ophalen van bestellingen. Herlaad alsjeblieft je browser.",
    title: "Bestelgeschiedenis",
    loadMore: "Meer laden",
    noOrders: "Geen bestellingen",
    user: {
        loadMore: "Meer laden",
        product: {
            title: "Volgorde",
            tracking: "Volgen",
            quantity: "Hoeveelheid",
            reason: "Reden",
            control: {
                cancel: {
                    cta: "Klik om te annuleren",
                    tooltip: "Klik om dit item te annuleren",
                    message: "Wij hebben uw bestelling geannuleerd."
                },
                received: {
                    cta: "Product ontvangen",
                    tooltip: "Klik om de levering te bevestigen",
                    message: "Bedankt dat u bevestigt dat u uw product heeft ontvangen."
                },
                notReceived: {
                    cta: "Product niet ontvangen",
                    tooltip: "Klik om de niet-levering te bevestigen"
                },
                hide: {
                    cta: "Artikel verbergen",
                    tooltip: "Klik om het item uit de lijst te verbergen"
                }
            }
        }
    }
}