export default {
    header: "Erősítse meg a címet és küldje el a rendelést",
    message: "Helyes az alábbi szállítási cím?",
    tip: "Kattintson <b>az IGEN gombra</b> a szállítási cím megerősítéséhez és a megrendelés elküldéséhez. Kattintson <b>a NEM gombra</b> a beküldés visszavonásához és másik cím kiválasztásához.",
    info: "A rendelés leadása után nem módosítható. Ha a megrendelt termék utólagos rendelése van, vagy későbbi szállítási dátummal rendelkezik, és költözést tervez, kérjük ezt vegye figyelembe a szállítási cím kiválasztásakor. Továbbá, ha közelgő ünnep vagy nyaralás van, és nem lesz otthon a termék átvételére, kérjük, hogy a rendelések leadását várják addig, amíg haza nem érkezik a termékek átvételéhez.",
    warning: {
        message: "Kérjük, még egyszer ellenőrizze, hogy a {fields} helyes, mivel előfordulhat, hogy a szállítmányozó cég nem tudja kiszállítani az Ön vagy megrendelését, illetve nem tudja Önnel kapcsolatba lépni a rendelésével kapcsolatban. | Kérjük, még egyszer ellenőrizze, hogy a {fields} helyesek, mivel előfordulhat, hogy a szállítmányozó cég nem tudja kiszállítani az Ön vagy megrendelését, vagy nem tudja felvenni Önnel a kapcsolatot a rendelésével kapcsolatban.",
        name: "Név",
        street: "utca",
        phone: "Telefon"
    },
    buttons: {
        yes: "Igen",
        no: "Nem"
    }
}