export default {
    unverified: "Niet geverifieerd",
    verificationLink: "Verificatie-e-mail opnieuw verzenden",
    buttons: {
        delete: {
            cta: "Verwijderen",
            processing: "Verwijderen"
        }
    },
    addressDeleted: "Adres is verwijderd",
    verificationEmailSent: "De email is verzonden",
    deleteAddressConfirmation: "Weet u zeker dat u dit adres wilt verwijderen?"
}