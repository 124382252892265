export default {
    title: {
        new: "Nieuw adres toevoegen",
        complete: "Volledig adres",
        edit: "verander adres"
    },

    incomplete: "Vul de verplichte adresvelden in",

    info: {
        "CHN": "Voer uw adres in het Chinees in.</br> Voer uw adres in het Chinees in.",
        "BGR": "Voer uw <b>thuisadres</b> in het Engels in om uw artikelen te ontvangen.",
        "PRI": "Voer uw huisadres in en geen postbus om pakketten te ontvangen.",
        "SGP": "Vermeld uw unitnummer in uw adres.",
        "SVK": "Voer uw adres in <b>het Engels</b> in",
        "POL": "Voer uw adres in <b>het Engels</b> in"
    },

    model: {
        country: {
            label: "Land"
        }
    },

    field: {
        default: {
            "name": {
                "label": {
                    "Name": "Naam"
                },
                "placeholder": {
                    "Name": "Naam",
                    "Name in English": "Naam in het Engels"
                }
            },
            personalEmail: {
                label: {
                    "Personal Email": "Persoonlijke email"
                },
                placeholder: {
                    "Personal Email": "Persoonlijke email"
                }
            },
            managerEmail: {
                label: {
                    "Manager Email": "E-mailadres van de manager"
                },
                placeholder: {
                    "Manager Email": "E-mailadres van de manager"
                }
            },
            managerName: {
                label: {"Manager Name": "Naam van de manager"},
                placeholder: {"Manager Name": "Naam van de manager"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Postcode",
                    "Post Code": "Postcode",
                    "Postal Code": "Postcode",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "Postcode",
                    "Post Code": "Postcode",
                    "Postal Code": "Postcode",
                    "CPA": "CPA"
                }
            },
            "province": {
                "label": {
                    "Province": "Provincie",
                    "Prefecture": "Prefectuur",
                    "Province/Region": "Provincie regio",
                    "Province Code": "Provinciecode"
                },
                "placeholder": {
                    "Province": "Provincie",
                    "Prefecture": "Prefectuur",
                    "Province/Region": "Provincie regio",
                    "Province Code": "Provinciecode"
                }
            },
            "phoneNumber": {
                "label": {
                    "Phone Number": "Telefoonnummer"
                },
                "placeholder": {
                    "Phone Number": "Telefoonnummer"
                }
            },
            "firstStreet": {
                "label": {
                    "Street": "Straat",
                    "Tax ID": "BTW-nummer"
                },
                "placeholder": {
                    "Street": "Straat",
                    "Street in English": "Straat in het Engels",
                    "Tax ID (RUT)": "Belastingnummer (RUT)",
                    "Number/Street/Apartment": "Nummer/Straat/Appartement"
                }
            },
            "secondStreet": {
                "label": {
                    "District": "Wijk",
                    "Colonia": "Keulen",
                    "Canton": "Kanton",
                    "Street": "Straat"
                },
                "placeholder": {
                    "District": "Wijk",
                    "Colonia": "Keulen",
                    "Canton": "Kanton",
                    "Number/Street/Apartment": "Nummer/Straat/Appartement"
                }
            },
            "city": {
                "label": {
                    "City": "Stad",
                    "Town/City/Locality": "Plaats/stad/plaats",
                    "Town/City": "Stad/stad",
                    "City/Municipality/Locality": "Stad/gemeente/plaats",
                    "City/Town/Locality": "Stad/plaats/plaats",
                    "City/Town": "Stad/plaats",
                    "District": "Wijk",
                    "City/Town/Locallity": "Stad/plaats/plaats",
                    "Town/City/Sub Locality": "Stad/stad/subplaats"
                },
                "placeholder": {
                    "City": "Stad",
                    "Town/City": "Stad/stad",
                    "Town/City/Locality": "Plaats/stad/plaats",
                    "City/Municipality/Locality": "Stad/gemeente/plaats",
                    "City/Town/Locality": "Stad/plaats/plaats",
                    "City/Town in English": "Stad/plaats in het Engels",
                    "District": "Wijk",
                    "City/Town/Locallity": "Stad/plaats/plaats",
                    "Town/City/Sub Locality": "Stad/stad/subplaats"
                }
            },
            identifier: {
                label: {
                    "identifier": "Identificatie",
                    "Identifier": "Identificatie"
                },
                placeholder: {
                    "identifier": "Identificatie",
                    "Identifier": "Identificatie"
                }
            },
            "region": {
                "label": {
                    "Region": "Regio",
                    "County": "District",
                    "Locality": "Plaats"
                },
                "placeholder": {
                    "Region": "Regio",
                    "County": "District",
                    "Locality": "Plaats"
                }
            },
            "poBox": {
                "label": {
                    "PO BOX": "POSTBUS"
                },
                "placeholder": {
                    "PO BOX": "POSTBUS"
                }
            },
            state: {
                "label": {
                    "State": "Staat",
                    "Province/Region": "Provincie regio",
                    "Province": "Provincie",
                    "Department": "Afdeling",
                    "Municipality/Comuna": "Gemeente/Comuna"
                },
                "placeholder": {
                    "State": "Staat",
                    "Province/Region": "Provincie regio",
                    "Province": "Provincie",
                    "Department": "Afdeling",
                    "Municipality/Comuna": "Gemeente/Comuna"
                },
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alaska",
                    "Arizona": "Arizona",
                    "Arkansas": "Arkansas",
                    "California": "Californië",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "District van Columbia",
                    "Florida": "Florida",
                    "Georgia": "Georgië",
                    "Hawaii": "Hawaii",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Louisiana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Mississippi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebraska",
                    "Nevada": "Nevada",
                    "New Hampshire": "New Hampshire",
                    "New Jersey": "New Jersey",
                    "New Mexico": "New Mexico",
                    "New York": "New York",
                    "North Carolina": "Noord Carolina",
                    "North Dakota": "Noord-Dakota",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregon",
                    "Pennsylvania": "Pennsylvania",
                    "Rhode Island": "Rhode Island",
                    "South Carolina": "zuid Carolina",
                    "South Dakota": "zuid Dakota",
                    "Tennessee": "Tennessee",
                    "Texas": "Texas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Virginia",
                    "Washington": "Washington",
                    "West Virginia": "West Virginia",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            "deliveryInstructions": {
                "label": {
                    "Delivery Instructions": "Leveringsinstructies"
                },
                "placeholder": {
                    "Extra address information, delivery instructions, etc. ": "Extra adresgegevens, afleverinstructies etc. ",
                    "Extra address information, delivery instructions, etc. in English ": "Extra adresgegevens, afleverinstructies etc. in het Engels ",
                    "Extra address information, delivery instructions, etc": "Extra adresgegevens, afleverinstructies etc."
                }
            },
        },
        BRA: {
            name: {
                label: {"Full name": "Voor-en achternaam"},
                placeholder: {"Full name": "Voor-en achternaam"}
            },
            firstStreet: {
                label: {"Address 1": "adres 1"},
                placeholder: {"Address 1": "Straat + huis-/appartementnummer"}
            },
            secondStreet: {
                label: {
                    "Address 2": "Adres 2",
                    "District": "Wijk"
                },
                placeholder: {
                    "Address 2": "Straat + huis-/appartementnummer",
                    "District": "Wijk"
                }
            },
            city: {
                label: {City: "Stad"},
                placeholder: {City: "Stad"},
            },
            state: {
                label: {State: "Staat"},
                placeholder: {State: "Staat"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Postcode",
                    "Post Code": "Postcode",
                    "Postal Code": "Postcode",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "Postcode",
                    "Post Code": "Postcode",
                    "Postal Code": "Postcode",
                    "CPA": "CPA"
                }
            },
            identifier: {
                "label": {
                    "Tax ID": "BTW-nummer"
                },
                "placeholder": {
                    "Tax ID (CDI, CUIT, CUIL, etc)": "Belastingnummer (CDI, CUIT, CUIL, enz.)"
                }
            }
        }
    },

    errors: {
        required: "{field} Is benodigd.",
        mask: "{field} is ongeldig."
    },

    buttons: {
        submit: {
            text: "Redden",
            saving: "Besparing"
        },
        cancel: {
            text: "Annuleren"
        }
    },

    disclaimer: "Voor het toevoegen van dit adres is e-mailverificatie vereist. Nadat u het adres heeft opgeslagen, controleert u uw e-mail en klikt u op de link daarin.",

    message: {
        added: "Adres is toegevoegd",
        updated: "Adres is bijgewerkt"
    }
}