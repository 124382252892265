export default {
    required: "需要一个数值",
    email: "值不是有效的电子邮件地址",
    mask: "该值无效",
    maxValue: "最大值为{maxValue}",
    regex: "该值无效",
    server: {
        DELEGATES_NOT_ENABLED: "未为此合作伙伴启用代理。",
        DELEGATE_DOES_NOT_EXIST: "委托不存在",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "无法更新不属于您的委托。",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "无法删除不属于您的委托。",
        DELEGATE_ALREADY_EXISTS: "匹配此条件的现有委托规则已存在。",
        DELEGATE_CANNOT_BE_SELF: "您无法将自己添加为代理人。"
    }
}