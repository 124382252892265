export default {
    menu: {
        approvals: "Aprovações",
        delegates: "Delegados"
    },
    approvals: {
        title: "Aprovação",
        error: "Um erro ocorreu",
        saved: "Aprovações enviadas",
        noApprovals: "Nada a aprovar",
        review: "Revise e edite o centro de custo se estiver incorreto",
        priorApprovals: "Aprovações prévias",
        noOrders: "Nada a aprovar",
        noPriorApprovals: "Sem aprovações prévias",
        approved: "aprovado",
        notApproved: "Não aprovado",
        confirm: "Ainda existem pedidos com aprovações pendentes. Deseja continuar com as alterações atuais?",
        manager: "Gerente",
        orders: {
            name: "Nome",
            date: "Data do pedido",
            details: "detalhes do pedido",
            cost: "Custo",
            costCenter: "Centro de custo",
            approval: "Aprovação"
        },
        model: {
            reason: {
                placeholder: "Razão"
            },
            approve: {
                yes: {
                    label: "Sim"
                },
                no: {
                    label: "Não"
                }
            }
        },
        buttons: {
            submit: {
                text: "Enviar",
                processing: "Em processamento"
            }
        }
    },
    delegates: {
        title: "Delegados",
        error: "Um erro ocorreu",
        noDelegates: "Nenhum item",
        delegateDelete: "O delegado foi excluído",
        confirm: {
            delete: "Excluir delegado?"
        },
        delegates: {
            email: "E-mail",
            startDate: "Data de início",
            endDate: "Data final",
        },
        buttons: {
            add: {
                text: "Adicionar Delegado"
            }
        }
    }
}