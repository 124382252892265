export default {
    title: "Szállítási visszaigazolás",
    header: "@:views.survey.delivery.title",
    result: {
        header: "Köszönöm",
        message: "Köszönjük a választ."
    },
    status: {
        yes: "Kérjük, ellenőrizze alább a bejelölt termékeket a kézbesítés megerősítéséhez, és törölje a jelölést a nem kapott termékek jelöléséből. Ezután nyomja meg a Küldés gombot a megerősítéshez.",
        no: "Kérjük, ellenőrizze, hogy az alábbi termékek egyikét sem kapta-e meg. Ha ezek közül a termékek közül bármelyiket megkapta, kérjük, jelölje be a kapott termék(ek) melletti négyzetet, és a megerősítéshez nyomja meg a Küldés gombot."
    },
    model: {
        checked: {
            label: "Megkapta"
        }
    },
    button: {
        submit: {
            cta: "Beküldés",
            processing: "Beküldés"
        }
    }
}