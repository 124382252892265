export default {
    status: {
        acknowledged: "已订购",
        created: "已创建",
        sent: "已订购",
        confirmed: "已订购",
        not_confirmed: "未订购",
        delivery_email: "已发货",
        shipped: "已发货",
        delayed: "延迟",
        preparing: "准备中",
        processing: "加工",
        processed: "处理",
        submitted: "已订购",
        delivery_not_received: "已发货",
        shipment_exception: "已发货",
        pending_approval: "待办的",
        approved: "得到正式认可的",
        not_approved: "不批准",
        canceled: "取消",
        returned: "回",
        lost_shipment: "丢失的货物",
        delivery_not_confirmed: "发表",
        delivered: "发表",
        delivery_received: "发表",
        refurbished: "退货完成",
        returned_to_office: "回到办公室",
        return_to_employer: "返回雇主",
        return_pickup_scheduled: "已安排取件",
        return_pickup_completed: "取件完成",
        return_pickup_excluded: "不包含接送服务",
        return_pickup_submitted: "提货已提交",
        return_pickup_finalized: "取货已完成",
        disposed: "处置",
        damaged: "损坏的"
    }
}