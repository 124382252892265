export default {
    header: "Conferma l'indirizzo e invia l'ordine",
    message: "Il tuo indirizzo di spedizione elencato di seguito è corretto?",
    tip: "Fare clic <b>su SI</b> per confermare l'indirizzo di spedizione e inviare l'ordine. Fare clic su <b>NO</b> per annullare l'invio e scegliere un altro indirizzo.",
    info: "Una volta effettuato un ordine, non è possibile modificarlo. Se il prodotto che stai ordinando è in arretrato o ha una data di spedizione successiva e hai intenzione di spostarti, tienilo in considerazione quando scegli l'indirizzo di spedizione. Inoltre, se c'è una vacanza o una vacanza imminente e non sarai a casa per accettare il prodotto, ti chiediamo di sospendere l'effettuazione di qualsiasi ordine fino a quando non sarai a casa per ricevere i prodotti.",
    warning: {
        message: "Verifica che il tuo {fields} è corretto poiché la compagnia di spedizioni potrebbe non essere in grado di consegnare il tuo ordine o di contattarti in merito al tuo ordine. | Verifica che il tuo {fields} sono corretti poiché la compagnia di spedizioni potrebbe non essere in grado di consegnare il tuo ordine o di contattarti in merito al tuo ordine.",
        name: "Nome",
        street: "Strada",
        phone: "Telefono"
    },
    buttons: {
        yes: "SÌ",
        no: "NO"
    }
}