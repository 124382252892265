const text = {
    countryAfterComponentsBeforeSelection: "Tenga en cuenta que su elección de país se utilizará como parte de su dirección de envío para enviarle productos directamente. Si no ve su país en la lista, Gear actualmente no está disponible en este momento en su país.",
    countryAfterComponentsAllowedSelection: "Tenga en cuenta que su elección de país se utilizará como parte de su dirección de envío para enviarle productos directamente.",
    countryAfterComponentsLimitedSelection: "Actualmente, Gear está disponible en tu país, pero se abre por etapas. A menos que haya recibido una notificación, es posible que no pueda acceder a Gear en este momento en su país.",
    disclaimer: "Al hacer clic en el siguiente Botón a continuación, reconozco que soy un empleado de la Compañía y doy mi consentimiento para que la Compañía y su socio RemoteRetail almacenen y procesen la información personal enviada en este sitio para proporcionarme el contenido solicitado. RemoteRetail necesita la información de contacto que proporciono para que puedan comunicarse conmigo con información relacionada con mis pedidos. Los proveedores externos también pueden recibir mi información para enviarme productos.</p><p> Puedo darme de baja de estas comunicaciones en cualquier momento. Para obtener más información sobre cómo puedo darme de baja, las prácticas de privacidad y los compromisos para proteger y respetar mi privacidad, consulte la <a href='{privacyPolicyUrl}' target='_blank'>Política de privacidad</a> ."
};

export default {
    title: "¡Bienvenido, {userName}!",
    description: {
        default: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`,
        virtualworker: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories that you need to work effectively from home. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`
    },
    text: {
        default: "Para empezar, te haremos una serie de preguntas.",
        virtualworker: "Para comenzar, le haremos una serie de preguntas para ayudarnos a comprender su espacio de trabajo desde el hogar para ayudar a dar forma a la dirección futura de este programa."
    },
    afterComponents: {
        beforeSelection: `${text.countryAfterComponentsBeforeSelection}`,
        allowedSelection: `${text.countryAfterComponentsAllowedSelection}`,
        limitedSelection: `${text.countryAfterComponentsLimitedSelection}`,
        autoLoginBeforeSelection: `${text.countryAfterComponentsBeforeSelection}<br/><br/>${text.disclaimer}`,
        autoLoginAllowedSelection: `${text.countryAfterComponentsAllowedSelection}<br/><br/>${text.disclaimer}`,
        autoLoginLimitedSelection: `${text.countryAfterComponentsLimitedSelection}<br/><br/>${text.disclaimer}`
    },
    buttons: {
        back: "Atrás",
        next: "Próximo",
        submit: "Entregar"
    },
    entityinfo: {
        title: "Información de la entidad",
        description: "Para ayudarnos a enrutar correctamente su pedido, proporciónenos la siguiente información:",
    },
    equipmentsurvey: {
        title: "Encuesta de equipos",
        description: "La siguiente página le preguntará sobre el tipo de equipo que más necesita para ayudar a la Compañía a comprender mejor las prioridades.",
        info: "Tenga en cuenta que el equipo que se muestra es solo para fines de recopilación de información y no implica que la Compañía proporcionará este equipo en el futuro."
    },
    workplace: {
        title: "¿Cuéntanos más sobre tu oficina en casa?",
        description: "Para ayudarnos a comprender mejor su espacio de trabajo, seleccione una de las opciones a continuación",
    },
    priority: {
        title: "Prioridades de equipo",
        description: "Seleccione sus dos opciones principales de equipo que más necesita"
    },
    "additional-questions": {
        title: "Preguntas adicionales"
    },
    "equipment-assessment": {
        title: "Evaluación de equipos",
        description: "El siguiente conjunto de preguntas <b>determinará qué equipo estará disponible para que ordene desde Gear según sus respuestas.</b>"
    },
    headset: {
        title: "Equipo actual"
    },
    "office-worker": {
        title: "Oficina"
    },
    "keyboard-mouse": {
        title: "Teclado y ratón"
    },
    webcam: {
        title: "Cámara web"
    },
    monitor: {
        title: "Monitor"
    },
    usbhub: {
        title: "Concentrador USB"
    },
    "equipment-list": {
        title: "Equipo",
        init: "initEquipmentList",
        description: {
            loading: "<span class=\"animate-spin icon-spinner\"></span>",
            error: "Solicitud incorrecta. Ocurrió un error.",
            allowed: "Según sus respuestas, será elegible para los siguientes artículos:{items}",
            empty: "Según sus respuestas, actualmente no será elegible para ningún artículo en Gear."
        },
        text: "<ul class=\"text-left\" style=\"width: 300px; margin: 2rem auto 0\">{list}</ul>",
        afterComponents: "<small>Si necesita hacer alguna corrección, presione el botón Atrás para cambiar sus respuestas.</small>"
    },
    saved: "Salvado"
}