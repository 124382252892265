export default {
    title: {
        new: "Přidat novou adresu",
        complete: "Kompletní adresa",
        edit: "Upravit adresu"
    },

    incomplete: "Vyplňte prosím povinná pole adresy",

    info: {
        "CHN": "Zadejte prosím svou adresu v čínštině.</br> Zadejte prosím svou adresu v čínštině.",
        "BGR": "Chcete-li dostávat položky, zadejte svou <b>domácí adresu</b> v angličtině.",
        "PRI": "Pro příjem balíků prosím zadejte svou domácí adresu, nikoli PO box.",
        "SGP": "Do adresy uveďte číslo jednotky.",
        "SVK": "Zadejte prosím svou adresu v <b>angličtině</b>",
        "POL": "Zadejte prosím svou adresu v <b>angličtině</b>"
    },

    model: {
        country: {
            label: "Země"
        }
    },

    field: {
        default: {
            "name": {
                "label": {
                    "Name": "název"
                },
                "placeholder": {
                    "Name": "název",
                    "Name in English": "Jméno v angličtině"
                }
            },
            personalEmail: {
                label: {
                    "Personal Email": "Osobní email"
                },
                placeholder: {
                    "Personal Email": "Osobní email"
                }
            },
            managerEmail: {
                label: {
                    "Manager Email": "E-mail manažera"
                },
                placeholder: {
                    "Manager Email": "E-mail manažera"
                }
            },
            managerName: {
                label: {"Manager Name": "Jméno manažera"},
                placeholder: {"Manager Name": "Jméno manažera"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "PSČ",
                    "Post Code": "PSČ",
                    "Postal Code": "poštovní směrovací číslo",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "PSČ",
                    "Post Code": "PSČ",
                    "Postal Code": "poštovní směrovací číslo",
                    "CPA": "CPA"
                }
            },
            "province": {
                "label": {
                    "Province": "Provincie",
                    "Prefecture": "Prefektura",
                    "Province/Region": "Provincie/oblast",
                    "Province Code": "Provinční zákoník"
                },
                "placeholder": {
                    "Province": "Provincie",
                    "Prefecture": "Prefektura",
                    "Province/Region": "Provincie/oblast",
                    "Province Code": "Provinční zákoník"
                }
            },
            "phoneNumber": {
                "label": {
                    "Phone Number": "Telefonní číslo"
                },
                "placeholder": {
                    "Phone Number": "Telefonní číslo"
                }
            },
            "firstStreet": {
                "label": {
                    "Street": "ulice",
                    "Tax ID": "DIČ"
                },
                "placeholder": {
                    "Street": "ulice",
                    "Street in English": "Ulice v angličtině",
                    "Tax ID (RUT)": "DIČ (RUT)",
                    "Number/Street/Apartment": "Číslo/Ulice/Apartmán"
                }
            },
            "secondStreet": {
                "label": {
                    "District": "Okres",
                    "Colonia": "Kolín nad Rýnem",
                    "Canton": "Kanton",
                    "Street": "ulice"
                },
                "placeholder": {
                    "District": "Okres",
                    "Colonia": "Kolín nad Rýnem",
                    "Canton": "Kanton",
                    "Number/Street/Apartment": "Číslo/Ulice/Apartmán"
                }
            },
            "city": {
                "label": {
                    "City": "Město",
                    "Town/City/Locality": "Město/Město/Lokalita",
                    "Town/City": "Město/město",
                    "City/Municipality/Locality": "Město/Obec/Lokalita",
                    "City/Town/Locality": "Město/Město/Lokalita",
                    "City/Town": "Město/město",
                    "District": "Okres",
                    "City/Town/Locallity": "Město/Město/Lokalita",
                    "Town/City/Sub Locality": "Město/město/část"
                },
                "placeholder": {
                    "City": "Město",
                    "Town/City": "Město/město",
                    "Town/City/Locality": "Město/Město/Lokalita",
                    "City/Municipality/Locality": "Město/Obec/Lokalita",
                    "City/Town/Locality": "Město/Město/Lokalita",
                    "City/Town in English": "Město v angličtině",
                    "District": "Okres",
                    "City/Town/Locallity": "Město/Město/Lokalita",
                    "Town/City/Sub Locality": "Město/město/část"
                }
            },
            identifier: {
                label: {
                    "identifier": "Identifikátor",
                    "Identifier": "Identifikátor"
                },
                placeholder: {
                    "identifier": "Identifikátor",
                    "Identifier": "Identifikátor"
                }
            },
            "region": {
                "label": {
                    "Region": "Kraj",
                    "County": "okres",
                    "Locality": "Lokalita"
                },
                "placeholder": {
                    "Region": "Kraj",
                    "County": "okres",
                    "Locality": "Lokalita"
                }
            },
            "poBox": {
                "label": {
                    "PO BOX": "P.O. BOX"
                },
                "placeholder": {
                    "PO BOX": "P.O. BOX"
                }
            },
            state: {
                "label": {
                    "State": "Stát",
                    "Province/Region": "Provincie/oblast",
                    "Province": "Provincie",
                    "Department": "oddělení",
                    "Municipality/Comuna": "Obec/obec"
                },
                "placeholder": {
                    "State": "Stát",
                    "Province/Region": "Provincie/oblast",
                    "Province": "Provincie",
                    "Department": "oddělení",
                    "Municipality/Comuna": "Obec/obec"
                },
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Aljaška",
                    "Arizona": "Arizona",
                    "Arkansas": "Arkansas",
                    "California": "Kalifornie",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "District Of Columbia",
                    "Florida": "Florida",
                    "Georgia": "Gruzie",
                    "Hawaii": "Havaj",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Louisiana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Mississippi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebraska",
                    "Nevada": "Nevada",
                    "New Hampshire": "New Hampshire",
                    "New Jersey": "New Jersey",
                    "New Mexico": "Nové Mexiko",
                    "New York": "New York",
                    "North Carolina": "Severní Karolina",
                    "North Dakota": "Severní Dakota",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregon",
                    "Pennsylvania": "Pensylvánie",
                    "Rhode Island": "Rhode Island",
                    "South Carolina": "Jižní Karolína",
                    "South Dakota": "Jižní Dakota",
                    "Tennessee": "Tennessee",
                    "Texas": "Texas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Virginie",
                    "Washington": "Washington",
                    "West Virginia": "západní Virginie",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            "deliveryInstructions": {
                "label": {
                    "Delivery Instructions": "instrukce k doručení"
                },
                "placeholder": {
                    "Extra address information, delivery instructions, etc. ": "Další informace o adrese, pokyny k doručení atd. ",
                    "Extra address information, delivery instructions, etc. in English ": "Další informace o adrese, pokyny k doručení atd. v angličtině ",
                    "Extra address information, delivery instructions, etc": "Další informace o adrese, pokyny k doručení atd."
                }
            },
        },
        BRA: {
            name: {
                label: {"Full name": "Celé jméno"},
                placeholder: {"Full name": "Celé jméno"}
            },
            firstStreet: {
                label: {"Address 1": "Adresa 1"},
                placeholder: {"Address 1": "Ulice + číslo domu/bytu"}
            },
            secondStreet: {
                label: {
                    "Address 2": "Adresa 2",
                    "District": "Okres"
                },
                placeholder: {
                    "Address 2": "Ulice + číslo domu/bytu",
                    "District": "Okres"
                }
            },
            city: {
                label: {City: "Město"},
                placeholder: {City: "Město"},
            },
            state: {
                label: {State: "Stát"},
                placeholder: {State: "Stát"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "PSČ",
                    "Post Code": "PSČ",
                    "Postal Code": "poštovní směrovací číslo",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "PSČ",
                    "Post Code": "PSČ",
                    "Postal Code": "poštovní směrovací číslo",
                    "CPA": "CPA"
                }
            },
            identifier: {
                "label": {
                    "Tax ID": "DIČ"
                },
                "placeholder": {
                    "Tax ID (CDI, CUIT, CUIL, etc)": "DIČ (CDI, CUIT, CUIL atd.)"
                }
            }
        }
    },

    errors: {
        required: "{field} je požadováno.",
        mask: "{field} je neplatný."
    },

    buttons: {
        submit: {
            text: "Uložit",
            saving: "Ukládání"
        },
        cancel: {
            text: "zrušení"
        }
    },

    disclaimer: "Přidání této adresy bude vyžadovat ověření e-mailem. Po uložení adresy zkontrolujte svůj e-mail a klikněte na odkaz uvnitř.",

    message: {
        added: "Adresa byla přidána",
        updated: "Adresa byla aktualizována"
    }
}