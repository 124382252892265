import {platform} from "@/api/platform";
import {retryRequest} from "@/helpers/request";
import {getBranchById, getParentsChain} from "@/helpers/tree";
import {CATALOG_ROOT_CATEGORY} from "@/constants";

export default {
    namespaced: true,
    state: {
        loading: false,
        items: [],
        activeId: null,
        type: null,
        rootItem: null
    },
    getters: {
        loading: state => state.loading,
        items: state => state.items,
        find: state => id => getBranchById(state.items, id) || state.items[0],
        activeId: state => state.activeId,
        active: state => getBranchById(state.items, state.activeId),
        type: state => state.type,
        chain: state => !state.activeId
        || (state.rootItem && state.rootItem.id == state.activeId)
        || !getBranchById(state.items, state.activeId)
            ? []
            : getParentsChain(state.items, getBranchById(state.items, state.activeId)),
        rootItem: state => state.rootItem,
    },
    mutations: {
        setLoading: (state, loading) => state.loading = loading,
        setItems: (state, tree) => state.items = tree,
        setActiveId: (state, activeId) => state.activeId = activeId,
        setType: (state, type) => state.type = type,
        setRootItem: (state, rootItem) => state.rootItem = rootItem
    },
    actions: {
        fetch(context, {type, activeId}) {

            context.commit("setLoading", true);
            context.commit("setType", type);

            return retryRequest(() => platform.get(`/api/v2/groupingList/${type}`))
                .then(response => {

                    if (true === CATALOG_ROOT_CATEGORY) {

                        context.commit("setRootItem", response.data.results.groupings[0]);
                        context.commit("setItems", response.data.results.groupings[0].children);
                    } else {

                        context.commit("setItems", response.data.results.groupings);
                    }

                    if (activeId) {

                        context.commit("setActiveId", activeId);
                    }

                    return {
                        activeId: context.getters["activeId"],
                        items: context.getters["items"]
                    }
                })
                .finally(() => context.commit("setLoading", false))
        },
        setActiveId: (context, activeId) => context.commit("setActiveId", activeId)
    }
}