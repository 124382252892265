export default {
    disclaimer: {
        label_default: "Tudomásul veszem és elfogadom, hogy az általam megrendelt/kapott tételek O-I felhasználandó ingatlan O-I munkával kapcsolatos tevékenységek, és mint ilyenek, O-I fenntartja a jogot, hogy kérje az ingatlan visszaszolgáltatását, amikor kilépek O-I-ből (vagy korábban, ha a O-I megköveteli).",
        label_type_1: "Megerősítem, hogy az otthoni feladatok önértékelése pontosan és jóhiszeműen megtörtént, és minden felszerelést vagy bútort O-I helyen, vagy a Gear-en keresztül megrendelt, felhasználom a O-I-vel végzett munkám céljaira. Tudomásul veszem és elfogadom, hogy az általam megrendelt/kapott tételek O-I felhasználandó ingatlan O-I munkával kapcsolatos tevékenységek, és mint ilyenek, O-I fenntartja a jogot, hogy kérje az ingatlan visszaszolgáltatását, amikor kilépek O-I-ből (vagy korábban, ha a O-I megköveteli).",
        errors: {
            required: "Kérjük, fogadja el a használati feltételeket"
        }
    },
    cost_center: {
        label: "Költséghelyi kód",
        placeholder: "Költséghelyi kód",
        cost_center_not_found: "A nem jelen lévő költséghelyek megjelenése akár 24 órát is igénybe vehet. Ha nem látja a megfelelő költséghelyet, forduljon a Global Service Deskhez.",
        errors: {
            regex: "Érvénytelen költséghelyi kód"
        }
    },
    company_code: {
        label: "Cég kód"
    },
    cost_center_name: {
        label: "Költséghely neve"
    },
    order_note: {
        label: "Rendelési megjegyzés",
        placeholder: "Opcionális megjegyzés a vezetőnek jóváhagyásra",
    }
}