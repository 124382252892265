export default {
    title: "Commande au nom de",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Veuillez remplir l'e-mail de l'utilisateur pour commander au nom de",
            errors: {
                myself: "L'e-mail doit être différent de votre"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Soumettre",
            processing: "Soumission"
        }
    },
    error: {
        userNotExists: "{email} n'existe pas"
    }
}