import partnerMessages from "@i18n_messages_en-US"

import components from "./components"
import views from "./views"
import db from "./db";
import navbar from "./navbar";
import navigation from "./navigation";
import breadcrumbs from "./breadcrumbs";
import orders from "./orders";
import widget from "./widget";
import quiz from "./quiz";
import modal from "./modal";
import errors from "./errors";
import products from "./products";
import checkout_config from "./checkout_config";
import approval_config from "./approval_config";
import catalog from "./catalog";
import footer from "./footer";

export default {
    components,
    db,
    navbar,
    views,
    navigation,
    breadcrumbs,
    orders,
    widget,
    quiz,
    modal,
    errors,
    products,
    checkout_config,
    approval_config,
    catalog,
    footer,

    networkIssuesHeader: "Problémy s připojením ke službám Gear",
    networkIssues: "Omlouváme se, máme problémy s připojením ke službám DXC Gear. Než budete pokračovat, zkontrolujte, zda jste odpojeni od sítě VPN."
};