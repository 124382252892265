export default {
    400: {
        title: "Solicitud incorrecta",
        header: "@:views.errors.400.title",
        html: "Solicitud incorrecta. Por favor, inténtelo de nuevo más tarde."
    },
    401: {
        title: "Hemos encontrado un error al iniciar sesión",
        header: "@:views.errors.401.title",
        html: "Póngase en contacto <a href='{email}' target='_blank'>con el soporte</a> ."
    },
    404: {
        title: "Página no encontrada",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Acceso no permitido",
        header: "Acceso no permitido",

        contact: "O-I El periférico solo está disponible para los empleados. Si es un empleado y ve este mensaje, trabaje con su gente y cultura local para actualizar su estado.",

        subject: "Equipo - Elegibilidad",

        html: "<p class='text-left'>@:views.errors.403.eligibility @:views.errors.403.contact</p>",
        error: {
            disabled: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Actualmente no eres elegible. Póngase en contacto con <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Soporte</a> para obtener más información.</p>"
            },
            "not-active": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Actualmente no eres elegible. Trabaje con su gente y cultura local para obtener más información.</p>"
            },
            "not-allowed":{
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I El periférico solo está disponible para los empleados. Si es un empleado y ve este mensaje, trabaje con su gente y cultura local para actualizar su estado.</p>"
            },
            "no-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>No tiene un administrador activo en su perfil de ServiceNow. Trabaje con su representante local de Gente y Cultura para actualizar a su gerente.</p>"
            },
            "self-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Su administrador está configurado como usted en su perfil de ServiceNow. Trabaje con su representante local de Gente y Cultura para actualizar a su gerente.</p>"
            },
            "no-location": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>No tiene una ubicación en su perfil de ServiceNow. Trabaje con su representante local de People &amp; Culture para actualizar su ubicación.</p>"
            },
            eligibility: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I El periférico solo está disponible para los empleados. Si es un empleado y ve este mensaje, trabaje con su gente y cultura local para actualizar su estado.</p>",
            },
            "eligibility-location": {
                header: "Parece que llegas un poco temprano...",
                html: "<p class='text-left'>Gracias por su interés en O-I Periférico. Parece que ha llegado al sitio antes de ser habilitado e invitado directamente. O-I El periférico se está implementando en etapas, país por país y por grupos de usuarios. Cuando esté activado, recibirá un correo electrónico que contiene una invitación personal con instrucciones.</p><p class='text-left'> Haga clic en <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Soporte</a> para preguntas o problemas con su pedido o el sitio.</p>",
            },
            "secondary-account": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Actualmente está intentando acceder a O-I Periférico en una cuenta secundaria. Intente iniciar sesión de nuevo con su cuenta principal.</p>"
            },
            "empty-core-id": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Su cuenta de ServiceNow no tiene un nombre de cuenta. Trabaje con su representante local de People &amp; Culture para actualizar su cuenta.</p>"
            },
            country: {
                header: "@:views.errors.403.header",
                html: "@:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "no-permissions": {
                header: "Sin acceso",
                subject: "Sin acceso",
                html: "No tienes permisos para abrir esta página.",
            },
            "virtual-denied": {
                header: "Parece que llegas un poco temprano...",
                html: "<p class='text-left'>Gracias por su interés en O-I Periférico. Parece que ha llegado al sitio antes de ser habilitado e invitado directamente. O-I El periférico se está implementando en etapas, país por país y por grupos de usuarios. Cuando esté activado, recibirá un correo electrónico que contiene una invitación personal con instrucciones.</p><p class='text-left'> Haga clic en <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Soporte</a> para preguntas o problemas con su pedido o el sitio.</p>",
            }
        }
    },
    500: {
        title: "Error inesperado",
        header: "@:views.errors.500.title",
        message: "Ocurrió un error. Por favor, inténtelo de nuevo más tarde."
    },
    code: {
        title: "Error inesperado",
        message: "@:views.errors.code.title",
        error: "Error {code}."
    }
}