<template>
    <div class="text-center small" v-html="t('text', widget_optionsModels)"></div>
</template>

<script>

    import mixin_widget from "@mixins_widget";

    export default {
        name: "OrdersQuestion",
        mixins: [mixin_widget],
        data() {

            return {
                t_path: "components.orders.question",
            }
        }
    }
</script>