export default {
    400: {
        title: "Pedido ruim",
        header: "@:views.errors.400.title",
        html: "Pedido ruim. Por favor, tente novamente mais tarde."
    },
    401: {
        title: "Encontramos um erro no login",
        header: "@:views.errors.401.title",
        html: "Entre em contato com <a href='{email}' target='_blank'>o suporte</a> ."
    },
    404: {
        title: "Página não encontrada",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Acesso não permitido",
        header: "Acesso não permitido",

        contact: "O-I Periférico está disponível apenas para funcionários. Se você é um funcionário e vê esta mensagem, trabalhe com seu pessoal e cultura local para atualizar seu status.",

        subject: "Equipamento - Elegibilidade",

        html: "<p class='text-left'>@:views.errors.403.eligibility @:views.errors.403.contact</p>",
        error: {
            disabled: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>No momento, você não está qualificado. Entre em contato com <a href='#' role='button' data-widget-trigger='{widgetOptions}'>o Suporte</a> para obter mais informações.</p>"
            },
            "not-active": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>No momento, você não está qualificado. Por favor, trabalhe com o seu Povo e Cultura local para mais informações.</p>"
            },
            "not-allowed":{
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I Periférico está disponível apenas para funcionários. Se você é um funcionário e vê esta mensagem, trabalhe com seu pessoal e cultura local para atualizar seu status.</p>"
            },
            "no-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Você não tem um gerente ativo listado em seu perfil da ServiceNow. Trabalhe com seu representante local de Pessoas e Cultura para atualizar seu gerente.</p>"
            },
            "self-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Seu gerente é definido como você em seu perfil da ServiceNow. Trabalhe com seu representante local de Pessoas e Cultura para atualizar seu gerente.</p>"
            },
            "no-location": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Você não tem um local listado em seu perfil da ServiceNow. Trabalhe com seu representante local de Pessoas e Cultura para atualizar sua localização.</p>"
            },
            eligibility: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I Periférico está disponível apenas para funcionários. Se você é um funcionário e vê esta mensagem, por favor, trabalhe com seu pessoal e cultura local para atualizar seu status.</p>",
            },
            "eligibility-location": {
                header: "Parece que você está um pouco adiantado...",
                html: "<p class='text-left'>Obrigado pelo seu interesse em O-I Periférico. Parece que você acessou o site antes de ser habilitado e convidado diretamente. O-I O Peripheral está sendo implantado em etapas, país por país e por grupos de usuários. Quando você estiver ativado, receberá um e-mail contendo um convite pessoal com instruções.</p><p class='text-left'> Clique em <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Suporte</a> para dúvidas ou problemas com seu pedido ou site.</p>",
            },
            "secondary-account": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Você está tentando acessar O-I Periférico em uma conta secundária. Tente fazer login novamente com sua conta principal.</p>"
            },
            "empty-core-id": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Sua conta ServiceNow não tem um nome de conta. Trabalhe com seu representante local de Pessoas e Cultura para atualizar sua conta.</p>"
            },
            country: {
                header: "@:views.errors.403.header",
                html: "@:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "no-permissions": {
                header: "Sem acesso",
                subject: "Sem acesso",
                html: "Você não tem permissão para abrir esta página.",
            },
            "virtual-denied": {
                header: "Parece que você está um pouco adiantado...",
                html: "<p class='text-left'>Obrigado pelo seu interesse em O-I Periférico. Parece que você acessou o site antes de ser habilitado e convidado diretamente. O-I O Peripheral está sendo implantado em etapas, país por país e por grupos de usuários. Quando você estiver ativado, receberá um e-mail contendo um convite pessoal com instruções.</p><p class='text-left'> Clique em <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Suporte</a> para dúvidas ou problemas com seu pedido ou site.</p>",
            }
        }
    },
    500: {
        title: "Erro inesperado",
        header: "@:views.errors.500.title",
        message: "Um erro ocorreu. Por favor, tente novamente mais tarde."
    },
    code: {
        title: "Erro inesperado",
        message: "@:views.errors.code.title",
        error: "Erro {code}."
    }
}