import mixin_constraints from "@/mixins/constraints";

export default {
    mixins: [mixin_constraints],
    methods: {
        cart_add(item) {

            this.$store.dispatch("user/cart/addItem", item).then(() => {

                if (!this.constraints_hasAnyAvailableProducts()) {

                    this.$router.push({name: "order.submit"});
                }
            });
        },
        cart_remove(item) {

            this.$store.dispatch("user/cart/removeItem", item);
        },
        cart_setAmount(item, amount) {

            this.$store.dispatch("user/cart/setItemAmount", {
                item,
                amount: parseInt(amount)
            });
        },
        cart_find(target) {

            const id = this.$store.getters["user/cart/id"];

            return this.$store
                .getters["user/cart/items"]
                .find(source => source.item[id] == target[id])
        }
    }
}