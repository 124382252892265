export default {
    title: "Ankieta portalu",
    header: "@:views.survey.customer.title",
    model: {
        service_rating: {
            label: "W skali od 1 do 10, gdzie 1 oznacza bardzo niezadowolony, a 10 bardzo zadowolony, jak oceniłbyś tę usługę?",
        },
        service_likes: {
            label: "Co ci się podobało?",
            placeholder: ""
        },
        service_improvements: {
            label: "Co można poprawić?",
            placeholder: ""
        },
        service_easy_to_follow: {
            label: "W skali od 1 do 10, gdzie 1 oznacza wcale niejasne, a 10 łatwe do zrozumienia, jak oceniasz komunikację dotyczącą tej witryny?"
        },
        item: {
            setup_rating: {
                label: "W skali od 1 do 10, gdzie 1 oznacza bardzo trudne, a 10 bardzo łatwe, jak trudna była instalacja i konfiguracja \"{name}\"?",
            },
            comments: {
                label: "Proszę podać jakieś komentarze na temat {name}?",
                placeholder: ""
            }
        }
    },
    disclaimer: "Informujemy, że odpowiedzi na ankietę zostaną udostępnione O-I i możemy skontaktować się z Tobą w sprawie Twojej opinii.",
    button: {
        submit: {
            cta: "Składać",
            processing: "Przedkładający"
        }
    }
}