export default {
    title: "Bejelentkezés ellenőrzése szükséges",
    header: "@:views.auth.confirm.title",
    message: "A folytatáshoz ellenőrizze a vállalati e-mail-címét, hogy megtalálja-e az e-mail-ellenőrző linket. A folytatáshoz kattintson az e-mailben található linkre.",
    messageBeforeCta: "Ha nem látja az e-mailt, kattintson a gombra",
    messageAfterCta: "újraküldeni.",
    secondMessage: "Ha továbbra sem látja az e-mailt, ellenőrizze még egyszer a spam mappát, vagy lépjen kapcsolatba velünk a következő címen <a href='mailto:{email}?subject={subject}'>: {email}</a> .",
    subject: "Gear - TFA",
    buttons: {
        cta: "itt",
        processing: "itt"
    },
    headerAlreadyConfirmed: "Bejelentkezés ellenőrzése",
    messageAlreadyConfirmed: "Már megerősítette az e-mail-címét."
}