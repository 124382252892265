<template>
    <div class="auth-login">
        <div class="auth-banner">
            <div class="container">
                <div class="row">
                    <div class="col-12 d-flex">
                        <div class="align-self-center">
                            <div class="auth-banner-header h1">
                                {{t('banner.header')}}
                            </div>
                            <div class="auth-banner-message">
                                {{t('banner.message')}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="container">
        <div class="row">
            <div class="col-12 offset-lg-2 col-lg-8 pt-5">
                <p class="text-center">{{t('message')}}</p>

                <div class="form-check text-left py-3">
                    <input type="checkbox" id="loginDisclaimer" class="form-check-input" v-model="model.disclaimer">

                    <label for="loginDisclaimer" class="form-check-label"
                           v-html="t('disclaimer', {privacyPolicyUrl: privacyPolicyUrl})"></label>
                </div>

                <div class="text-center">
                    <button type="button" class="btn btn-primary"
                            :disabled="!model.disclaimer||healtcheck||processing"
                            @click="login">
                        {{$t('navigation.login')}}
                        <spinner v-if="processing"/>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin_view from "@/mixins/view";
    import mixin_auth from "@mixins_auth";

    import PageMessage from "@/components/page/PageMessage";
    import Spinner from "@/components/Spinner";

    const MESSAGE_PROCESS_LOGIN = "process_login";

    export default {
        name: "ViewsAuthLogin",
        components: {
            Spinner,
            PageMessage
        },
        mixins: [mixin_view, mixin_auth],
        data() {

            return {
                t_path: "views.auth.login",

                healtcheck: false,

                model: {
                    disclaimer: false
                }
            }
        },
        computed: {
            privacyPolicyUrl() {

                return process.env.VUE_APP_PRIVACY_LINK
                    ? process.env.VUE_APP_PRIVACY_LINK
                    : this.$router.resolve({name: "privacy"}).fullPath;
            }
        },
        methods: {
            processLogin() {

                const emailVerifiedUrl = `${window.location.origin}${this.$router.resolve({name: "home"}).fullPath}`;
                const url = `${this.$platform.defaults.baseURL}/api/v2/sso/login?RelayState=${encodeURIComponent(emailVerifiedUrl)}`;

                window.open(url);
            },
            login() {

                this.healtcheck = true;

                // Check for API is accessible and only after it allow login process
                return this.$platform.get('/', {timeout: 10000})
                    .then(() => window.postMessage(MESSAGE_PROCESS_LOGIN))
                    .catch(error => {

                        if (error.isAxiosError && "undefined" === typeof error.response) {

                            return this.$toast.error(this.$t(`networkIssues`), {
                                timeout: 10000
                            });
                        }

                        return this.$toast.error(error);
                    })
                    .finally(() => this.healtcheck = false);
            },
            listenMessage(event) {

                if (event.data) {

                    if (this.auth_check(event.data) && event.data.token && event.data.userId) {

                        this.auth_prepare(event.data);

                        this.processing = true;

                        this.auth_authenticate(
                            event.data.token,
                            event.data.userId,
                            event.data.stylistTags ? event.data.stylistTags.map(t => t.name) : [],
                            this.auth_permissions(event.data),
                            event.data.requireAcknowledgement,
                            event.data.expirationTime
                        );
                    } else if (MESSAGE_PROCESS_LOGIN == event.data) {

                        this.processLogin();
                    }
                }
            }
        },
        mounted() {

            window.addEventListener("message", this.listenMessage);
        },
        unmounted() {

            window.removeEventListener("message", this.listenMessage);
        }
    }
</script>