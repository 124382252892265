export default {
    header: "Adresse bestätigen und Bestellung absenden",
    message: "Ist Ihre unten aufgeführte Lieferadresse korrekt?",
    tip: "Klicken Sie auf <b>JA</b> , um die Lieferadresse zu bestätigen und die Bestellung abzusenden. Klicken Sie auf <b>NEIN</b> , um die Übermittlung abzubrechen und eine andere Adresse auszuwählen.",
    info: "Sobald eine Bestellung aufgegeben wurde, kann sie nicht mehr geändert werden. Wenn das von Ihnen bestellte Produkt nachbestellt ist oder ein späteres Versanddatum hat und Sie einen Umzug planen, berücksichtigen Sie dies bitte bei der Auswahl der Lieferadresse. Wenn ein bevorstehender Feiertag oder Urlaub ansteht und Sie das Produkt nicht zu Hause annehmen können, bitten wir Sie, Bestellungen aufzugeben, bis Sie zu Hause sind, um die Produkte zu erhalten.",
    warning: {
        message: "Bitte überprüfen Sie, ob Ihre {fields} korrekt ist, da das Versandunternehmen möglicherweise nicht in der Lage ist, Ihre Bestellung zu liefern oder Sie bezüglich Ihrer Bestellung zu kontaktieren. | Bitte überprüfen Sie, ob Ihre {fields} korrekt sind, da das Versandunternehmen möglicherweise nicht in der Lage ist, Ihre Bestellung zu liefern oder Sie bezüglich Ihrer Bestellung zu kontaktieren.",
        name: "Name",
        street: "Straße",
        phone: "Telefon"
    },
    buttons: {
        yes: "Ja",
        no: "NEIN"
    }
}