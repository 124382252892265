export default {
    user: {
        create: {
            title: "Nuovo utente",
            model: {
                email: {
                    label: "E-mail",
                    placeholder: "Compila l'e-mail"
                },
                name: {
                    label: "Nome",
                    placeholder: "Compila il nome"
                },
                country: {
                    label: "Paese",
                    placeholder: "Compila il paese"
                }
            },
            cta: {
                submit: {
                    text: "Invia",
                    processing: "Invio"
                },
                cancel: {
                    text: "Annulla"
                }
            }
        }
    }
}