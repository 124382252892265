export default {
    user: {
        create: {
            title: "Új felhasználó",
            model: {
                email: {
                    label: "Email",
                    placeholder: "Töltse ki az e-mailt"
                },
                name: {
                    label: "Név",
                    placeholder: "Töltse ki a nevet"
                },
                country: {
                    label: "Ország",
                    placeholder: "Töltse ki az országot"
                }
            },
            cta: {
                submit: {
                    text: "Beküldés",
                    processing: "Beküldés"
                },
                cancel: {
                    text: "Megszünteti"
                }
            }
        }
    }
}