export default {
    model: {
        email: {
            label: "E-mail",
            placeholder: "E-mail del delegato"
        },
        start_on: {
            label: "Data d'inizio",
            placeholder: "Data di inizio della delegazione"
        },
        end_on: {
            label: "Data di fine",
            placeholder: "Opzionale"
        },
        accept: {
            tip: "Delegando la tua responsabilità di approvazione, certifichi di assumerti la responsabilità per qualsiasi ordine approvato dal tuo delegato.",
            label: "Accetto"
        }
    },
    buttons: {
        submit: {
            cta: "Confermare",
            processing: "Salvataggio"
        },
        close: {
            cta: "Annulla"
        }
    },
    saved: "Salvato"
}