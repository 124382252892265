export default {
    error: "Probléma lépett fel a rendelések lekérésekor. Kérem frissítse a böngészőjét.",
    title: "Rendelési előzmények",
    loadMore: "Load More",
    noOrders: "Nincs rendelés",
    user: {
        loadMore: "Load More",
        product: {
            title: "Rendelés",
            tracking: "Követés",
            quantity: "Mennyiség",
            reason: "Ok",
            control: {
                cancel: {
                    cta: "Kattintson a visszavonáshoz",
                    tooltip: "Kattintson az elem törléséhez",
                    message: "Megrendelését töröltük."
                },
                received: {
                    cta: "Termék átvett",
                    tooltip: "Kattintson a kézbesítés megerősítéséhez",
                    message: "Köszönjük, hogy megerősítette, hogy megkapta termékét."
                },
                notReceived: {
                    cta: "A termék nem érkezett meg",
                    tooltip: "Kattintson a nem kézbesítés megerősítéséhez"
                },
                hide: {
                    cta: "Elem elrejtése",
                    tooltip: "Kattintson az elem elrejtéséhez a listából"
                }
            }
        }
    }
}