export default {
    error: "检索订单时出现问题。请刷新您的浏览器。",
    title: "订单历史记录",
    loadMore: "装载更多",
    noOrders: "没有订单",
    user: {
        loadMore: "装载更多",
        product: {
            title: "命令",
            tracking: "追踪",
            quantity: "数量",
            reason: "原因",
            control: {
                cancel: {
                    cta: "点击取消",
                    tooltip: "点击取消该项目",
                    message: "我们已取消您的订单。"
                },
                received: {
                    cta: "产品已收到",
                    tooltip: "点击确认发货",
                    message: "感谢您确认收到产品。"
                },
                notReceived: {
                    cta: "未收到产品",
                    tooltip: "点击确认未送达"
                },
                hide: {
                    cta: "隐藏项目",
                    tooltip: "单击以隐藏列表中的项目"
                }
            }
        }
    }
}