<template>
    <div v-if="showBanners" class="banners">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div v-for="banner in banners" :key="banner.id" class="banner">
                        {{banner.title}}
                        <router-link :to="banner.to" class="link">{{banner.cta}}</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex"

    import {types} from "@/store/user/surveys";

    export default {
        name: "Banners",
        data() {

            return {
                t_path: "components.banners"
            };
        },
        computed: {
            ...mapGetters({
                items: "user/surveys/items"
            }),
            showBanners() {


                return 0 < this.banners.length && -1 === ["auth.sso"].indexOf(this.$route.name);
            },
            surveys() {

                return this.$store
                    .getters["user/surveys/items"]
                    .filter(
                        item => !(
                            -1 !== ["approvals.delegate", "approvals"].indexOf(this.$route.name)
                            && item.survey_type === types.pending_approvals
                        )
                    )
                    .map(item => {

                        const title = this.te(item.data.banner_title)
                            ? this.t(item.data.banner_title)
                            : item.data.banner_title;
                        const id = item._id ? item._id : Math.random();

                        if (item.survey_type === types.pending_approvals) {

                            // Do not show banner for impersonated users
                            return true === this.isImpersonated ? null : {
                                id,
                                title,
                                to: {name: "approvals"},
                                cta: this.t("approve")
                            }
                        }

                        return {
                            id,
                            title,
                            to: {name: "surveys.view", params: {survey_id: item._id}},
                            cta: this.t("open")
                        };
                    })
                    .filter(survey => null !== survey);
            },
            isImpersonated() {

                return null !== this.$store.getters["user/impersonatorToken"];
            },
            impersonatorBanner() {

                return true === this.isImpersonated
                    ? {
                        id: this.$store.getters["user/impersonatorToken"],
                        title: this.t("impersonation.title", {name: this.$store.getters["user/profile/firstName"]}),
                        to: {
                            name: "auth.logout",
                            query: {
                                switch: "true"
                            }
                        },
                        cta: this.t("impersonation.cta")
                    }
                    : null
            },
            banners() {

                const result = [];

                if (null !== this.impersonatorBanner) {

                    result.push(this.impersonatorBanner);
                }

                this.surveys.forEach(survey => result.push(survey));

                return result;
            }
        }
    }
</script>