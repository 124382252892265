export default {
    return: {
        title: "Visszatérés",
        header: "Visszaküldendő tételek",
        message: "Az alábbi vállalati berendezéseket vissza kell küldeni. Az űrlap elküldése után külön felvesszük Önnel a kapcsolatot az átvétel és/vagy a szállítás egyeztetése érdekében. Vezetőjét tájékoztatják a visszatérés állapotáról.",

        model: {
            address: {
                country: {
                    label: "Ország"
                }
            },
            exclude: {
                label: "Kizárni a visszaküldésből"
            },
            textarea: {
                placeholder: "Oka: vezetői jóváhagyás, korábban visszaküldve stb."
            },
            return_note: {
                label: "Jegyzetek visszaküldése",
                placeholder: "Nem kötelező: Írja be a visszaküldendő tételekkel kapcsolatos megjegyzéseket."
            },
            additional_disposal: {
                label: "Vannak olyan fizikai DXC-rekordjai, amelyeket biztonságosan meg kell semmisíteni?"
            },
            additional_item: {
                type: {
                    label: "típus",
                    options: {
                        "chair": "Szék",
                        "desk": "Asztal",
                        "desk riser": "Íróasztal emelő",
                        "docking station": "Dokkoló",
                        "footrest": "Lábtartó",
                        "headset": "Fejhallgató",
                        "hdmi cable": "HDMI kábel",
                        "keyboard": "Billentyűzet",
                        "kvm switch": "KVM kapcsoló",
                        "lamp": "Lámpa",
                        "laptop riser": "Laptop emelő",
                        "monitor": "Monitor",
                        "mouse": "Egér",
                        "other": "Egyéb",
                        "speakerphone": "Kihangosító",
                        "usb hub": "USB Hub",
                        "webcam": "Webkamera",
                    }
                },
                detail: {
                    label: "Részlet",
                    placeholder: "Részlet"
                },
                quantity: {
                    label: "Mennyiség",
                    placeholder: "Mennyiség"
                }
            }
        },

        submitted: {
            title: "Visszaküldve",
            message: "Visszaküldése megtörtént. Az áru átvételével és/vagy szállításával kapcsolatban külön felvesszük Önnel a kapcsolatot."
        },
        nothingToReturn: {
            title: "Visszaküldési információ",
            message: "Jelenleg nincs visszaküldhető tétele a Gear-en belül."
        },
        notEligible: {
            title: "Visszaküldési információ",
            message: "Jelenleg a Gear nem tudja feldolgozni a visszaküldést. Kérjük, vegye fel a kapcsolatot a helyi vezetővel vagy a HR csapattal a tételek visszaküldésével kapcsolatban."
        },

        button: {
            add: {
                cta: "Nem listázott elem hozzáadása"
            },
            submit: {
                processing: "Beküldés",
                cta: "Beküldés"
            }
        }
    }
}