export default {
    login: "Acceso",
    logout: "Cerrar sesión",
    home: "Hogar",
    user: {
        profile: {
            edit: "Editar perfil"
        }
    },
    approvals: {
        delegates: "Delegados"
    },
    impersonation: "Orden en nombre de"
}