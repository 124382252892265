export default {
    error: "There was an issue retrieving orders. Please refresh your browser.",
    title: "Orders history",
    loadMore: "Load More",
    noOrders: "No Orders",
    user: {
        loadMore: "Load More",
        product: {
            title: "Order",
            tracking: "Tracking",
            quantity: "Quantity",
            reason: "Reason",
            control: {
                cancel: {
                    cta: "Click&nbsp;to&nbsp;cancel",
                    tooltip: "Click to cancel this item",
                    message: "We have cancelled your order."
                },
                received: {
                    cta: "Mark as received",
                    tooltip: "Click to confirm delivery",
                    message: "Thank you for confirming that you received your product."
                },
                notReceived: {
                    cta: "Mark as not received",
                    tooltip: "Click to confirm non-delivery"
                },
                hide: {
                    cta: "Hide item",
                    tooltip: "Click to hide item from the list"
                }
            }
        }
    }
}