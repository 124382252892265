export default {
    title: "Artículos no entregados",
    header: "@:views.survey.deliveryfaq.title",
    result: {
        header: "Gracias",
        message: "Gracias por su respuesta."
    },
    message: "Ha indicado que no ha recibido su artículo a pesar de que el mensajero lo marcó como entregado.",
    cta: "Siga estos pasos para ayudarnos a solucionar el problema:",
    items: {
        1: {
            message: "Haga clic en el enlace de seguimiento:"
        },
        2: {
            message: "Revisa el estado de la entrega. Comprobar:",
            items: {
                1: "Quién firmó por el paquete",
                2: "Si fue devuelto al remitente",
                3: "Si el paquete está esperando a ser recogido"
            }
        },
        3: {
            message: "Compruebe si hay un aviso de entrega que indique dónde se puede haber dejado el artículo."
        },
        4: {
            message: "Consulte con los vecinos, familiares y amigos que puedan haber tomado el paquete."
        },
        5: {
            message: "Verifique los lugares comunes donde los mensajeros dejan artículos en su ausencia."
        }
    },
    question: "¿Ha ayudado esto a resolver su consulta de entrega? ¿Tiene ahora el artículo o sabe quién lo tiene?",
    buttons: {
        yes: "Sí",
        no: "No",
        saving: "Ahorro"
    }
}