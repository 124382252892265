export default {
    title: "Revisión del pedido",
    header: "@:views.order.submitted.title",

    noItems: "No hay productos seleccionados",

    model: {
        addressId: {
            errors: {
                required: "Por favor complete la dirección",
                noAddress: "No hay dirección disponible. Por favor contacte al soporte."
            }
        },
        product: {
            placeholder: "Por favor complete la cantidad"
        }
    },

    createNewAddress: "Crear nueva dirección",

    button: {
        submit: {
            cta: "Orden de envio",
            processing: "Sumisión"
        },
        remove: "Eliminar del pedido"
    },

    errors: {
        itemNotExist: "Elemento «{name}» No existe en el catálogo. Será eliminado.",
        requestError: "Hubo un problema al enviar su pedido. Por favor intenta enviar de nuevo."
    }
}