export default {
    title: "Pomoc techniczna",
    header: "Pomoc techniczna",
    model: {
        email: {
            label: "Adres e-mail użytkownika",
            placeholder: "Podaj adres e-mail użytkownika"
        }
    },
    cta: "Składać",
    processing: "Ładowanie"
}