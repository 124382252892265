export default {
    disclaimer: {
        label_default: "Je reconnais et accepte que les articles que je commande/reçois sont O-I propriété à utiliser pour O-I activités liées au travail, et à ce titre, O-I se réserve le droit de demander la restitution du bien à ma sortie de O-I (ou plus tôt, si requis par O-I).",
        label_type_1: "Je confirme que l'auto-évaluation pour le travail à domicile a été remplie avec précision et de bonne foi, et que tout équipement ou mobilier fourni par O-I emplacement ou commandé via Gear sera utilisé par moi dans le cadre de mon travail avec O-I. Je reconnais et accepte que les articles que je commande/reçois sont O-I propriété à utiliser pour O-I activités liées au travail, et à ce titre, O-I se réserve le droit de demander la restitution du bien à ma sortie de O-I (ou plus tôt, si requis par O-I).",
        errors: {
            required: "Veuillez accepter les conditions d'utilisation"
        }
    },
    cost_center: {
        label: "Code du centre de coûts",
        placeholder: "Code du centre de coûts",
        cost_center_not_found: "Les centres de coûts non présents peuvent mettre jusqu'à 24 heures pour apparaître. Contactez le Global Service Desk si vous ne voyez pas le centre de coûts approprié.",
        errors: {
            regex: "Code de centre de coûts invalide"
        }
    },
    company_code: {
        label: "Code de l'entreprise"
    },
    cost_center_name: {
        label: "Nom du centre de coûts"
    },
    order_note: {
        label: "Bon de commande",
        placeholder: "Note facultative au gestionnaire pour approbation",
    }
}