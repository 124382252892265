const text = {
    countryAfterComponentsBeforeSelection: "请注意，您选择的国家/地区将用作送货地址的一部分，以便将产品直接发送给您。如果您在列表中没有看到您所在的国家/地区，则 Gear 目前在您所在的国家/地区不可用。",
    countryAfterComponentsAllowedSelection: "请注意，您选择的国家/地区将用作送货地址的一部分，以便将产品直接发送给您。",
    countryAfterComponentsLimitedSelection: "装备目前在您所在的国家/地区可用，但正在分阶段开放。除非您收到通知，否则您目前可能无法在您所在的国家/地区访问 Gear。",
    disclaimer: "单击下面的下一个按钮，即表示我承认我是公司员工，并且我同意公司及其合作伙伴 RemoteRetail 存储和处理在本网站上提交的个人信息，以便向我提供所请求的内容。 RemoteRetail 需要我提供的联系信息，以便他们可以与我联系并提供与我的订单相关的信息。第三方供应商也可能会收到我的信息，以便向我运送产品。</p><p>我可以随时取消订阅这些通讯。有关如何取消订阅、隐私惯例以及保护和尊重我的隐私的承诺的更多信息，请查看<a href='{privacyPolicyUrl}' target='_blank'>隐私政策</a>。"
};

export default {
    title: "欢迎，{userName}！",
    description: {
        default: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`,
        virtualworker: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories that you need to work effectively from home. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`
    },
    text: {
        default: "首先，我们会问您一系列问题。",
        virtualworker: "首先，我们将询问您一系列问题，以帮助我们了解您的在家工作空间，从而帮助塑造该计划的未来方向。"
    },
    afterComponents: {
        beforeSelection: `${text.countryAfterComponentsBeforeSelection}`,
        allowedSelection: `${text.countryAfterComponentsAllowedSelection}`,
        limitedSelection: `${text.countryAfterComponentsLimitedSelection}`,
        autoLoginBeforeSelection: `${text.countryAfterComponentsBeforeSelection}<br/><br/>${text.disclaimer}`,
        autoLoginAllowedSelection: `${text.countryAfterComponentsAllowedSelection}<br/><br/>${text.disclaimer}`,
        autoLoginLimitedSelection: `${text.countryAfterComponentsLimitedSelection}<br/><br/>${text.disclaimer}`
    },
    buttons: {
        back: "后退",
        next: "下一个",
        submit: "提交"
    },
    entityinfo: {
        title: "实体信息",
        description: "为了帮助我们正确发送您的订单，请向我们提供以下信息：",
    },
    equipmentsurvey: {
        title: "设备调查",
        description: "下一页将询问您最需要的设备类型，以帮助公司更好地了解优先事项。",
        info: "请注意，所示设备仅供收集信息之用，并不意味着公司将来会提供该设备。"
    },
    workplace: {
        title: "告诉我们更多关于您的家庭办公室的信息吗？",
        description: "为了帮助我们更好地了解您的工作空间，请选择以下选项之一",
    },
    priority: {
        title: "设备优先级",
        description: "请选择您最需要的前两项设备"
    },
    "additional-questions": {
        title: "其他问题"
    },
    "equipment-assessment": {
        title: "设备评估",
        description: "以下一组问题将<b>根据您的回答确定您可以从 Gear 中订购哪些设备。</b>"
    },
    headset: {
        title: "现有设备"
    },
    "office-worker": {
        title: "办公室"
    },
    "keyboard-mouse": {
        title: "键盘和鼠标"
    },
    webcam: {
        title: "摄像头"
    },
    monitor: {
        title: "监视器"
    },
    usbhub: {
        title: "USB集线器"
    },
    "equipment-list": {
        title: "设备",
        init: "初始化设备列表",
        description: {
            loading: "<span class=\"animate-spin icon-spinner\"></span>",
            error: "错误的请求。发生错误。",
            allowed: "根据您的回答，您将有资格获得以下项目：{items}",
            empty: "根据您的回答，您目前没有资格获得 Gear 中的任何物品。"
        },
        text: "<ul class=\"text-left\" style=\"width: 300px; margin: 2rem auto 0\">{list}</ul>",
        afterComponents: "<small>如果您需要进行任何更正，请点击后退按钮更改您的答案。</small>"
    },
    saved: "已保存"
}