const text = {
    countryAfterComponentsBeforeSelection: "Bitte beachten Sie, dass Ihre Länderauswahl als Teil Ihrer Lieferadresse verwendet wird, um Produkte direkt an Sie zu versenden. Wenn Ihr Land nicht in der Liste aufgeführt ist, ist Gear derzeit in Ihrem Land nicht verfügbar.",
    countryAfterComponentsAllowedSelection: "Bitte beachten Sie, dass Ihre Länderauswahl als Teil Ihrer Lieferadresse verwendet wird, um Produkte direkt an Sie zu versenden.",
    countryAfterComponentsLimitedSelection: "Ausrüstung ist derzeit in Ihrem Land verfügbar, die Öffnung erfolgt jedoch schrittweise. Sofern Sie keine Benachrichtigung erhalten haben, können Sie derzeit in Ihrem Land möglicherweise nicht auf Gear zugreifen.",
    disclaimer: "Indem ich unten auf die Schaltfläche „Weiter“ klicke, bestätige ich, dass ich ein Mitarbeiter des Unternehmens bin und erkläre mich damit einverstanden, dass das Unternehmen und sein Partner RemoteRetail die auf dieser Website übermittelten personenbezogenen Daten speichern und verarbeiten, um mir die angeforderten Inhalte bereitzustellen. RemoteRetail benötigt die von mir angegebenen Kontaktinformationen, damit sie mich mit Informationen zu meinen Bestellungen kontaktieren können. Auch Drittanbieter können meine Daten erhalten, um mir Produkte zuzusenden.</p><p> Ich kann diese Mitteilungen jederzeit abbestellen. Weitere Informationen dazu, wie ich mich abmelden kann, zu den Datenschutzpraktiken und zu den Verpflichtungen zum Schutz und zur Achtung meiner Privatsphäre finden Sie in der <a href='{privacyPolicyUrl}' target='_blank'>Datenschutzrichtlinie</a> ."
};

export default {
    title: "Willkommen, {userName}!",
    description: {
        default: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`,
        virtualworker: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories that you need to work effectively from home. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`
    },
    text: {
        default: "Zu Beginn stellen wir Ihnen eine Reihe von Fragen.",
        virtualworker: "Zu Beginn stellen wir Ihnen eine Reihe von Fragen, die uns helfen sollen, Ihren Arbeitsplatz im Homeoffice zu verstehen und so die zukünftige Ausrichtung dieses Programms mitzugestalten."
    },
    afterComponents: {
        beforeSelection: `${text.countryAfterComponentsBeforeSelection}`,
        allowedSelection: `${text.countryAfterComponentsAllowedSelection}`,
        limitedSelection: `${text.countryAfterComponentsLimitedSelection}`,
        autoLoginBeforeSelection: `${text.countryAfterComponentsBeforeSelection}<br/><br/>${text.disclaimer}`,
        autoLoginAllowedSelection: `${text.countryAfterComponentsAllowedSelection}<br/><br/>${text.disclaimer}`,
        autoLoginLimitedSelection: `${text.countryAfterComponentsLimitedSelection}<br/><br/>${text.disclaimer}`
    },
    buttons: {
        back: "Zurück",
        next: "Nächste",
        submit: "Einreichen"
    },
    entityinfo: {
        title: "Entitätsinformationen",
        description: "Um uns dabei zu helfen, Ihre Bestellung ordnungsgemäß weiterzuleiten, geben Sie uns bitte die folgenden Informationen an:",
    },
    equipmentsurvey: {
        title: "Ausrüstungsumfrage",
        description: "Auf der nächsten Seite werden Sie nach der Art der Ausrüstung gefragt, die Sie am meisten benötigen, um dem Unternehmen zu helfen, die Prioritäten besser zu verstehen.",
        info: "Bitte beachten Sie, dass die gezeigte Ausrüstung nur zu Informationszwecken dient und nicht bedeutet, dass das Unternehmen diese Ausrüstung in Zukunft bereitstellen wird."
    },
    workplace: {
        title: "Erzählen Sie uns mehr über Ihr Homeoffice?",
        description: "Damit wir Ihren Arbeitsbereich besser verstehen, wählen Sie bitte eine der folgenden Optionen aus",
    },
    priority: {
        title: "Ausrüstungsprioritäten",
        description: "Bitte wählen Sie die beiden wichtigsten Geräte aus, die Sie am meisten benötigen"
    },
    "additional-questions": {
        title: "Weitere Fragen"
    },
    "equipment-assessment": {
        title: "Ausrüstungsbewertung",
        description: "Anhand der folgenden Fragen wird <b>anhand Ihrer Antworten ermittelt, welche Ausrüstung Sie in Gear bestellen können.</b>"
    },
    headset: {
        title: "Aktuelle Ausrüstung"
    },
    "office-worker": {
        title: "Büro"
    },
    "keyboard-mouse": {
        title: "Tastatur und Maus"
    },
    webcam: {
        title: "Webcam"
    },
    monitor: {
        title: "Monitor"
    },
    usbhub: {
        title: "USB-Hub"
    },
    "equipment-list": {
        title: "Ausrüstung",
        init: "initEquipmentList",
        description: {
            loading: "<span class=\"animate-spin icon-spinner\"></span>",
            error: "Ungültige Anforderung. Ein Fehler ist aufgetreten.",
            allowed: "Basierend auf Ihren Antworten haben Sie Anspruch auf die folgenden Elemente:{items}",
            empty: "Basierend auf Ihren Antworten haben Sie derzeit keinen Anspruch auf Gegenstände in Gear."
        },
        text: "<ul class=\"text-left\" style=\"width: 300px; margin: 2rem auto 0\">{list}</ul>",
        afterComponents: "<small>Wenn Sie Korrekturen vornehmen müssen, klicken Sie bitte auf die Schaltfläche „Zurück“, um Ihre Antworten zu ändern.</small>"
    },
    saved: "Gerettet"
}