export default {
    return: {
        title: "Powrót",
        header: "Przedmioty do zwrotu",
        message: "Następujące wyposażenie Spółki ma zostać zwrócone. Po przesłaniu tego formularza skontaktujemy się z Tobą oddzielnie w celu ustalenia odbioru i/lub wysyłki. Twój kierownik zostanie poinformowany o statusie Twojego zwrotu.",

        model: {
            address: {
                country: {
                    label: "Kraj"
                }
            },
            exclude: {
                label: "Wyklucz ze zwrotu"
            },
            textarea: {
                placeholder: "Powód: zatwierdzenie przez kierownika, wcześniej zwrócony itp."
            },
            return_note: {
                label: "Notatki zwrotne",
                placeholder: "Opcjonalnie: Wprowadź uwagi dotyczące towarów, które mają zostać zwrócone."
            },
            additional_disposal: {
                label: "Czy masz jakieś fizyczne rekordy DXC, które należy bezpiecznie usunąć"
            },
            additional_item: {
                type: {
                    label: "Typ",
                    options: {
                        "chair": "Krzesło",
                        "desk": "Biurko",
                        "desk riser": "Podstawa biurka",
                        "docking station": "Stacja dokująca",
                        "footrest": "Podnóżek",
                        "headset": "Zestaw słuchawkowy",
                        "hdmi cable": "Kabel HDMI",
                        "keyboard": "Klawiatura",
                        "kvm switch": "Przełącznik KVM",
                        "lamp": "Lampa",
                        "laptop riser": "Podnośnik do laptopa",
                        "monitor": "Monitor",
                        "mouse": "Mysz",
                        "other": "Inny",
                        "speakerphone": "Zestaw głośnomówiący",
                        "usb hub": "Rozdzielacz USB",
                        "webcam": "Kamerka internetowa",
                    }
                },
                detail: {
                    label: "Szczegół",
                    placeholder: "Szczegół"
                },
                quantity: {
                    label: "Ilość",
                    placeholder: "Ilość"
                }
            }
        },

        submitted: {
            title: "Zwrot przesłany",
            message: "Twój zwrot został przesłany. Skontaktujemy się z Tobą oddzielnie w sprawie odbioru i/lub wysyłki przedmiotu."
        },
        nothingToReturn: {
            title: "Informacje zwrotne",
            message: "Obecnie w Gear nie masz żadnych przedmiotów do zwrotu."
        },
        notEligible: {
            title: "Informacje zwrotne",
            message: "W tej chwili Gear nie może przetworzyć Twojego zwrotu. Skontaktuj się z lokalnym kierownikiem lub zespołem HR, aby dowiedzieć się, jak zwrócić produkty."
        },

        button: {
            add: {
                cta: "Dodaj przedmiot niepubliczny"
            },
            submit: {
                processing: "Przedkładający",
                cta: "Składać"
            }
        }
    }
}