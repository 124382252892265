export default {
    title: "Verifica di accesso richiesta",
    header: "@:views.auth.confirm.title",
    message: "Per continuare, controlla l'e-mail della tua azienda per un link di verifica dell'e-mail. Fare clic sul collegamento nell'e-mail per procedere.",
    messageBeforeCta: "Se non vedi l'email, clicca",
    messageAfterCta: "inviare nuovamente.",
    secondMessage: "Se continui a non vedere l'e-mail, ricontrolla la cartella dello spam o contattaci all'indirizzo <a href='mailto:{email}?subject={subject}'>{email}</a> .",
    subject: "Ingranaggio - TFA",
    buttons: {
        cta: "Qui",
        processing: "Qui"
    },
    headerAlreadyConfirmed: "Verifica dell'accesso",
    messageAlreadyConfirmed: "Hai già confermato la tua email."
}