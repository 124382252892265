export default {
    disclaimer: {
        label_default: "I acknowledge and agree that the items I am ordering / receiving are O-I property to be used for O-I work related activities, and as such, O-I reserves the right to request return of the property upon my exit from O-I (or sooner, if required by O-I).",
        label_type_1: "I confirm that the self-assessment for homeworking was completed accurately and in good faith, and any equipment or furniture provided from O-I location or ordered through Gear will be used by me for the purpose of my work with O-I. I acknowledge and agree that the items I am ordering / receiving are O-I property to be used for O-I work related activities, and as such, O-I reserves the right to request return of the property upon my exit from O-I (or sooner, if required by O-I).",
        errors: {
            required: "Please accept terms of use"
        }
    },
    cost_center: {
        label: "Cost Center Code",
        placeholder: "Cost Center Code",
        cost_center_not_found: "Costs centers not present can take up to 24 hours to appear.  Contact the  Global Service Desk if you do not see the appropriate cost center.",
        errors: {
            regex: "Invalid cost center code"
        }
    },
    company_code: {
        label: "Company Code"
    },
    cost_center_name: {
        label: "Cost Center Name"
    },
    order_note: {
        label: "Order Note",
        placeholder: "Optional note to manager for approval",
    }
}