import auth from "./auth";
import errors from "./errors";
import address from "./address";
import order from "./order";
import survey from "./survey";
import home from "./home";
import products from "./products"
import catalog from "./catalog"
import quiz from "./quiz";
import tunnel from "./tunnel";
import surveys from "./surveys";
import _return from "./return";
import approval from "./approval"
import acknowledgement from "./acknowledgement"
import privacy from "./privacy"
import terms from "./terms"
import helpdesk from "./helpdesk"
import impersonation from "./impersonation"

export default {
    auth,
    errors,
    address,
    order,
    survey,
    home,
    products,
    catalog,
    quiz,
    tunnel,
    surveys,
    return: _return,
    approval,
    acknowledgement,
    privacy,
    terms,
    helpdesk,
    impersonation,

    maintenance: {
        header: "We will be back soon!",
        message: "We are very sorry for the inconvenience but we are performing maintenance.<br/>Please check back soon..."
    }
}