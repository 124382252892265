<template>
    <popper v-if="display" :content="message" offset-distance="2" :hover="true" class="font-weight-normal text-transform-none">
        <span class="products-product-limit text-danger">
            <span class="icon-info-circled "></span>
        </span>
    </popper>
</template>

<script>

    import Popper from "vue3-popper";

    export default {
        name: "ProductsProductLimit",
        components: {
            Popper
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.products.limit",
                state: {
                    display: false
                }
            };
        },
        computed: {
            isLoading() {

                return this.$store.getters["user/orders/loading"] || this.$store.getters["user/constraints/loading"];
            },
            display() {

                return this.isLoading
                    ? this.state.displayOrdered
                    : ("undefined" !== typeof this.value.allowed_to_order && false === this.value.allowed_to_order);
            },
            message() {

                if (1 < this.value.product_type.length) {

                    return this.t("type.default_multiple");
                }

                for (let i = 0; i < this.value.product_type.length; i ++) {

                    if (true === this.te(`type.${this.value.product_type[i]}`)) {

                        return this.t(`type.${this.value.product_type[i]}`);
                    }
                }

                return this.t("type.default");
            }
        },
        watch: {
            isLoading: {
                immediate: true,
                handler(newValue) {

                    if (false === newValue) {

                        this.state.display = this.display;
                    }
                }
            }
        }
    }
</script>