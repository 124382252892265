export default {
    400: {
        title: "Mauvaise demande",
        header: "@:views.errors.400.title",
        html: "Mauvaise demande. Veuillez réessayer plus tard."
    },
    401: {
        title: "Nous avons rencontré une erreur lors de la connexion",
        header: "@:views.errors.401.title",
        html: "Veuillez contacter <a href='{email}' target='_blank'>l'assistance</a> ."
    },
    404: {
        title: "Page non trouvée",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Accès non autorisé",
        header: "Accès non autorisé",

        contact: "O-I Le périphérique n'est disponible que pour les employés. Si vous êtes un employé et que vous voyez ce message, veuillez travailler avec votre People &amp; Culture local pour mettre à jour votre statut.",

        subject: "Équipement - Admissibilité",

        html: "<p class='text-left'>@:views.errors.403.eligibility @:views.errors.403.contact</p>",
        error: {
            disabled: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Vous n'êtes actuellement pas éligible. Veuillez contacter <a href='#' role='button' data-widget-trigger='{widgetOptions}'>l'assistance</a> pour plus d'informations.</p>"
            },
            "not-active": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Vous n'êtes actuellement pas éligible. Veuillez travailler avec votre People &amp; Culture local pour plus d'informations.</p>"
            },
            "not-allowed":{
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I Le périphérique n'est disponible que pour les employés. Si vous êtes un employé et que vous voyez ce message, veuillez travailler avec votre People &amp; Culture local pour mettre à jour votre statut.</p>"
            },
            "no-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Vous n'avez pas de gestionnaire actif répertorié dans votre profil ServiceNow. Veuillez travailler avec votre représentant local People &amp; Culture pour informer votre responsable.</p>"
            },
            "self-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Votre responsable est défini comme vous dans votre profil ServiceNow. Veuillez travailler avec votre représentant local People &amp; Culture pour informer votre responsable.</p>"
            },
            "no-location": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Vous n'avez pas d'emplacement répertorié dans votre profil ServiceNow. Veuillez travailler avec votre représentant local People &amp; Culture pour mettre à jour votre emplacement.</p>"
            },
            eligibility: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I Le périphérique n'est disponible que pour les employés. Si vous êtes un employé et que vous voyez ce message, veuillez travailler avec votre People &amp; Culture local pour mettre à jour votre statut.</p>",
            },
            "eligibility-location": {
                header: "Apparemment tu es un peu en avance...",
                html: "<p class='text-left'>Merci de votre intérêt pour O-I Périphérique. Il semble que vous ayez atteint le site avant d'être activé et invité directement. O-I Les périphériques sont déployés par étapes pays par pays et par groupes d'utilisateurs. Une fois activé, vous recevrez un e-mail contenant une invitation personnelle avec des instructions.</p><p class='text-left'> Cliquez sur <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Support</a> pour des questions ou des problèmes avec votre commande ou le site.</p>",
            },
            "secondary-account": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Vous essayez actuellement d'accéder à O-I Périphérique sur un compte secondaire. Veuillez réessayer de vous connecter avec votre compte principal.</p>"
            },
            "empty-core-id": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Votre compte ServiceNow n'a pas de nom de compte. Veuillez contacter votre représentant People &amp; Culture local pour mettre à jour votre compte.</p>"
            },
            country: {
                header: "@:views.errors.403.header",
                html: "@:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "no-permissions": {
                header: "Pas d'accès",
                subject: "Pas d'accès",
                html: "Vous n'êtes pas autorisé à ouvrir cette page.",
            },
            "virtual-denied": {
                header: "Apparemment tu es un peu en avance...",
                html: "<p class='text-left'>Merci de votre intérêt pour O-I Périphérique. Il semble que vous ayez atteint le site avant d'être activé et invité directement. O-I Les périphériques sont déployés par étapes pays par pays et par groupes d'utilisateurs. Une fois activé, vous recevrez un e-mail contenant une invitation personnelle avec des instructions.</p><p class='text-left'> Cliquez sur <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Support</a> pour des questions ou des problèmes avec votre commande ou le site.</p>",
            }
        }
    },
    500: {
        title: "Erreur inattendue",
        header: "@:views.errors.500.title",
        message: "Une erreur s'est produite. Veuillez réessayer plus tard."
    },
    code: {
        title: "Erreur inattendue",
        message: "@:views.errors.code.title",
        error: "Erreur {code}."
    }
}