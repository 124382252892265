export default {
    ordered: {
        type: {
            "ac_router": "Heb al een andere AC-router besteld",
            "chair": "Heb al een andere stoel besteld",
            "default": "Al besteld",
            "default_multiple": "Heb al soortgelijke producten besteld",
            "desk": "Al ander bureau besteld",
            "desktop": "Heb al een andere desktop besteld",
            "desktop_riser": "Heb al een andere desktopverhoger besteld",
            "detector": "Al verschillende damp- en geluidsdetectoren besteld",
            "displayport_cable": "Heb al een andere displayport-kabel besteld",
            "docking station": "Heb al een ander dockingstation besteld",
            "dual_usb_adapter": "Heb al verschillende dual-band USB-adapters besteld",
            "ear cushion": "Al ander oorkussen besteld",
            "footrest": "Heb al een andere voetsteun besteld",
            "hdmi_cable": "Al verschillende HDMI kabels besteld",
            "hdmi_displayport_cable": "Heb al een andere HDMI naar DisplayPort kabel besteld",
            "hdmi_vga_cable": "Al verschillende HDMI naar VGA kabel besteld",
            "headset": "Al een ander audioapparaat besteld",
            "keyboard": "Heb al een ander toetsenbord besteld",
            "kvm switch": "Al verschillende kvm-schakelaars besteld",
            "kvm_switch": "Al verschillende kvm-schakelaars besteld",
            "lamp": "Heb al een andere lamp besteld",
            "laptop stand": "Al verschillende laptopstandaarden besteld",
            "laptop": "Heb al een andere laptop besteld",
            "mesh_router": "Heb al verschillende mesh-routers besteld",
            "monitor": "Heb al een andere monitor besteld",
            "mouse": "Heb al verschillende muizen besteld",
            "patch_cord": "Heb al een ander cat6-patchsnoer besteld",
            "power_center": "Al verschillende Power Center besteld",
            "power_strip": "Heb al een andere stekkerdoos besteld",
            "smart_plug": "Al verschillende smart plug mini besteld",
            "smart_power_strip": "Al verschillende slimme stekkerdozen besteld",
            "thunderbolt_cable": "Al verschillende USB-C naar Thunderbolt-kabel besteld",
            "travel_router": "Heb al een andere reisrouter besteld",
            "usb hub": "Heb al een andere usb-hub besteld",
            "usb_adapter": "Heb al een andere usb-adapter besteld",
            "usb_hub": "Heb al een andere hub besteld",
            "webcam": "Heb al een andere webcam besteld"
        },
        product: "Besteld"
    },
    request: "Verzoek",
    add: "Voeg toe aan winkelkar",
    remove: "Verwijderen uit winkelwagen",
    added: "Toegevoegd aan winkelwagen",
}