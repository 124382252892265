export default {
    400: {
        title: "错误的请求",
        header: "@:views.errors.400.title",
        html: "错误的请求。请稍后再试。"
    },
    401: {
        title: "我们在登录时遇到错误",
        header: "@:views.errors.401.title",
        html: "请联系<a href='{email}' target='_blank'>支持</a>。"
    },
    404: {
        title: "找不到网页",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "不允许访问",
        header: "不允许访问",

        contact: "O-I外围设备仅供员工使用。如果您是员工并看到此消息，请与您当地的人员和文化部门合作更新您的状态。",

        subject: "齿轮 - 资格",

        html: "<p class='text-left'>@:views.errors.403.eligibility @:views.errors.403.contact</p>",
        error: {
            disabled: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>您目前不符合资格。请联系<a href='#' role='button' data-widget-trigger='{widgetOptions}'>支持</a>以获取更多信息。</p>"
            },
            "not-active": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>您目前不符合资格。请与您当地的人民和文化合作以获取更多信息。</p>"
            },
            "not-allowed":{
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I外围设备仅供员工使用。如果您是员工并看到此消息，请与您当地的人员和文化部门合作更新您的状态。</p>"
            },
            "no-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>您的 ServiceNow 个人资料中没有列出活跃的经理。请与您当地的人与文化代表合作，向您的经理通报最新情况。</p>"
            },
            "self-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>您的经理在您的 ServiceNow 个人资料中设置为您。请与您当地的人与文化代表合作，向您的经理通报最新情况。</p>"
            },
            "no-location": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>您的 ServiceNow 配置文件中没有列出位置。请与您当地的人文代表合作更新您的位置。</p>"
            },
            eligibility: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I外围设备仅供员工使用。如果您是员工并看到此消息，请与您当地的人员和文化合作以更新您的状态。</p>",
            },
            "eligibility-location": {
                header: "看来你来的有点早啊。。。",
                html: "<p class='text-left'>感谢您对 O-I 的关注外设。看起来您在被启用和直接邀请之前已经访问了该站点。 O-I外围设备正在按国家和用户群体分阶段推出。激活后，您将收到一封电子邮件，其中包含带有说明的个人邀请。</p><p class='text-left'>如果您对订单或网站有任何疑问或问题，请单击<a href='#' role='button' data-widget-trigger='{widgetOptions}'>“支持”</a> 。</p>",
            },
            "secondary-account": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>您当前正在尝试访问 O-I辅助帐户上的外围设备。请尝试使用您的主帐户重新登录。</p>"
            },
            "empty-core-id": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>您的 ServiceNow 帐户没有帐户名。请与您当地的人与文化代表合作更新您的帐户。</p>"
            },
            country: {
                header: "@:views.errors.403.header",
                html: "@:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "no-permissions": {
                header: "禁止访问",
                subject: "禁止访问",
                html: "您没有打开此页面的权限。",
            },
            "virtual-denied": {
                header: "看来你来的有点早啊。。。",
                html: "<p class='text-left'>感谢您对 O-I 的关注外设。看起来您在被启用和直接邀请之前已经访问了该站点。 O-I外围设备正在按国家和用户群体分阶段推出。激活后，您将收到一封电子邮件，其中包含带有说明的个人邀请。</p><p class='text-left'>如果您对订单或网站有任何疑问或问题，请单击<a href='#' role='button' data-widget-trigger='{widgetOptions}'>“支持”</a> 。</p>",
            }
        }
    },
    500: {
        title: "意外的错误",
        header: "@:views.errors.500.title",
        message: "发生错误。请稍后再试。"
    },
    code: {
        title: "意外的错误",
        message: "@:views.errors.code.title",
        error: "错误 {code}。"
    }
}