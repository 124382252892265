export default {
    message: {
        //     constraints  ordered
        default_everything_everything: "<p>Hasta la fecha, ha pedido todo lo que tiene a su disposición en Gear.</p><p> Si tiene una pregunta específica sobre esto, haga clic en el enlace <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Soporte de Gear</a> arriba y envíe su pregunta.</p>",
        default_everything_something: "<p>Los artículos pedidos anteriormente ya no se muestran.</p><p> Si tiene una pregunta específica sobre esto, haga clic en el enlace <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Soporte de Gear</a> arriba y envíe su pregunta.</p>",
        default_partial_everything: "<p>No hay productos adicionales para ordenar.</p><p> ¿Cambió su situación desde la primera vez que contestó su encuesta de equipo? Haga clic <a href='{equipmentQuizLink}'><b>aquí</b></a> para revisar las respuestas de su encuesta sobre equipos.</p>",
        default_partial_something: "<p>Los artículos pedidos anteriormente ya no se muestran.</p><p> ¿Cambió su situación desde la primera vez que contestó su encuesta de equipo? Haga clic <a href='{equipmentQuizLink}'><b>aquí</b></a> para revisar las respuestas de su encuesta sobre equipos.</p>",
        default_partial_noitems: "<p>¿Cambió su situación desde la primera vez que contestó su encuesta de equipo? Haga clic <a href='{equipmentQuizLink}'><b>aquí</b></a> para revisar las respuestas de su encuesta sobre equipos.</p>",

        ehs_everything_everything: "<p>Hasta la fecha, ha pedido todo lo que tiene a su disposición en Gear.</p><p> La disponibilidad de productos se basa en la encuesta EHS. Si necesita asistencia adicional, comuníquese con <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_everything_something: "<p>Los artículos pedidos anteriormente ya no se muestran.</p><p> La disponibilidad de productos se basa en la encuesta EHS. Si necesita asistencia adicional, comuníquese con <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_everything: "<p>Hasta la fecha, ha pedido todo lo que tiene a su disposición en Gear.</p><p> La disponibilidad de productos se basa en la encuesta EHS. Si necesita asistencia adicional, comuníquese con <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_something: "<p>Los artículos pedidos anteriormente ya no se muestran.</p><p> La disponibilidad de productos se basa en la encuesta EHS. Si necesita asistencia adicional, comuníquese con <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_noitems: "<p>La disponibilidad de productos se basa en la encuesta EHS. Si necesita asistencia adicional, comuníquese con <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
    }
}