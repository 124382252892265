export default {

    title: "Cargando...",

    responses: {
        "200_tfalogin": {
            title: "Verificacion de inicio de sesion",
            header: "@:views.tunnel.service.response.200_tfalogin.title",
            message: "El inicio de sesión ha sido verificado."
        },
        "404_tfalogin": {
            title: "Verificacion de inicio de sesion",
            header: "@:views.tunnel.service.response.404_tfalogin.title",
            message: "Token válido no encontrado. Es posible que su token haya caducado."
        },
        "200_tfaaddress": {
            title: "Verificación de dirección",
            header: "@:views.tunnel.service.response.200_tfaaddress.title",
            message: "Gracias por verificar tu dirección. Ahora puede elegir esto como una dirección de envío cuando realiza un pedido."
        },
        "404_tfaaddress": {
            title: "Verificación de dirección",
            header: "@:views.tunnel.service.response.404_tfaaddress.title",
            message: "Token válido no encontrado. Es posible que su token haya caducado."
        },
        "201_delivery": {
            title: "Gracias",
            header: "Gracias",
            message: "Gracias por su respuesta."
        },
        "201_shipment_received": {
            title: "Gracias",
            header: "Gracias",
            message: "Gracias por su respuesta."
        },
        "400": {
            title: "Solicitud incorrecta. Ocurrió un error.",
            header: "@:views.tunnel.service.response.400.title",
            message: "Solicitud incorrecta. Ocurrió un error."
        }
    }
}