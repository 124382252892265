export default {

    title: "Ładowanie...",

    responses: {
        "200_tfalogin": {
            title: "Weryfikacja logowania",
            header: "@:views.tunnel.service.response.200_tfalogin.title",
            message: "Logowanie zostało zweryfikowane."
        },
        "404_tfalogin": {
            title: "Weryfikacja logowania",
            header: "@:views.tunnel.service.response.404_tfalogin.title",
            message: "Nie znaleziono prawidłowego tokena. Twój token mógł wygasnąć."
        },
        "200_tfaaddress": {
            title: "Weryfikacja adresu",
            header: "@:views.tunnel.service.response.200_tfaaddress.title",
            message: "Dziękujemy za zweryfikowanie adresu. Możesz teraz wybrać ten adres jako adres wysyłki podczas składania zamówienia."
        },
        "404_tfaaddress": {
            title: "Weryfikacja adresu",
            header: "@:views.tunnel.service.response.404_tfaaddress.title",
            message: "Nie znaleziono prawidłowego tokena. Twój token mógł wygasnąć."
        },
        "201_delivery": {
            title: "Dziękuję",
            header: "Dziękuję",
            message: "Dziękuję za odpowiedź."
        },
        "201_shipment_received": {
            title: "Dziękuję",
            header: "Dziękuję",
            message: "Dziękuję za odpowiedź."
        },
        "400": {
            title: "Zła prośba. Wystąpił błąd.",
            header: "@:views.tunnel.service.response.400.title",
            message: "Zła prośba. Wystąpił błąd."
        }
    }
}