export default {
    unverified: "Unbestätigt",
    verificationLink: "Bestätigungsmail erneut senden",
    buttons: {
        delete: {
            cta: "Löschen",
            processing: "Löschen"
        }
    },
    addressDeleted: "Adresse wurde gelöscht",
    verificationEmailSent: "Die Email wurde verschickt",
    deleteAddressConfirmation: "Möchten Sie diese Adresse wirklich löschen?"
}