export default {
    "India": "Indien",
    "United States of America": "vereinigte Staaten von Amerika",
    "United Kingdom": "Großbritannien",
    "Philippines": "Philippinen",
    "Australia": "Australien",
    "Spain": "Spanien",
    "Bulgaria": "Bulgarien",
    "Poland": "Polen",
    "Malaysia": "Malaysia",
    "Russian Federation": "Russische Föderation",
    "Ukraine": "Ukraine",
    "Germany": "Deutschland",
    "Brazil": "Brasilien",
    "Canada": "Kanada",
    "Romania": "Rumänien",
    "France": "Frankreich",
    "China": "China",
    "Italy": "Italien",
    "Costa Rica": "Costa Rica",
    "Mexico": "Mexiko",
    "Slovakia": "Slowakei",
    "Vietnam": "Vietnam",
    "Singapore": "Singapur",
    "Argentina": "Argentinien",
    "Denmark": "Dänemark",
    "Japan": "Japan",
    "Belgium": "Belgien",
    "Czech Republic": "Tschechien",
    "Netherlands": "Niederlande",
    "Switzerland": "Schweiz",
    "Sweden": "Schweden",
    "New Zealand": "Neuseeland",
    "Hungary": "Ungarn",
    "Egypt": "Ägypten",
    "Lithuania": "Litauen",
    "United Arab Emirates": "Vereinigte Arabische Emirate",
    "Ireland": "Irland",
    "Hong Kong": "Hongkong",
    "Austria": "Österreich",
    "Saudi Arabia": "Saudi-Arabien",
    "Portugal": "Portugal",
    "Norway": "Norwegen",
    "Chile": "Chile",
    "Thailand": "Thailand",
    "Taiwan": "Taiwan",
    "Tunisia": "Tunesien",
    "Israel": "Israel",
    "South Africa": "Südafrika",
    "Colombia": "Kolumbien",
    "Serbia": "Serbien",
    "Luxembourg": "Luxemburg",
    "Peru": "Peru",
    "Finland": "Finnland",
    "Brunei Darussalam": "Brunei Darussalam",
    "Korea, Republic of": "Korea, Republik von",
    "Indonesia": "Indonesien",
    "Jordan": "Jordanien",
    "Fiji": "Fidschi",
    "Morocco": "Marokko",
    "Nigeria": "Nigeria",
    "Panama": "Panama",
    "Qatar": "Katar",
    "Greece": "Griechenland",
    "Turkey": "Truthahn",
    "Croatia": "Kroatien",
    "Kazakhstan": "Kasachstan",
    "Cyprus": "Zypern"
}