export default {
    login: "Přihlásit se",
    logout: "Odhlásit se",
    home: "Domov",
    user: {
        profile: {
            edit: "Upravit profil"
        }
    },
    approvals: {
        delegates: "Delegáti"
    },
    impersonation: "Objednávejte jménem"
}