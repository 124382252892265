export default {
    message: {
        //     constraints  ordered
        default_everything_everything: "<p>Até o momento, você solicitou tudo o que está disponível no Gear.</p><p> Se você tiver uma pergunta específica sobre isso, clique no link <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a> acima e envie sua pergunta.</p>",
        default_everything_something: "<p>Os itens solicitados anteriormente não são mais exibidos.</p><p> Se você tiver uma pergunta específica sobre isso, clique no link <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a> acima e envie sua pergunta.</p>",
        default_partial_everything: "<p>Não existem produtos adicionais a encomendar.</p><p> Sua situação mudou desde quando você respondeu pela primeira vez à pesquisa de equipamentos? Clique <a href='{equipmentQuizLink}'><b>aqui</b></a> para revisar suas respostas da pesquisa de equipamentos.</p>",
        default_partial_something: "<p>Os itens solicitados anteriormente não são mais exibidos.</p><p> Sua situação mudou desde quando você respondeu pela primeira vez à pesquisa de equipamentos? Clique <a href='{equipmentQuizLink}'><b>aqui</b></a> para revisar suas respostas da pesquisa de equipamentos.</p>",
        default_partial_noitems: "<p>Sua situação mudou desde quando você respondeu pela primeira vez à pesquisa de equipamentos? Clique <a href='{equipmentQuizLink}'><b>aqui</b></a> para revisar suas respostas da pesquisa de equipamentos.</p>",

        ehs_everything_everything: "<p>Até o momento, você solicitou tudo o que está disponível no Gear.</p><p> A disponibilidade de produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, entre em contato <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_everything_something: "<p>Os itens solicitados anteriormente não são mais exibidos.</p><p> A disponibilidade de produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, entre em contato <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_everything: "<p>Até o momento, você solicitou tudo o que está disponível no Gear.</p><p> A disponibilidade de produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, entre em contato <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_something: "<p>Os itens solicitados anteriormente não são mais exibidos.</p><p> A disponibilidade de produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, entre em contato <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_noitems: "<p>A disponibilidade de produtos é baseada na pesquisa EHS. Se precisar de suporte adicional, entre em contato <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
    }
}