<template>
    <div class="d-flex">
        <img class="thumbnail" :src="value.pictures[0].medium" alt="">

        <div class="flex-grow-1">
            <!-- Name -->
            {{name}}

            <!-- Tracking -->
            <div v-if="value.tracking" class="tracking">
                {{t('tracking')}}:
                <span v-if="value.tracking_url">
                    <a :href="value.tracking_url" target="_blank" rel="noopener">{{value.tracking}}</a>
                </span>
                <span v-else>{{value.tracking}}</span>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin_products from "@/mixins/products";

    export default {
        name: "SurveyDeliveryProduct",
        mixins: [mixin_products],
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.survey.delivery.product",
            };
        },
        computed: {
            name() {

                return this.products_translateProductName(this.value);
            }
        }
    }
</script>