export default {
    error: "Ocorreu um problema ao recuperar pedidos. Atualize seu navegador.",
    title: "Histórico de pedidos",
    loadMore: "Carregue mais",
    noOrders: "Sem pedidos",
    user: {
        loadMore: "Carregue mais",
        product: {
            title: "Ordem",
            tracking: "Monitorando",
            quantity: "Quantidade",
            reason: "Razão",
            control: {
                cancel: {
                    cta: "Clique para cancelar",
                    tooltip: "Clique para cancelar este item",
                    message: "Cancelamos seu pedido."
                },
                received: {
                    cta: "Produto recebido",
                    tooltip: "Clique para confirmar a entrega",
                    message: "Obrigado por confirmar que recebeu seu produto."
                },
                notReceived: {
                    cta: "Produto não recebido",
                    tooltip: "Clique para confirmar a não entrega"
                },
                hide: {
                    cta: "Ocultar item",
                    tooltip: "Clique para ocultar o item da lista"
                }
            }
        }
    }
}