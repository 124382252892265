export default {
    message: {
        //     constraints  ordered
        default_everything_everything: "<p>迄今为止，您已经订购了 Gear 中可用的所有产品。</p><p>如果您对此有具体问题，请单击上面的<a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a>链接并提交您的问题。</p>",
        default_everything_something: "<p>以前订购的商品不再显示。</p><p>如果您对此有具体问题，请单击上面的<a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a>链接并提交您的问题。</p>",
        default_partial_everything: "<p>没有额外的产品可以订购。</p><p>与您第一次回答设备调查时相比，您的情况有变化吗？单击<a href='{equipmentQuizLink}'><b>此处</b></a>查看您的设备调查答案。</p>",
        default_partial_something: "<p>以前订购的商品不再显示。</p><p>与您第一次回答设备调查时相比，您的情况有变化吗？单击<a href='{equipmentQuizLink}'><b>此处</b></a>查看您的设备调查答案。</p>",
        default_partial_noitems: "<p>与您第一次回答设备调查时相比，您的情况有变化吗？单击<a href='{equipmentQuizLink}'><b>此处</b></a>查看您的设备调查答案。</p>",

        ehs_everything_everything: "<p>迄今为止，您已经订购了 Gear 中可用的所有产品。</p><p>产品可用性基于 EHS 调查。如果您需要其他支持，请联系<a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_everything_something: "<p>以前订购的商品不再显示。</p><p>产品可用性基于 EHS 调查。如果您需要其他支持，请联系<a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_everything: "<p>迄今为止，您已经订购了 Gear 中可用的所有产品。</p><p>产品可用性基于 EHS 调查。如果您需要其他支持，请联系<a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_something: "<p>以前订购的商品不再显示。</p><p>产品可用性基于 EHS 调查。如果您需要其他支持，请联系<a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_noitems: "<p>产品可用性基于 EHS 调查。如果您需要其他支持，请联系<a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
    }
}