export default {
    country: {
        label: "Pays de résidence",
        errors: {
            required: "Une sélection s'impose"
        }
    },
    entity: {
        label: "Entité",
        errors: {
            required: "Une sélection s'impose"
        }
    },
    location: {
        label: "Emplacement du bureau",
        errors: {
            required: "Une sélection s'impose"
        }
    },
    workplace: {
        values: {
            "Dedicated Office": {
                title: "Bureau dédié",
                description: "Bureau à domicile avec un emplacement permanent dédié comme bureau"
            },
            "Semi-Dedicated": {
                title: "Semi-dédié",
                description: "Emplacement temporaire pour répondre aux besoins d'un environnement de travail à domicile"
            },
            "Floating": {
                title: "Flottant",
                description: "Pas d'espace dédié au bureau à domicile mais partagé avec d'autres lieux comme une salle à manger ou un salon"
            }
        },
        errors: {
            required: "Une sélection s'impose"
        }
    },
    priority: {
        label: "Priorité la plus élevée",
        values: {
            "Monitor": {
                title: "Moniteur"
            },
            "Docking Station": {
                title: "Station d'accueil"
            },
            "Keyboard/Mouse": {
                title: "Clavier souris"
            },
            "Web Cam": {
                title: "Webcam"
            },
            "Headset": {
                title: "Casque"
            },
            "Office Chair": {
                title: "Chaise de bureau"
            },
            "Desk": {
                title: "Bureau"
            },
            "Adapters": {
                title: "Adaptateurs"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Alimentation sans interruption (UPS)"
            },
            "Something Else": {
                title: "Autre chose"
            },
            "Nothing Needed": {
                title: "Rien de nécessaire"
            }
        },
        errors: {
            requiredUnless: "Une sélection s'impose",
            requiredIf: "Une sélection s'impose",
            required: "Une sélection s'impose"
        }
    },
    priorityDetails: {
        placeholder: "Fournir des détails"
    },
    secondaryPriority: {
        label: "Deuxième priorité la plus élevée",
        values: {
            "Monitor": {
                title: "Moniteur"
            },
            "Second Monitor": {
                title: "Deuxième moniteur"
            },
            "Docking Station": {
                title: "Station d'accueil"
            },
            "Keyboard/Mouse": {
                title: "Clavier souris"
            },
            "Web Cam": {
                title: "Webcam"
            },
            "Headset": {
                title: "Casque"
            },
            "Office Chair": {
                title: "Chaise de bureau"
            },
            "Desk": {
                title: "Bureau"
            },
            "Adapters": {
                title: "Adaptateurs"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Alimentation sans interruption (UPS)"
            },
            "Something Else": {
                title: "Autre chose"
            },
            "Nothing Needed": {
                title: "Rien de nécessaire"
            }
        },
        errors: {
            requiredUnless: "Une sélection s'impose",
            requiredIf: "Une sélection s'impose",
            required: "Une sélection s'impose"
        }
    },
    secondaryPriorityDetails: {
        placeholder: "Fournir des détails"
    },
    improveWorkspace: {
        label: "Comment amélioreriez-vous votre espace de travail ?",
        placeholder: "Facultatif",
    },
    improveRemoteWorking: {
        label: "En dehors de l'équipement, qu'est-ce qui vous aiderait à être plus efficace en télétravail ?",
        placeholder: "Facultatif",
    },
    receivedEquipmentHeadset: {
        label: "Avez-vous reçu un casque ou un haut-parleur sans fil de la Société au cours des 24 derniers mois ?",
        values: {
            Yes: {
                name: "Oui"
            },
            No: {
                name: "Non"
            }
        }
    },
    issuesHeadset: {
        label: "Avez-vous des problèmes avec cela?",
        values: {
            Yes: {
                name: "Oui"
            },
            No: {
                name: "Non"
            }
        }
    },
    issuesDescriptionHeadset: {
        placeholder: "Décrivez vos problèmes"
    },
    isOfficeWorker: {
        label: "Travaillez-vous du bureau à temps plein?",
        values: {
            Yes: {
                name: "Oui"
            },
            No: {
                name: "Non"
            }
        }
    },
    receivedKeyboardMouse: {
        label: "Avez-vous reçu un clavier et une souris de la Société au cours des 12 derniers mois ?",
        values: {
            Yes: {
                name: "Oui"
            },
            No: {
                name: "Non"
            }
        }
    },
    issuesKeyboardMouse: {
        label: "Avez-vous des problèmes avec cela?",
        values: {
            Yes: {
                name: "Oui"
            },
            No: {
                name: "Non"
            }
        }
    },
    issuesDescriptionKeyboardMouse: {
        placeholder: "Décrivez vos problèmes"
    },
    needWebcam: {
        label: "Avez-vous besoin d'une webcam externe pour le travail (pas celle intégrée à votre ordinateur portable ?",
        values: {
            Yes: {
                name: "Oui"
            },
            No: {
                name: "Non"
            }
        }
    },
    needWebcamExternal: {
        label: "Avez-vous besoin d'une webcam USB externe (pas celle intégrée à votre ordinateur portable) ?",
        values: {
            Yes: {
                name: "Oui"
            },
            No: {
                name: "Non"
            }
        }
    },
    needMonitor: {
        label: "Avez-vous besoin d'un moniteur pour le travail ?",
        values: {
            Yes: {
                name: "Oui"
            },
            No: {
                name: "Non"
            }
        }
    },
    receivedMonitor: {
        label: "Avez-vous reçu un moniteur de la Société au cours des 4 dernières années ?",
        values: {
            Yes: {
                name: "Oui"
            },
            No: {
                name: "Non"
            }
        }
    },
    issuesMonitor: {
        label: "Avez-vous des problèmes avec cela?",
        values: {
            Yes: {
                name: "Oui"
            },
            No: {
                name: "Non"
            }
        }
    },
    issuesDescriptionMonitor: {
        placeholder: "Décrivez vos problèmes"
    },
    needUsbhub: {
        label: "Avez-vous besoin d'un hub USB externe pour travailler ?",
        values: {
            Yes: {
                name: "Oui"
            },
            No: {
                name: "Non"
            }
        }
    },
    issuesDescriptionUsbhub: {
        placeholder: "Décrivez vos problèmes"
    }
}