export default {
    title: "订单审核",
    header: "@:views.order.submitted.title",

    noItems: "沒有選中產品",

    model: {
        addressId: {
            errors: {
                required: "请填写地址",
                noAddress: "没有可用地址。请联系支持人员。"
            }
        },
        product: {
            placeholder: "请填写金额"
        }
    },

    createNewAddress: "创建新地址",

    button: {
        submit: {
            cta: "提交订单",
            processing: "提交"
        },
        remove: "从订单中删除"
    },

    errors: {
        itemNotExist: "目录中不存在项目 «{name}»。它将被删除。",
        requestError: "提交订单时出现问题。请尝试重新提交。"
    }
}