const text = {
    countryAfterComponentsBeforeSelection: "Pamiętaj, że wybrany przez Ciebie kraj zostanie użyty jako część Twojego adresu wysyłki, aby wysłać produkty bezpośrednio do Ciebie. Jeśli nie widzisz swojego kraju na liście, oznacza to, że Gear nie jest obecnie dostępny w Twoim kraju.",
    countryAfterComponentsAllowedSelection: "Pamiętaj, że wybrany przez Ciebie kraj zostanie użyty jako część Twojego adresu wysyłki, aby wysłać produkty bezpośrednio do Ciebie.",
    countryAfterComponentsLimitedSelection: "Sprzęt jest obecnie dostępny w Twoim kraju, ale będzie otwierany etapami. Jeśli nie otrzymasz powiadomienia, możesz obecnie nie mieć dostępu do Gear w swoim kraju.",
    disclaimer: "Klikając następny przycisk poniżej, potwierdzam, że jestem pracownikiem Firmy i wyrażam zgodę na przechowywanie i przetwarzanie przez Firmę i jej partnera RemoteRetail danych osobowych przesłanych na tej stronie w celu dostarczenia żądanej treści. RemoteRetail potrzebuje podanych przeze mnie informacji kontaktowych, aby mogli kontaktować się ze mną w sprawie informacji związanych z moimi zamówieniami. Dostawcy zewnętrzni mogą również otrzymywać moje dane w celu wysyłania do mnie produktów.</p><p> W każdej chwili mogę zrezygnować z otrzymywania tych komunikatów. Aby uzyskać więcej informacji na temat tego, jak mogę zrezygnować z subskrypcji, praktyk dotyczących prywatności oraz zobowiązań dotyczących ochrony i poszanowania mojej prywatności, zapoznaj się z <a href='{privacyPolicyUrl}' target='_blank'>Polityką prywatności</a> ."
};

export default {
    title: "Witaj, {userName}!",
    description: {
        default: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`,
        virtualworker: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories that you need to work effectively from home. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`
    },
    text: {
        default: "Na początek zadamy Ci serię pytań.",
        virtualworker: "Na początek zadamy Ci serię pytań, które pomogą nam zrozumieć Twoją przestrzeń do pracy z domu i pomóc w kształtowaniu przyszłego kierunku tego programu."
    },
    afterComponents: {
        beforeSelection: `${text.countryAfterComponentsBeforeSelection}`,
        allowedSelection: `${text.countryAfterComponentsAllowedSelection}`,
        limitedSelection: `${text.countryAfterComponentsLimitedSelection}`,
        autoLoginBeforeSelection: `${text.countryAfterComponentsBeforeSelection}<br/><br/>${text.disclaimer}`,
        autoLoginAllowedSelection: `${text.countryAfterComponentsAllowedSelection}<br/><br/>${text.disclaimer}`,
        autoLoginLimitedSelection: `${text.countryAfterComponentsLimitedSelection}<br/><br/>${text.disclaimer}`
    },
    buttons: {
        back: "Z powrotem",
        next: "Następny",
        submit: "Składać"
    },
    entityinfo: {
        title: "Informacje o jednostce",
        description: "Aby pomóc nam prawidłowo skierować Twoje zamówienie, podaj nam następujące informacje:",
    },
    equipmentsurvey: {
        title: "Badanie sprzętu",
        description: "Na następnej stronie zostaniesz zapytany o rodzaj sprzętu, którego najbardziej potrzebujesz, aby pomóc Firmie lepiej zrozumieć priorytety.",
        info: "Należy pamiętać, że przedstawiony sprzęt służy wyłącznie do celów zbierania informacji i nie oznacza, że firma dostarczy ten sprzęt w przyszłości."
    },
    workplace: {
        title: "Powiedz nam więcej o swoim domowym biurze?",
        description: "Aby pomóc nam lepiej zrozumieć Twój obszar roboczy, wybierz jedną z poniższych opcji",
    },
    priority: {
        title: "Priorytety wyposażenia",
        description: "Wybierz dwie najlepsze opcje sprzętu, którego najbardziej potrzebujesz"
    },
    "additional-questions": {
        title: "Dodatkowe pytania"
    },
    "equipment-assessment": {
        title: "Ocena sprzętu",
        description: "Poniższy zestaw pytań <b>określi, jaki sprzęt będzie można zamówić w Gear na podstawie udzielonych odpowiedzi.</b>"
    },
    headset: {
        title: "Obecny sprzęt"
    },
    "office-worker": {
        title: "Biuro"
    },
    "keyboard-mouse": {
        title: "Klawiatura i mysz"
    },
    webcam: {
        title: "Kamerka internetowa"
    },
    monitor: {
        title: "Monitor"
    },
    usbhub: {
        title: "Rozdzielacz USB"
    },
    "equipment-list": {
        title: "Sprzęt",
        init: "initListaWyposażenia",
        description: {
            loading: "<span class=\"animate-spin icon-spinner\"></span>",
            error: "Zła prośba. Wystąpił błąd.",
            allowed: "W oparciu o Twoje odpowiedzi będziesz mieć prawo do następujących przedmiotów:{items}",
            empty: "Z Twoich odpowiedzi wynika, że obecnie nie kwalifikujesz się do żadnych przedmiotów w Gear."
        },
        text: "<ul class=\"text-left\" style=\"width: 300px; margin: 2rem auto 0\">{list}</ul>",
        afterComponents: "<small>Jeśli chcesz wprowadzić poprawki, naciśnij przycisk Wstecz, aby zmienić swoje odpowiedzi.</small>"
    },
    saved: "Zapisane"
}