export default {
    view: {
        success: {
            header: "已提交调查",
            message: "感谢您的反馈意见。"
        },
        productChoice: {
            cta: "节省",
            processing: "保存"
        },
        addressForm: {
            cta: "节省",
            processing: "保存"
        }
    },
    delivery: {
        title: "交货调查",
        message: "您是否收到了下面列出的所有物品？",
        cta: {
            yes: "是的",
            no: "不",
            processing: "保存"
        }
    }
}