export default {
    required: "Ein Wert ist erforderlich",
    email: "Der Wert ist keine gültige E-Mail-Adresse",
    mask: "Der Wert ist ungültig",
    maxValue: "Der Maximalwert ist {maxValue}",
    regex: "Der Wert ist ungültig",
    server: {
        DELEGATES_NOT_ENABLED: "Für diesen Partner sind keine Stellvertreter aktiviert.",
        DELEGATE_DOES_NOT_EXIST: "Delegierter existiert nicht",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "Ein Delegierter, der nicht Ihnen gehört, kann nicht aktualisiert werden.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Ein Delegierter, der Ihnen nicht gehört, kann nicht gelöscht werden.",
        DELEGATE_ALREADY_EXISTS: "Es existiert bereits eine Delegate-Regel, die diesen Kriterien entspricht.",
        DELEGATE_CANNOT_BE_SELF: "Sie können sich nicht selbst als Delegierter hinzufügen."
    }
}