export default {
    title: "Encuesta del portal",
    header: "@:views.survey.customer.title",
    model: {
        service_rating: {
            label: "En una escala del 1 al 10, donde 1 es muy insatisfecho y 10 muy satisfecho, ¿cómo calificaría este servicio?",
        },
        service_likes: {
            label: "¿Qué te gustó?",
            placeholder: ""
        },
        service_improvements: {
            label: "¿Qué se puede mejorar?",
            placeholder: ""
        },
        service_easy_to_follow: {
            label: "En una escala del 1 al 10, siendo 1 nada claro y 10 fácil de seguir, ¿cómo calificaría las comunicaciones con respecto a este sitio?"
        },
        item: {
            setup_rating: {
                label: "En una escala del 1 al 10, donde 1 es muy difícil y 10 es muy fácil, ¿qué tan difícil fue instalar y configurar su \"{name}\"?",
            },
            comments: {
                label: "Proporcione algún comentario sobre {name}?",
                placeholder: ""
            }
        }
    },
    disclaimer: "Tenga en cuenta que las respuestas de su encuesta se compartirán con O-I y podemos hacer un seguimiento con usted con respecto a sus comentarios.",
    button: {
        submit: {
            cta: "Entregar",
            processing: "Sumisión"
        }
    }
}