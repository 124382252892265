export default {

    title: "Bezig met laden...",

    responses: {
        "200_tfalogin": {
            title: "Inlogverificatie",
            header: "@:views.tunnel.service.response.200_tfalogin.title",
            message: "Inloggen is geverifieerd."
        },
        "404_tfalogin": {
            title: "Inlogverificatie",
            header: "@:views.tunnel.service.response.404_tfalogin.title",
            message: "Geldig token niet gevonden. Uw token is mogelijk verlopen."
        },
        "200_tfaaddress": {
            title: "Adresverificatie",
            header: "@:views.tunnel.service.response.200_tfaaddress.title",
            message: "Bedankt voor het verifiëren van je adres. U kunt dit nu als verzendadres kiezen wanneer u een bestelling plaatst."
        },
        "404_tfaaddress": {
            title: "Adresverificatie",
            header: "@:views.tunnel.service.response.404_tfaaddress.title",
            message: "Geldig token niet gevonden. Uw token is mogelijk verlopen."
        },
        "201_delivery": {
            title: "Bedankt",
            header: "Bedankt",
            message: "Bedankt voor je reactie."
        },
        "201_shipment_received": {
            title: "Bedankt",
            header: "Bedankt",
            message: "Bedankt voor je reactie."
        },
        "400": {
            title: "Foute aanvraag. Er is een fout opgetreden.",
            header: "@:views.tunnel.service.response.400.title",
            message: "Foute aanvraag. Er is een fout opgetreden."
        }
    }
}