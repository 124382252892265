export default {
    400: {
        title: "Bad Request",
        header: "@:views.errors.400.title",
        html: "Bad Request. Please try again later."
    },
    401: {
        title: "We've encountered an error on login",
        header: "@:views.errors.401.title",
        html: "Please contact <a href='{email}' target='_blank'>support</a>."
    },
    404: {
        title: "Page Not Found",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Access Not Allowed",
        header: "Access Not Allowed",

        contact: "O-I Peripheral is only available to employees. If you are an employee and see this message please work with your local People & Culture to update your status.",

        subject: "Gear - Eligibility",

        html: "<p class='text-left'> @:views.errors.403.eligibility @:views.errors.403.contact </p>",
        error: {
            disabled: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>You are currently not eligible. Please contact <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Support</a> for more information.</p>"
            },
            "not-active": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>You are currently not eligible.  Please work with your local People & Culture for more information.</p>"
            },
            "not-allowed":{
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I Peripheral is only available to employees. If you are an employee and see this message please work with your local People & Culture to update your status.</p>"
            },
            "no-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>You do not have an active manager listed in your ServiceNow profile.  Please work with your local People & Culture representative to update your manager.</p>"
            },
            "self-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Your manager is set as you in your ServiceNow profile.  Please work with your local People & Culture representative to update your manager.</p>"
            },
            "no-location": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>You do not have a location listed in your ServiceNow profile.  Please work with your local People & Culture representative to update your location.</p>"
            },
            eligibility: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I Peripheral is only available to employees. If you are an employee and see this message, please work with your local People & Culture to update your status.</p>",
            },
            "eligibility-location": {
                header: "Looks like you're a bit early...",
                html: "<p class='text-left'>Thank you for your interest in O-I Peripheral. It appears that you have reached the site before being enabled and invited directly. O-I Peripheral is being rolled out in stages country by country and by groups of users. When you are activated, you will receive an email containing a personal invite with instructions.</p><p class='text-left'>Click on <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Support</a> for questions or issues with your order or the site.</p>",
            },
            "secondary-account": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>You are currently trying to access O-I Peripheral on a secondary account. Please try logging in again with your primary account.</p>"
            },
            "empty-core-id": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Your ServiceNow account does not have an account name. Please work with your local People & Culture representative to update your account.</p>"
            },
            country: {
                header: "@:views.errors.403.header",
                html: " @:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "no-permissions": {
                header: "No Access",
                subject: "No Access",
                html: "You do not have permissions to open this page.",
            },
            "virtual-denied": {
                header: "Looks like you're a bit early...",
                html: "<p class='text-left'>Thank you for your interest in O-I Peripheral. It appears that you have reached the site before being enabled and invited directly. O-I Peripheral is being rolled out in stages country by country and by groups of users. When you are activated, you will receive an email containing a personal invite with instructions.</p><p class='text-left'>Click on <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Support</a> for questions or issues with your order or the site.</p>",
            }
        }
    },
    500: {
        title: "Unexpected error",
        header: "@:views.errors.500.title",
        message: "An error occurred. Please try again later."
    },
    code: {
        title: "Unexpected error",
        message: "@:views.errors.code.title",
        error: "Error {code}."
    }
}