export default {
    title: "Inlogverificatie vereist",
    header: "@:views.auth.confirm.title",
    message: "Controleer uw bedrijfse-mail voor een e-mailverificatielink om door te gaan. Klik op de link in de e-mail om verder te gaan.",
    messageBeforeCta: "Als u de e-mail niet ziet, klikt u op",
    messageAfterCta: "opnieuw verzenden.",
    secondMessage: "Als je de e-mail nog steeds niet ziet, controleer dan je spamfolder of neem contact met ons op via <a href='mailto:{email}?subject={subject}'>{email}</a> .",
    subject: "Uitrusting - TFA",
    buttons: {
        cta: "hier",
        processing: "hier"
    },
    headerAlreadyConfirmed: "Inlogverificatie",
    messageAlreadyConfirmed: "Je hebt je e-mailadres al bevestigd."
}