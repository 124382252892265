export default {
    title: "Revisione dell'ordine",
    header: "@:views.order.submitted.title",

    noItems: "Nessun prodotto selezionato",

    model: {
        addressId: {
            errors: {
                required: "Per favore inserisci l'indirizzo",
                noAddress: "Nessun indirizzo disponibile. Si prega di contattare l'assistenza."
            }
        },
        product: {
            placeholder: "Si prega di compilare l'importo"
        }
    },

    createNewAddress: "Crea nuovo indirizzo",

    button: {
        submit: {
            cta: "Inviare ordine",
            processing: "Invio"
        },
        remove: "Rimuovi dall'ordine"
    },

    errors: {
        itemNotExist: "Elemento «{name}» non esiste in catalogo. Verrà rimosso.",
        requestError: "Si è verificato un problema durante l'invio dell'ordine. Prova a inviare nuovamente."
    }
}