export default {
    error: "Wystąpił problem podczas pobierania zamówień. Proszę odświeżyć swoją przeglądarkę.",
    meta: {
        default: "Zamówienia",
        my_orders: "Moje zamówienia",
        my_gear: "Otrzymane"
    },
    loadMore: "Załaduj więcej",
    noOrders: "Brak zamówień",
    order: {
        product: {
            title: "Zamówienie",
            tracking: "Śledzenie",
            quantity: "Ilość",
            control: {
                cancel: {
                    cta: "Kliknij, aby anulować",
                    tooltip: "Kliknij, aby anulować ten przedmiot",
                    message: "Anulowaliśmy Twoje zamówienie."
                },
                cancelRequest: {
                    cta: "Prośba o anulowanie",
                    tooltip: "Kliknij, aby przesłać prośbę o anulowanie"
                },
                received: {
                    cta: "Oznacz jako odebrane",
                    tooltip: "Kliknij, aby potwierdzić dostawę",
                    message: "Dziękujemy za potwierdzenie otrzymania produktu."
                },
                notReceived: {
                    cta: "Oznacz jako nieotrzymane",
                    tooltip: "Kliknij, aby potwierdzić brak dostawy"
                },
                damaged: {
                    cta: "Oznacz jako uszkodzony/zepsuty",
                    tooltip: "Kliknij, aby utworzyć zgłoszenie do pomocy technicznej"
                },
                hide: {
                    cta: "Ukryj przedmiot",
                    tooltip: "Kliknij, aby ukryć pozycję na liście"
                }
            },
            requestToCancelInfo: "Skontaktujemy się z dostawcą, aby poprosić o anulowanie. Należy pamiętać, że jest to jedynie prośba i anulowanie może nie być możliwe. Gdy tylko ustalimy, czy można anulować usługę, poinformujemy Cię o tym.",
            cancelOk: "OK",
            reason: "Powód",
            orderedBy: "Zamówiony przez",
            model: {
                cancel: {
                    reason: {
                        label: "Powody anulowania",
                        placeholder: "Powody anulowania"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Składać",
                        processing: "Przedkładający"
                    },
                    cancel: {
                        cta: "Anulować"
                    }
                }
            }
        }
    },
    help: {
        text: "<p>Masz pytania dotyczące zamówienia?<br/> Aby uzyskać pomoc, odwiedź nasze <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>Centrum wsparcia</b></a></p><p> Potrzebujesz produktu, którego nie ma na liście?<br/> Kliknij <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>tutaj,</b></a> aby wysłać sugestię</p>",
        email: {
            subject: ""
        }
    }
}