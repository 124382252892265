export default {
    login: "Zaloguj sie",
    logout: "Wyloguj",
    home: "Dom",
    user: {
        profile: {
            edit: "Edytuj profil"
        }
    },
    approvals: {
        delegates: "Delegaci"
    },
    impersonation: "Zamówienie w imieniu"
}