export default {
    title: "Verificação de login necessária",
    header: "@:views.auth.confirm.title",
    message: "Para continuar, verifique o e-mail da sua empresa para obter um link de verificação de e-mail. Clique no link no e-mail para prosseguir.",
    messageBeforeCta: "Caso não veja o e-mail, clique em",
    messageAfterCta: "para reenviar.",
    secondMessage: "Se você ainda não vê o e-mail, verifique sua pasta de spam ou entre em contato conosco em <a href='mailto:{email}?subject={subject}'>{email}</a> .",
    subject: "Engrenagem - TFA",
    buttons: {
        cta: "aqui",
        processing: "aqui"
    },
    headerAlreadyConfirmed: "Verificação de login",
    messageAlreadyConfirmed: "Você já confirmou seu e-mail."
}