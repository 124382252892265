export default {
    title: "Portaal onderzoek",
    header: "@:views.survey.customer.title",
    model: {
        service_rating: {
            label: "Op een schaal van 1 tot 10, waarbij 1 zeer ontevreden is en 10 zeer tevreden, hoe zou u deze service beoordelen?",
        },
        service_likes: {
            label: "Wat vond je leuk?",
            placeholder: ""
        },
        service_improvements: {
            label: "Wat kan er verbeterd worden?",
            placeholder: ""
        },
        service_easy_to_follow: {
            label: "Op een schaal van 1-10, waarbij 1 helemaal niet duidelijk is en 10 gemakkelijk te volgen, hoe zou u de communicatie over deze site beoordelen?"
        },
        item: {
            setup_rating: {
                label: "Op een schaal van 1 tot 10, waarbij 1 heel moeilijk is en 10 heel gemakkelijk, hoe moeilijk was het om je \"{name}\" te installeren en in te stellen?",
            },
            comments: {
                label: "Geef a.u.b. commentaar op {name}?",
                placeholder: ""
            }
        }
    },
    disclaimer: "Houd er rekening mee dat uw antwoorden op de enquête worden gedeeld met O-I en we kunnen contact met u opnemen met betrekking tot uw feedback.",
    button: {
        submit: {
            cta: "Indienen",
            processing: "Indienen"
        }
    }
}