export default {

    title: "Načítání...",

    responses: {
        "200_tfalogin": {
            title: "Ověření přihlášení",
            header: "@:views.tunnel.service.response.200_tfalogin.title",
            message: "Přihlášení bylo ověřeno."
        },
        "404_tfalogin": {
            title: "Ověření přihlášení",
            header: "@:views.tunnel.service.response.404_tfalogin.title",
            message: "Nebyl nalezen platný token. Platnost vašeho tokenu možná vypršela."
        },
        "200_tfaaddress": {
            title: "Ověření adresy",
            header: "@:views.tunnel.service.response.200_tfaaddress.title",
            message: "Děkujeme za ověření vaší adresy. Tuto adresu si nyní můžete zvolit jako dodací adresu při zadávání objednávky."
        },
        "404_tfaaddress": {
            title: "Ověření adresy",
            header: "@:views.tunnel.service.response.404_tfaaddress.title",
            message: "Nebyl nalezen platný token. Platnost vašeho tokenu možná vypršela."
        },
        "201_delivery": {
            title: "Děkuji",
            header: "Děkuji",
            message: "Děkuji za Vaši odpověď."
        },
        "201_shipment_received": {
            title: "Děkuji",
            header: "Děkuji",
            message: "Děkuji za Vaši odpověď."
        },
        "400": {
            title: "Špatný požadavek. Vyskytla se chyba.",
            header: "@:views.tunnel.service.response.400.title",
            message: "Špatný požadavek. Vyskytla se chyba."
        }
    }
}