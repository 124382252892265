import emitter, {EVENTS} from "@/api/events";
import {ORDERS_LIMIT} from "@/constants";

export default {
    methods: {
        events_listen() {

            const orders = () => {

                return Promise.all([
                    this.$store.dispatch("user/constraints/fetch"),
                    this.$store.dispatch("user/orders/fetch", {skip: 0, limit: ORDERS_LIMIT})
                ]);
            }

            emitter.on(EVENTS.USER_ORDER_SUBMIT, orders);
            emitter.on(EVENTS.USER_ORDER_CANCEL, orders);
            emitter.on(EVENTS.USER_ORDER_RECEIVED, orders);
            emitter.on(EVENTS.USER_ORDER_NOT_RECEIVED, orders);
            emitter.on(EVENTS.USER_ORDER_UNDO, orders);
            emitter.on(EVENTS.USER_ORDER_HIDE, orders);
        }
    }
}