export default {
    title: "Recenze objednávky",
    header: "@:views.order.submitted.title",

    noItems: "Žádné vybrané produkty",

    model: {
        addressId: {
            errors: {
                required: "Vyplňte prosím adresu",
                noAddress: "Žádná adresa není k dispozici. Kontaktujte prosím podporu."
            }
        },
        product: {
            placeholder: "Vyplňte prosím částku"
        }
    },

    createNewAddress: "Vytvořit novou adresu",

    button: {
        submit: {
            cta: "Odeslat objednávku",
            processing: "Odesílání"
        },
        remove: "Odebrat z objednávky"
    },

    errors: {
        itemNotExist: "Položka «{name}» v katalogu neexistuje. Bude odstraněn.",
        requestError: "Při odesílání vaší objednávky došlo k problému. Zkuste prosím odeslat znovu."
    }
}