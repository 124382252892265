export default {
    title: "Bestellübersicht",
    header: "@:views.order.submitted.title",

    noItems: "Keine ausgewählten Produkte",

    model: {
        addressId: {
            errors: {
                required: "Bitte Adresse eintragen",
                noAddress: "Es ist keine Adresse verfügbar. Bitte wenden Sie sich an den Support."
            }
        },
        product: {
            placeholder: "Bitte Betrag eintragen"
        }
    },

    createNewAddress: "Neue Adresse anlegen",

    button: {
        submit: {
            cta: "Bestellung abschicken",
            processing: "Einreichen"
        },
        remove: "Aus Bestellung entfernen"
    },

    errors: {
        itemNotExist: "Der Eintrag «{name}» existiert nicht im Katalog. Er wird entfernt.",
        requestError: "Beim Übermitteln Ihrer Bestellung ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
    }
}