export default {
    home: "Domov",
    order: {
        submit: "Zkontrolovat objednávku"
    },
    tunnel: {
        loginVerification: "Ověření přihlášení",
        addressVerification: "Ověření adresy"
    },
    catalog: "Katalog",
    all: "Všechno",
    return: {
        return: "Vrátit se"
    },
    errors: {
        404: "@:views.errors.404.title"
    },
    impersonation: "Objednávejte jménem"
}