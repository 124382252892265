export default {
    view: {
        success: {
            header: "Sondaggio inviato",
            message: "Grazie per il tuo feedback."
        },
        productChoice: {
            cta: "Salva",
            processing: "Salvataggio"
        },
        addressForm: {
            cta: "Salva",
            processing: "Salvataggio"
        }
    },
    delivery: {
        title: "Indagine sulla consegna",
        message: "Hai ricevuto tutti gli articoli elencati di seguito?",
        cta: {
            yes: "SÌ",
            no: "NO",
            processing: "Salvataggio"
        }
    }
}