export default {
    title: "Potwierdzenie dostawy",
    header: "@:views.survey.delivery.title",
    result: {
        header: "Dziękuję",
        message: "Dziękuję za odpowiedź."
    },
    status: {
        yes: "Sprawdź zaznaczone produkty poniżej, aby potwierdzić dostawę i odznacz te, których nie otrzymałeś. Następnie naciśnij Prześlij, aby potwierdzić.",
        no: "Sprawdź, czy nie otrzymałeś żadnego z poniższych produktów. Jeśli otrzymałeś którykolwiek z tych produktów, zaznacz pole obok otrzymanych produktów i naciśnij Prześlij, aby potwierdzić."
    },
    model: {
        checked: {
            label: "Otrzymane"
        }
    },
    button: {
        submit: {
            cta: "Składać",
            processing: "Przedkładający"
        }
    }
}