export default {
    cost_center: {
        label: "@:checkout_config.cost_center.label",
        placeholder: "@:checkout_config.cost_center.placeholder",
        cost_center_not_found: "@:checkout_config.cost_center.cost_center_not_found",
        errors: {
            regex: "@:checkout_config.cost_center.errors.regex"
        },
        decorators: {
            cost_center_details: {
                label: "Firmencode/Center-Name",
                message: "{company_code} / {cost_center_name}"
            }
        }
    },
    company_code: {
        label: "@:checkout_config.company_code.label",
    },
    cost_center_name: {
        label: "@:checkout_config.cost_center_name.label",
    },
    order_note: {
        label: "@:checkout_config.order_note.label",
    }
}