export default {
    login: "Se connecter",
    logout: "Se déconnecter",
    home: "Maison",
    user: {
        profile: {
            edit: "Editer le profil"
        }
    },
    approvals: {
        delegates: "Délégués"
    },
    impersonation: "Commande au nom de"
}