export default {
    menu: {
        approvals: "Aprobaciones",
        delegates: "delegados"
    },
    approvals: {
        title: "Aprobación",
        error: "Ocurrió un error",
        saved: "Aprobaciones enviadas",
        noApprovals: "nada que aprobar",
        review: "Revise y edite el centro de costos si es incorrecto",
        priorApprovals: "Aprobaciones previas",
        noOrders: "nada que aprobar",
        noPriorApprovals: "Sin aprobaciones previas",
        approved: "Aprobado",
        notApproved: "No aprovado",
        confirm: "Todavía hay pedidos con aprobaciones pendientes. ¿Desea continuar con los cambios actuales?",
        manager: "Gerente",
        orders: {
            name: "Nombre",
            date: "Fecha de orden",
            details: "Detalles del pedido",
            cost: "Costo",
            costCenter: "Centro de Costo",
            approval: "Aprobación"
        },
        model: {
            reason: {
                placeholder: "Razón"
            },
            approve: {
                yes: {
                    label: "Sí"
                },
                no: {
                    label: "No"
                }
            }
        },
        buttons: {
            submit: {
                text: "Entregar",
                processing: "Procesando"
            }
        }
    },
    delegates: {
        title: "delegados",
        error: "Ocurrió un error",
        noDelegates: "No hay artículos",
        delegateDelete: "El delegado ha sido eliminado",
        confirm: {
            delete: "¿Eliminar delegado?"
        },
        delegates: {
            email: "Correo electrónico",
            startDate: "Fecha de inicio",
            endDate: "Fecha final",
        },
        buttons: {
            add: {
                text: "Agregar delegado"
            }
        }
    }
}