export default {
    menu: {
        approvals: "批准",
        delegates: "代表们"
    },
    approvals: {
        title: "赞同",
        error: "发生错误",
        saved: "已提交批准",
        noApprovals: "没有什么可批准的",
        review: "如果不正确，请检查并编辑成本中心",
        priorApprovals: "事先批准",
        noOrders: "没有什么可批准的",
        noPriorApprovals: "没有事先批准",
        approved: "得到正式认可的",
        notApproved: "不批准",
        confirm: "仍有待批准的订单。您希望继续当前的更改吗？",
        manager: "经理",
        orders: {
            name: "姓名",
            date: "订购日期",
            details: "订单详细信息",
            cost: "成本",
            costCenter: "成本中心",
            approval: "赞同"
        },
        model: {
            reason: {
                placeholder: "原因"
            },
            approve: {
                yes: {
                    label: "是的"
                },
                no: {
                    label: "不"
                }
            }
        },
        buttons: {
            submit: {
                text: "提交",
                processing: "加工"
            }
        }
    },
    delegates: {
        title: "代表们",
        error: "发生错误",
        noDelegates: "没有相关产品",
        delegateDelete: "代表已被删除",
        confirm: {
            delete: "删除委托？"
        },
        delegates: {
            email: "电子邮件",
            startDate: "开始日期",
            endDate: "结束日期",
        },
        buttons: {
            add: {
                text: "添加代表"
            }
        }
    }
}