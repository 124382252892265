export default {
    message: {
        //     constraints  ordered
        default_everything_everything: "<p>K dnešnímu dni jste si objednali vše, co máte v Gear k dispozici.</p><p> Máte-li k tomu konkrétní dotaz, klikněte na výše uvedený odkaz <a href='#' role='button' data-widget-trigger='{widgetOptions}'>podpory Gear</a> a odešlete svůj dotaz.</p>",
        default_everything_something: "<p>Dříve objednané položky se již nezobrazují.</p><p> Máte-li k tomu konkrétní dotaz, klikněte na výše uvedený odkaz <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Podpora Gear</a> a odešlete svůj dotaz.</p>",
        default_partial_everything: "<p>K objednání nejsou žádné další produkty.</p><p> Změnila se vaše situace od doby, kdy jste poprvé odpověděli na průzkum týkající se vybavení? Kliknutím <a href='{equipmentQuizLink}'><b>sem</b></a> zobrazíte odpovědi na svůj průzkum týkající se vybavení.</p>",
        default_partial_something: "<p>Dříve objednané položky se již nezobrazují.</p><p> Změnila se vaše situace od doby, kdy jste poprvé odpověděli na průzkum týkající se vybavení? Kliknutím <a href='{equipmentQuizLink}'><b>sem</b></a> zobrazíte odpovědi na svůj průzkum týkající se vybavení.</p>",
        default_partial_noitems: "<p>Změnila se vaše situace od doby, kdy jste poprvé odpověděli na průzkum týkající se vybavení? Kliknutím <a href='{equipmentQuizLink}'><b>sem</b></a> zobrazíte odpovědi na svůj průzkum týkající se vybavení.</p>",

        ehs_everything_everything: "<p>K dnešnímu dni jste si objednali vše, co máte v Gear k dispozici.</p><p> Dostupnost produktů je založena na průzkumu EHS. Pokud potřebujete další podporu, kontaktujte prosím <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_everything_something: "<p>Dříve objednané položky se již nezobrazují.</p><p> Dostupnost produktů je založena na průzkumu EHS. Pokud potřebujete další podporu, kontaktujte prosím <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_everything: "<p>K dnešnímu dni jste si objednali vše, co máte v Gear k dispozici.</p><p> Dostupnost produktů je založena na průzkumu EHS. Pokud potřebujete další podporu, kontaktujte prosím <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_something: "<p>Dříve objednané položky se již nezobrazují.</p><p> Dostupnost produktů je založena na průzkumu EHS. Pokud potřebujete další podporu, kontaktujte prosím <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_noitems: "<p>Dostupnost produktů je založena na průzkumu EHS. Pokud potřebujete další podporu, kontaktujte prosím <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
    }
}