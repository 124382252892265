export default {
    title: "Portál felmérés",
    header: "@:views.survey.customer.title",
    model: {
        service_rating: {
            label: "Hogyan értékelné ezt a szolgáltatást egy 1-től 10-ig terjedő skálán, ahol az 1-es a nagyon elégedetlen, a 10-es pedig a nagyon elégedett?",
        },
        service_likes: {
            label: "Mit szerettél?",
            placeholder: ""
        },
        service_improvements: {
            label: "Mit lehet javítani?",
            placeholder: ""
        },
        service_easy_to_follow: {
            label: "Hogyan értékelné az oldallal kapcsolatos kommunikációt egy 1-től 10-ig terjedő skálán, ahol az 1 egyáltalán nem egyértelmű és a 10-es, amely könnyen követhető?"
        },
        item: {
            setup_rating: {
                label: "Egy 1-től 10-ig terjedő skálán, ahol az 1-es nagyon nehéz, a 10-es pedig nagyon könnyű, mennyire volt nehéz a \"{name}\" telepítése és beállítása?",
            },
            comments: {
                label: "Kérjük, tegyen megjegyzéseket a következőhöz: {name}?",
                placeholder: ""
            }
        }
    },
    disclaimer: "Felhívjuk figyelmét, hogy felmérési válaszait megosztjuk a következővel: O-I és felvesszük Önnel a kapcsolatot visszajelzésével kapcsolatban.",
    button: {
        submit: {
            cta: "Beküldés",
            processing: "Beküldés"
        }
    }
}