export default {
    model: {
        email: {
            label: "电子邮件",
            placeholder: "代表电子邮件"
        },
        start_on: {
            label: "开始日期",
            placeholder: "开始授权日期"
        },
        end_on: {
            label: "结束日期",
            placeholder: "选修的"
        },
        accept: {
            tip: "通过委托您的批准责任，您证明您对您的委托人批准的任何订单承担责任和责任。",
            label: "我接受"
        }
    },
    buttons: {
        submit: {
            cta: "确认",
            processing: "保存"
        },
        close: {
            cta: "取消"
        }
    },
    saved: "已保存"
}