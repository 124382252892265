export default {
    types: {
        "ac_router": "交流路由器",
        "chair": "椅子",
        "desk": "桌子",
        "desktop": "桌面",
        "desktop_riser": "桌面转接卡",
        "detector": "电子烟和噪音探测器",
        "displayport_cable": "显示端口电缆",
        "docking station": "扩展坞",
        "dual_usb_adapter": "双频 USB 适配器",
        "ear cushion": "耳垫",
        "footrest": "脚踏板",
        "hdmi_cable": "HDMI 线",
        "hdmi_displayport_cable": "HDMI 转 DisplayPort 电缆",
        "hdmi_vga_cable": "HDMI 转 VGA 线",
        "headset": "耳机",
        "keyboard": "键盘",
        "kvm switch": "多电脑切换器",
        "kvm_switch": "多电脑切换器",
        "lamp": "灯",
        "laptop stand": "笔记本电脑支架",
        "laptop": "笔记本电脑",
        "mesh_router": "网状路由器",
        "monitor": "监视器",
        "mouse": "老鼠",
        "patch_cord": "六类跳线",
        "power_center": "电源中心",
        "power_strip": "电源板",
        "smart_plug": "迷你智能插头",
        "smart_power_strip": "智能插排",
        "thunderbolt_cable": "USB-C 转 Thunderbolt",
        "travel_router": "旅行路由器",
        "usb hub": "USB集线器",
        "usb_adapter": "USB适配器",
        "usb_hub": "USB集线器",
        "webcam": "摄像头"
    }
}