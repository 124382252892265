export default {
    user: {
        create: {
            title: "Nový uživatel",
            model: {
                email: {
                    label: "E-mailem",
                    placeholder: "Vyplňte email"
                },
                name: {
                    label: "název",
                    placeholder: "Vyplňte jméno"
                },
                country: {
                    label: "Země",
                    placeholder: "Vyplňte zemi"
                }
            },
            cta: {
                submit: {
                    text: "Předložit",
                    processing: "Odesílání"
                },
                cancel: {
                    text: "zrušení"
                }
            }
        }
    }
}