export default {
    type: {
        "ac_router": "Dosáhli jste limitu objednávek AC routerů",
        "chair": "Dosáhli jste limitu objednávek židlí",
        "default": "Dosáhli jste limitu objednávek",
        "default_multiple": "Dosáhli jste limitu objednávek podobných produktů",
        "desk": "Dosáhli jste limitu objednávek stolů",
        "desktop": "Dosáhli jste limitu objednávek desktopu",
        "desktop_riser": "Dosáhli jste limitu objednávek stolních podpěr",
        "detector": "Dosáhli jste limitu objednávek detektorů kouře a hluku",
        "displayport_cable": "Dosáhli jste limitu objednávek kabelů Displayport",
        "docking station": "Dosáhli jste limitu objednávek dokovacích stanic",
        "dual_usb_adapter": "Dosáhli jste limitu objednávek dvoupásmových adaptérů USB",
        "ear cushion": "Dosáhli jste limitu objednávek náušníků",
        "footrest": "Dosáhli jste objednacího limitu stupaček",
        "hdmi_cable": "Dosáhli jste limitu objednávek kabelů HDMI",
        "hdmi_displayport_cable": "Dosáhli jste limitu objednávek kabelů HDMI na DisplayPort",
        "hdmi_vga_cable": "Dosáhli jste limitu objednávek kabelů HDMI na VGA",
        "headset": "Dosáhli jste limitu objednávek audio zařízení",
        "keyboard": "Dosáhli jste limitu objednávek klávesnic",
        "kvm switch": "Dosáhli jste objednacího limitu kvm spínačů",
        "kvm_switch": "Dosáhli jste objednacího limitu kvm spínačů",
        "lamp": "Dosáhli jste limitu objednávek lamp",
        "laptop stand": "Dosáhli jste limitu objednávek stojanů na notebooky",
        "laptop": "Dosáhli jste limitu objednávek notebooků",
        "mesh_router": "Dosáhli jste limitu objednávek síťových routerů",
        "monitor": "Dosáhli jste limitu objednávek monitorů",
        "mouse": "Dosáhli jste limitu objednávek myší",
        "patch_cord": "Dosáhli jste limitu objednávek propojovacích kabelů Cat6",
        "power_center": "Dosáhli jste limitu objednávek Power Center",
        "power_strip": "Dosáhli jste limitu objednávek prodlužovacích kabelů",
        "smart_plug": "Dosáhli jste limitu objednávek chytrých zástrček mini",
        "smart_power_strip": "Dosáhli jste limitu objednávek chytrých prodlužovacích kabelů",
        "thunderbolt_cable": "Dosáhli jste limitu objednávek USB-C na Thunderbolt",
        "travel_router": "Dosáhli jste limitu objednávek cestovních routerů",
        "usb hub": "Dosáhli jste limitu objednávek rozbočovačů USB",
        "usb_adapter": "Dosáhli jste limitu objednávek adaptérů USB",
        "usb_hub": "Dosáhli jste limitu objednávek rozbočovačů USB",
        "webcam": "Dosáhli jste limitu objednávek webových kamer"
    }
}