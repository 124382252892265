<template>
    <div class="impersonation-orders-history-user-product">
        <!-- Thumbnail -->
        <div class="impersonation-orders-history-user-product-thumbnail">
            <img v-if="thumbnail" class="impersonation-orders-history-user-product-thumbnail-img" :src="thumbnail"
                 alt=""/>
        </div>

        <div class="flex-grow-1">
            <div class="d-flex">
                <div class="impersonation-orders-history-user-product-name">
                    <div class="flex-grow-1 text-break">
                        {{title}}
                        <div v-if="quantity && 1 < quantity">
                            <small>{{t('quantity')}}: {{quantity}}</small>
                        </div>
                    </div>
                    <div v-html="createdOn"></div>
                </div>
                <div class="flex-grow-0 pl-3">
                    <button type="button" class="btn-switcher" @click="display.details=!display.details">
                        <span :class="{'icon-plus':!display.details,'icon-minus':display.details}"></span>
                    </button>
                </div>
            </div>

            <div v-if="display.details">
                <div class="info">
                    <!-- Status -->
                    <div>
                        {{displayStatus}}
                        <span class="pl-2" v-html="statusDate"></span>
                    </div>
                    <!-- Tracking -->
                    <div v-if="product.tracking" class="tracking">
                        {{t('tracking')}}:
                        <span v-if="product.trackingUrl">
                            <a :href="product.trackingUrl" target="_blank" rel="noopener">{{product.tracking}}</a>
                        </span>
                        <span v-else>{{product.tracking}}</span>
                    </div>
                    <!-- Not approved reason-->
                    <div v-if="approvalReason" class="status">
                        {{t('reason')}}: {{approvalReason}}
                    </div>
                    <!-- Status -->
                    <div v-if="estimatedDelivery" class="status">
                        {{estimatedDelivery}}
                    </div>
                </div>
                <!-- Cancel -->
                <div v-if="ctaAllowed.cancel" class="control">
                    <popper :content="t('control.cancel.tooltip')" offset-distance="2" :hover="true">
                        <button type="button" class="btn btn-link btn-sm"
                                :disabled="processing.cancel"
                                @click="cancel(product)">
                            <span v-if="processing.cancel" class="icon-spinner animate-spin"></span>
                            <span v-html="t('control.cancel.cta')"></span>
                        </button>
                    </popper>
                </div>
                <!-- Mark as received -->
                <div v-if="ctaAllowed.received" class="control">
                    <popper :content="t('control.received.tooltip')" offset-distance="2" :hover="true">
                        <button type="button" class="btn btn-link btn-sm" :disabled="processing.received"
                                @click="received">
                            <span v-if="processing.received" class="icon-spinner animate-spin"></span>
                            <span v-html="t('control.received.cta')"></span>
                        </button>
                    </popper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import get from "lodash/get.js";
    import Popper from "vue3-popper";
    import useVuelidate from "@vuelidate/core";

    import mixin_products from "@/mixins/products";
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";
    import {ORDER_STATUS} from "@/constants";

    import Spinner from "@/components/Spinner";
    import FormTextarea from "@/components/form/FormTextarea";
    import ModalComponentsImpersonationOrdersActionUndo
        from "@/modal/components/impersonation/orders/ModalComponentsImpersonationOrdersActionUndo";

    export default {
        name: "ImpersonationOrdersHistoryUserProduct",
        mixins: [mixin_products],
        components: {
            Spinner,
            FormTextarea,
            Popper
        },
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        props: {
            product: {
                type: Object,
                required: true
            },
            userId: {
                type: Number,
                required: true
            },
            userEmail: {
                type: String,
                required: true
            },
            expand: Boolean
        },
        data() {

            return {
                t_path: "components.impersonation.orders.history.user.product",

                display: {
                    cancel: false,
                    details: !!this.expand
                },

                processing: {
                    cancel: false,
                    received: false,
                    hide: false
                }
            }
        },
        computed: {
            thumbnail() {

                return this.product.pictures[0].medium;
            },
            title() {

                return this.products_translateProductName(this.product);
            },
            createdOn() {

                return this
                    .d(this.product.statusHistory[this.product.statusHistory.length - 1].createdOn, "date")
                    .toString()
                    .replace(/\s/ig, "&nbsp;");
            },
            quantity() {

                return this.product.quantity;
            },
            estimatedDelivery() {

                return this.product.estimated_ship_date && this.product.estimated_ship_date.message
                    ? getTranslationValue(this.product.estimated_ship_date, ["message"], this.$i18n)
                    : null
            },
            ctaAllowed() {

                return {
                    cancel: -1 !== [
                        ORDER_STATUS.CREATED,
                        ORDER_STATUS.CONFIRMED,
                        ORDER_STATUS.SUBMITTED,
                        ORDER_STATUS.PENDING_APPROVAL,
                        ORDER_STATUS.APPROVED
                    ].indexOf(this.product.status),
                    received: -1 !== [
                        ORDER_STATUS.ACKNOWLEDGED,
                        ORDER_STATUS.DELIVERY_EMAIL,
                        ORDER_STATUS.SHIPPED,
                        ORDER_STATUS.PROCESSING,
                        ORDER_STATUS.PROCESSED,
                        ORDER_STATUS.DELIVERY_NOT_RECEIVED,
                        ORDER_STATUS.SHIPMENT_EXCEPTION,
                        ORDER_STATUS.DELIVERY_NOT_CONFIRMED,
                    ].indexOf(this.product.status),
                    hide: -1 !== [
                        ORDER_STATUS.CANCELED,
                        ORDER_STATUS.RETURNED,
                        ORDER_STATUS.NOT_APPROVED
                    ].indexOf(this.product.status)
                }
            },
            displayStatus() {

                return this.$t(`orders.status.${this.product.status}`);
            },
            statusDate() {

                return this
                    .d(this.product.statusHistory[0].createdOn, "date")
                    .toString()
                    .replace(/\s/ig, "&nbsp;");
            },
            genericName() {

                return this.products_translateProductName(this.product);
            },
            note() {

                return this.products_translateProductNote(this.product);
            },
            approvalReason() {

                return -1 === [ORDER_STATUS.CANCELED].indexOf(this.product.status)
                    ? get(this.product, "approval.approval_reason", null)
                    : null;
            }
        },
        methods: {
            cancel(product) {

                this.processing.cancel = true;

                this.$store
                    .dispatch("user/impersonation/orders/cancel", {
                        userId: this.userId,
                        orderItemId: product._id,
                        sendEmail: true
                    })
                    .then(() => {

                        this.$modal
                            .show({
                                component: ModalComponentsImpersonationOrdersActionUndo,
                                props: {
                                    message: this.t("control.cancel.message"),
                                    orderProductId: this.product._id,
                                    userId: this.userId
                                }
                            })
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing.cancel = false);
            },
            received() {

                this.processing.received = true;

                return this.$store
                    .dispatch("user/impersonation/orders/received", {
                        userId: this.userId,
                        orderItemId: this.product._id
                    })
                    .then(() => {

                        this.$modal
                            .show({
                                component: ModalComponentsImpersonationOrdersActionUndo,
                                props: {
                                    message: this.t("control.received.message"),
                                    orderProductId: this.product._id,
                                    userId: this.userId
                                }
                            });
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing.received = false);
            }
        }
    }
</script>