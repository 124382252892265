export default {
    types: {
        "ac_router": "Roteador AC",
        "chair": "Cadeira",
        "desk": "Mesa",
        "desktop": "Área de Trabalho",
        "desktop_riser": "Riser de área de trabalho",
        "detector": "Detector de vapor e ruído",
        "displayport_cable": "Cabo DisplayPort",
        "docking station": "Estação de acoplamento",
        "dual_usb_adapter": "Adaptador USB de banda dupla",
        "ear cushion": "Almofada de ouvido",
        "footrest": "Apoio para os pés",
        "hdmi_cable": "Cabo HDMI",
        "hdmi_displayport_cable": "Cabo HDMI para DisplayPort",
        "hdmi_vga_cable": "Cabo HDMI para VGA",
        "headset": "Fone de ouvido",
        "keyboard": "Teclado",
        "kvm switch": "Interruptor KVM",
        "kvm_switch": "Interruptor KVM",
        "lamp": "Lâmpada",
        "laptop stand": "Suporte para laptop",
        "laptop": "Computador portátil",
        "mesh_router": "Roteador de malha",
        "monitor": "Monitor",
        "mouse": "Rato",
        "patch_cord": "Cabo de remendo Cat6",
        "power_center": "Centro de energia",
        "power_strip": "Faixa de energia",
        "smart_plug": "Plugue inteligente mini",
        "smart_power_strip": "Filtro de energia inteligente",
        "thunderbolt_cable": "USB-C para Thunderbolt",
        "travel_router": "Roteador de viagem",
        "usb hub": "Hub USB",
        "usb_adapter": "Adaptador USB",
        "usb_hub": "Hub USB",
        "webcam": "Webcam"
    }
}