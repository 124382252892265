export default {
    status: {
        acknowledged: "ordenado",
        created: "Criada",
        sent: "ordenado",
        confirmed: "ordenado",
        not_confirmed: "Não encomendado",
        delivery_email: "Enviado",
        shipped: "Enviado",
        delayed: "Atrasado",
        preparing: "Preparando",
        processing: "Em processamento",
        processed: "Processado",
        submitted: "ordenado",
        delivery_not_received: "Enviado",
        shipment_exception: "Enviado",
        pending_approval: "Pendente",
        approved: "aprovado",
        not_approved: "Não aprovado",
        canceled: "Cancelado",
        returned: "Devolvida",
        lost_shipment: "Remessa Perdida",
        delivery_not_confirmed: "Entregue",
        delivered: "Entregue",
        delivery_received: "Entregue",
        refurbished: "Devolução concluída",
        returned_to_office: "voltou ao escritório",
        return_to_employer: "Devolvido ao Empregador",
        return_pickup_scheduled: "Coleta programada",
        return_pickup_completed: "Coleta concluída",
        return_pickup_excluded: "Recolha excluída",
        return_pickup_submitted: "Coleta enviada",
        return_pickup_finalized: "Recolha finalizada",
        disposed: "Descartado",
        damaged: "Danificado"
    }
}