export default {
    type: {
        "ac_router": "Hai raggiunto il limite di ordini di router AC",
        "chair": "Hai raggiunto il limite di ordini di sedie",
        "default": "Hai raggiunto il limite degli ordini",
        "default_multiple": "Hai raggiunto il limite di ordini di prodotti simili",
        "desk": "Hai raggiunto il limite di ordinazioni di scrivanie",
        "desktop": "Hai raggiunto il limite di ordini del desktop",
        "desktop_riser": "Hai raggiunto il limite di ordini di alzate da tavolo",
        "detector": "Hai raggiunto il limite di ordini di rilevatori di vaporizzazione e rumore",
        "displayport_cable": "Hai raggiunto il limite di ordini di cavi DisplayPort",
        "docking station": "Hai raggiunto il limite di ordini di docking station",
        "dual_usb_adapter": "Hai raggiunto il limite di ordini di adattatori USB dual band",
        "ear cushion": "Hai raggiunto il limite di ordini di cuscinetti auricolari",
        "footrest": "Hai raggiunto il limite di ordinazioni di pedane",
        "hdmi_cable": "Hai raggiunto il limite di ordini di cavi HDMI",
        "hdmi_displayport_cable": "Hai raggiunto il limite di ordini di cavi da HDMI a DisplayPort",
        "hdmi_vga_cable": "Hai raggiunto il limite di ordini di cavi HDMI-VGA",
        "headset": "Hai raggiunto il limite di ordini di dispositivi audio",
        "keyboard": "Hai raggiunto il limite di ordinazioni di tastiere",
        "kvm switch": "Hai raggiunto il limite di ordini di switch KVM",
        "kvm_switch": "Hai raggiunto il limite di ordini di switch KVM",
        "lamp": "Hai raggiunto il limite di ordinazione di lampade",
        "laptop stand": "Hai raggiunto il limite di ordini di supporti per laptop",
        "laptop": "Hai raggiunto il limite di ordini di laptop",
        "mesh_router": "Hai raggiunto il limite di ordini di router mesh",
        "monitor": "Hai raggiunto il limite di ordini di monitor",
        "mouse": "Hai raggiunto il limite di ordini di mouse",
        "patch_cord": "Hai raggiunto il limite di ordini di cavi di connessione Cat6",
        "power_center": "Hai raggiunto il limite di ordini di Power Center",
        "power_strip": "Hai raggiunto il limite di ordini di prese multiple",
        "smart_plug": "Hai raggiunto il limite di ordini di smart plugs mini",
        "smart_power_strip": "Hai raggiunto il limite di ordini di ciabatte intelligenti",
        "thunderbolt_cable": "Hai raggiunto il limite di ordini da USB-C a Thunderbolt",
        "travel_router": "Hai raggiunto il limite di ordinazione dei router da viaggio",
        "usb hub": "Hai raggiunto il limite di ordini di hub USB",
        "usb_adapter": "Hai raggiunto il limite di ordini di adattatori USB",
        "usb_hub": "Hai raggiunto il limite di ordini di hub USB",
        "webcam": "Hai raggiunto il limite di ordini di webcam"
    }
}