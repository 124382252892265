export default {
    disclaimer: {
        label_default: "Przyjmuję do wiadomości i zgadzam się, że pozycje, które zamawiam/otrzymuję to O-I właściwość do użycia dla O-I czynności związane z pracą i jako takie O-I zastrzega sobie prawo do zażądania zwrotu nieruchomości po moim wyjeździe z O-I (lub wcześniej, jeśli wymaga tego O-I).",
        label_type_1: "Potwierdzam, że samoocena pracy domowej została przeprowadzona dokładnie i w dobrej wierze, a wszelkie wyposażenie lub meble dostarczone przez O-I lokalizacji lub zamówione za pośrednictwem Gear będą przeze mnie wykorzystywane do celów mojej pracy z O-I. Przyjmuję do wiadomości i zgadzam się, że pozycje, które zamawiam/otrzymuję to O-I właściwość do użycia dla O-I czynności związane z pracą i jako takie O-I zastrzega sobie prawo do zażądania zwrotu nieruchomości po moim wyjeździe z O-I (lub wcześniej, jeśli wymaga tego O-I).",
        errors: {
            required: "Proszę zaakceptować warunki użytkowania"
        }
    },
    cost_center: {
        label: "Kod centrum kosztów",
        placeholder: "Kod centrum kosztów",
        cost_center_not_found: "Brak centrów kosztów może zająć do 24 godzin. Skontaktuj się z Global Service Desk, jeśli nie widzisz odpowiedniego centrum kosztów.",
        errors: {
            regex: "Nieprawidłowy kod centrum kosztów"
        }
    },
    company_code: {
        label: "Kod firmowy"
    },
    cost_center_name: {
        label: "Nazwa centrum kosztów"
    },
    order_note: {
        label: "Uwaga do zamówienia",
        placeholder: "Opcjonalna notatka dla kierownika do zatwierdzenia",
    }
}