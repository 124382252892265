export default {
    400: {
        title: "Brutta richiesta",
        header: "@:views.errors.400.title",
        html: "Brutta richiesta. Per favore riprova più tardi."
    },
    401: {
        title: "Abbiamo riscontrato un errore durante l'accesso",
        header: "@:views.errors.401.title",
        html: "Si prega di contattare <a href='{email}' target='_blank'>l'assistenza</a> ."
    },
    404: {
        title: "Pagina non trovata",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Accesso non consentito",
        header: "Accesso non consentito",

        contact: "O-I La periferica è disponibile solo per i dipendenti. Se sei un dipendente e visualizzi questo messaggio, collabora con le persone e la cultura locale per aggiornare il tuo stato.",

        subject: "Ingranaggio - Idoneità",

        html: "<p class='text-left'>@:views.errors.403.eligibility @:views.errors.403.contact</p>",
        error: {
            disabled: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Al momento non sei idoneo. Si prega di contattare <a href='#' role='button' data-widget-trigger='{widgetOptions}'>il supporto</a> per ulteriori informazioni.</p>"
            },
            "not-active": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Al momento non sei idoneo. Si prega di lavorare con le persone e la cultura locali per ulteriori informazioni.</p>"
            },
            "not-allowed":{
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I La periferica è disponibile solo per i dipendenti. Se sei un dipendente e visualizzi questo messaggio, collabora con le persone e la cultura locali per aggiornare il tuo stato.</p>"
            },
            "no-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Non hai un manager attivo elencato nel tuo profilo ServiceNow. Collabora con il tuo rappresentante locale di People &amp; Culture per aggiornare il tuo manager.</p>"
            },
            "self-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Il tuo manager è impostato come te nel tuo profilo ServiceNow. Collabora con il tuo rappresentante locale di People &amp; Culture per aggiornare il tuo manager.</p>"
            },
            "no-location": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Non hai una posizione elencata nel tuo profilo ServiceNow. Collabora con il tuo rappresentante locale di People &amp; Culture per aggiornare la tua posizione.</p>"
            },
            eligibility: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I La periferica è disponibile solo per i dipendenti. Se sei un dipendente e visualizzi questo messaggio, collabora con le persone e la cultura locale per aggiornare il tuo stato.</p>",
            },
            "eligibility-location": {
                header: "Sembra che tu sia un po' in anticipo...",
                html: "<p class='text-left'>Grazie per il tuo interesse per O-I Periferica. Sembra che tu abbia raggiunto il sito prima di essere abilitato e invitato direttamente. O-I La periferica viene implementata gradualmente paese per paese e per gruppi di utenti. Una volta attivato, riceverai un'e-mail contenente un invito personale con le istruzioni.</p><p class='text-left'> Clicca su <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Supporto</a> per domande o problemi con il tuo ordine o il sito.</p>",
            },
            "secondary-account": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Stai tentando di accedere a O-I Periferica su un account secondario. Prova ad accedere di nuovo con il tuo account principale.</p>"
            },
            "empty-core-id": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Il tuo account ServiceNow non ha un nome account. Collabora con il tuo rappresentante locale di People &amp; Culture per aggiornare il tuo account.</p>"
            },
            country: {
                header: "@:views.errors.403.header",
                html: "@:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "no-permissions": {
                header: "Nessun accesso",
                subject: "Nessun accesso",
                html: "Non hai i permessi per aprire questa pagina.",
            },
            "virtual-denied": {
                header: "Sembra che tu sia un po' in anticipo...",
                html: "<p class='text-left'>Grazie per il tuo interesse per O-I Periferica. Sembra che tu abbia raggiunto il sito prima di essere abilitato e invitato direttamente. O-I La periferica viene implementata gradualmente paese per paese e per gruppi di utenti. Una volta attivato, riceverai un'e-mail contenente un invito personale con le istruzioni.</p><p class='text-left'> Clicca su <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Supporto</a> per domande o problemi con il tuo ordine o il sito.</p>",
            }
        }
    },
    500: {
        title: "Errore inaspettato",
        header: "@:views.errors.500.title",
        message: "Si è verificato un errore. Per favore riprova più tardi."
    },
    code: {
        title: "Errore inaspettato",
        message: "@:views.errors.code.title",
        error: "Errore {code}."
    }
}