export default {
    country: {
        label: "land van verblijf",
        errors: {
            required: "Een selectie is vereist"
        }
    },
    entity: {
        label: "Entiteit",
        errors: {
            required: "Een selectie is vereist"
        }
    },
    location: {
        label: "Kantoor locatie",
        errors: {
            required: "Een selectie is vereist"
        }
    },
    workplace: {
        values: {
            "Dedicated Office": {
                title: "Toegewijd kantoor",
                description: "Kantoor aan huis met een vaste locatie ingericht als kantoor"
            },
            "Semi-Dedicated": {
                title: "Semi-toegewijd",
                description: "Tijdelijke locatie om tegemoet te komen aan de noden van een thuiswerkomgeving"
            },
            "Floating": {
                title: "Drijvend",
                description: "Geen aparte ruimte voor thuiskantoor maar wordt gedeeld met andere locaties zoals een eetkamer of woonkamer"
            }
        },
        errors: {
            required: "Een selectie is vereist"
        }
    },
    priority: {
        label: "Hoogste prioriteit",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Docking Station": {
                title: "Docking station"
            },
            "Keyboard/Mouse": {
                title: "Toetsenbord muis"
            },
            "Web Cam": {
                title: "webcam"
            },
            "Headset": {
                title: "Hoofdtelefoon"
            },
            "Office Chair": {
                title: "Bureaustoel"
            },
            "Desk": {
                title: "Bureau"
            },
            "Adapters": {
                title: "Adapters"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Ononderbroken stroomvoorziening (UPS)"
            },
            "Something Else": {
                title: "Iets anders"
            },
            "Nothing Needed": {
                title: "Niets nodig"
            }
        },
        errors: {
            requiredUnless: "Een selectie is vereist",
            requiredIf: "Een selectie is vereist",
            required: "Een selectie is vereist"
        }
    },
    priorityDetails: {
        placeholder: "Geef details"
    },
    secondaryPriority: {
        label: "Tweede hoogste prioriteit",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Second Monitor": {
                title: "Tweede monitor"
            },
            "Docking Station": {
                title: "Docking station"
            },
            "Keyboard/Mouse": {
                title: "Toetsenbord muis"
            },
            "Web Cam": {
                title: "webcam"
            },
            "Headset": {
                title: "Hoofdtelefoon"
            },
            "Office Chair": {
                title: "Bureaustoel"
            },
            "Desk": {
                title: "Bureau"
            },
            "Adapters": {
                title: "Adapters"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Ononderbroken stroomvoorziening (UPS)"
            },
            "Something Else": {
                title: "Iets anders"
            },
            "Nothing Needed": {
                title: "Niets nodig"
            }
        },
        errors: {
            requiredUnless: "Een selectie is vereist",
            requiredIf: "Een selectie is vereist",
            required: "Een selectie is vereist"
        }
    },
    secondaryPriorityDetails: {
        placeholder: "Geef details"
    },
    improveWorkspace: {
        label: "Hoe zou u uw werkruimte verbeteren?",
        placeholder: "Optioneel",
    },
    improveRemoteWorking: {
        label: "Wat zou u, afgezien van apparatuur, helpen om effectiever te werken op afstand?",
        placeholder: "Optioneel",
    },
    receivedEquipmentHeadset: {
        label: "Heeft u in de afgelopen 24 maanden een draadloze headset of speakerphone van het bedrijf ontvangen?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "Nee"
            }
        }
    },
    issuesHeadset: {
        label: "Heb je er problemen mee?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "Nee"
            }
        }
    },
    issuesDescriptionHeadset: {
        placeholder: "Beschrijf uw problemen"
    },
    isOfficeWorker: {
        label: "Werk je fulltime vanuit kantoor?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "Nee"
            }
        }
    },
    receivedKeyboardMouse: {
        label: "Hebt u in de afgelopen 12 maanden een toetsenbord en muis van het Bedrijf ontvangen?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "Nee"
            }
        }
    },
    issuesKeyboardMouse: {
        label: "Heb je er problemen mee?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "Nee"
            }
        }
    },
    issuesDescriptionKeyboardMouse: {
        placeholder: "Beschrijf uw problemen"
    },
    needWebcam: {
        label: "Heb je een externe webcam nodig voor je werk (niet de webcam die in je laptop is ingebouwd?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "Nee"
            }
        }
    },
    needWebcamExternal: {
        label: "Heeft u een externe USB-webcam nodig (niet die in uw laptop is ingebouwd)?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "Nee"
            }
        }
    },
    needMonitor: {
        label: "Heb je een monitor nodig voor je werk?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "Nee"
            }
        }
    },
    receivedMonitor: {
        label: "Hebt u in de afgelopen 4 jaar een monitor van het bedrijf ontvangen?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "Nee"
            }
        }
    },
    issuesMonitor: {
        label: "Heb je er problemen mee?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "Nee"
            }
        }
    },
    issuesDescriptionMonitor: {
        placeholder: "Beschrijf uw problemen"
    },
    needUsbhub: {
        label: "Heb je voor je werk een externe USB hub nodig?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "Nee"
            }
        }
    },
    issuesDescriptionUsbhub: {
        placeholder: "Beschrijf uw problemen"
    }
}