export default {
    400: {
        title: "Zła prośba",
        header: "@:views.errors.400.title",
        html: "Zła prośba. Spróbuj ponownie później."
    },
    401: {
        title: "Wystąpił błąd podczas logowania",
        header: "@:views.errors.401.title",
        html: "Skontaktuj się z <a href='{email}' target='_blank'>pomocą techniczną</a> ."
    },
    404: {
        title: "Strona nie znaleziona",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Brak dostępu",
        header: "Brak dostępu",

        contact: "O-I Urządzenia peryferyjne są dostępne tylko dla pracowników. Jeśli jesteś pracownikiem i widzisz tę wiadomość, współpracuj z lokalnym personelem i kulturą, aby zaktualizować swój status.",

        subject: "Sprzęt — kwalifikowalność",

        html: "<p class='text-left'>@:views.errors.403.eligibility @:views.errors.403.contact</p>",
        error: {
            disabled: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Obecnie nie kwalifikujesz się. Skontaktuj się z <a href='#' role='button' data-widget-trigger='{widgetOptions}'>pomocą techniczną</a> , aby uzyskać więcej informacji.</p>"
            },
            "not-active": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Obecnie nie kwalifikujesz się. Aby uzyskać więcej informacji, skontaktuj się z lokalnymi osobami i kulturą.</p>"
            },
            "not-allowed":{
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I Urządzenia peryferyjne są dostępne tylko dla pracowników. Jeśli jesteś pracownikiem i widzisz tę wiadomość, współpracuj z lokalnym personelem i kulturą, aby zaktualizować swój status.</p>"
            },
            "no-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Nie masz aktywnego menedżera wymienionego w Twoim profilu ServiceNow. Współpracuj z lokalnym przedstawicielem People &amp; Culture, aby zaktualizować swojego menedżera.</p>"
            },
            "self-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Twój menedżer jest ustawiony tak, jak Ty w swoim profilu ServiceNow. Współpracuj z lokalnym przedstawicielem People &amp; Culture, aby zaktualizować swojego menedżera.</p>"
            },
            "no-location": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Nie masz podanej lokalizacji w swoim profilu ServiceNow. Skontaktuj się z lokalnym przedstawicielem People &amp; Culture, aby zaktualizować swoją lokalizację.</p>"
            },
            eligibility: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I Urządzenia peryferyjne są dostępne tylko dla pracowników. Jeśli jesteś pracownikiem i widzisz tę wiadomość, współpracuj z lokalnymi pracownikami i kulturą, aby zaktualizować swój status.</p>",
            },
            "eligibility-location": {
                header: "Wygląda na to, że jesteś trochę za wcześnie...",
                html: "<p class='text-left'>Dziękujemy za zainteresowanie O-I Peryferyjny. Wygląda na to, że dotarłeś do witryny przed bezpośrednim włączeniem i zaproszeniem. O-I Urządzenia peryferyjne są wdrażane etapami w poszczególnych krajach i grupach użytkowników. Po aktywacji otrzymasz wiadomość e-mail zawierającą osobiste zaproszenie z instrukcjami.</p><p class='text-left'> Kliknij <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Wsparcie</a> w przypadku pytań lub problemów z zamówieniem lub witryną.</p>",
            },
            "secondary-account": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Obecnie próbujesz uzyskać dostęp do O-I Urządzenie peryferyjne na koncie dodatkowym. Spróbuj zalogować się ponownie przy użyciu konta podstawowego.</p>"
            },
            "empty-core-id": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Twoje konto ServiceNow nie ma nazwy konta. Skontaktuj się z lokalnym przedstawicielem People &amp; Culture, aby zaktualizować swoje konto.</p>"
            },
            country: {
                header: "@:views.errors.403.header",
                html: "@:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "no-permissions": {
                header: "Brak dostępu",
                subject: "Brak dostępu",
                html: "Nie masz uprawnień, aby otworzyć tę stronę.",
            },
            "virtual-denied": {
                header: "Wygląda na to, że jesteś trochę za wcześnie...",
                html: "<p class='text-left'>Dziękujemy za zainteresowanie O-I Peryferyjny. Wygląda na to, że dotarłeś do witryny przed bezpośrednim włączeniem i zaproszeniem. O-I Urządzenia peryferyjne są wdrażane etapami w poszczególnych krajach i grupach użytkowników. Po aktywacji otrzymasz wiadomość e-mail zawierającą osobiste zaproszenie z instrukcjami.</p><p class='text-left'> Kliknij <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Wsparcie</a> w przypadku pytań lub problemów z zamówieniem lub witryną.</p>",
            }
        }
    },
    500: {
        title: "Niespodziewany błąd",
        header: "@:views.errors.500.title",
        message: "Wystąpił błąd. Spróbuj ponownie później."
    },
    code: {
        title: "Niespodziewany błąd",
        message: "@:views.errors.code.title",
        error: "Błąd {code}."
    }
}