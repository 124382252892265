import {createRouter, createWebHistory} from "vue-router"
import store from "@/store";
import helperPermissions from "@helpers_permissions";

// Routes
import _return from "./routes/return";
import acknowledgement from "./routes/acknowledgement";
import address from "./routes/address";
import approval from "./routes/approval";
import auth from "./routes/auth";
import catalog from "./routes/catalog";
import errors from "./routes/errors";
import helpdesk from "./routes/helpdesk";
import impersonation from "./routes/impersonation";
import main from "./routes/main";
import order from "./routes/order";
import privacy from "./routes/privacy";
import products from "./routes/products";
import quiz from "./routes/quiz";
import survey from "./routes/survey";
import surveys from "./routes/surveys";
import terms from "./routes/terms";
import tunnel from "./routes/tunnel";

import {startAutoLogin} from "@/helpers/auth";
import {AUTOLOGIN_ENABLED} from "@/constants";

const routes = [auth, order, survey, errors, products, catalog, quiz, address, tunnel, surveys, _return, approval, main, acknowledgement, helpdesk, impersonation, privacy, terms]
    .map(i => i.routes)
    .reduce((result, arr) => result.concat(arr), []);

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {

    if (true === AUTOLOGIN_ENABLED && to.meta && false === to.meta.allowAutoLogin) {

        return next({name: "home"});
    }

    /**
     * Route is always allowed
     */
    if (!to.meta || "undefined" === typeof to.meta.requireAuth) {

        return next();
    }

    /**
     * Route requires authentication, but user is not authenticated
     */
    if (true === to.meta.requireAuth && false === store.getters["user/isAuthenticated"]) {

        return true === AUTOLOGIN_ENABLED
            ? startAutoLogin({targeturi: to.fullPath})
            : next({name: "auth.login", query: {...to.query, targeturi: to.path}});
    }

    /**
     * Route forbids being authenticated, but user is
     */
    if (false === to.meta.requireAuth && store.getters["user/isAuthenticated"]) {

        // Logout user
        return next({name: "auth.logout"});
    }

    if (true === to.meta.requireAuth && true === store.getters["user/isAuthenticated"]) {

        if ("auth.logout" === to.name) {

            return next();
        }

        // We need to use async operation here to make sure user data loaded in App component
        const init = setInterval(() => {

            if (null !== store.getters["user/profile/equipment_submitted"] && true === store.getters["user/loaded"]) {

                clearInterval(init);

                // Check for user needs to have approved acknowledgement
                if (true === store.getters["user/requireAcknowledgement"] && true !== store.getters["user/acknowledgement"]) {

                    if ("acknowledgement" === to.name) {

                        return next();
                    }

                    return next({name: "acknowledgement", query: {targeturi: to.fullPath}});
                }

                if (
                    "acknowledgement" === to.name
                    && (
                        true === store.getters["user/acknowledgement"]
                        || false === store.getters["user/requireAcknowledgement"]
                    )
                ) {

                    return next({name: "home"});
                }

                if ("undefined" !== typeof to.meta.permissions) {

                    if (!to.meta.permissions.reduce((result, permission) => result || store.getters["user/permissions/hasPermission"](permission), false)) {

                        return next(helperPermissions.getRouteByPermissionFail(to.meta.permissions[0]));
                    }
                }

                const forceQuiz = store.getters["user/profile/forceQuizEquipment"];

                if (forceQuiz && -1 == ["quiz", "auth.logout", "surveys.view"].indexOf(to.name)) {

                    return next({name: "quiz", params: {init: true}});
                }

                next();
            }
        }, 10);

        return;
    }

    next();
});

export default router
