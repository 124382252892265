<template>
    <div>
        <!-- Loading -->
        <div v-if="loading" class="container">
            <div class="row">
                <div class="col-12">
                    <loading/>
                </div>
            </div>
        </div>

        <!-- User country is not eligible for returning items-->
        <page-message v-else-if="message.eligibility">
            <template #header>{{t('notEligible.title')}}</template>
            <template #html>
                <div v-html="t('notEligible.message')"></div>
            </template>
            <template #cta>
                <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
            </template>
        </page-message>

        <!-- User does not have items to return-->
        <page-message v-else-if="message.nothingToReturn">
            <template #header>{{t('nothingToReturn.title')}}</template>
            <template #html>
                <div v-html="t('nothingToReturn.message')"></div>
            </template>
            <template #cta>
                <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
            </template>
        </page-message>

        <!-- Success -->
        <page-message v-else-if="message.success">
            <template #header>{{t('submitted.title')}}</template>
            <template #html>
                <div v-html="t('submitted.message')"></div>
            </template>
            <template #cta>
                <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
            </template>
        </page-message>

        <!-- Items to return-->
        <div class="container" v-else-if="0 <= model.items.length">
            <form @submit.prevent="base_submit('model', 'submit')">
                <div class="row">
                    <div class="col-12 col-lg-8">
                        <h3>{{t('header')}}</h3>
                        <p>{{t('message')}}</p>

                        <div v-for="(item, itemIndex) in model.items" :key="'product_' + item.id"
                             class="views-order-submit__product">
                            <div class="d-flex">
                                <!-- Product image -->
                                <img class="views-order-submit__product__image"
                                     :src="products[item.id].pictures[0].medium"
                                     :alt="products[item.id].name">

                                <div class="flex-grow-1 pl-3">
                                    <div>
                                        <!-- Product name -->
                                        <div class="views-order-submit__product__name pb-2">
                                            {{products[item.id].name}}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-for="(additionalItem, additionalItemIndex) in model.additional_items"
                             :key="'additional_product_' + additionalItemIndex">
                            <div class="row">
                                <div class="col-4">
                                    <form-select select-class="form-control form-control-sm"
                                                 v-model="model.additional_items[additionalItemIndex].type"
                                                 :validator="v$.model.additional_items[additionalItemIndex].type"
                                                 :label="t('model.additional_item.type.label')"
                                                 :options="display.additional_items_supported_types"
                                                 :disabled="processing"></form-select>
                                </div>
                                <div class="col-4">
                                    <form-textarea textarea-class="form-control form-control-sm"
                                                   v-model="model.additional_items[additionalItemIndex].detail"
                                                   :validator="v$.model.additional_items[additionalItemIndex].detail"
                                                   :label="t('model.additional_item.detail.label')"
                                                   :placeholder="t('model.additional_item.detail.placeholder')"
                                                   :rows="2"
                                                   :disabled="processing"/>
                                </div>
                                <div class="col-4">
                                    <form-textbox textbox-class="form-control form-control-sm"
                                                  v-model="model.additional_items[additionalItemIndex].quantity"
                                                  :validator="v$.model.additional_items[additionalItemIndex].quantity"
                                                  :label="t('model.additional_item.quantity.label')"
                                                  :placeholder="t('model.additional_item.quantity.placeholder')"
                                                  :disabled="processing"/>
                                </div>
                            </div>
                        </div>

                        <div v-if="display.additional_items_supported" class="form-group text-center">
                            <button type="button" class="btn btn-sm btn-primary" :disabled="processing" @click="add">
                                {{t('button.add.cta')}}
                            </button>
                        </div>

                        <form-textarea textarea-class="form-control form-control-sm"
                                       v-model="model.return_note"
                                       :validator="v$.model.return_note"
                                       :label="t('model.return_note.label')"
                                       :placeholder="t('model.return_note.placeholder')"
                                       :rows="3"
                                       :disabled="processing"/>

                        <div v-if="display.additional_disposal" class="form-group">
                            <form-checkbox :disabled="processing"
                                           :label="t('model.additional_disposal.label')"
                                           v-model="model.additional_disposal"/>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4">
                        <panel class="panel-secondary">

                            <form-select :model-value="model.address.country"
                                         select-class="form-control form-control-sm"
                                         :label="t('model.address.country.label')"
                                         :options="countries"
                                         option-value="alpha3"
                                         :disabled="true"></form-select>

                            <div v-for="(field, index) in address_form_getAddressFields(addressConfig)"
                                 :key="field.field">

                                <form-textbox v-if="fieldTypes.text == field.type"
                                              textbox-class="form-control form-control-sm"
                                              v-model="model.address[field.field]"
                                              :validator="v$.model.address[field.field]"
                                              :label="address_form_getAddressFieldTranslation(field, 'label', model.address.country)"
                                              :placeholder="address_form_getAddressFieldTranslation(field, 'placeholder', model.address.country)"
                                              :focus="0==index"
                                              :errors="addressErrors[field.field]"
                                              :mask="field.mask"
                                              :maxlength="field.maxlength"
                                              :max-characters="'undefined'===typeof field.mask?field.maxlength:null"
                                              :disabled="processing"/>

                                <form-textarea v-if="fieldTypes.textarea == field.type"
                                               textarea-class="form-control form-control-sm"
                                               v-model="model.address[field.field]"
                                               :validator="v$.model.address[field.field]"
                                               :label="address_form_getAddressFieldTranslation(field, 'label', model.address.country)"
                                               :placeholder="address_form_getAddressFieldTranslation(field, 'placeholder', model.address.country)"
                                               :focus="0==index"
                                               :errors="addressErrors[field.field]"
                                               :maxlength="field.maxlength"
                                               :rows="field.rows"
                                               :max-characters="'undefined'===typeof field.mask?field.maxlength:null"
                                               :disabled="processing"/>

                                <form-select v-if="fieldTypes.dropdown == field.type"
                                             select-class="form-control form-control-sm"
                                             v-model="model.address[field.field]"
                                             :validator="v$.model.address[field.field]"
                                             :label="address_form_getAddressFieldTranslation(field, 'label', model.address.country)"
                                             :options="address_form_getAddressFieldOptions(field)"
                                             :focus="0==index"
                                             :errors="addressErrors[field.field]"
                                             :disabled="processing"/>
                            </div>

                            <div class="form-group text-center pt-2">
                                <button type="submit" class="btn btn-sm btn-primary" :disabled="processing">
                                    <spinner v-if="processing"/>
                                    {{processing ? t('button.submit.processing') : t('button.submit.cta')}}
                                </button>
                            </div>
                        </panel>
                    </div>
                </div>
            </form>
        </div>


    </div>
</template>

<script>

    import {mapGetters} from "vuex"
    import useVuelidate from "@vuelidate/core";
    import {minValue, required, integer} from "@vuelidate/validators"

    import mixin_view from "@/mixins/view";
    import mixin_address_form from "@/mixins/address/form";

    import PageMessage from "@/components/page/PageMessage";
    import Loading from "@/components/Loading";
    import FormTextbox from "@/components/form/FormTextbox";
    import FormSelect from "@/components/form/FormSelect";
    import FormTextarea from "@/components/form/FormTextarea";
    import Panel from "@/components/Panel";
    import Spinner from "@/components/Spinner";
    import FormCheckbox from "@/components/form/FormCheckbox";

    const fieldTypes = {
        text: "text",
        textarea: "textarea",
        dropdown: "dropdown"
    };

    const model = {};

    export default {
        name: "ViewsReturn",
        mixins: [mixin_view, mixin_address_form],
        components: {
            FormCheckbox,
            Spinner,
            Panel,
            Loading,
            PageMessage,
            FormTextbox,
            FormSelect,
            FormTextarea,
        },
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        data() {

            const addressConfig = this.$store.getters["user/address/configReturn"]

            model.items = [];
            model.additional_items = [];
            model.return_note = null;
            model.additional_disposal = false;
            model.address = {
                country: this.$store.getters["user/profile/country"],
            };

            this.address_form_getAddressFields(addressConfig).forEach(field => model.address[field.field] = null);

            return {
                t_path: "views.return.return",

                loading: false,
                processing: false,
                message: {
                    eligibility: false,
                    nothingToReturn: false,
                    success: false
                },
                products: {},
                display: {
                    additional_items_supported_types: [],
                    additional_disposal: false,
                    additional_items_supported: false
                },

                addressConfig,
                model,
                fieldTypes,
            }
        },
        computed: {
            ...mapGetters({
                countries: "db/countries/items",
            }),
            addressErrors() {

                return this.address_form_getErrorMessages(
                    this.address_form_getAddressFields(this.addressConfig),
                    this.model.country
                );
            }
        },
        validations() {

            const model = {
                address: {
                    ...this.address_form_createValidationRules(
                        this.address_form_getAddressFields(this.addressConfig)
                    ).model
                },
                items: {},
                return_note: {},
                additional_items: {}
            };

            this.model.items.forEach((item, itemIndex) => {
                model.items[itemIndex] = {
                    id: {
                        required,
                    }
                };
            });

            this.model.additional_items.forEach((item, itemIndex) => {
                model.additional_items[itemIndex] = {
                    type: {
                        required
                    },
                    detail: {
                        required
                    },
                    quantity: {
                        required,
                        integer,
                        minValue: minValue(1)
                    }
                }
            });

            return {
                model,
            }
        },
        methods: {
            submit() {

                const userId = this.$store.getters["user/id"];
                const request = {
                    address: this.model.address,
                    items: this.model.items.map(item => ({
                        id: item.id,
                        status: "return_pickup_submitted"
                    })),
                    return_properties: {
                        return_note: this.model.return_note,
                        additional_disposal: this.model.additional_disposal,
                        additional_items: this.model.additional_items
                    }
                }

                this.$activity.log("return_products_submit", request);

                this.$order
                    .post(`/users/${userId}/items/return-pickup`, request)
                    .then(() => this.message.success = true)
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing = false);
            },
            add() {

                this.model.additional_items.push({
                    type: null,
                    detail: null,
                    quantity: null
                });
            },
            fetch() {

                const userId = this.$store.getters["user/id"];

                this.loading = true;

                return Promise
                    .all([
                        this.$order.get(`/users/${userId}/items/eligible-return-pickup`),
                        this.$order.get(`/items/refurbishment-constraints`, {params: {country: this.$store.getters["user/profile/country"]}})
                    ])
                    .then(responses => {

                        const [responseProducts, responseConstraints] = responses;

                        const products = responseProducts.data.results;
                        const constraints = responseConstraints.data.results;

                        // Set display options
                        this.display.additional_items_supported_types = constraints.supported_types
                            ? constraints.supported_types.map(value => ({
                                name: this.t(`model.additional_item.type.options.${value.toLowerCase()}`),
                                value
                            }))
                            : [];
                        this.display.additional_disposal = !!constraints.additional_disposal_support;
                        this.display.additional_items_supported = !!constraints.additional_items_supported;

                        if (0 === products.length) {
                            // Show appropriate message
                            this.message.nothingToReturn = true;

                            this.$activity.log("return_no_products", {});

                            return false;
                        }

                        this.$activity.log("return_products", {
                            products
                        });

                        // Prepare products list
                        this.products = products.reduce((result, product) => {

                            result[product._id] = product;

                            return result;
                        }, {});

                        // Select all products
                        this.model.items = products.map(item => ({id: item._id}));
                    })
                    .catch(error => {

                        if (error.response && 400 == error.response.status) {

                            this.message.eligibility = true;
                            return false;
                        }

                        this.base_error(error);
                    })
                    .finally(() => this.loading = false);
            },
        },
        watch: {
            "model.exclude": {
                deep: true,
                handler(exclude) {

                    const keys = Object.keys(exclude);

                    this.model.include.splice(0);

                    this.products.forEach(product => {

                        if (-1 === keys.indexOf(product.item.id)) {

                            this.model.include.push(product.item.id);
                        }
                    });
                }
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>