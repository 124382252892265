export default {
    error: "Un problème est survenu lors de la récupération des commandes. S'il vous plaît rafraîchir votre navigateur.",
    title: "Historique des commandes",
    loadMore: "Charger plus",
    noOrders: "Aucune commande",
    user: {
        loadMore: "Charger plus",
        product: {
            title: "Commande",
            tracking: "Suivi",
            quantity: "Quantité",
            reason: "Raison",
            control: {
                cancel: {
                    cta: "Cliquez pour annuler",
                    tooltip: "Cliquez pour annuler cet article",
                    message: "Nous avons annulé votre commande."
                },
                received: {
                    cta: "Produit reçu",
                    tooltip: "Cliquez pour confirmer la livraison",
                    message: "Merci d'avoir confirmé que vous avez reçu votre produit."
                },
                notReceived: {
                    cta: "Produit non reçu",
                    tooltip: "Cliquez pour confirmer la non-livraison"
                },
                hide: {
                    cta: "Masquer l'élément",
                    tooltip: "Cliquez pour masquer l'élément de la liste"
                }
            }
        }
    }
}