export default {
    title: "Se requiere verificación de inicio de sesión",
    header: "@:views.auth.confirm.title",
    message: "Para continuar, consulte el correo electrónico de su empresa para obtener un enlace de verificación de correo electrónico. Haga clic en el enlace del correo electrónico para continuar.",
    messageBeforeCta: "Si no ve el correo electrónico, haga clic en",
    messageAfterCta: "para reenviar",
    secondMessage: "Si aún no ve el correo electrónico, verifique dos veces su carpeta de correo no deseado o contáctenos en <a href='mailto:{email}?subject={subject}'>{email}</a> .",
    subject: "Engranaje - TFA",
    buttons: {
        cta: "aquí",
        processing: "aquí"
    },
    headerAlreadyConfirmed: "Verificacion de inicio de sesion",
    messageAlreadyConfirmed: "Ya has confirmado tu correo electrónico."
}