const text = {
    countryAfterComponentsBeforeSelection: "Nota che la tua scelta del Paese verrà utilizzata come parte del tuo indirizzo di spedizione per inviare i prodotti direttamente a te. Se non vedi il tuo Paese nell'elenco, Gear non è attualmente disponibile nel tuo Paese.",
    countryAfterComponentsAllowedSelection: "Nota che la tua scelta del Paese verrà utilizzata come parte del tuo indirizzo di spedizione per inviare i prodotti direttamente a te.",
    countryAfterComponentsLimitedSelection: "Gear è attualmente disponibile nel tuo paese, ma la sua apertura è graduale. A meno che tu non abbia ricevuto la notifica, potresti non essere in grado di accedere a Gear in questo momento nel tuo paese.",
    disclaimer: "Facendo clic sul pulsante successivo di seguito, riconosco di essere un dipendente della Società e acconsento alla Società e al suo partner RemoteRetail di archiviare ed elaborare le informazioni personali inviate su questo sito per fornirmi il contenuto richiesto. RemoteRetail ha bisogno delle informazioni di contatto che fornisco in modo che possano contattarmi con informazioni relative ai miei ordini. I fornitori di terze parti possono anche ricevere le mie informazioni per spedirmi i prodotti.</p><p> Posso annullare l'iscrizione a queste comunicazioni in qualsiasi momento. Per ulteriori informazioni su come annullare l'iscrizione, le pratiche sulla privacy e gli impegni per proteggere e rispettare la mia privacy, consultare l' <a href='{privacyPolicyUrl}' target='_blank'>Informativa sulla privacy</a> ."
};

export default {
    title: "Benvenuto, {userName}!",
    description: {
        default: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`,
        virtualworker: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories that you need to work effectively from home. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`
    },
    text: {
        default: "Per iniziare, ti faremo una serie di domande.",
        virtualworker: "Per iniziare, ti faremo una serie di domande per aiutarci a capire il tuo spazio di lavoro da casa per aiutare a plasmare la direzione futura di questo programma."
    },
    afterComponents: {
        beforeSelection: `${text.countryAfterComponentsBeforeSelection}`,
        allowedSelection: `${text.countryAfterComponentsAllowedSelection}`,
        limitedSelection: `${text.countryAfterComponentsLimitedSelection}`,
        autoLoginBeforeSelection: `${text.countryAfterComponentsBeforeSelection}<br/><br/>${text.disclaimer}`,
        autoLoginAllowedSelection: `${text.countryAfterComponentsAllowedSelection}<br/><br/>${text.disclaimer}`,
        autoLoginLimitedSelection: `${text.countryAfterComponentsLimitedSelection}<br/><br/>${text.disclaimer}`
    },
    buttons: {
        back: "Indietro",
        next: "Prossimo",
        submit: "Invia"
    },
    entityinfo: {
        title: "Informazioni sull'entità",
        description: "Per aiutarci a indirizzare correttamente il tuo ordine, ti preghiamo di fornirci le seguenti informazioni:",
    },
    equipmentsurvey: {
        title: "Indagine sull'attrezzatura",
        description: "La pagina successiva ti chiederà il tipo di attrezzatura di cui hai più bisogno per aiutare l'azienda a comprendere meglio le priorità.",
        info: "Si prega di notare che l'attrezzatura mostrata è solo a scopo di raccolta di informazioni e non implica che la Società fornirà tale attrezzatura in futuro."
    },
    workplace: {
        title: "Raccontaci di più sul tuo ufficio a casa?",
        description: "Per aiutarci a comprendere meglio il tuo spazio di lavoro, seleziona una delle opzioni seguenti",
    },
    priority: {
        title: "Priorità dell'attrezzatura",
        description: "Seleziona le tue prime due scelte di attrezzature di cui hai più bisogno"
    },
    "additional-questions": {
        title: "Domande aggiuntive"
    },
    "equipment-assessment": {
        title: "Valutazione dell'attrezzatura",
        description: "La seguente serie di domande <b>determinerà quale attrezzatura sarà disponibile per te da ordinare all'interno di Gear in base alle tue risposte.</b>"
    },
    headset: {
        title: "Attrezzatura attuale"
    },
    "office-worker": {
        title: "Ufficio"
    },
    "keyboard-mouse": {
        title: "Tastiera e mouse"
    },
    webcam: {
        title: "Webcam"
    },
    monitor: {
        title: "Tenere sotto controllo"
    },
    usbhub: {
        title: "Hub USB"
    },
    "equipment-list": {
        title: "Attrezzatura",
        init: "initEquipmentList",
        description: {
            loading: "<span class=\"animate-spin icon-spinner\"></span>",
            error: "Brutta richiesta. Si è verificato un errore.",
            allowed: "In base alle tue risposte, avrai diritto ai seguenti elementi:{items}",
            empty: "In base alle tue risposte, al momento non sarai idoneo per nessun oggetto in Gear."
        },
        text: "<ul class=\"text-left\" style=\"width: 300px; margin: 2rem auto 0\">{list}</ul>",
        afterComponents: "<small>Se è necessario apportare correzioni, premere il pulsante Indietro per modificare le risposte.</small>"
    },
    saved: "Salvato"
}