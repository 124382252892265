export default {
    methods: {
        address_isIncompleted(address, {tags}) {

            tags = "undefined" === typeof tags ? [] : tags;

            return true === address.incomplete
                || (
                    0 < tags.length
                    && this.$store.getters["user/address/config"].reduce(
                        (isIncompleted, field) => true === isIncompleted
                            || (
                                false === isIncompleted
                                && !address[field.field]
                                && field.validation
                                && field.validation.required
                                && "undefined" !== typeof field.tags
                                && 0 < field.tags.length
                                && 0 < field.tags.filter(fieldTag => -1 !== tags.indexOf(fieldTag)).length
                            ),
                        false
                    )
                );
        }
    }
}