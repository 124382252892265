export default {
    400: {
        title: "Rossz kérés",
        header: "@:views.errors.400.title",
        html: "Rossz kérés. Kérlek, próbáld újra később."
    },
    401: {
        title: "Hibát észleltünk a bejelentkezés során",
        header: "@:views.errors.401.title",
        html: "Kérjük, lépjen kapcsolatba <a href='{email}' target='_blank'>az ügyfélszolgálattal</a> ."
    },
    404: {
        title: "Az oldal nem található",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Hozzáférés nem engedélyezett",
        header: "A hozzáférés nem engedélyezett",

        contact: "O-I A periféria csak az alkalmazottak számára érhető el. Ha Ön alkalmazott, és látja ezt az üzenetet, kérjük, lépjen kapcsolatba a helyi emberekkel és kultúrával, hogy frissítse állapotát.",

        subject: "Felszerelés – Jogosultság",

        html: "<p class='text-left'>@:views.errors.403.eligibility @:views.errors.403.contact</p>",
        error: {
            disabled: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Jelenleg nem jogosult. További információért forduljon <a href='#' role='button' data-widget-trigger='{widgetOptions}'>az ügyfélszolgálathoz</a> .</p>"
            },
            "not-active": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Jelenleg nem jogosult. További információért kérjük, működjön együtt a helyi emberekkel és kultúrával.</p>"
            },
            "not-allowed":{
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I A periféria csak az alkalmazottak számára érhető el. Ha Ön alkalmazott, és látja ezt az üzenetet, kérjük, lépjen kapcsolatba a helyi emberekkel és kultúrával, hogy frissítse állapotát.</p>"
            },
            "no-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Nincs aktív kezelője a ServiceNow-profiljában. Kérjük, működjön együtt helyi People &amp; Culture képviselőjével, hogy frissítse a menedzserét.</p>"
            },
            "self-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Az Ön menedzsere a ServiceNow-profiljában Önként van beállítva. Kérjük, működjön együtt helyi People &amp; Culture képviselőjével, hogy frissítse a menedzserét.</p>"
            },
            "no-location": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Nem szerepel a ServiceNow-profiljában szereplő hely. Kérjük, lépjen kapcsolatba helyi People &amp; Culture képviselőjével a tartózkodási helyének frissítése érdekében.</p>"
            },
            eligibility: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I A periféria csak az alkalmazottak számára érhető el. Ha Ön alkalmazott, és látja ezt az üzenetet, kérjük, működjön együtt a helyi emberekkel és kultúrával, hogy frissítse állapotát.</p>",
            },
            "eligibility-location": {
                header: "Úgy tűnik, kicsit korán jöttél...",
                html: "<p class='text-left'>Köszönjük érdeklődését O-I Kerületi. Úgy tűnik, hogy az engedélyezés és a közvetlen meghívás előtt elérte a webhelyet. O-I A perifériát szakaszosan, országonként és felhasználói csoportonként vezetik be. Amikor aktiválódik, egy e-mailt fog kapni, amely egy személyes meghívót tartalmaz az utasításokkal.</p><p class='text-left'> Kattintson <a href='#' role='button' data-widget-trigger='{widgetOptions}'>a Támogatás</a> elemre, ha kérdése vagy problémája van a rendelésével vagy az oldallal kapcsolatban.</p>",
            },
            "secondary-account": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Jelenleg megpróbál hozzáférni O-I Periféria másodlagos fiókon. Kérjük, próbáljon meg újra bejelentkezni az elsődleges fiókjával.</p>"
            },
            "empty-core-id": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>ServiceNow-fiókjának nincs fiókneve. Kérjük, lépjen kapcsolatba helyi People &amp; Culture képviselőjével fiókja frissítéséhez.</p>"
            },
            country: {
                header: "@:views.errors.403.header",
                html: "@:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "no-permissions": {
                header: "Nincs hozzáférés",
                subject: "Nincs hozzáférés",
                html: "Nincs engedélye az oldal megnyitásához.",
            },
            "virtual-denied": {
                header: "Úgy tűnik, kicsit korán jöttél...",
                html: "<p class='text-left'>Köszönjük érdeklődését O-I Kerületi. Úgy tűnik, hogy az engedélyezés és a közvetlen meghívás előtt elérte a webhelyet. O-I A perifériát szakaszosan, országonként és felhasználói csoportonként vezetik be. Amikor aktiválódik, kap egy e-mailt, amely egy személyes meghívót tartalmaz az utasításokkal.</p><p class='text-left'> Kattintson <a href='#' role='button' data-widget-trigger='{widgetOptions}'>a Támogatás</a> elemre, ha kérdése vagy problémája van a rendelésével vagy az oldallal kapcsolatban.</p>",
            }
        }
    },
    500: {
        title: "Váratlan hiba",
        header: "@:views.errors.500.title",
        message: "Hiba lépett fel. Kérlek, próbáld újra később."
    },
    code: {
        title: "Váratlan hiba",
        message: "@:views.errors.code.title",
        error: "Hiba {code}."
    }
}