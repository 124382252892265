export default {
    model: {
        email: {
            label: "Email",
            placeholder: "E-Mail delegieren"
        },
        start_on: {
            label: "Startdatum",
            placeholder: "Datum für den Beginn der Delegation"
        },
        end_on: {
            label: "Endtermin",
            placeholder: "Optional"
        },
        accept: {
            tip: "Indem Sie Ihre Genehmigungsverantwortung delegieren, bestätigen Sie, dass Sie die Verantwortung und Verantwortung für alle von Ihrem Delegierten genehmigten Bestellungen übernehmen.",
            label: "Ich akzeptiere"
        }
    },
    buttons: {
        submit: {
            cta: "Bestätigen",
            processing: "Sparen"
        },
        close: {
            cta: "Stornieren"
        }
    },
    saved: "Gerettet"
}