export default {
    unverified: "Não verificado",
    verificationLink: "Reenviar email de verificação",
    buttons: {
        delete: {
            cta: "Excluir",
            processing: "Apagando"
        }
    },
    addressDeleted: "O endereço foi excluído",
    verificationEmailSent: "O e-mail foi enviado",
    deleteAddressConfirmation: "Tem certeza de que deseja excluir este endereço?"
}