export default {
    status: {
        acknowledged: "Ordenado",
        created: "Creado",
        sent: "Ordenado",
        confirmed: "Ordenado",
        not_confirmed: "No ordenado",
        delivery_email: "Enviado",
        shipped: "Enviado",
        delayed: "Demorado",
        preparing: "Preparando",
        processing: "Procesando",
        processed: "Procesada",
        submitted: "Ordenado",
        delivery_not_received: "Enviado",
        shipment_exception: "Enviado",
        pending_approval: "Pendiente",
        approved: "Aprobado",
        not_approved: "No aprovado",
        canceled: "Cancelado",
        returned: "devuelto",
        lost_shipment: "Envío perdido",
        delivery_not_confirmed: "Entregado",
        delivered: "Entregado",
        delivery_received: "Entregado",
        refurbished: "Devolución completada",
        returned_to_office: "Regresó a la oficina",
        return_to_employer: "Devuelto al empleador",
        return_pickup_scheduled: "Recogida programada",
        return_pickup_completed: "Recogida completada",
        return_pickup_excluded: "Recogida Excluida",
        return_pickup_submitted: "Recogida enviada",
        return_pickup_finalized: "Recogida finalizada",
        disposed: "Dispuesto",
        damaged: "Dañado"
    }
}