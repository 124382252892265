export default {
    type: {
        "ac_router": "Elérte a váltakozó áramú útválasztók rendelési korlátját",
        "chair": "Elérte a székrendelési limitet",
        "default": "Elérte a rendelési korlátot",
        "default_multiple": "Elérte a hasonló termékek rendelési határát",
        "desk": "Elérte az asztalok rendelési korlátját",
        "desktop": "Elérte az asztali számítógépek rendelési korlátját",
        "desktop_riser": "Elérte az asztali felszállók rendelési korlátját",
        "detector": "Elérte a gőz- és zajérzékelők rendelési határát",
        "displayport_cable": "Elérte a displayport kábelek rendelési korlátját",
        "docking station": "Elérte a dokkolóállomások rendelési korlátját",
        "dual_usb_adapter": "Elérte a kétsávos USB-adapterek rendelési korlátját",
        "ear cushion": "Elérte a fülpárnák rendelési határát",
        "footrest": "Elérte a lábtartók rendelési határát",
        "hdmi_cable": "Elérte a HDMI-kábelek rendelési korlátját",
        "hdmi_displayport_cable": "Elérte a HDMI–DisplayPort kábelek rendelési korlátját",
        "hdmi_vga_cable": "Elérte a HDMI–VGA-kábelek rendelési korlátját",
        "headset": "Elérte az audioeszközök rendelési korlátját",
        "keyboard": "Elérte a billentyűzetek rendelési korlátját",
        "kvm switch": "Elérte a kvm kapcsolók rendelési határát",
        "kvm_switch": "Elérte a kvm kapcsolók rendelési határát",
        "lamp": "Elérte a lámpák rendelési határát",
        "laptop stand": "Elérte a laptop állványok rendelési határát",
        "laptop": "Elérte a laptopok rendelési korlátját",
        "mesh_router": "Elérte a hálós útválasztók rendelési korlátját",
        "monitor": "Elérte a monitorok rendelési korlátját",
        "mouse": "Elérte az egerek rendelési korlátját",
        "patch_cord": "Elérte a Cat6 patch kábelek rendelési határát",
        "power_center": "Elérte a Power Centerek rendelési korlátját",
        "power_strip": "Elérte az elosztók rendelési határát",
        "smart_plug": "Elérte a mini intelligens csatlakozók rendelési határát",
        "smart_power_strip": "Elérte az intelligens elosztók rendelési határát",
        "thunderbolt_cable": "Elérte az USB-C rendelési korlátját a Thunderbolt számára",
        "travel_router": "Elérte az utazási útválasztók rendelési korlátját",
        "usb hub": "Elérte az USB-elosztók rendelési korlátját",
        "usb_adapter": "Elérte az USB-adapterek rendelési korlátját",
        "usb_hub": "Elérte az USB-elosztók rendelési korlátját",
        "webcam": "Elérte a webkamerák rendelési korlátját"
    }
}