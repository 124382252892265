export default {
    title: {
        new: "Add New Address",
        complete: "Complete Address",
        edit: "Edit Address"
    },

    incomplete: "Please fill required address fields",

    info: {
        "CHN": "Please enter your address in Chinese. </br>请用中文输入你的地址.",
        "BGR": "Enter your <b>home address</b> in English to receive your items.",
        "PRI": "Please enter your home address not a PO box to receive packages.",
        "SGP": "Please include your unit # in your address.",
        "SVK": "Please enter your address in <b>English</b>",
        "POL": "Please enter your address in <b>English</b>"
    },

    model: {
        country: {
            label: "Country"
        }
    },

    field: {
        default: {
            "name": {
                "label": {
                    "Name": "Name"
                },
                "placeholder": {
                    "Name": "Name",
                    "Name in English": "Name in English"
                }
            },
            personalEmail: {
                label: {
                    "Personal Email": "Personal Email"
                },
                placeholder: {
                    "Personal Email": "Personal Email"
                }
            },
            managerEmail: {
                label: {
                    "Manager Email": "Manager's Email"
                },
                placeholder: {
                    "Manager Email": "Manager's Email"
                }
            },
            managerName: {
                label: {"Manager Name": "Manager's Name"},
                placeholder: {"Manager Name": "Manager's Name"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Zipcode",
                    "Post Code": "Post Code",
                    "Postal Code": "Postal Code",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "Zipcode",
                    "Post Code": "Post Code",
                    "Postal Code": "Postal Code",
                    "CPA": "CPA"
                }
            },
            "province": {
                "label": {
                    "Province": "Province",
                    "Prefecture": "Prefecture",
                    "Province/Region": "Province/Region",
                    "Province Code": "Province Code"
                },
                "placeholder": {
                    "Province": "Province",
                    "Prefecture": "Prefecture",
                    "Province/Region": "Province/Region",
                    "Province Code": "Province Code"
                }
            },
            "phoneNumber": {
                "label": {
                    "Phone Number": "Phone Number"
                },
                "placeholder": {
                    "Phone Number": "Phone Number"
                }
            },
            "firstStreet": {
                "label": {
                    "Street": "Street",
                    "Tax ID": "Tax ID"
                },
                "placeholder": {
                    "Street": "Street",
                    "Street in English": "Street in English",
                    "Tax ID (RUT)": "Tax ID (RUT)",
                    "Number/Street/Apartment": "Number/Street/Apartment"
                }
            },
            "secondStreet": {
                "label": {
                    "District": "District",
                    "Colonia": "Colonia",
                    "Canton": "Canton",
                    "Street": "Street"
                },
                "placeholder": {
                    "District": "District",
                    "Colonia": "Colonia",
                    "Canton": "Canton",
                    "Number/Street/Apartment": "Number/Street/Apartment"
                }
            },
            "city": {
                "label": {
                    "City": "City",
                    "Town/City/Locality": "Town/City/Locality",
                    "Town/City": "Town/City",
                    "City/Municipality/Locality": "City/Municipality/Locality",
                    "City/Town/Locality": "City/Town/Locality",
                    "City/Town": "City/Town",
                    "District": "District",
                    "City/Town/Locallity": "City/Town/Locallity",
                    "Town/City/Sub Locality": "Town/City/Sub Locality"
                },
                "placeholder": {
                    "City": "City",
                    "Town/City": "Town/City",
                    "Town/City/Locality": "Town/City/Locality",
                    "City/Municipality/Locality": "City/Municipality/Locality",
                    "City/Town/Locality": "City/Town/Locality",
                    "City/Town in English": "City/Town in English",
                    "District": "District",
                    "City/Town/Locallity": "City/Town/Locallity",
                    "Town/City/Sub Locality": "Town/City/Sub Locality"
                }
            },
            identifier: {
                label: {
                    "identifier": "Identifier",
                    "Identifier": "Identifier"
                },
                placeholder: {
                    "identifier": "Identifier",
                    "Identifier": "Identifier"
                }
            },
            "region": {
                "label": {
                    "Region": "Region",
                    "County": "County",
                    "Locality": "Locality"
                },
                "placeholder": {
                    "Region": "Region",
                    "County": "County",
                    "Locality": "Locality"
                }
            },
            "poBox": {
                "label": {
                    "PO BOX": "PO BOX"
                },
                "placeholder": {
                    "PO BOX": "PO BOX"
                }
            },
            state: {
                "label": {
                    "State": "State",
                    "Province/Region": "Province/Region",
                    "Province": "Province",
                    "Department": "Department",
                    "Municipality/Comuna": "Municipality/Comuna"
                },
                "placeholder": {
                    "State": "State",
                    "Province/Region": "Province/Region",
                    "Province": "Province",
                    "Department": "Department",
                    "Municipality/Comuna": "Municipality/Comuna"
                },
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alaska",
                    "Arizona": "Arizona",
                    "Arkansas": "Arkansas",
                    "California": "California",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "District Of Columbia",
                    "Florida": "Florida",
                    "Georgia": "Georgia",
                    "Hawaii": "Hawaii",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Louisiana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Mississippi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebraska",
                    "Nevada": "Nevada",
                    "New Hampshire": "New Hampshire",
                    "New Jersey": "New Jersey",
                    "New Mexico": "New Mexico",
                    "New York": "New York",
                    "North Carolina": "North Carolina",
                    "North Dakota": "North Dakota",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregon",
                    "Pennsylvania": "Pennsylvania",
                    "Rhode Island": "Rhode Island",
                    "South Carolina": "South Carolina",
                    "South Dakota": "South Dakota",
                    "Tennessee": "Tennessee",
                    "Texas": "Texas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Virginia",
                    "Washington": "Washington",
                    "West Virginia": "West Virginia",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            "deliveryInstructions": {
                "label": {
                    "Delivery Instructions": "Delivery Instructions"
                },
                "placeholder": {
                    "Extra address information, delivery instructions, etc. ": "Extra address information, delivery instructions, etc. ",
                    "Extra address information, delivery instructions, etc. in English ": "Extra address information, delivery instructions, etc. in English ",
                    "Extra address information, delivery instructions, etc": "Extra address information, delivery instructions, etc."
                }
            },
        },
        BRA: {
            name: {
                label: {"Full name": "Full Name"},
                placeholder: {"Full name": "Full Name"}
            },
            firstStreet: {
                label: {"Address 1": "Address 1"},
                placeholder: {"Address 1": "Street + house/apt number"}
            },
            secondStreet: {
                label: {
                    "Address 2": "Address 2",
                    "District": "District"
                },
                placeholder: {
                    "Address 2": "Street + house/apt number",
                    "District": "District"
                }
            },
            city: {
                label: {City: "City"},
                placeholder: {City: "City"},
            },
            state: {
                label: {State: "State"},
                placeholder: {State: "State"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Zipcode",
                    "Post Code": "Post Code",
                    "Postal Code": "Postal Code",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "Zipcode",
                    "Post Code": "Post Code",
                    "Postal Code": "Postal Code",
                    "CPA": "CPA"
                }
            },
            identifier: {
                "label": {
                    "Tax ID": "Tax ID"
                },
                "placeholder": {
                    "Tax ID (CDI, CUIT, CUIL, etc)": "Tax ID (CDI, CUIT, CUIL, etc)"
                }
            }
        }
    },

    errors: {
        required: "{field} is required.",
        mask: "{field} is invalid."
    },

    buttons: {
        submit: {
            text: "Save",
            saving: "Saving"
        },
        cancel: {
            text: "Cancel"
        }
    },

    disclaimer: "Adding this address will require email verification. After saving the address, please check your email and click the link within.",

    message: {
        added: "Address has been added",
        updated: "Address has been updated"
    }
}