export default {
    title: "需要登录验证",
    header: "@:views.auth.confirm.title",
    message: "要继续，请查看您公司的电子邮件以获取电子邮件验证链接。单击电子邮件中的链接以继续。",
    messageBeforeCta: "如果您没有看到电子邮件，请单击",
    messageAfterCta: "重新发送。",
    secondMessage: "如果您仍然没有看到电子邮件，请仔细检查您的垃圾邮件文件夹或通过<a href='mailto:{email}?subject={subject}'>{email}</a>联系我们.",
    subject: "齿轮-TFA",
    buttons: {
        cta: "这里",
        processing: "这里"
    },
    headerAlreadyConfirmed: "登录验证",
    messageAlreadyConfirmed: "您已经确认了您的电子邮件。"
}