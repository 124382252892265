export default {
    view: {
        success: {
            header: "Umfrage eingereicht",
            message: "Danke für Ihre Rückmeldung."
        },
        productChoice: {
            cta: "Speichern",
            processing: "Speichern"
        },
        addressForm: {
            cta: "Speichern",
            processing: "Speichern"
        }
    },
    delivery: {
        title: "Lieferumfrage",
        message: "Haben Sie alle unten aufgeführten Artikel erhalten?",
        cta: {
            yes: "Ja",
            no: "NEIN",
            processing: "Speichern"
        }
    }
}