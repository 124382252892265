<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>{{t('title')}}</h1>

                <p v-html="t('updatedDt')"></p>
                <p v-html="t('policy', translationParameters)"></p>

                <h2 v-html="t('dataTransferHeader', translationParameters)"></h2>
                <p v-html="t('dataTransferMessage', translationParameters)"></p>

                <h2 v-html="t('linksInformationHeader', translationParameters)"></h2>
                <p v-html="t('linksInformationMessage', translationParameters)"></p>

                <h2 v-html="t('rrInformationHeader', translationParameters)"></h2>
                <p v-html="t('rrInformation1', translationParameters)"></p>
                <p v-html="t('rrInformation2', translationParameters)"></p>
                <p v-html="t('rrInformation3', translationParameters)"></p>
                <p v-html="t('rrInformation4', translationParameters)"></p>
                <p v-html="t('rrInformation5', translationParameters)"></p>

                <h2 v-html="t('rrPersonalInformationHeader', translationParameters)"></h2>
                <p v-html="t('rrPersonalInformationMessage', translationParameters)"></p>

                <h2 v-html="t('analyticsInformationHeader', translationParameters)"></h2>
                <p v-html="t('analyticsInformationMessage1', translationParameters)"></p>
                <p v-html="t('analyticsInformationMessage2', translationParameters)"></p>
                <p v-html="t('analyticsInformationMessage3', translationParameters)"></p>
                <p v-html="t('analyticsInformationMessage4', translationParameters)"></p>

                <h2 v-html="t('choiceHeader', translationParameters)"></h2>
                <p v-html="t('choiceMessage', translationParameters)"></p>

                <h2 v-html="t('trackHeader', translationParameters)"></h2>
                <p v-html="t('trackMessage', translationParameters)"></p>

                <h2 v-html="t('retentionPolicyHeader', translationParameters)"></h2>
                <p v-html="t('retentionPolicyMessage', translationParameters)"></p>

                <h2 v-html="t('securityHeader', translationParameters)"></h2>
                <p v-html="t('securityMessage', translationParameters)"></p>

                <h2 v-html="t('shareHeader', translationParameters)"></h2>
                <p v-html="t('shareMessage', translationParameters)"></p>

                <h2 v-html="t('childrenPrivacyHeader', translationParameters)"></h2>
                <p v-html="t('childrenPrivacyMessage', translationParameters)"></p>

                <h2 v-html="t('userHeader', translationParameters)"></h2>
                <p v-html="t('userMessage', translationParameters)"></p>

                <h2 v-html="t('notificationHeader', translationParameters)"></h2>
                <p v-html="t('notificationMessage', translationParameters)"></p>

                <h2 v-html="t('rrStaffHeader', translationParameters)"></h2>
                <p v-html="t('rrStaffMessage', translationParameters)"></p>

                <h2 v-html="t('contactHeader', translationParameters)"></h2>
                <p v-html="t('contactMessage', translationParameters)"></p>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin_view from "@/mixins/view";

    export default {
        name: "ViewsPrivacy",
        mixins: [mixin_view],
        data() {

            return {
                t_path: "views.privacy",
                translationParameters: {
                    termsUrl: this.$router.resolve({name: "terms"}).fullPath,
                    contactEmail: "contact@remoteretail.com",
                    privacyEmail: "privacy@remoteretail.com"
                }
            }
        }
    }
</script>