export default {
    header: "Confirmer l'adresse et soumettre la commande",
    message: "Votre adresse de livraison indiquée ci-dessous est-elle correcte ?",
    tip: "Cliquez sur <b>OUI</b> pour confirmer l'adresse de livraison et soumettre la commande. Cliquez sur <b>NON</b> pour annuler la soumission et choisir une autre adresse.",
    info: "Une fois qu'une commande est passée, elle ne peut plus être modifiée. Si le produit que vous commandez est en rupture de stock ou a une date d'expédition ultérieure et que vous prévoyez de déménager, veuillez en tenir compte lors du choix de l'adresse de livraison. De plus, s'il y a des vacances ou des vacances à venir et que vous ne serez pas à la maison pour accepter le produit, nous vous demandons de ne pas passer de commande jusqu'à ce que vous soyez à la maison pour recevoir les produits.",
    warning: {
        message: "Veuillez vérifier que votre {fields} est correct car la société de transport peut ne pas être en mesure de livrer votre commande ou de vous contacter au sujet de votre commande. | Veuillez vérifier que votre {fields} sont corrects car la société de transport peut ne pas être en mesure de livrer votre commande ou de vous contacter au sujet de votre commande.",
        name: "Nom",
        street: "Rue",
        phone: "Téléphone"
    },
    buttons: {
        yes: "Oui",
        no: "Non"
    }
}