import _return from "./return";
import acknowledgement from "./acknowledgement"
import address from "./address";
import approval from "./approval"
import auth from "./auth";
import catalog from "./catalog"
import errors from "./errors";
import helpdesk from "./helpdesk"
import home from "./home";
import impersonation from "./impersonation"
import order from "./order";
import privacy from "./privacy"
import products from "./products"
import quiz from "./quiz";
import survey from "./survey";
import surveys from "./surveys";
import terms from "./terms"
import tunnel from "./tunnel";

export default {
    acknowledgement,
    address,
    approval,
    auth,
    catalog,
    errors,
    helpdesk,
    home,
    impersonation,
    order,
    privacy,
    products,
    quiz,
    return: _return,
    survey,
    surveys,
    terms,
    tunnel,

    maintenance: {
        header: "We will be back soon!",
        message: "We are very sorry for the inconvenience but we are performing maintenance.<br/>Please check back soon..."
    }
}