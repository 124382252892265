export default {
    ordered: {
        type: {
            "ac_router": "J'ai déjà commandé un autre routeur AC",
            "chair": "J'ai déjà commandé une chaise différente",
            "default": "Déjà commandé",
            "default_multiple": "J'ai déjà commandé des produits similaires",
            "desk": "J'ai déjà commandé un autre bureau",
            "desktop": "J'ai déjà commandé un autre bureau",
            "desktop_riser": "J'ai déjà commandé un autre support de bureau",
            "detector": "J'ai déjà commandé différents détecteurs de vapeur et de bruit",
            "displayport_cable": "J'ai déjà commandé un autre câble DisplayPort",
            "docking station": "J'ai déjà commandé une autre station d'accueil",
            "dual_usb_adapter": "J'ai déjà commandé un autre adaptateur USB double bande",
            "ear cushion": "J'ai déjà commandé un coussinet d'oreille différent",
            "footrest": "J'ai déjà commandé un autre repose-pieds",
            "hdmi_cable": "J'ai déjà commandé différents câbles HDMI",
            "hdmi_displayport_cable": "J'ai déjà commandé un autre câble HDMI vers DisplayPort",
            "hdmi_vga_cable": "J'ai déjà commandé un autre câble HDMI vers VGA",
            "headset": "J'ai déjà commandé un autre appareil audio",
            "keyboard": "J'ai déjà commandé un autre clavier",
            "kvm switch": "J'ai déjà commandé un autre commutateur KVM",
            "kvm_switch": "J'ai déjà commandé un autre commutateur KVM",
            "lamp": "J'ai déjà commandé une autre lampe",
            "laptop stand": "J'ai déjà commandé un autre support pour ordinateur portable",
            "laptop": "J'ai déjà commandé un autre ordinateur portable",
            "mesh_router": "J'ai déjà commandé un autre routeur maillé",
            "monitor": "J'ai déjà commandé un autre moniteur",
            "mouse": "J'ai déjà commandé différentes souris",
            "patch_cord": "J'ai déjà commandé un autre cordon de brassage cat6",
            "power_center": "J'ai déjà commandé un autre Power Center",
            "power_strip": "J'ai déjà commandé une multiprise différente",
            "smart_plug": "J'ai déjà commandé différentes prises intelligentes mini",
            "smart_power_strip": "J'ai déjà commandé différentes multiprises intelligentes",
            "thunderbolt_cable": "J'ai déjà commandé un autre câble USB-C vers Thunderbolt",
            "travel_router": "J'ai déjà commandé un autre routeur de voyage",
            "usb hub": "J'ai déjà commandé un autre hub USB",
            "usb_adapter": "J'ai déjà commandé un autre adaptateur USB",
            "usb_hub": "J'ai déjà commandé un hub différent",
            "webcam": "J'ai déjà commandé une autre webcam"
        },
        product: "Commandé"
    },
    request: "Demande",
    add: "Ajouter au panier",
    remove: "Supprimer du panier",
    added: "Ajouté au panier",
}