<template>
    <div :class="class">
        <label v-if="label" :for="labelFor">{{label}}<span v-if="_required" class="label-required">*</span></label>

        <div class="input-group" :class="{'is-invalid':isInvalid,'is-valid':isValid}">
            <flat-pickr v-model="date" :class="inputClass" :id="labelFor" :placeholder="placeholder" :config="config"/>
            <div v-show="allowClear" class="input-group-append">
                <button class="btn btn-default px-3" type="button" data-clear>
                    <span class="icon-cancel"></span>
                </button>
            </div>
        </div>

        <slot name="tip"></slot>

        <div v-for="error in _errors" :key="error" class="invalid-feedback">
            <span class="icon-attention-circled"></span>
            {{error}}
        </div>
    </div>
</template>

<script>

    import flatPickr from "vue-flatpickr-component";

    import "flatpickr/dist/flatpickr.css";

    import form from "@/mixins/form";

    export default {
        name: "FormDate",
        mixins: [form],
        emits: ["update:modelValue"],
        components: {
            flatPickr
        },
        props: {
            modelValue: {},
            label: String,
            inputClass: {
                type: String,
                default: "form-control form-control-date"
            },
            class: {
                type: String,
                default: "form-group"
            },
            minDate: {
                type: [Date, String, Number],
                default: null
            },
            maxDate: {
                type: [Date, String, Number],
                default: null
            },
            disableDates: {
                type: Array,
                default: () => []
            },
            milliseconds: {
                type: Boolean,
                default: false
            }
        },
        data() {

            return {
                date: this.modelValue
            }
        },
        computed: {
            allowClear() {

                return this.date && (!this.validator || !this.validator.required)
            },
            config() {

                return {
                    ...this.$store.getters["picker/dateConfig"],
                    wrap: true,
                    minDate: this.minDate,
                    maxDate: this.maxDate,
                    disable: this.disableDates
                }
            }
        },
        watch: {
            date() {

                this.$emit("update:modelValue", this.date && this.milliseconds ? this.date * 1000 : this.date);
            }
        }
    }
</script>