export default {
    required: "se requiere un valor",
    email: "El valor no es una dirección de correo electrónico válida.",
    mask: "El valor no es válido",
    maxValue: "El valor máximo es {maxValue}",
    regex: "El valor no es válido",
    server: {
        DELEGATES_NOT_ENABLED: "Los delegados no están habilitados para este socio.",
        DELEGATE_DOES_NOT_EXIST: "El delegado no existe",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "No se puede actualizar un delegado que no le pertenece.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "No se puede eliminar un delegado que no le pertenece.",
        DELEGATE_ALREADY_EXISTS: "Ya existe una regla de delegado que coincide con este criterio.",
        DELEGATE_CANNOT_BE_SELF: "No puedes agregarte como delegado."
    }
}