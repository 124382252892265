import get from 'lodash/get.js'
import mixin_quiz from "../quiz.mjs";

export default {
    mixins: [mixin_quiz],
    data() {

        return {
            page: null
        }
    },
    methods: {
        quiz_page_isLastPage(quiz, page, isSubmitted, submittedVersion, allowedVersion) {

            return null === this.quiz_page_getNextPage(quiz, page, isSubmitted, submittedVersion, allowedVersion);
        },
        quiz_page_getQueue(quiz, isSubmitted, submittedVersion, allowedVersion) {

            const allowedVersionPages = quiz.pages.filter(page => {

                return "undefined" === typeof page.allowedVersion
                    || -1 !== page.allowedVersion.indexOf(allowedVersion)
            });

            const submittedUserProfileVersionPages = quiz.pages.filter(page => {

                return isSubmitted && submittedVersion
                    && "undefined" !== typeof page.allowedVersion
                    && -1 !== page.allowedVersion.indexOf(submittedVersion)
            });

            const submittedIds = submittedUserProfileVersionPages.map(page => page.id);

            return allowedVersionPages.filter(page => -1 === submittedIds.indexOf(page.id))

        },
        quiz_page_getPrevPage(quiz, currentPage, isSubmitted, submittedVersion, allowedVersion) {

            if (!currentPage) {

                return null;
            }

            const pages = this.quiz_page_getQueue(quiz, isSubmitted, submittedVersion, allowedVersion);

            let index = pages.findIndex(page => page.id == currentPage.id);

            if (0 >= index) {

                return null;
            }

            let components = [];

            for (let i = index - 1; i >= 0; i--) {

                // Check on the page level
                if (!this.quiz_isPageShouldBeDisplayed(quiz, pages[i])) {

                    continue;
                }

                if ("undefined" !== typeof pages[i].components) {

                    // Check on the components level
                    components = this.quiz_getComponentsToDisplay(quiz, pages[i].components);

                    if (0 < components.length) {

                        return pages[i];
                    }
                } else {

                    return pages[i];
                }
            }

            return null;

        },
        quiz_page_getNextPage(quiz, currentPage, isSubmitted, submittedVersion, allowedVersion) {

            if (!currentPage) {

                return null;
            }

            const pages = this.quiz_page_getQueue(quiz, isSubmitted, submittedVersion, allowedVersion);

            // Check for current page is the latest
            let index = pages.findIndex(page => page.id == currentPage.id);

            if (-1 === index) {

                index = 0;
            }

            // Check for current page is last one
            if (index == pages.length - 1) {

                return null;
            }

            let components = [];

            // Go thought next pages and return the first one that should be displayed
            for (let i = index + 1; i < pages.length; i++) {

                // Check on the page level
                if (!this.quiz_isPageShouldBeDisplayed(quiz, pages[i])) {

                    continue;
                }

                if ("undefined" !== typeof pages[i].components) {

                    // Check on the components level
                    components = this.quiz_getComponentsToDisplay(quiz, pages[i].components);

                    if (0 < components.length) {

                        return pages[i];
                    }
                } else {

                    return pages[i];
                }
            }

            return null;
        },
        /**
         * Return page text properties
         * @param quiz
         * @param quizPage
         * @returns {{}}
         */
        quiz_page_getPageContent(quiz, quizPage) {

            if (!quizPage) {

                return {};
            }

            // Prepare page prefix for translations
            const prefix = quizPage.id ? `${quizPage.id}.` : "";

            // Go through possible page text options
            return quiz.options.page.text.reduce((result, textProperty) => {

                const value = quizPage[textProperty];

                let defaultTranslation;

                // Check for page has property
                if ("undefined" === typeof value) {

                    return result;
                }

                // Check for page text property is text and does not require additional logic
                if ("string" === typeof value) {

                    result[textProperty] = {
                        text: this.quiz_getPageText(`${prefix}${value}`, value),
                        align: get(quizPage, `align.${textProperty}`)
                    };

                    return result;
                }

                // Go through all conditions and check matches
                Object.keys(value).forEach(translation => {

                    if (true === value[translation]) {

                        // Translation by default
                        defaultTranslation = translation;
                    } else if ("undefined" === typeof result[textProperty]) { // Check for property still not set

                        if (this.quiz_conditions_match(quiz, value[translation])) {

                            result[textProperty] = {
                                text: this.quiz_getPageText(`${prefix}${translation}`),
                                align: get(quizPage, `align.${textProperty}`)
                            };
                        }
                    }
                });

                if ("undefined" === typeof result[textProperty] && defaultTranslation) {

                    result[textProperty] = {
                        text: this.quiz_getPageText(`${prefix}${defaultTranslation}`),
                        align: get(quizPage, `align.${textProperty}`)
                    };
                }

                return result;
            }, {});
        }
    }
}