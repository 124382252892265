export default {
    error: "检索订单时出现问题。请刷新您的浏览器。",
    meta: {
        default: "命令",
        my_orders: "我的订单",
        my_gear: "已收到"
    },
    loadMore: "装载更多",
    noOrders: "没有订单",
    order: {
        product: {
            title: "命令",
            tracking: "追踪",
            quantity: "数量",
            control: {
                cancel: {
                    cta: "点击取消",
                    tooltip: "点击取消该项目",
                    message: "我们已取消您的订单。"
                },
                cancelRequest: {
                    cta: "要求取消",
                    tooltip: "点击提交取消请求"
                },
                received: {
                    cta: "标记为已收到",
                    tooltip: "点击确认发货",
                    message: "感谢您确认收到产品。"
                },
                notReceived: {
                    cta: "标记为未收到",
                    tooltip: "点击确认未送达"
                },
                damaged: {
                    cta: "标记为损坏/破损",
                    tooltip: "单击以创建支持票证"
                },
                hide: {
                    cta: "隐藏项目",
                    tooltip: "单击以隐藏列表中的项目"
                }
            },
            requestToCancelInfo: "我们将联系供应商请求取消。请注意，这只是一个请求，可能无法取消。一旦我们确定是否可以取消，我们会通知您。",
            cancelOk: "好的",
            reason: "原因",
            orderedBy: "订购",
            model: {
                cancel: {
                    reason: {
                        label: "取消原因",
                        placeholder: "取消原因"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "提交",
                        processing: "正在提交"
                    },
                    cancel: {
                        cta: "取消"
                    }
                }
            }
        }
    },
    help: {
        text: "<p>对您的订单有疑问吗？<br/>请参阅我们的<a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>支持中心</b></a>寻求帮助</p><p>您需要未列出的产品吗？<br/>单击<a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>此处</b></a>发送建议</p>",
        email: {
            subject: ""
        }
    }
}