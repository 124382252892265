export default {
    error: "Při načítání objednávek došlo k problému. Obnovte prosím svůj prohlížeč.",
    title: "Historie objednávek",
    loadMore: "Načíst další",
    noOrders: "Žádné objednávky",
    user: {
        loadMore: "Načíst další",
        product: {
            title: "Objednat",
            tracking: "Sledování",
            quantity: "Množství",
            reason: "Důvod",
            control: {
                cancel: {
                    cta: "Klikněte pro zrušení",
                    tooltip: "Kliknutím tuto položku zrušíte",
                    message: "Vaši objednávku jsme zrušili."
                },
                received: {
                    cta: "Produkt přijat",
                    tooltip: "Kliknutím potvrdíte doručení",
                    message: "Děkujeme za potvrzení, že jste produkt obdrželi."
                },
                notReceived: {
                    cta: "Produkt nebyl přijat",
                    tooltip: "Kliknutím potvrdíte nedoručení"
                },
                hide: {
                    cta: "Skrýt položku",
                    tooltip: "Kliknutím skryjete položku ze seznamu"
                }
            }
        }
    }
}