export default {
    disclaimer: {
        label_default: "Eu reconheço e concordo que os itens que estou solicitando / recebendo são O-I propriedade a ser usada para O-I atividades relacionadas ao trabalho e, como tal, O-I reserva-se o direito de solicitar a devolução da propriedade após minha saída de O-I (ou antes, se exigido por O-I).",
        label_type_1: "Confirmo que a autoavaliação para trabalhos de casa foi concluída com precisão e de boa fé, e qualquer equipamento ou mobiliário fornecido por O-I local ou solicitado através do Gear será usado por mim para o propósito do meu trabalho com O-I. Eu reconheço e concordo que os itens que estou solicitando / recebendo são O-I propriedade a ser usada para O-I atividades relacionadas ao trabalho e, como tal, O-I reserva-se o direito de solicitar a devolução da propriedade após minha saída de O-I (ou antes, se exigido por O-I).",
        errors: {
            required: "Aceite os termos de uso"
        }
    },
    cost_center: {
        label: "Código do Centro de Custo",
        placeholder: "Código do Centro de Custo",
        cost_center_not_found: "Centros de custos não presentes podem levar até 24 horas para aparecer. Entre em contato com o Global Service Desk se você não vir o centro de custo apropriado.",
        errors: {
            regex: "Código de centro de custo inválido"
        }
    },
    company_code: {
        label: "Código da companhia"
    },
    cost_center_name: {
        label: "Nome do Centro de Custo"
    },
    order_note: {
        label: "Nota de pedido",
        placeholder: "Nota opcional ao gerente para aprovação",
    }
}