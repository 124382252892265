export default {
    title: "Wymagana weryfikacja logowania",
    header: "@:views.auth.confirm.title",
    message: "Aby kontynuować, sprawdź firmową pocztę e-mail, aby uzyskać link weryfikacyjny. Kliknij łącze w wiadomości e-mail, aby kontynuować.",
    messageBeforeCta: "Jeśli nie widzisz wiadomości e-mail, kliknij",
    messageAfterCta: "wysłać ponownie.",
    secondMessage: "Jeśli nadal nie widzisz wiadomości e-mail, sprawdź dwukrotnie folder ze spamem lub skontaktuj się z nami pod adresem <a href='mailto:{email}?subject={subject}'>{email}</a> .",
    subject: "Sprzęt - TFA",
    buttons: {
        cta: "Tutaj",
        processing: "Tutaj"
    },
    headerAlreadyConfirmed: "Weryfikacja logowania",
    messageAlreadyConfirmed: "Potwierdziłeś już swój adres e-mail."
}