<template>
    <div>
        <div class="home-catalog-message" v-if="catalogMessage" v-html="catalogMessage"></div>

        <categories-list :categories="groupings" :grouping="grouping"/>
    </div>
</template>

<script>

    import CategoriesList from "@/components/categories/CategoriesList";

    export default {
        name: "ViewsHomeTypeCategories",
        components: {
            CategoriesList
        },
        props: {
            groupings: {
                type: Array,
                required: true
            },
            grouping: {
                type: String,
                required: true
            },
            catalogMessage: String
        },
    }
</script>