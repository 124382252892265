import get from "lodash/get";


export default {
    data() {

        return {
            __dynamic_field_watch: {}
        };
    },
    methods: {
        dynamic_field_typeahead(field, value, render, source = {}) {

            if (!value || !field.typeahead) {

                return render([]);
            }

            const params = {[field.typeahead.query]: value};

            get(field.typeahead, ["params"], []).forEach(param => {

                if ("user_id" == param) {

                    params[param] = source[param] ? source[param] : this.$store.getters["user/id"];
                }
            })

            this[`$${field.typeahead.service}`]
                .get(field.typeahead.uri, {params})
                .then(response => {

                    const result = field.typeahead.result.path
                        ? get(response.data, field.typeahead.result.path)
                        : response.data;

                    const items = result
                        .map(item => {
                            let value, name;

                            value = field.typeahead.result.value
                                ? get(item, field.typeahead.result.value)
                                : item;

                            name = value;

                            if (field.typeahead.result.name) {

                                if ("string" === typeof field.typeahead.result.name) {

                                    name = field.typeahead.result.name
                                        ? get(item, field.typeahead.result.name)
                                        : value
                                }

                                if (true === Array.isArray(field.typeahead.result.name)) {

                                    name = field.typeahead.result.name.map(property => {

                                        if ("string" === typeof get(item, property)) {

                                            return get(item, property);
                                        }

                                        return {
                                            ...property,
                                            name: get(item, property.property)
                                        }
                                    });
                                }
                            }

                            return {
                                value,
                                name
                            }
                        })
                        .filter(item => !!item.value);

                    render(items);
                });
        },
        dynamic_field_watch(field, model) {

            Object
                .entries(field.watch ? field.watch : {})
                .forEach(entry => {

                    const [watchField, watchConfig] = entry;

                    this.$watch(`${model}.${watchField}`, value => {

                        if ("request" === watchConfig.type) {

                            this[`$${watchConfig.service}`]
                                .get(watchConfig.uri, {params: {[watchConfig.query]: value}})
                                .then(response => {

                                    const result = watchConfig.result.path
                                        ? get(response.data, watchConfig.result.path)
                                        : response.data;

                                    let targetValue;

                                    if (watchConfig.result.property_compare) {

                                        targetValue = result.find(item => value == get(item, watchConfig.result.property_compare));
                                    } else if (watchConfig.result.compare) {

                                        targetValue = result.find(item => value == item);
                                    } else {

                                        targetValue = result;
                                    }

                                    // Set model value
                                    if (targetValue) {

                                        if (watchConfig.result.property_pull) {
                                            // We need to pull specific property from object
                                            if (targetValue[watchConfig.result.property_pull]) {

                                                if ("undefined" !== typeof get(this, model)) {

                                                    get(this, model)[field.payload.field] = targetValue[watchConfig.result.property_pull];
                                                }
                                            }
                                        } else {
                                            if ("undefined" !== typeof get(this, model)) {
                                                // Set value as is
                                                get(this, model)[field.payload.field] = targetValue;
                                            }
                                        }
                                    } else {
                                        if ("undefined" !== typeof get(this, model)) {

                                            get(this, model)[field.payload.field] = null;
                                        }
                                    }
                                });
                        }
                    });
                })
        },
        dynamic_field_messageNotFound(field, translationPrefix) {

            const path = translationPrefix ? `${translationPrefix}.` : "";

            return field.typeahead
                && field.typeahead.result
                && field.typeahead.result.message_not_found
                && this.$te(`${path}${field.typeahead.result.message_not_found}`)
                    ? this.$t(`${path}${field.typeahead.result.message_not_found}`)
                    : null;
        },
        dynamic_field_infinityScroll(field) {

            return !!field.typeahead;
        }
    }
}