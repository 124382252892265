export default {
    login: "Login",
    logout: "Disconnettersi",
    home: "Casa",
    user: {
        profile: {
            edit: "Modifica Profilo"
        }
    },
    approvals: {
        delegates: "Delegati"
    },
    impersonation: "Ordine per conto di"
}