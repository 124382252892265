export default {
    title: "Indagine sul portale",
    header: "@:views.survey.customer.title",
    model: {
        service_rating: {
            label: "Su una scala da 1 a 10, dove 1 è molto insoddisfatto e 10 molto soddisfatto, come valuteresti questo servizio?",
        },
        service_likes: {
            label: "Cosa ti piaceva?",
            placeholder: ""
        },
        service_improvements: {
            label: "Cosa può essere migliorato?",
            placeholder: ""
        },
        service_easy_to_follow: {
            label: "Su una scala da 1 a 10, dove 1 non è affatto chiaro e 10 è facile da seguire, come valuteresti le comunicazioni relative a questo sito?"
        },
        item: {
            setup_rating: {
                label: "Su una scala da 1 a 10, dove 1 è molto difficile e 10 è molto facile, quanto è stato difficile installare e configurare il tuo \"{name}\"?",
            },
            comments: {
                label: "Si prega di fornire eventuali commenti su {name}?",
                placeholder: ""
            }
        }
    },
    disclaimer: "Tieni presente che le tue risposte al sondaggio verranno condivise con O-I e potremmo ricontattarti in merito al tuo feedback.",
    button: {
        submit: {
            cta: "Invia",
            processing: "Invio"
        }
    }
}