const text = {
    countryAfterComponentsBeforeSelection: "Vegye figyelembe, hogy a kiválasztott országot a szállítási cím részeként használjuk fel a termékek közvetlenül Önnek történő elküldéséhez. Ha nem látja országát a listában, a Gear jelenleg nem érhető el az Ön országában.",
    countryAfterComponentsAllowedSelection: "Vegye figyelembe, hogy a kiválasztott országot a szállítási cím részeként használjuk fel a termékek közvetlenül Önnek történő elküldéséhez.",
    countryAfterComponentsLimitedSelection: "A Gear jelenleg elérhető az Ön országában, de szakaszosan nyílik meg. Ha nem kap értesítést, előfordulhat, hogy jelenleg nem tudja elérni a Geart az Ön országában.",
    disclaimer: "Az alábbi gombra kattintva tudomásul veszem, hogy a Vállalat alkalmazottja vagyok, és hozzájárulok ahhoz, hogy a Vállalat és partnere, a RemoteRetail tárolja és feldolgozza az ezen az oldalon megadott személyes adatokat a kért tartalom biztosítása érdekében. A RemoteRetailnek szüksége van az általam megadott elérhetőségre, hogy kapcsolatba léphessen velem a rendeléseimmel kapcsolatos információkkal. Harmadik fél beszállítók is megkaphatják az adataimat, hogy termékeket küldjenek nekem.</p><p> Ezekről a kommunikációkról bármikor leiratkozhatok. Ha további információra van szüksége a leiratkozás módjáról, az adatvédelmi gyakorlatokról, valamint a személyes adataim védelmére és tiszteletben tartására vonatkozó kötelezettségvállalásokról, tekintse át az <a href='{privacyPolicyUrl}' target='_blank'>Adatvédelmi szabályzatot</a> ."
};

export default {
    title: "Üdvözlünk, {userName}!",
    description: {
        default: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`,
        virtualworker: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories that you need to work effectively from home. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`
    },
    text: {
        default: "Kezdésként egy sor kérdést teszünk fel Önnek.",
        virtualworker: "Kezdésként egy sor kérdést teszünk fel Önnek, hogy segítsen megérteni az otthoni munkavégzés munkaterületét, és így alakítsuk ki a program jövőbeli irányát."
    },
    afterComponents: {
        beforeSelection: `${text.countryAfterComponentsBeforeSelection}`,
        allowedSelection: `${text.countryAfterComponentsAllowedSelection}`,
        limitedSelection: `${text.countryAfterComponentsLimitedSelection}`,
        autoLoginBeforeSelection: `${text.countryAfterComponentsBeforeSelection}<br/><br/>${text.disclaimer}`,
        autoLoginAllowedSelection: `${text.countryAfterComponentsAllowedSelection}<br/><br/>${text.disclaimer}`,
        autoLoginLimitedSelection: `${text.countryAfterComponentsLimitedSelection}<br/><br/>${text.disclaimer}`
    },
    buttons: {
        back: "Vissza",
        next: "Következő",
        submit: "Beküldés"
    },
    entityinfo: {
        title: "Entitásinformáció",
        description: "Annak érdekében, hogy segítsen nekünk megrendelése megfelelő irányítását, kérjük, adja meg a következő információkat:",
    },
    equipmentsurvey: {
        title: "Berendezés felmérés",
        description: "A következő oldal arra kérdez rá, hogy milyen típusú berendezésekre van leginkább szüksége ahhoz, hogy a Vállalat jobban megértse a prioritásokat.",
        info: "Kérjük, vegye figyelembe, hogy a bemutatott berendezés csak információgyűjtési célokat szolgál, és nem jelenti azt, hogy a Vállalat ezt a berendezést a jövőben is biztosítani fogja."
    },
    workplace: {
        title: "Mesélne többet otthoni irodájáról?",
        description: "A munkaterület jobb megértése érdekében kérjük, válasszon az alábbi lehetőségek közül",
    },
    priority: {
        title: "Berendezés prioritások",
        description: "Kérjük, válassza ki a két legfontosabb felszerelést, amelyre leginkább szüksége van"
    },
    "additional-questions": {
        title: "További kérdések"
    },
    "equipment-assessment": {
        title: "Berendezés értékelése",
        description: "A következő kérdéssor <b>határozza meg, hogy a válaszai alapján milyen berendezéseket rendelhet meg a Gear-en belül.</b>"
    },
    headset: {
        title: "Jelenlegi felszerelés"
    },
    "office-worker": {
        title: "Hivatal"
    },
    "keyboard-mouse": {
        title: "Billentyűzet és egér"
    },
    webcam: {
        title: "Webkamera"
    },
    monitor: {
        title: "Monitor"
    },
    usbhub: {
        title: "USB Hub"
    },
    "equipment-list": {
        title: "Felszerelés",
        init: "initEquipmentList",
        description: {
            loading: "<span class=\"animate-spin icon-spinner\"></span>",
            error: "Rossz kérés. Hiba lépett fel.",
            allowed: "Válaszai alapján a következő tételekre lesz jogosult:{items}",
            empty: "Válaszai alapján jelenleg nem lesz jogosult a Gear egyik elemére sem."
        },
        text: "<ul class=\"text-left\" style=\"width: 300px; margin: 2rem auto 0\">{list}</ul>",
        afterComponents: "<small>Ha bármilyen javításra van szüksége, kérjük, nyomja meg a vissza gombot a válaszok megváltoztatásához.</small>"
    },
    saved: "Mentett"
}