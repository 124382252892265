export default {
    required: "Wymagana jest wartość",
    email: "Wartość nie jest prawidłowym adresem e-mail",
    mask: "Wartość jest nieprawidłowa",
    maxValue: "Maksymalna wartość to {maxValue}",
    regex: "Wartość jest nieprawidłowa",
    server: {
        DELEGATES_NOT_ENABLED: "Delegaci nie są włączeni dla tego partnera.",
        DELEGATE_DOES_NOT_EXIST: "Delegat nie istnieje",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "Nie można zaktualizować delegata, który nie należy do Ciebie.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Nie można usunąć delegata, który nie należy do Ciebie.",
        DELEGATE_ALREADY_EXISTS: "Istniejąca reguła delegowania spełniająca te kryteria już istnieje.",
        DELEGATE_CANNOT_BE_SELF: "Nie możesz dodać siebie jako delegata."
    }
}