export default {
    required: "È obbligatorio un valore",
    email: "Il valore non è un indirizzo email valido",
    mask: "Il valore non è valido",
    maxValue: "Il valore massimo è {maxValue}",
    regex: "Il valore non è valido",
    server: {
        DELEGATES_NOT_ENABLED: "I delegati non sono abilitati per questo partner.",
        DELEGATE_DOES_NOT_EXIST: "Il delegato non esiste",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "Impossibile aggiornare un delegato che non ti appartiene.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Impossibile eliminare un delegato che non ti appartiene.",
        DELEGATE_ALREADY_EXISTS: "Esiste già una regola delegata esistente che corrisponde a questi criteri.",
        DELEGATE_CANNOT_BE_SELF: "Non puoi aggiungerti come delegato."
    }
}