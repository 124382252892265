export default {
    ordered: {
        type: {
            "ac_router": "Já encomendei um roteador AC diferente",
            "chair": "Já encomendei cadeira diferente",
            "default": "Já encomendei",
            "default_multiple": "Já encomendei produtos semelhantes",
            "desk": "Já encomendei mesa diferente",
            "desktop": "Já encomendei um desktop diferente",
            "desktop_riser": "Já encomendei um riser de desktop diferente",
            "detector": "Já encomendei diferentes vape e detector de ruído",
            "displayport_cable": "Já encomendei um cabo displayport diferente",
            "docking station": "Já encomendei uma docking station diferente",
            "dual_usb_adapter": "Já encomendei um adaptador USB de banda dupla diferente",
            "ear cushion": "Já encomendei almofadas de ouvido diferentes",
            "footrest": "Já encomendei apoio para os pés diferente",
            "hdmi_cable": "Já encomendei cabos HDMI diferentes",
            "hdmi_displayport_cable": "Já encomendei um cabo HDMI para DisplayPort diferente",
            "hdmi_vga_cable": "Já encomendei um cabo HDMI para VGA diferente",
            "headset": "Já encomendei um dispositivo de áudio diferente",
            "keyboard": "Já encomendei um teclado diferente",
            "kvm switch": "Já encomendei um switch kvm diferente",
            "kvm_switch": "Já encomendei um switch kvm diferente",
            "lamp": "Já encomendei uma lâmpada diferente",
            "laptop stand": "Já encomendei um suporte para laptop diferente",
            "laptop": "Já encomendei um laptop diferente",
            "mesh_router": "Já encomendei um roteador mesh diferente",
            "monitor": "Já encomendei monitor diferente",
            "mouse": "Já encomendei ratos diferentes",
            "patch_cord": "Já encomendei um patch cord cat6 diferente",
            "power_center": "Já encomendei Power Center diferente",
            "power_strip": "Já encomendei um filtro de linha diferente",
            "smart_plug": "Já encomendei um mini plug inteligente diferente",
            "smart_power_strip": "Já encomendei um filtro de linha inteligente diferente",
            "thunderbolt_cable": "Já encomendei um cabo USB-C para Thunderbolt diferente",
            "travel_router": "Já encomendei um roteador de viagem diferente",
            "usb hub": "Já encomendei hub USB diferente",
            "usb_adapter": "Já encomendei adaptador USB diferente",
            "usb_hub": "Já encomendei hub diferente",
            "webcam": "Já encomendei uma webcam diferente"
        },
        product: "Encomendado"
    },
    request: "Solicitar",
    add: "Adicionar ao carrinho",
    remove: "Remover do carrinho",
    added: "Adicionado ao carrinho",
}