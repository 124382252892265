export default {
    title: {
        new: "添加新地址",
        complete: "完整地址",
        edit: "編輯地址"
    },

    incomplete: "请填写必填地址字段",

    info: {
        "CHN": "Please enter your address in Chinese. </br>请用中文输入你的地址.",
        "BGR": "用英文输入您的<b>家庭住址</b>以接收您的物品。",
        "PRI": "请输入您的家庭住址（而不是邮政信箱）来接收包裹。",
        "SGP": "请在您的地址中注明您的单位号码。",
        "SVK": "请用<b>英文</b>输入您的地址",
        "POL": "请用<b>英文</b>输入您的地址"
    },

    model: {
        country: {
            label: "国家"
        }
    },

    field: {
        default: {
            "name": {
                "label": {
                    "Name": "姓名"
                },
                "placeholder": {
                    "Name": "姓名",
                    "Name in English": "英文名"
                }
            },
            personalEmail: {
                label: {
                    "Personal Email": "个人电邮"
                },
                placeholder: {
                    "Personal Email": "个人电邮"
                }
            },
            managerEmail: {
                label: {
                    "Manager Email": "经理电子邮件"
                },
                placeholder: {
                    "Manager Email": "经理电子邮件"
                }
            },
            managerName: {
                label: {"Manager Name": "经理姓名"},
                placeholder: {"Manager Name": "经理姓名"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "邮政编码",
                    "Post Code": "邮政编码",
                    "Postal Code": "邮政编码",
                    "CPA": "注册会计师"
                },
                "placeholder": {
                    "Zipcode": "邮政编码",
                    "Post Code": "邮政编码",
                    "Postal Code": "邮政编码",
                    "CPA": "注册会计师"
                }
            },
            "province": {
                "label": {
                    "Province": "省",
                    "Prefecture": "州",
                    "Province/Region": "省/地区",
                    "Province Code": "省份代码"
                },
                "placeholder": {
                    "Province": "省",
                    "Prefecture": "州",
                    "Province/Region": "省/地区",
                    "Province Code": "省份代码"
                }
            },
            "phoneNumber": {
                "label": {
                    "Phone Number": "电话号码"
                },
                "placeholder": {
                    "Phone Number": "电话号码"
                }
            },
            "firstStreet": {
                "label": {
                    "Street": "街道",
                    "Tax ID": "税号"
                },
                "placeholder": {
                    "Street": "街道",
                    "Street in English": "街道的英语",
                    "Tax ID (RUT)": "税号 (RUT)",
                    "Number/Street/Apartment": "门牌号/街道/公寓"
                }
            },
            "secondStreet": {
                "label": {
                    "District": "区",
                    "Colonia": "科隆",
                    "Canton": "广州",
                    "Street": "街道"
                },
                "placeholder": {
                    "District": "区",
                    "Colonia": "科隆",
                    "Canton": "广州",
                    "Number/Street/Apartment": "门牌号/街道/公寓"
                }
            },
            "city": {
                "label": {
                    "City": "城市",
                    "Town/City/Locality": "城镇/城市/地区",
                    "Town/City": "城镇/城市",
                    "City/Municipality/Locality": "城市/市/地区",
                    "City/Town/Locality": "城市/城镇/地区",
                    "City/Town": "城市/城镇",
                    "District": "区",
                    "City/Town/Locallity": "城市/城镇/地区",
                    "Town/City/Sub Locality": "城镇/城市/分区"
                },
                "placeholder": {
                    "City": "城市",
                    "Town/City": "城镇/城市",
                    "Town/City/Locality": "城镇/城市/地区",
                    "City/Municipality/Locality": "城市/市/地区",
                    "City/Town/Locality": "城市/城镇/地区",
                    "City/Town in English": "城市/城镇 英文",
                    "District": "区",
                    "City/Town/Locallity": "城市/城镇/地区",
                    "Town/City/Sub Locality": "城镇/城市/分区"
                }
            },
            identifier: {
                label: {
                    "identifier": "标识符",
                    "Identifier": "标识符"
                },
                placeholder: {
                    "identifier": "标识符",
                    "Identifier": "标识符"
                }
            },
            "region": {
                "label": {
                    "Region": "地区",
                    "County": "县",
                    "Locality": "位置"
                },
                "placeholder": {
                    "Region": "地区",
                    "County": "县",
                    "Locality": "位置"
                }
            },
            "poBox": {
                "label": {
                    "PO BOX": "邮政信箱"
                },
                "placeholder": {
                    "PO BOX": "邮政信箱"
                }
            },
            state: {
                "label": {
                    "State": "状态",
                    "Province/Region": "省/地区",
                    "Province": "省",
                    "Department": "部门",
                    "Municipality/Comuna": "市政府/科穆纳"
                },
                "placeholder": {
                    "State": "状态",
                    "Province/Region": "省/地区",
                    "Province": "省",
                    "Department": "部门",
                    "Municipality/Comuna": "市政府/科穆纳"
                },
                options: {
                    "Alabama": "阿拉巴马州",
                    "Alaska": "阿拉斯加州",
                    "Arizona": "亚利桑那",
                    "Arkansas": "阿肯色州",
                    "California": "加利福尼亚州",
                    "Colorado": "科罗拉多州",
                    "Connecticut": "康涅狄格州",
                    "Delaware": "特拉华州",
                    "District Of Columbia": "哥伦比亚特区",
                    "Florida": "佛罗里达",
                    "Georgia": "乔治亚州",
                    "Hawaii": "夏威夷",
                    "Idaho": "爱达荷州",
                    "Illinois": "伊利诺伊州",
                    "Indiana": "印第安纳州",
                    "Iowa": "爱荷华州",
                    "Kansas": "堪萨斯州",
                    "Kentucky": "肯塔基州",
                    "Louisiana": "路易斯安那州",
                    "Maine": "缅因州",
                    "Maryland": "马里兰州",
                    "Massachusetts": "马萨诸塞州",
                    "Michigan": "密歇根州",
                    "Minnesota": "明尼苏达州",
                    "Mississippi": "密西西比州",
                    "Missouri": "密苏里州",
                    "Montana": "蒙大拿",
                    "Nebraska": "内布拉斯加州",
                    "Nevada": "内华达州",
                    "New Hampshire": "新罕布什尔",
                    "New Jersey": "新泽西州",
                    "New Mexico": "新墨西哥",
                    "New York": "纽约",
                    "North Carolina": "北卡罗来纳",
                    "North Dakota": "北达科他州",
                    "Ohio": "俄亥俄州",
                    "Oklahoma": "俄克拉荷马州",
                    "Oregon": "俄勒冈州",
                    "Pennsylvania": "宾夕法尼亚州",
                    "Rhode Island": "罗德岛",
                    "South Carolina": "南卡罗来纳",
                    "South Dakota": "南达科他州",
                    "Tennessee": "田纳西州",
                    "Texas": "德克萨斯州",
                    "Utah": "犹他州",
                    "Vermont": "佛蒙特",
                    "Virginia": "弗吉尼亚州",
                    "Washington": "华盛顿",
                    "West Virginia": "西弗吉尼亚",
                    "Wisconsin": "威斯康星州",
                    "Wyoming": "怀俄明州"
                }
            },
            "deliveryInstructions": {
                "label": {
                    "Delivery Instructions": "交货指示"
                },
                "placeholder": {
                    "Extra address information, delivery instructions, etc. ": "额外的地址信息、送货说明等。 ",
                    "Extra address information, delivery instructions, etc. in English ": "额外的地址信息、送货说明等（英文） ",
                    "Extra address information, delivery instructions, etc": "额外的地址信息、送货说明等。"
                }
            },
        },
        BRA: {
            name: {
                label: {"Full name": "全名"},
                placeholder: {"Full name": "全名"}
            },
            firstStreet: {
                label: {"Address 1": "地址1"},
                placeholder: {"Address 1": "街道 + 门牌号/公寓号"}
            },
            secondStreet: {
                label: {
                    "Address 2": "地址2",
                    "District": "区"
                },
                placeholder: {
                    "Address 2": "街道 + 门牌号/公寓号",
                    "District": "区"
                }
            },
            city: {
                label: {City: "城市"},
                placeholder: {City: "城市"},
            },
            state: {
                label: {State: "状态"},
                placeholder: {State: "状态"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "邮政编码",
                    "Post Code": "邮政编码",
                    "Postal Code": "邮政编码",
                    "CPA": "注册会计师"
                },
                "placeholder": {
                    "Zipcode": "邮政编码",
                    "Post Code": "邮政编码",
                    "Postal Code": "邮政编码",
                    "CPA": "注册会计师"
                }
            },
            identifier: {
                "label": {
                    "Tax ID": "税号"
                },
                "placeholder": {
                    "Tax ID (CDI, CUIT, CUIL, etc)": "税号（CDI、CUIT、CUIL 等）"
                }
            }
        }
    },

    errors: {
        required: "{field} 是必需的。",
        mask: "{field} 无效。"
    },

    buttons: {
        submit: {
            text: "节省",
            saving: "保存"
        },
        cancel: {
            text: "取消"
        }
    },

    disclaimer: "添加此地址需要验证电子邮件。保存地址后，请检查您的电子邮件并点击其中的链接。",

    message: {
        added: "地址已添加",
        updated: "地址已更新"
    }
}