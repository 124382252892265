export default {
    error: "Hubo un problema al recuperar pedidos. Por favor refresque su navegador.",
    meta: {
        default: "Pedidos",
        my_orders: "Mis ordenes",
        my_gear: "Recibió"
    },
    loadMore: "Carga más",
    noOrders: "Sin pedidos",
    order: {
        product: {
            title: "Orden",
            tracking: "Seguimiento",
            quantity: "Cantidad",
            control: {
                cancel: {
                    cta: "Haga clic para cancelar",
                    tooltip: "Haga clic para cancelar este artículo",
                    message: "Hemos cancelado tu pedido."
                },
                cancelRequest: {
                    cta: "Solicitud de cancelación",
                    tooltip: "Haga clic para enviar una solicitud de cancelación"
                },
                received: {
                    cta: "Marcar como recibido",
                    tooltip: "Haga clic para confirmar la entrega",
                    message: "Gracias por confirmar que recibió su producto."
                },
                notReceived: {
                    cta: "Marcar como no recibido",
                    tooltip: "Haga clic para confirmar la no entrega"
                },
                damaged: {
                    cta: "Marcar como dañado/roto",
                    tooltip: "Haga clic para crear ticket de soporte"
                },
                hide: {
                    cta: "Ocultar elemento",
                    tooltip: "Haga clic para ocultar el elemento de la lista"
                }
            },
            requestToCancelInfo: "Nos pondremos en contacto con el proveedor para solicitar una cancelación. Tenga en cuenta que esta es solo una solicitud y es posible que no sea posible cancelarla. Una vez que podamos determinar si se puede cancelar o no, se lo haremos saber.",
            cancelOk: "De acuerdo",
            reason: "Razón",
            orderedBy: "Ordenado por",
            model: {
                cancel: {
                    reason: {
                        label: "Razones de cancelación",
                        placeholder: "Razones de cancelación"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Entregar",
                        processing: "Sumisión"
                    },
                    cancel: {
                        cta: "Cancelar"
                    }
                }
            }
        }
    },
    help: {
        text: "<p>¿Tiene preguntas sobre su pedido?<br/> Consulte nuestro <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>Centro de soporte</b></a> para obtener ayuda</p><p> ¿Necesita un producto que no figura en la lista?<br/> Haga clic <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>aquí</b></a> para enviar una sugerencia</p>",
        email: {
            subject: ""
        }
    }
}