export default {
    status: {
        acknowledged: "Zamówione",
        created: "Utworzony",
        sent: "Zamówione",
        confirmed: "Zamówione",
        not_confirmed: "Nie zamówiony",
        delivery_email: "Wysłany",
        shipped: "Wysłany",
        delayed: "Opóźniony",
        preparing: "Przygotowanie",
        processing: "Przetwarzanie",
        processed: "Obrobiony",
        submitted: "Zamówione",
        delivery_not_received: "Wysłany",
        shipment_exception: "Wysłany",
        pending_approval: "Aż do",
        approved: "Zatwierdzony",
        not_approved: "Niezatwierdzone",
        canceled: "Odwołany",
        returned: "Zwrócony",
        lost_shipment: "Zagubiona przesyłka",
        delivery_not_confirmed: "Dostarczony",
        delivered: "Dostarczony",
        delivery_received: "Dostarczony",
        refurbished: "Zwrot zakończony",
        returned_to_office: "Wrócił do biura",
        return_to_employer: "Zwrócone do Pracodawcy",
        return_pickup_scheduled: "Zaplanowano odbiór",
        return_pickup_completed: "Odbiór zakończony",
        return_pickup_excluded: "Odbiór wykluczony",
        return_pickup_submitted: "Odbiór przesłany",
        return_pickup_finalized: "Odbiór sfinalizowany",
        disposed: "Usunięty",
        damaged: "Uszkodzony"
    }
}