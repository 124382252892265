export default {
    title: {
        new: "Agregar nueva dirección",
        complete: "Completa tu direccion",
        edit: "Editar dirección"
    },

    incomplete: "Por favor complete los campos de dirección requeridos",

    info: {
        "CHN": "Por favor ingrese su dirección en chino.</br> Por favor ingrese su dirección en chino.",
        "BGR": "Ingrese <b>la dirección de su casa</b> en inglés para recibir sus artículos.",
        "PRI": "Ingrese la dirección de su casa, no un apartado postal, para recibir paquetes.",
        "SGP": "Por favor incluya su número de unidad en su dirección.",
        "SVK": "Por favor ingrese su dirección en <b>inglés</b>",
        "POL": "Por favor ingrese su dirección en <b>inglés</b>"
    },

    model: {
        country: {
            label: "País"
        }
    },

    field: {
        default: {
            "name": {
                "label": {
                    "Name": "Nombre"
                },
                "placeholder": {
                    "Name": "Nombre",
                    "Name in English": "Nombre en ingles"
                }
            },
            personalEmail: {
                label: {
                    "Personal Email": "Email personal"
                },
                placeholder: {
                    "Personal Email": "Email personal"
                }
            },
            managerEmail: {
                label: {
                    "Manager Email": "Correo electrónico del gerente"
                },
                placeholder: {
                    "Manager Email": "Correo electrónico del gerente"
                }
            },
            managerName: {
                label: {"Manager Name": "El nombre del gerente"},
                placeholder: {"Manager Name": "El nombre del gerente"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Código postal",
                    "Post Code": "Código postal",
                    "Postal Code": "Código Postal",
                    "CPA": "contador público"
                },
                "placeholder": {
                    "Zipcode": "Código postal",
                    "Post Code": "Código postal",
                    "Postal Code": "Código Postal",
                    "CPA": "contador público"
                }
            },
            "province": {
                "label": {
                    "Province": "Provincia",
                    "Prefecture": "Prefectura",
                    "Province/Region": "Provincia",
                    "Province Code": "Código de provincia"
                },
                "placeholder": {
                    "Province": "Provincia",
                    "Prefecture": "Prefectura",
                    "Province/Region": "Provincia",
                    "Province Code": "Código de provincia"
                }
            },
            "phoneNumber": {
                "label": {
                    "Phone Number": "Número de teléfono"
                },
                "placeholder": {
                    "Phone Number": "Número de teléfono"
                }
            },
            "firstStreet": {
                "label": {
                    "Street": "Calle",
                    "Tax ID": "Identificación del impuesto"
                },
                "placeholder": {
                    "Street": "Calle",
                    "Street in English": "calle en ingles",
                    "Tax ID (RUT)": "Identificación Fiscal (RUT)",
                    "Number/Street/Apartment": "Número/Calle/Apartamento"
                }
            },
            "secondStreet": {
                "label": {
                    "District": "Distrito",
                    "Colonia": "Colonia",
                    "Canton": "Cantón",
                    "Street": "Calle"
                },
                "placeholder": {
                    "District": "Distrito",
                    "Colonia": "Colonia",
                    "Canton": "Cantón",
                    "Number/Street/Apartment": "Número/Calle/Apartamento"
                }
            },
            "city": {
                "label": {
                    "City": "Ciudad",
                    "Town/City/Locality": "Pueblo/Ciudad/Localidad",
                    "Town/City": "Pueblo/Ciudad",
                    "City/Municipality/Locality": "Ciudad/Municipio/Localidad",
                    "City/Town/Locality": "Ciudad/Pueblo/Localidad",
                    "City/Town": "Ciudad/Pueblo",
                    "District": "Distrito",
                    "City/Town/Locallity": "Ciudad/Pueblo/Localidad",
                    "Town/City/Sub Locality": "Pueblo/Ciudad/Sublocalidad"
                },
                "placeholder": {
                    "City": "Ciudad",
                    "Town/City": "Pueblo/Ciudad",
                    "Town/City/Locality": "Pueblo/Ciudad/Localidad",
                    "City/Municipality/Locality": "Ciudad/Municipio/Localidad",
                    "City/Town/Locality": "Ciudad/Pueblo/Localidad",
                    "City/Town in English": "Ciudad/pueblo en inglés",
                    "District": "Distrito",
                    "City/Town/Locallity": "Ciudad/Pueblo/Localidad",
                    "Town/City/Sub Locality": "Pueblo/Ciudad/Sublocalidad"
                }
            },
            identifier: {
                label: {
                    "identifier": "Identificador",
                    "Identifier": "Identificador"
                },
                placeholder: {
                    "identifier": "Identificador",
                    "Identifier": "Identificador"
                }
            },
            "region": {
                "label": {
                    "Region": "Región",
                    "County": "Condado",
                    "Locality": "Localidad"
                },
                "placeholder": {
                    "Region": "Región",
                    "County": "Condado",
                    "Locality": "Localidad"
                }
            },
            "poBox": {
                "label": {
                    "PO BOX": "APARTADO DE CORREOS"
                },
                "placeholder": {
                    "PO BOX": "APARTADO DE CORREOS"
                }
            },
            state: {
                "label": {
                    "State": "Estado",
                    "Province/Region": "Provincia",
                    "Province": "Provincia",
                    "Department": "Departamento",
                    "Municipality/Comuna": "Municipio/Comuna"
                },
                "placeholder": {
                    "State": "Estado",
                    "Province/Region": "Provincia",
                    "Province": "Provincia",
                    "Department": "Departamento",
                    "Municipality/Comuna": "Municipio/Comuna"
                },
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alaska",
                    "Arizona": "Arizona",
                    "Arkansas": "Arkansas",
                    "California": "California",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "Distrito de Columbia",
                    "Florida": "Florida",
                    "Georgia": "Georgia",
                    "Hawaii": "Hawai",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Luisiana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Misisipí",
                    "Missouri": "Misuri",
                    "Montana": "Montana",
                    "Nebraska": "Nebraska",
                    "Nevada": "Nevada",
                    "New Hampshire": "Nuevo Hampshire",
                    "New Jersey": "New Jersey",
                    "New Mexico": "Nuevo Mexico",
                    "New York": "Nueva York",
                    "North Carolina": "Carolina del Norte",
                    "North Dakota": "Dakota del Norte",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregón",
                    "Pennsylvania": "Pensilvania",
                    "Rhode Island": "Rhode Island",
                    "South Carolina": "Carolina del Sur",
                    "South Dakota": "Dakota del Sur",
                    "Tennessee": "Tennesse",
                    "Texas": "Texas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Virginia",
                    "Washington": "Washington",
                    "West Virginia": "Virginia del Oeste",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            "deliveryInstructions": {
                "label": {
                    "Delivery Instructions": "Instrucciones de entrega"
                },
                "placeholder": {
                    "Extra address information, delivery instructions, etc. ": "Información adicional de dirección, instrucciones de entrega, etc. ",
                    "Extra address information, delivery instructions, etc. in English ": "Información adicional de dirección, instrucciones de entrega, etc. en inglés. ",
                    "Extra address information, delivery instructions, etc": "Información adicional de dirección, instrucciones de entrega, etc."
                }
            },
        },
        BRA: {
            name: {
                label: {"Full name": "Nombre completo"},
                placeholder: {"Full name": "Nombre completo"}
            },
            firstStreet: {
                label: {"Address 1": "Dirección 1"},
                placeholder: {"Address 1": "Calle + número de casa/depto."}
            },
            secondStreet: {
                label: {
                    "Address 2": "Dirección 2",
                    "District": "Distrito"
                },
                placeholder: {
                    "Address 2": "Calle + número de casa/depto.",
                    "District": "Distrito"
                }
            },
            city: {
                label: {City: "Ciudad"},
                placeholder: {City: "Ciudad"},
            },
            state: {
                label: {State: "Estado"},
                placeholder: {State: "Estado"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Código postal",
                    "Post Code": "Código postal",
                    "Postal Code": "Código Postal",
                    "CPA": "contador público"
                },
                "placeholder": {
                    "Zipcode": "Código postal",
                    "Post Code": "Código postal",
                    "Postal Code": "Código Postal",
                    "CPA": "contador público"
                }
            },
            identifier: {
                "label": {
                    "Tax ID": "Identificación del impuesto"
                },
                "placeholder": {
                    "Tax ID (CDI, CUIT, CUIL, etc)": "Identificación Fiscal (CDI, CUIT, CUIL, etc)"
                }
            }
        }
    },

    errors: {
        required: "{field} se requiere.",
        mask: "{field} es inválido."
    },

    buttons: {
        submit: {
            text: "Ahorrar",
            saving: "Ahorro"
        },
        cancel: {
            text: "Cancelar"
        }
    },

    disclaimer: "Agregar esta dirección requerirá verificación por correo electrónico. Después de guardar la dirección, verifique su correo electrónico y haga clic en el enlace que contiene.",

    message: {
        added: "La dirección ha sido agregada.",
        updated: "La dirección ha sido actualizada."
    }
}