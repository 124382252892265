export default {
    model: {
        email: {
            label: "Email",
            placeholder: "E-mail delegálása"
        },
        start_on: {
            label: "Kezdő dátum",
            placeholder: "A delegálás megkezdésének dátuma"
        },
        end_on: {
            label: "Befejezés dátuma",
            placeholder: "Választható"
        },
        accept: {
            tip: "A jóváhagyási felelősség átruházásával Ön igazolja, hogy elszámoltatható és felelősséget vállal a megbízott által jóváhagyott bármely megrendelésért.",
            label: "elfogadom"
        }
    },
    buttons: {
        submit: {
            cta: "megerősít",
            processing: "Megtakarítás"
        },
        close: {
            cta: "Megszünteti"
        }
    },
    saved: "Mentett"
}