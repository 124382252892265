export default {
    model: {
        email: {
            label: "E-mailem",
            placeholder: "Delegátský e-mail"
        },
        start_on: {
            label: "Datum zahájení",
            placeholder: "Datum zahájení delegování"
        },
        end_on: {
            label: "Datum ukončení",
            placeholder: "Volitelný"
        },
        accept: {
            tip: "Delegováním své odpovědnosti za schvalování potvrzujete, že přebíráte odpovědnost a odpovědnost za jakékoli objednávky schválené vaším delegátem.",
            label: "přijímám"
        }
    },
    buttons: {
        submit: {
            cta: "Potvrdit",
            processing: "Ukládání"
        },
        close: {
            cta: "zrušení"
        }
    },
    saved: "Uložené"
}