export default {
    "India": "Indie",
    "United States of America": "Stany Zjednoczone Ameryki",
    "United Kingdom": "Zjednoczone Królestwo",
    "Philippines": "Filipiny",
    "Australia": "Australia",
    "Spain": "Hiszpania",
    "Bulgaria": "Bułgaria",
    "Poland": "Polska",
    "Malaysia": "Malezja",
    "Russian Federation": "Federacja Rosyjska",
    "Ukraine": "Ukraina",
    "Germany": "Niemcy",
    "Brazil": "Brazylia",
    "Canada": "Kanada",
    "Romania": "Rumunia",
    "France": "Francja",
    "China": "Chiny",
    "Italy": "Włochy",
    "Costa Rica": "Kostaryka",
    "Mexico": "Meksyk",
    "Slovakia": "Słowacja",
    "Vietnam": "Wietnam",
    "Singapore": "Singapur",
    "Argentina": "Argentyna",
    "Denmark": "Dania",
    "Japan": "Japonia",
    "Belgium": "Belgia",
    "Czech Republic": "Republika Czeska",
    "Netherlands": "Holandia",
    "Switzerland": "Szwajcaria",
    "Sweden": "Szwecja",
    "New Zealand": "Nowa Zelandia",
    "Hungary": "Węgry",
    "Egypt": "Egipt",
    "Lithuania": "Litwa",
    "United Arab Emirates": "Zjednoczone Emiraty Arabskie",
    "Ireland": "Irlandia",
    "Hong Kong": "Hongkong",
    "Austria": "Austria",
    "Saudi Arabia": "Arabia Saudyjska",
    "Portugal": "Portugalia",
    "Norway": "Norwegia",
    "Chile": "Chile",
    "Thailand": "Tajlandia",
    "Taiwan": "Tajwan",
    "Tunisia": "Tunezja",
    "Israel": "Izrael",
    "South Africa": "Afryka Południowa",
    "Colombia": "Kolumbia",
    "Serbia": "Serbia",
    "Luxembourg": "Luksemburg",
    "Peru": "Peru",
    "Finland": "Finlandia",
    "Brunei Darussalam": "Brunei Darussalam",
    "Korea, Republic of": "Republika Korei",
    "Indonesia": "Indonezja",
    "Jordan": "Jordania",
    "Fiji": "Fidżi",
    "Morocco": "Maroko",
    "Nigeria": "Nigeria",
    "Panama": "Panama",
    "Qatar": "Katar",
    "Greece": "Grecja",
    "Turkey": "Indyk",
    "Croatia": "Chorwacja",
    "Kazakhstan": "Kazachstan",
    "Cyprus": "Cypr"
}