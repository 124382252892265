export default {
    title: "Confirmação de entrega",
    header: "@:views.survey.delivery.title",
    result: {
        header: "Obrigado",
        message: "Obrigado pela sua resposta."
    },
    status: {
        yes: "Por favor, verifique os produtos marcados abaixo para confirmar a entrega e desmarque os que você não recebeu. Em seguida, clique em Enviar para confirmar.",
        no: "Verifique se você não recebeu nenhum dos produtos abaixo. Se você recebeu algum desses produtos, marque a caixa ao lado do(s) produto(s) recebido(s) e clique em Enviar para confirmar."
    },
    model: {
        checked: {
            label: "Recebido"
        }
    },
    button: {
        submit: {
            cta: "Enviar",
            processing: "Enviando"
        }
    }
}