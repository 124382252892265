export default {
    home: "家",
    order: {
        submit: "查看订单"
    },
    tunnel: {
        loginVerification: "登录验证",
        addressVerification: "地址验证"
    },
    catalog: "目录",
    all: "全部",
    return: {
        return: "返回"
    },
    errors: {
        404: "@:views.errors.404.title"
    },
    impersonation: "代订"
}