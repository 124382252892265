<template>
    <span class="address-viewer">
    <!-- Address -->
        <span class="address">
            <!-- Address details-->
            <span v-for="item in items" :key="item" class="item pre-white-space">{{item}}</span>
        </span>

        <span class="control">
            <span v-if="showDeleteButton" class="d-block pb-3">
                <button type="button" class="btn btn-link btn-control"
                        :disabled="disabled||processing" @click="deleteEmail(value._id)">
                    <span v-if="deleting">
                        <span class="animate-spin icon-spinner"></span>{{t('buttons.delete.processing')}}
                    </span>
                    <span v-else>
                        {{t('buttons.delete.cta')}}
                    </span>
                </button>
            </span>

            <span v-if="showTfaButton&&value.tfaNeedAddressVerification" class="d-block">

                <span class="title">{{t('unverified')}}</span>

                <button type="button" class="btn btn-link btn-control"
                        :disabled="disabled||processing"
                        @click="sendVerificationEmail(value._id)">
                    <loading v-if="verification"></loading>
                    <span v-else>{{t('verificationLink')}}</span>
                </button>

            </span>
        </span>

    </span>
</template>

<script>

    import get from "lodash/get.js";

    import mixin_address_form from "@/mixins/address/form";

    import Loading from "../Loading";

    export default {
        name: "AddressViewer",
        mixins: [mixin_address_form],
        components: {
            Loading
        },
        props: {
            value: {
                type: Object,
                required: true
            },
            tags: {
                type: Array,
                default: () => []
            },
            disabled: Boolean,
            showDeleteButton: Boolean,
            showTfaButton: Boolean
        },
        data() {

            return {
                t_path: "components.address.viewer",

                deleting: false,
                verification: false
            }
        },
        computed: {
            items() {

                return this.$store.getters["user/address/config"].reduce(
                    (result, field) => {

                        if (
                            (
                                "undefined" === typeof field.tags
                                || 0 < field.tags.filter(fieldTag => -1 !== this.tags.indexOf(fieldTag)).length
                            )
                            && this.value[field.field]
                        ) {

                            let fieldValue = this.value[field.field];

                            if ("dropdown" === field.type) {

                                fieldValue = get(
                                    this.address_form_getAddressFieldOption(field, fieldValue),
                                    "name",
                                    fieldValue
                                );
                            }

                            if ("undefined" === typeof field.tags) {

                                result.push(fieldValue);
                            } else {

                                const label = this.address_form_getAddressFieldTranslation(
                                    field,
                                    "label",
                                    this.$store.getters["user/profile/country"]
                                );

                                result.push(`${label}: ${fieldValue}`);
                            }
                        }

                        return result;
                    },
                    [this.value.country]
                );
            }
        },
        methods: {
            deleteEmail(addressId) {

                const addressDeleted = this.t('addressDeleted');
                const deleteAddressConfirmation = this.t('deleteAddressConfirmation');

                this.$modal.confirm(deleteAddressConfirmation).then(() => {

                    this.processing = true;
                    this.deleting = true;

                    return this.$store.dispatch("user/address/delete", addressId)
                        .then(() => this.$toast.success(addressDeleted))
                        .catch(error => this.base_error(error))
                        .finally(() => {

                            this.processing = false;
                            this.deleting = false;
                        });
                });
            },
            sendVerificationEmail: function (addressId) {

                this.processing = true;
                this.verification = true;

                return this.$store.dispatch("user/address/confirm", addressId)
                    .then(() => this.$toast.success(this.t('verificationEmailSent')))
                    .catch(error => this.base_error(error))
                    .finally(() => {

                        this.processing = false;
                        this.verification = false;
                    });
            }
        }
    }
</script>