export default {
    title: "物品未送达",
    header: "@:views.survey.deliveryfaq.title",
    result: {
        header: "谢谢",
        message: "感谢您的答复。"
    },
    message: "尽管快递员将其标记为已送达，但您已表示您尚未收到您的物品。",
    cta: "请按照以下步骤帮助我们解决问题：",
    items: {
        1: {
            message: "点击跟踪链接："
        },
        2: {
            message: "查看交付状态。检查：",
            items: {
                1: "谁签收包裹",
                2: "如果退回给发件人",
                3: "如果包裹正在等待取件"
            }
        },
        3: {
            message: "检查是否有指示物品可能被留在何处的送货通知。"
        },
        4: {
            message: "向可能拿走包裹的邻居、亲戚和朋友核实。"
        },
        5: {
            message: "检查快递员在您不在时留下物品的常见地点。"
        }
    },
    question: "这是否有助于解决您的送货查询？您现在拥有该物品或知道谁拥有它？",
    buttons: {
        yes: "是的",
        no: "不",
        saving: "保存"
    }
}