export default {
    error: "Er is een probleem opgetreden bij het ophalen van bestellingen. Herlaad alsjeblieft je browser.",
    meta: {
        default: "Bestellingen",
        my_orders: "mijn bestellingen",
        my_gear: "Ontvangen"
    },
    loadMore: "Meer laden",
    noOrders: "Geen bestellingen",
    order: {
        product: {
            title: "Volgorde",
            tracking: "Volgen",
            quantity: "Hoeveelheid",
            control: {
                cancel: {
                    cta: "Klik om te annuleren",
                    tooltip: "Klik om dit item te annuleren",
                    message: "Wij hebben uw bestelling geannuleerd."
                },
                cancelRequest: {
                    cta: "Verzoek om te annuleren",
                    tooltip: "Klik om een annuleringsverzoek in te dienen"
                },
                received: {
                    cta: "Markeer als ontvangen",
                    tooltip: "Klik om de levering te bevestigen",
                    message: "Bedankt dat u bevestigt dat u uw product heeft ontvangen."
                },
                notReceived: {
                    cta: "Markeer als niet ontvangen",
                    tooltip: "Klik om de niet-levering te bevestigen"
                },
                damaged: {
                    cta: "Markeer als beschadigd/gebroken",
                    tooltip: "Klik om een supportticket aan te maken"
                },
                hide: {
                    cta: "Artikel verbergen",
                    tooltip: "Klik om het item uit de lijst te verbergen"
                }
            },
            requestToCancelInfo: "Wij nemen contact op met de leverancier om een annulering aan te vragen. Houd er rekening mee dat dit slechts een verzoek is en dat annuleren mogelijk niet mogelijk is. Zodra we kunnen bepalen of het kan worden geannuleerd of niet, laten we u dit weten.",
            cancelOk: "OK",
            reason: "Reden",
            orderedBy: "Besteld door",
            model: {
                cancel: {
                    reason: {
                        label: "Redenen voor annulering",
                        placeholder: "Redenen voor annulering"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Indienen",
                        processing: "Indienen"
                    },
                    cancel: {
                        cta: "Annuleren"
                    }
                }
            }
        }
    },
    help: {
        text: "<p>Heeft u vragen over uw bestelling?<br/> Raadpleeg ons <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>Ondersteuningscentrum</b></a> voor hulp</p><p> Heeft u een product nodig dat niet in de lijst staat?<br/> Klik <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>hier</b></a> om een suggestie te sturen</p>",
        email: {
            subject: ""
        }
    }
}