export default {
    error: "Si è verificato un problema durante il recupero degli ordini. Per favore, ricarica il browser.",
    title: "Storico degli ordini",
    loadMore: "Carica altro",
    noOrders: "Nessun ordine",
    user: {
        loadMore: "Carica altro",
        product: {
            title: "Ordine",
            tracking: "Monitoraggio",
            quantity: "Quantità",
            reason: "Motivo",
            control: {
                cancel: {
                    cta: "Fare clic per annullare",
                    tooltip: "Fare clic per annullare questo elemento",
                    message: "Abbiamo annullato il tuo ordine."
                },
                received: {
                    cta: "Prodotto ricevuto",
                    tooltip: "Fare clic per confermare la consegna",
                    message: "Grazie per aver confermato di aver ricevuto il tuo prodotto."
                },
                notReceived: {
                    cta: "Prodotto non ricevuto",
                    tooltip: "Fare clic per confermare la mancata consegna"
                },
                hide: {
                    cta: "Nascondi elemento",
                    tooltip: "Fare clic per nascondere l'elemento dall'elenco"
                }
            }
        }
    }
}