import ViewsImpersonation from "@/views/impersonation/ViewsImpersonation";

import {home} from "@/router/breadcrumbs";
import {PERMISSIONS} from "@/constants";

export default {
    routes: [{
        path: "/impersonation",
        name: "impersonation",
        component: ViewsImpersonation,
        meta: {
            requireAuth: true,
            breadcrumbs: [home, {
                text: "impersonation"
            }],
            permissions: [PERMISSIONS.IMPERSONATE_USER]
        }
    }]
}