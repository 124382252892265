export default {
    ordered: {
        type: {
            "ac_router": "Ya he pedido un enrutador de CA diferente",
            "chair": "Ya pedí una silla diferente",
            "default": "Ya ordenado",
            "default_multiple": "Ya he pedido productos similares",
            "desk": "Ya pedí un escritorio diferente",
            "desktop": "Ya pedí un escritorio diferente",
            "desktop_riser": "Ya pedí un elevador de escritorio diferente",
            "detector": "Ya pedí diferentes detectores de ruido y vapeo.",
            "displayport_cable": "Ya pedí un cable displayport diferente",
            "docking station": "Ya he pedido otra estación de acoplamiento",
            "dual_usb_adapter": "Ya pedí un adaptador USB de doble banda diferente",
            "ear cushion": "Ya pedí otra almohadilla para los oídos",
            "footrest": "Ya pedí un reposapiés diferente",
            "hdmi_cable": "Ya pedí diferentes cables HDMI",
            "hdmi_displayport_cable": "Ya pedí un cable HDMI a DisplayPort diferente",
            "hdmi_vga_cable": "Ya pedí un cable HDMI a VGA diferente",
            "headset": "Ya pedí un dispositivo de audio diferente",
            "keyboard": "Ya pedí un teclado diferente",
            "kvm switch": "Ya pedí un interruptor kvm diferente",
            "kvm_switch": "Ya pedí un interruptor kvm diferente",
            "lamp": "Ya pedí una lámpara diferente.",
            "laptop stand": "Ya pedí un soporte para computadora portátil diferente",
            "laptop": "Ya pedí otra computadora portátil",
            "mesh_router": "Ya pedí un enrutador de malla diferente",
            "monitor": "Ya pedí un monitor diferente",
            "mouse": "Ya pedí diferentes ratones.",
            "patch_cord": "Ya pedí un cable de conexión cat6 diferente",
            "power_center": "Ya pedí un Power Center diferente",
            "power_strip": "Ya pedí una regleta diferente",
            "smart_plug": "Ya pedí diferentes enchufes inteligentes mini",
            "smart_power_strip": "Ya he pedido una regleta inteligente diferente",
            "thunderbolt_cable": "Ya pedí un cable USB-C a Thunderbolt diferente",
            "travel_router": "Ya he pedido un router de viaje diferente",
            "usb hub": "Ya pedí un concentrador USB diferente",
            "usb_adapter": "Ya pedí un adaptador USB diferente",
            "usb_hub": "Ya pedí un centro diferente",
            "webcam": "Ya pedí otra cámara web"
        },
        product: "Ordenado"
    },
    request: "Pedido",
    add: "Añadir a la cesta",
    remove: "Quitar del carrito",
    added: "Agregado al carrito",
}