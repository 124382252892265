export default {
    "India": "Indie",
    "United States of America": "Spojené státy americké",
    "United Kingdom": "Spojené království",
    "Philippines": "Filipíny",
    "Australia": "Austrálie",
    "Spain": "Španělsko",
    "Bulgaria": "Bulharsko",
    "Poland": "Polsko",
    "Malaysia": "Malajsie",
    "Russian Federation": "Ruská Federace",
    "Ukraine": "Ukrajina",
    "Germany": "Německo",
    "Brazil": "Brazílie",
    "Canada": "Kanada",
    "Romania": "Rumunsko",
    "France": "Francie",
    "China": "Čína",
    "Italy": "Itálie",
    "Costa Rica": "Kostarika",
    "Mexico": "Mexiko",
    "Slovakia": "Slovensko",
    "Vietnam": "Vietnam",
    "Singapore": "Singapur",
    "Argentina": "Argentina",
    "Denmark": "Dánsko",
    "Japan": "Japonsko",
    "Belgium": "Belgie",
    "Czech Republic": "Česká republika",
    "Netherlands": "Holandsko",
    "Switzerland": "Švýcarsko",
    "Sweden": "Švédsko",
    "New Zealand": "Nový Zéland",
    "Hungary": "Maďarsko",
    "Egypt": "Egypt",
    "Lithuania": "Litva",
    "United Arab Emirates": "Spojené arabské emiráty",
    "Ireland": "Irsko",
    "Hong Kong": "Hongkong",
    "Austria": "Rakousko",
    "Saudi Arabia": "Saudská arábie",
    "Portugal": "Portugalsko",
    "Norway": "Norsko",
    "Chile": "Chile",
    "Thailand": "Thajsko",
    "Taiwan": "Tchaj-wan",
    "Tunisia": "Tunisko",
    "Israel": "Izrael",
    "South Africa": "Jižní Afrika",
    "Colombia": "Kolumbie",
    "Serbia": "Srbsko",
    "Luxembourg": "Lucembursko",
    "Peru": "Peru",
    "Finland": "Finsko",
    "Brunei Darussalam": "Brunej Darussalam",
    "Korea, Republic of": "Korejská republika",
    "Indonesia": "Indonésie",
    "Jordan": "Jordán",
    "Fiji": "Fidži",
    "Morocco": "Maroko",
    "Nigeria": "Nigérie",
    "Panama": "Panama",
    "Qatar": "Katar",
    "Greece": "Řecko",
    "Turkey": "krocan",
    "Croatia": "Chorvatsko",
    "Kazakhstan": "Kazachstán",
    "Cyprus": "Kypr"
}