export default {
    unverified: "Inconfirmado",
    verificationLink: "Reenviar correo electrónico de verificación",
    buttons: {
        delete: {
            cta: "Borrar",
            processing: "Borrando"
        }
    },
    addressDeleted: "La dirección ha sido eliminada",
    verificationEmailSent: "E-mail ha sido enviado",
    deleteAddressConfirmation: "¿Está seguro de que desea eliminar esta dirección?"
}