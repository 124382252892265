export const PAGE_LOGIN_VERIFIED = "login";
export const PAGE_ADDRESS_VERIFIED = "address";

export default {
    getPageDestination(query) {

        switch (query.page) {

            case PAGE_LOGIN_VERIFIED:

                return "tunnel.loginVerification";

            case PAGE_ADDRESS_VERIFIED:

                return "tunnel.addressVerification";

            default:
                return null;
        }
    }
}