export default {

    title: "Betöltés...",

    responses: {
        "200_tfalogin": {
            title: "Bejelentkezés ellenőrzése",
            header: "@:views.tunnel.service.response.200_tfalogin.title",
            message: "A bejelentkezés ellenőrzése megtörtént."
        },
        "404_tfalogin": {
            title: "Bejelentkezés ellenőrzése",
            header: "@:views.tunnel.service.response.404_tfalogin.title",
            message: "Érvényes token nem található. Lehet, hogy a tokenje lejárt."
        },
        "200_tfaaddress": {
            title: "Cím ellenőrzése",
            header: "@:views.tunnel.service.response.200_tfaaddress.title",
            message: "Köszönjük, hogy igazolta a címét. Mostantól ezt is kiválaszthatja szállítási címként a rendelés leadásakor."
        },
        "404_tfaaddress": {
            title: "Cím ellenőrzése",
            header: "@:views.tunnel.service.response.404_tfaaddress.title",
            message: "Érvényes token nem található. Lehet, hogy a tokenje lejárt."
        },
        "201_delivery": {
            title: "Köszönöm",
            header: "Köszönöm",
            message: "Köszönjük a választ."
        },
        "201_shipment_received": {
            title: "Köszönöm",
            header: "Köszönöm",
            message: "Köszönjük a választ."
        },
        "400": {
            title: "Rossz kérés. Hiba lépett fel.",
            header: "@:views.tunnel.service.response.400.title",
            message: "Rossz kérés. Hiba lépett fel."
        }
    }
}