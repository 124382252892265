export default {
    unverified: "未验证",
    verificationLink: "重新发送验证邮件",
    buttons: {
        delete: {
            cta: "删除",
            processing: "删除"
        }
    },
    addressDeleted: "地址已被删除",
    verificationEmailSent: "邮件已发送",
    deleteAddressConfirmation: "您确定要删除此地址吗？"
}