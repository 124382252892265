export default {
    title: "Portalumfrage",
    header: "@:views.survey.customer.title",
    model: {
        service_rating: {
            label: "Auf einer Skala von 1 bis 10, wobei 1 sehr unzufrieden und 10 sehr zufrieden ist, wie würden Sie diesen Service bewerten?",
        },
        service_likes: {
            label: "Was hat dir gefallen?",
            placeholder: ""
        },
        service_improvements: {
            label: "Was kann verbessert werden?",
            placeholder: ""
        },
        service_easy_to_follow: {
            label: "Auf einer Skala von 1 bis 10, wobei 1 überhaupt nicht klar und 10 leicht verständlich bedeutet, wie würden Sie die Kommunikation zu dieser Website bewerten?"
        },
        item: {
            setup_rating: {
                label: "Auf einer Skala von 1 bis 10, wobei 1 sehr schwierig und 10 sehr einfach bedeutet, wie schwierig war die Installation und Einrichtung Ihres \"{name}\"?",
            },
            comments: {
                label: "Bitte geben Sie Kommentare zu {name} ab?",
                placeholder: ""
            }
        }
    },
    disclaimer: "Bitte beachten Sie, dass Ihre Umfrageantworten mit O-I geteilt werden und wir können Sie bezüglich Ihres Feedbacks kontaktieren.",
    button: {
        submit: {
            cta: "Einreichen",
            processing: "Einreichen"
        }
    }
}