export default {
    400: {
        title: "Foute aanvraag",
        header: "@:views.errors.400.title",
        html: "Foute aanvraag. Probeer het later opnieuw."
    },
    401: {
        title: "Er is een fout opgetreden bij het inloggen",
        header: "@:views.errors.401.title",
        html: "Neem contact op met <a href='{email}' target='_blank'>de ondersteuning</a> ."
    },
    404: {
        title: "Pagina niet gevonden",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Toegang niet toegestaan",
        header: "Toegang niet toegestaan",

        contact: "O-I Randapparatuur is alleen beschikbaar voor werknemers. Als u een werknemer bent en dit bericht ziet, werk dan samen met uw lokale People &amp; Culture om uw status bij te werken.",

        subject: "Uitrusting - Geschiktheid",

        html: "<p class='text-left'>@:views.errors.403.eligibility @:views.errors.403.contact</p>",
        error: {
            disabled: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>U komt momenteel niet in aanmerking. Neem contact op met <a href='#' role='button' data-widget-trigger='{widgetOptions}'>de ondersteuning</a> voor meer informatie.</p>"
            },
            "not-active": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>U komt momenteel niet in aanmerking. Werk samen met uw lokale bevolking en cultuur voor meer informatie.</p>"
            },
            "not-allowed":{
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I Randapparatuur is alleen beschikbaar voor werknemers. Als u een werknemer bent en dit bericht ziet, werk dan samen met uw lokale People &amp; Culture om uw status bij te werken.</p>"
            },
            "no-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>U heeft geen actieve beheerder vermeld in uw ServiceNow-profiel. Werk samen met uw lokale People &amp; Culture-vertegenwoordiger om uw manager op de hoogte te houden.</p>"
            },
            "self-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Uw manager is ingesteld als u in uw ServiceNow-profiel. Werk samen met uw lokale People &amp; Culture-vertegenwoordiger om uw manager op de hoogte te houden.</p>"
            },
            "no-location": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>U heeft geen locatie vermeld in uw ServiceNow-profiel. Werk samen met uw lokale People &amp; Culture-vertegenwoordiger om uw locatie bij te werken.</p>"
            },
            eligibility: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I Randapparatuur is alleen beschikbaar voor werknemers. Als u een werknemer bent en dit bericht ziet, werk dan samen met uw lokale People &amp; Culture om uw status bij te werken.</p>",
            },
            "eligibility-location": {
                header: "Zo te zien ben je een beetje vroeg...",
                html: "<p class='text-left'>Bedankt voor uw interesse in O-I Randapparatuur. Het lijkt erop dat u de site heeft bereikt voordat u bent ingeschakeld en rechtstreeks bent uitgenodigd. O-I Randapparatuur wordt stapsgewijs uitgerold, land per land en per gebruikersgroep. Als je geactiveerd bent, ontvang je een e-mail met daarin een persoonlijke uitnodiging met instructies.</p><p class='text-left'> Klik op <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Support</a> voor vragen of problemen met uw bestelling of de site.</p>",
            },
            "secondary-account": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>U probeert momenteel toegang te krijgen tot O-I Randapparatuur op een secundair account. Probeer opnieuw in te loggen met uw primaire account.</p>"
            },
            "empty-core-id": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Uw ServiceNow-account heeft geen accountnaam. Werk samen met uw lokale People &amp; Culture-vertegenwoordiger om uw account bij te werken.</p>"
            },
            country: {
                header: "@:views.errors.403.header",
                html: "@:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "no-permissions": {
                header: "Geen toegang",
                subject: "Geen toegang",
                html: "U heeft geen rechten om deze pagina te openen.",
            },
            "virtual-denied": {
                header: "Zo te zien ben je een beetje vroeg...",
                html: "<p class='text-left'>Bedankt voor uw interesse in O-I Randapparatuur. Het lijkt erop dat u de site heeft bereikt voordat u bent ingeschakeld en rechtstreeks bent uitgenodigd. O-I Randapparatuur wordt stapsgewijs uitgerold, land per land en per gebruikersgroep. Als je geactiveerd bent, ontvang je een e-mail met daarin een persoonlijke uitnodiging met instructies.</p><p class='text-left'> Klik op <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Support</a> voor vragen of problemen met uw bestelling of de site.</p>",
            }
        }
    },
    500: {
        title: "Onverwachte fout",
        header: "@:views.errors.500.title",
        message: "Er is een fout opgetreden. Probeer het later opnieuw."
    },
    code: {
        title: "Onverwachte fout",
        message: "@:views.errors.code.title",
        error: "Fout {code}."
    }
}