<template>
    <div class="impersonation-orders-history">
        <div class="title">{{t('title')}}</div>

        <loading v-if="true === loading && 0 === users.length"/>
        <div v-else-if="false === loading && 0 === total">
            <div class="no-impersonation-orders">{{t('noOrders')}}</div>
        </div>
        <div v-else-if="0 < users.length">
            <impersonation-orders-history-user v-for="user in users"
                                               :key="user.id"
                                               :user-id="user.id"
                                               :user-email="user.email"
                                               :name="user.name"/>

            <!-- Load more-->
            <div v-if="total > users.length" class="load-more">
                <button type="button"
                        class="btn btn-link p-0"
                        :disabled="loading"
                        @click="load(users.length, true, true)">
                    {{t('loadMore')}}
                    <spinner v-if="true === loading"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import Loading from "@/components/Loading";
    import Spinner from "@/components/Spinner";
    import ImpersonationOrdersHistoryUser from "@/components/impersonation/ImpersonationOrdersHistoryUser";

    export default {
        name: "ImpersonationOrdersHistory",
        components: {
            Loading,
            Spinner,
            ImpersonationOrdersHistoryUser
        },
        data() {

            return {
                t_path: "components.impersonation.orders.history",
                loading: false,
                total: 0,
                users: []
            };
        },
        methods: {
            load(skip, append, changeLoadingIndicator, limit) {

                if (changeLoadingIndicator) {

                    this.loading = true;
                }

                this.$store
                    .dispatch("user/impersonation/orders/loadUsers", {
                        skip,
                        limit: limit ? limit : process.env.VUE_APP_COMPONENT_IMPERSONATION_ORDERS_HISTORY_CHUNK_SIZE
                    })
                    .then(result => {

                        this.total = result.total;
                        this.users = append ? this.users.concat(result.items) : result.items;
                    })
                    .finally(() => this.loading = false);
            },
            fetch() {

                this.load(0, false, true, process.env.VUE_APP_COMPONENT_IMPERSONATION_ORDERS_HISTORY_CHUNK_SIZE);
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>