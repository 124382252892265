export default {
    "India": "India",
    "United States of America": "Stati Uniti d'America",
    "United Kingdom": "Regno Unito",
    "Philippines": "Filippine",
    "Australia": "Australia",
    "Spain": "Spagna",
    "Bulgaria": "Bulgaria",
    "Poland": "Polonia",
    "Malaysia": "Malaysia",
    "Russian Federation": "Federazione Russa",
    "Ukraine": "Ucraina",
    "Germany": "Germania",
    "Brazil": "Brasile",
    "Canada": "Canada",
    "Romania": "Romania",
    "France": "Francia",
    "China": "Cina",
    "Italy": "Italia",
    "Costa Rica": "Costa Rica",
    "Mexico": "Messico",
    "Slovakia": "Slovacchia",
    "Vietnam": "Vietnam",
    "Singapore": "Singapore",
    "Argentina": "Argentina",
    "Denmark": "Danimarca",
    "Japan": "Giappone",
    "Belgium": "Belgio",
    "Czech Republic": "Repubblica Ceca",
    "Netherlands": "Olanda",
    "Switzerland": "Svizzera",
    "Sweden": "Svezia",
    "New Zealand": "Nuova Zelanda",
    "Hungary": "Ungheria",
    "Egypt": "Egitto",
    "Lithuania": "Lituania",
    "United Arab Emirates": "Emirati Arabi Uniti",
    "Ireland": "Irlanda",
    "Hong Kong": "Hong Kong",
    "Austria": "Austria",
    "Saudi Arabia": "Arabia Saudita",
    "Portugal": "Portogallo",
    "Norway": "Norvegia",
    "Chile": "Chile",
    "Thailand": "Tailandia",
    "Taiwan": "Taiwan",
    "Tunisia": "Tunisia",
    "Israel": "Israele",
    "South Africa": "Sud Africa",
    "Colombia": "Colombia",
    "Serbia": "Serbia",
    "Luxembourg": "Lussemburgo",
    "Peru": "Perù",
    "Finland": "Finlandia",
    "Brunei Darussalam": "Brunei Darusalam",
    "Korea, Republic of": "Corea, Repubblica di",
    "Indonesia": "Indonesia",
    "Jordan": "Giordania",
    "Fiji": "Figi",
    "Morocco": "Marocco",
    "Nigeria": "Nigeria",
    "Panama": "Panama",
    "Qatar": "Qatar",
    "Greece": "Grecia",
    "Turkey": "Tacchino",
    "Croatia": "Croazia",
    "Kazakhstan": "Kazakistan",
    "Cyprus": "Cipro"
}