import {offsetLocalTimestampToUTC} from "@/helpers/date";

export default {
    methods: {
        form_payload_getDateUtcMilliseconds(localDateTimeMilliseconds) {

            return localDateTimeMilliseconds
                ? offsetLocalTimestampToUTC(parseInt(localDateTimeMilliseconds))
                : localDateTimeMilliseconds;
        }
    }
}