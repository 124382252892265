export default {
    message: {
        //     constraints  ordered
        default_everything_everything: "<p>Bisher haben Sie alles bestellt, was Ihnen in Gear zur Verfügung steht.</p><p> Wenn Sie eine spezielle Frage dazu haben, klicken Sie bitte oben auf den <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear-Support-</a> Link und senden Sie Ihre Frage.</p>",
        default_everything_something: "<p>Bereits bestellte Artikel werden nicht mehr angezeigt.</p><p> Wenn Sie eine spezielle Frage dazu haben, klicken Sie bitte oben auf den <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear-Support-</a> Link und senden Sie Ihre Frage.</p>",
        default_partial_everything: "<p>Es müssen keine zusätzlichen Produkte bestellt werden.</p><p> Hat sich Ihre Situation seit der ersten Beantwortung Ihrer Ausrüstungsumfrage geändert? Klicken Sie <a href='{equipmentQuizLink}'><b>hier</b></a> , um die Antworten Ihrer Geräteumfrage zu überprüfen.</p>",
        default_partial_something: "<p>Bereits bestellte Artikel werden nicht mehr angezeigt.</p><p> Hat sich Ihre Situation seit der ersten Beantwortung Ihrer Ausrüstungsumfrage geändert? Klicken Sie <a href='{equipmentQuizLink}'><b>hier</b></a> , um die Antworten Ihrer Geräteumfrage zu überprüfen.</p>",
        default_partial_noitems: "<p>Hat sich Ihre Situation seit der ersten Beantwortung Ihrer Ausrüstungsumfrage geändert? Klicken Sie <a href='{equipmentQuizLink}'><b>hier</b></a> , um die Antworten Ihrer Geräteumfrage zu überprüfen.</p>",

        ehs_everything_everything: "<p>Bisher haben Sie alles bestellt, was Ihnen in Gear zur Verfügung steht.</p><p> Die Verfügbarkeit von Produkten basiert auf der EHS-Umfrage. Wenn Sie zusätzliche Unterstützung benötigen, wenden Sie sich bitte an <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_everything_something: "<p>Bereits bestellte Artikel werden nicht mehr angezeigt.</p><p> Die Verfügbarkeit von Produkten basiert auf der EHS-Umfrage. Wenn Sie zusätzliche Unterstützung benötigen, wenden Sie sich bitte an <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_everything: "<p>Bisher haben Sie alles bestellt, was Ihnen in Gear zur Verfügung steht.</p><p> Die Verfügbarkeit von Produkten basiert auf der EHS-Umfrage. Wenn Sie zusätzliche Unterstützung benötigen, wenden Sie sich bitte an <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_something: "<p>Bereits bestellte Artikel werden nicht mehr angezeigt.</p><p> Die Verfügbarkeit von Produkten basiert auf der EHS-Umfrage. Wenn Sie zusätzliche Unterstützung benötigen, wenden Sie sich bitte an <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_noitems: "<p>Die Verfügbarkeit von Produkten basiert auf der EHS-Umfrage. Wenn Sie zusätzliche Unterstützung benötigen, wenden Sie sich bitte an <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
    }
}