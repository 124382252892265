export default {
    title: "Articoli non consegnati",
    header: "@:views.survey.deliveryfaq.title",
    result: {
        header: "Grazie",
        message: "Grazie per la vostra risposta."
    },
    message: "Hai indicato di non aver ricevuto il tuo articolo nonostante il corriere lo contrassegni come consegnato.",
    cta: "Segui questi passaggi per aiutarci a risolvere il problema:",
    items: {
        1: {
            message: "Clicca sul link di tracciamento:"
        },
        2: {
            message: "Esaminare lo stato della consegna. Controlla:",
            items: {
                1: "Chi ha firmato per il pacco",
                2: "Se è stato rispedito al mittente",
                3: "Se il pacco è in attesa di ritiro"
            }
        },
        3: {
            message: "Verificare la presenza di un avviso di consegna che indichi dove potrebbe essere stato lasciato l'articolo."
        },
        4: {
            message: "Verificare con vicini, parenti e amici che potrebbero aver preso il pacco."
        },
        5: {
            message: "Controlla i luoghi comuni in cui i corrieri lasciano gli articoli in tua assenza."
        }
    },
    question: "Questo ha aiutato a risolvere la tua richiesta di consegna? Ora possiedi l'oggetto o sai chi lo ha?",
    buttons: {
        yes: "SÌ",
        no: "NO",
        saving: "Salvataggio"
    }
}