export default {
    error: "Hubo un problema al recuperar pedidos. Por favor refresque su navegador.",
    title: "Historial de pedidos",
    loadMore: "Carga más",
    noOrders: "Sin pedidos",
    user: {
        loadMore: "Carga más",
        product: {
            title: "Orden",
            tracking: "Seguimiento",
            quantity: "Cantidad",
            reason: "Razón",
            control: {
                cancel: {
                    cta: "Haga clic para cancelar",
                    tooltip: "Haga clic para cancelar este artículo",
                    message: "Hemos cancelado tu pedido."
                },
                received: {
                    cta: "Producto recibido",
                    tooltip: "Haga clic para confirmar la entrega",
                    message: "Gracias por confirmar que recibió su producto."
                },
                notReceived: {
                    cta: "Producto no recibido",
                    tooltip: "Haga clic para confirmar la no entrega"
                },
                hide: {
                    cta: "Ocultar elemento",
                    tooltip: "Haga clic para ocultar el elemento de la lista"
                }
            }
        }
    }
}