import get from "lodash/get"
import {USER_TOKEN_STORAGE_KEY, USER_IMPERSONATOR_TOKEN_STORAGE_KEY} from "@/api/storage";
import countries from "@/i18n/countries";
import quiz from "@quiz_equipment";
import {PERMISSIONS} from "@/constants";
import helperPermissions from "@helpers_permissions";

export default {
    methods: {
        auth_check(data) {

            const errorFlag = get(data, "errorFlag", data);
            const permissions = this.auth_permissions(data);

            if (0 === permissions.permissions.length) {

                this.$router.push(helperPermissions.getRouteByPermissionFail(PERMISSIONS.EMPLOYEE, errorFlag));
                return false;
            }

            if (errorFlag.isLoginFailure) {

                // SSO login user failed for (user was not created or found in backend)
                this.$router.push({name: "errors.401"});
                return false;
            }

            if (errorFlag.tfaNeedLoginVerification) {

                this.$router.push({name: "auth.confirm", params: data});

                return false;
            }

            return true;
        },
        auth_prepare(data) {

            this.$store.commit("user/setId", data.userId);

            const quizModel = {
                stylistTags: null
            };

            if (data.countryCode) {

                // Predefine country
                quizModel.OKTA_country = data.countryCode;

                if ("undefined" !== typeof countries[data.countryCode]) {

                    this.$i18n.locale = countries[data.countryCode];

                    this.$store.dispatch("i18n/setLanguage", countries[data.countryCode]);
                    this.$store.dispatch("user/setLanguage", countries[data.countryCode]);
                }
            }

            if (data.stylistTags && Array.isArray(data.stylistTags)) {

                // Check for user has assigned stylist tags
                if (0 < data.stylistTags.length) {

                    quizModel.stylistTags = data.stylistTags.map(t => t.name);
                }
            }

            if (data.company) {

                // Check company is allowed
                const options = get(quiz, "components.entity.options", []);
                const entity = options.find(option => option.value == data.company);

                if (entity) {

                    if ("undefined" === typeof entity.display) {

                        // OKTA_entity is used to hide entity selection component (src/quiz/equipment/index.js)
                        quizModel.OKTA_entity = entity.value;

                        // Predefine entity
                        quizModel.entity = entity.value;
                    } else if (entity.display.country && data.countryCode && -1 !== entity.display.country.indexOf(data.countryCode)) {

                        // OKTA_entity is used to hide entity selection component (src/quiz/equipment/index.js)
                        quizModel.OKTA_entity = entity.value;

                        // Predefine entity
                        quizModel.entity = entity.value;
                    }
                }
            }

            if (!this.$store.getters["user/quiz/equipment/storage"](data.userId)) {
                // no data in storage for current user quiz
                this.$store.dispatch("user/quiz/equipment/storage", quizModel);
            }
        },
        auth_authenticate(token, id, stylistTags, permissions = [], requireAcknowledgement, expirationMilliseconds) {

            const auth = {
                token,
                id,
                stylistTags,
                permissions,
                requireAcknowledgement
            };

            // Save token
            this.$storage.set(USER_TOKEN_STORAGE_KEY, auth, expirationMilliseconds / 1000);

            // Update apis
            this.$order.setToken(auth.token);
            this.$platform.setToken(auth.token);
            this.$delivery.setToken(auth.token);

            this.$store
                .dispatch("user/fetch", auth)
                .then(() => {

                    // Update language just in case it is regular login
                    if (this.$store.getters["user/language"] && !this.$store.getters["user/impersonatorToken"]) {

                        this.$i18n.locale = this.$store.getters["user/language"];
                    }

                    if (this.$route.query.targeturi) {

                        const route = this.$router.resolve(this.$route.query.targeturi);

                        if ("auth.logout" !== route.name) {

                            if (route.name && route.meta.permissions && 0 < route.meta.permissions.length) {

                                if (this.$store.getters["user/permissions/hasPermission"](route.meta.permissions)) {

                                    return window.location.href = this.$route.query.targeturi;
                                }
                            } else {

                                return window.location.href = this.$route.query.targeturi;
                            }
                        }
                    }

                    if (this.$store.getters["user/permissions/hasPermission"](PERMISSIONS.EMPLOYEE)) {

                        return this.$router.push({name: "home"});
                    }

                    this.$router.push(helperPermissions.getRouteByPermissionFail(PERMISSIONS.EMPLOYEE));
                })
                .catch(error => this.$router.push({name: "errors.500"}));
        },
        auth_impersonate(token, id, stylistTags, permissions, requireAcknowledgement, expirationMilliseconds) {

            // Save impersonator token
            this.$storage.set(
                USER_IMPERSONATOR_TOKEN_STORAGE_KEY,
                this.$storage.get(USER_TOKEN_STORAGE_KEY),
                this.$storage.getExpire(USER_TOKEN_STORAGE_KEY)
            );

            // Replicate original token value
            this.$store.commit("user/setImpersonatorToken", {
                token: this.$store.getters["user/token"],
                id:  this.$store.getters["user/id"],
                stylistTags: this.$store.getters["user/stylistTags"],
                permissions: {
                    permissions: this.$store.getters["user/permissions/permissions"],
                    restrictions: this.$store.getters["user/permissions/restrictions"]
                },
                requireAcknowledgement: this.$store.getters["user/requireAcknowledgement"]
            });

            // Clear impersonator data
            this.$store.dispatch("user/logout", {force: false});

            // Authenticate impersonated user
            this.auth_authenticate(token, id, stylistTags, permissions, requireAcknowledgement, expirationMilliseconds);
        },
        auth_permissions(data) {

            const permissions = {
                permissions: data.permissions ? data.permissions : [],
                restrictions: {}
            };

            if (data.errorFlag) {
                permissions.restrictions[PERMISSIONS.EMPLOYEE] = data.errorFlag;
            }

            return permissions;
        }
    }
}