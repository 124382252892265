export default {
    return: {
        title: "Opbrengst",
        header: "Artikelen die geretourneerd moeten worden",
        message: "De volgende bedrijfsapparatuur moet worden geretourneerd. Na het verzenden van dit formulier wordt afzonderlijk contact met u opgenomen om een afspraak te maken voor het ophalen en/of verzenden. Je leidinggevende wordt op de hoogte gehouden van de status van je retour.",

        model: {
            address: {
                country: {
                    label: "Land"
                }
            },
            exclude: {
                label: "Uitsluiten van retournering"
            },
            textarea: {
                placeholder: "Reden: goedkeuring manager, eerder teruggestuurd enz."
            },
            return_note: {
                label: "Opmerkingen retourneren",
                placeholder: "Optioneel: Vul eventuele opmerkingen over de te retourneren artikelen in."
            },
            additional_disposal: {
                label: "Heeft u fysieke DXC-records die veilig moeten worden verwijderd?"
            },
            additional_item: {
                type: {
                    label: "Type",
                    options: {
                        "chair": "Stoel",
                        "desk": "Bureau",
                        "desk riser": "Bureauverhoger",
                        "docking station": "Docking station",
                        "footrest": "Voetsteun",
                        "headset": "Hoofdtelefoon",
                        "hdmi cable": "HDMI kabel",
                        "keyboard": "Toetsenbord",
                        "kvm switch": "KVM-schakelaar",
                        "lamp": "Lamp",
                        "laptop riser": "Laptopverhoger",
                        "monitor": "Monitor",
                        "mouse": "Muis",
                        "other": "Ander",
                        "speakerphone": "Luidsprekertelefoon",
                        "usb hub": "USB-hub",
                        "webcam": "Webcam",
                    }
                },
                detail: {
                    label: "Detail",
                    placeholder: "Detail"
                },
                quantity: {
                    label: "Hoeveelheid",
                    placeholder: "Hoeveelheid"
                }
            }
        },

        submitted: {
            title: "Retour ingediend",
            message: "Je retourzending is ingediend. Er wordt afzonderlijk contact met u opgenomen over het ophalen en/of verzenden van het artikel."
        },
        nothingToReturn: {
            title: "Informatie retourneren",
            message: "Op dit moment heb je geen items om terug te sturen binnen Gear."
        },
        notEligible: {
            title: "Informatie retourneren",
            message: "Op dit moment kan Gear uw retourzending niet verwerken. Neem contact op met uw lokale manager of HR-team over hoe u uw artikelen kunt retourneren."
        },

        button: {
            add: {
                cta: "Niet-vermeld item toevoegen"
            },
            submit: {
                processing: "Indienen",
                cta: "Indienen"
            }
        }
    }
}