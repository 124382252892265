<template>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <h1>{{t('title')}}</h1>

                <p v-html="t('updatedDt', translationParameters)"></p>
                <p v-html="t('terms1', translationParameters)"></p>
                <p v-html="t('terms2', translationParameters)"></p>
                <p v-html="t('terms3', translationParameters)"></p>

                <h2 v-html="t('serviceHeader', translationParameters)"></h2>
                <p v-html="t('serviceMessage1', translationParameters)"></p>
                <p v-html="t('serviceMessage2', translationParameters)"></p>

                <h2 v-html="t('publicAreasHeader', translationParameters)"></h2>
                <p v-html="t('publicAreasMessage1', translationParameters)"></p>
                <p v-html="t('publicAreasItems', translationParameters)"></p>
                <p v-html="t('publicAreasMessage2', translationParameters)"></p>

                <h2 v-html="t('terminationAndSuspensionHeader', translationParameters)"></h2>
                <p v-html="t('terminationAndSuspensionMessage', translationParameters)"></p>

                <h2 v-html="t('accountHeader', translationParameters)"></h2>
                <p v-html="t('accountMessage', translationParameters)"></p>

                <h2 v-html="t('paymentHeader', translationParameters)"></h2>
                <p v-html="t('paymentMessage1', translationParameters)"></p>
                <p v-html="t('paymentMessage2', translationParameters)"></p>
                <p v-html="t('paymentMessage3', translationParameters)"></p>

                <h2 v-html="t('rewardsHeader', translationParameters)"></h2>
                <p v-html="t('rewardsMessage1', translationParameters)"></p>
                <p v-html="t('rewardsMessage2', translationParameters)"></p>

                <h2 v-html="t('licenseAgreementHeader', translationParameters)"></h2>
                <p v-html="t('licenseAgreementMessage', translationParameters)"></p>

                <h2 v-html="t('rightsHeader', translationParameters)"></h2>
                <p v-html="t('rightsMessage', translationParameters)"></p>

                <h2 v-html="t('copyrightHeader', translationParameters)"></h2>
                <p v-html="t('copyrightMessage', translationParameters)"></p>
                <p v-html="t('copyrightItems', translationParameters)"></p>

                <h2 v-html="t('disclaimerHeader', translationParameters)"></h2>
                <p v-html="t('disclaimerMessage', translationParameters)"></p>

                <h2 v-html="t('liabilityHeader', translationParameters)"></h2>
                <p v-html="t('liabilityMessage', translationParameters)"></p>

                <h2 v-html="t('idemnificationHeader', translationParameters)"></h2>
                <p v-html="t('idemnificationMessage', translationParameters)"></p>

                <h2 v-html="t('privacyHeader', translationParameters)"></h2>
                <p v-html="t('privacyMessage', translationParameters)"></p>

                <h2 v-html="t('disputeResolutionHeader', translationParameters)"></h2>
                <p v-html="t('disputeResolutionMessage', translationParameters)"></p>

                <h2 v-html="t('coveringLawHeader', translationParameters)"></h2>
                <p v-html="t('coveringLawMessage', translationParameters)"></p>

                <h2 v-html="t('noAgencyHeader', translationParameters)"></h2>
                <p v-html="t('noAgencyMessage', translationParameters)"></p>

                <h2 v-html="t('generalProvisionHeader', translationParameters)"></h2>
                <p v-html="t('generalProvisionMessage', translationParameters)"></p>

                <h2 v-html="t('changesHeader', translationParameters)"></h2>
                <p v-html="t('changesMessage', translationParameters)"></p>

                <h2 v-html="t('contactHeader', translationParameters)"></h2>
                <p v-html="t('contactMessage', translationParameters)"></p>
            </div>
        </div>
    </div>
</template>

<script>

    import mixin_view from "@/mixins/view";

    export default {
        name: "ViewsTerms",
        mixins: [mixin_view],
        data() {

            return {
                t_path: "views.terms",

                translationParameters: {
                    privacyUrl: this.$router.resolve({name: "privacy"}).fullPath,
                    contactEmail: "contact@remoteretail.com"
                }
            }
        }
    }
</script>