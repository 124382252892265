<template>
    <page-message v-if="success">
        <template #header>{{t('success.header')}}</template>
        <template #html>
            <div>{{t('success.message')}}</div>
        </template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
    <div v-else class="container">
        <div class="row">
            <div class="col-12">
                <h3 v-if="survey_title">{{survey_title}}</h3>
                <p v-if="survey_description" v-html="survey_description"></p>

                <views-surveys-view-product-choice v-if="survey_type == types.product_choice"
                                                   :choices="survey_data.product_choice"
                                                   :disabled="processing"
                                                   @process="submit"/>

                <views-surveys-view-address-form v-if="survey_type == types.address_form"
                                                 :address="survey_data.address_form"
                                                 :disabled="processing"
                                                 @process="submit"/>

            </div>
        </div>
    </div>
</template>

<script>

    import mixin_view from "@/mixins/view";
    import {types} from "@/store/user/surveys";
    import {sanitize} from "@/helpers/html";
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";

    import Loading from "@/components/Loading";
    import PageMessage from "@/components/page/PageMessage";
    import ViewsSurveysViewProductChoice from "./view/ViewsSurveysViewProductChoice";
    import ViewsSurveysViewAddressForm from "./view/ViewsSurveysViewAddressForm";

    export default {
        name: "ViewsQuiz",
        mixins: [mixin_view],
        components: {
            ViewsSurveysViewAddressForm,
            ViewsSurveysViewProductChoice,
            PageMessage,
            Loading
        },
        data() {

            return {
                t_path: "views.surveys.view",
                processing: false,
                success: false,
                types,

                survey_id: null,
                survey_title: null,
                survey_description: null,
                survey_data: null,
                survey_type: null
            }
        },
        methods: {
            fetch() {

                this.survey_id = this.$route.params.survey_id;

                const survey = this.$store
                    .getters["user/surveys/items"]
                    .find(survey => survey._id == this.survey_id);

                if (!survey) {

                    return this.$router.push({name: "errors.404"});
                }

                this.$store.dispatch("user/surveys/hide", this.survey_id);
                this.survey_type = survey.survey_type;
                this.survey_title = survey.data.title;
                this.survey_description = sanitize(getTranslationValue(survey.data, "description", this.$i18n));
                this.survey_data = survey.data;

                this.view_setTitle(this.survey_title);

                setTimeout(() => this.breadcrumbs_set(
                    this.$route.meta.breadcrumbs.concat([{
                        text: this.survey_title
                    }])
                ), 100);

                this.$store.dispatch("user/surveys/view", this.survey_id);
            },
            submit(model) {

                this.processing = true;

                this.$store
                    .dispatch("user/surveys/post", {
                        survey_id: this.survey_id,
                        results: {
                            [this.survey_type]: model
                        }
                    })
                    .then(() => this.$store.dispatch("user/surveys/fetch"))
                    .then(() => this.success = true)
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing = false)
            },
        },
        mounted() {

            this.fetch();
        },
        beforeUnmount() {

            this.$store.dispatch("user/surveys/hide", null);
        }
    }
</script>