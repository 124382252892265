export default {
    disclaimer: {
        label_default: "Ik erken en ga ermee akkoord dat de items die ik bestel / ontvang O-I zijn eigenschap te gebruiken voor O-I werkgerelateerde activiteiten, en als zodanig O-I behoudt zich het recht voor om teruggave van het eigendom te vragen bij mijn vertrek uit O-I (of eerder, indien vereist door O-I).",
        label_type_1: "Ik bevestig dat de zelfevaluatie voor thuiswerk nauwkeurig en te goeder trouw is ingevuld, en dat alle apparatuur of meubels zijn geleverd door O-I locatie of besteld via Gear zal door mij worden gebruikt voor mijn werk met O-I. Ik erken en ga ermee akkoord dat de items die ik bestel / ontvang O-I zijn eigenschap te gebruiken voor O-I werkgerelateerde activiteiten, en als zodanig O-I behoudt zich het recht voor om teruggave van het eigendom te vragen bij mijn vertrek uit O-I (of eerder, indien vereist door O-I).",
        errors: {
            required: "Accepteer de gebruiksvoorwaarden"
        }
    },
    cost_center: {
        label: "Kostenplaatscode",
        placeholder: "Kostenplaatscode",
        cost_center_not_found: "Het kan tot 24 uur duren voordat kostenplaatsen worden weergegeven die niet aanwezig zijn. Neem contact op met de Global Service Desk als u de juiste kostenplaats niet ziet.",
        errors: {
            regex: "Ongeldige kostenplaatscode"
        }
    },
    company_code: {
        label: "Bedrijfscode"
    },
    cost_center_name: {
        label: "Naam kostenplaats"
    },
    order_note: {
        label: "Bestel notitie",
        placeholder: "Optionele opmerking voor goedkeuring aan manager",
    }
}