<template>
    <div class="form-group" :style="style">
        <div v-for="item in before" class="small pb-2">
            <div class="font-weight-bold">{{item.label}}</div>
            <div class="">{{item.message}}</div>
        </div>
        <slot></slot>
        <div v-for="item in after" class="small pt-2">
            <div class="font-weight-bold">{{item.label}}</div>
            <div class="">{{item.message}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ApprovalsApprovalFieldDecorators",
        props: {
            approvalConfigItem: {
                type: Object,
                required: true
            },
            order: {
                type: Object,
                required: true
            }
        },
        computed: {
            style() {

                if ("undefined" === typeof this.approvalConfigItem.decorators || 0 === this.approvalConfigItem.decorators.length) {

                    return [];
                }

                return this.approvalConfigItem.decorators.reduce((result, item) => {

                    if (item.width) {

                        if (!result.minWidth || parseInt(result.minWidth) < item.width) {

                            result.minWidth = `${item.width}px`
                        }
                    }

                    return result;
                }, {});
            },
            items() {

                if ("undefined" === typeof this.approvalConfigItem.decorators || 0 === this.approvalConfigItem.decorators.length) {

                    return [];
                }

                return this.approvalConfigItem.decorators.map(item => {

                    const replacements = ("undefined" === typeof item.values || !item.values.length ? [] : item.values)
                        .reduce((result, itemValue) => {

                            if (itemValue.source && "order" === itemValue.source.type) {

                                result[itemValue.replacement] = this.order[itemValue.source.value];
                            }

                            return result;
                        }, {});

                    const translationKey = `approval_config.${this.approvalConfigItem.payload.field}.decorators.${item.name}`;

                    return {
                        position: item.position,
                        label: this.$te(`${translationKey}.label`)
                            ? this.$t(`${translationKey}.label`, replacements)
                            : this.$t(item.label, replacements),
                        message: this.$te(`${translationKey}.message`)
                            ? this.$t(`${translationKey}.message`, replacements)
                            : this.$t(item.message, replacements),
                    }
                });
            },
            before() {

                return this.items.filter(item => "before" === item.position);
            },
            after() {

                return this.items.filter(item => "after" === item.position);
            }
        }
    }
</script>
