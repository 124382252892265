export default {
    error: "Wystąpił problem podczas pobierania zamówień. Proszę odświeżyć swoją przeglądarkę.",
    title: "Historia zamówień",
    loadMore: "Załaduj więcej",
    noOrders: "Brak zamówień",
    user: {
        loadMore: "Załaduj więcej",
        product: {
            title: "Zamówienie",
            tracking: "Śledzenie",
            quantity: "Ilość",
            reason: "Powód",
            control: {
                cancel: {
                    cta: "Kliknij, aby anulować",
                    tooltip: "Kliknij, aby anulować ten przedmiot",
                    message: "Anulowaliśmy Twoje zamówienie."
                },
                received: {
                    cta: "Produkt otrzymany",
                    tooltip: "Kliknij, aby potwierdzić dostawę",
                    message: "Dziękujemy za potwierdzenie otrzymania produktu."
                },
                notReceived: {
                    cta: "Produkt nie otrzymany",
                    tooltip: "Kliknij, aby potwierdzić brak dostawy"
                },
                hide: {
                    cta: "Ukryj przedmiot",
                    tooltip: "Kliknij, aby ukryć pozycję na liście"
                }
            }
        }
    }
}