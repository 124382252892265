export default {
    user: {
        create: {
            title: "Nouvel utilisateur",
            model: {
                email: {
                    label: "E-mail",
                    placeholder: "Remplir l'e-mail"
                },
                name: {
                    label: "Nom",
                    placeholder: "Remplissez le nom"
                },
                country: {
                    label: "Pays",
                    placeholder: "Remplir le pays"
                }
            },
            cta: {
                submit: {
                    text: "Soumettre",
                    processing: "Soumission"
                },
                cancel: {
                    text: "Annuler"
                }
            }
        }
    }
}