export default {
    menu: {
        approvals: "Goedkeuringen",
        delegates: "Afgevaardigden"
    },
    approvals: {
        title: "Goedkeuring",
        error: "Er is een fout opgetreden",
        saved: "Goedkeuringen ingediend",
        noApprovals: "Niets om goed te keuren",
        review: "Controleer en bewerk de kostenplaats als deze onjuist is",
        priorApprovals: "Voorafgaande goedkeuringen",
        noOrders: "Niets om goed te keuren",
        noPriorApprovals: "Geen voorafgaande goedkeuringen",
        approved: "Goedgekeurd",
        notApproved: "Niet goedgekeurd",
        confirm: "Er zijn nog bestellingen met wachtende goedkeuringen. Wilt u doorgaan met uw huidige wijzigingen?",
        manager: "Manager",
        orders: {
            name: "Naam",
            date: "Besteldatum",
            details: "Bestel Details",
            cost: "Kosten",
            costCenter: "Kostenplaats",
            approval: "Goedkeuring"
        },
        model: {
            reason: {
                placeholder: "Reden"
            },
            approve: {
                yes: {
                    label: "Ja"
                },
                no: {
                    label: "Nee"
                }
            }
        },
        buttons: {
            submit: {
                text: "Indienen",
                processing: "Verwerken"
            }
        }
    },
    delegates: {
        title: "Afgevaardigden",
        error: "Er is een fout opgetreden",
        noDelegates: "Geen items",
        delegateDelete: "Afgevaardigde is verwijderd",
        confirm: {
            delete: "Gemachtigde verwijderen?"
        },
        delegates: {
            email: "E-mail",
            startDate: "Begin datum",
            endDate: "Einddatum",
        },
        buttons: {
            add: {
                text: "Afgevaardigde toevoegen"
            }
        }
    }
}