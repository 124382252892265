export const AUTOLOGIN_ENABLED = 1 == process.env.VUE_APP_ENABLED_AUTOLOGIN;

export const PERMISSIONS = {
    EMPLOYEE: "order",
    MANAGER: "approval",
    HELPDESK: "helpdesk",
    IMPERSONATE_USER: "impersonate_user",
    CREATE_USER: "create_user"
};

export const CATALOG_ROOT_CATEGORY = 1 == process.env.VUE_APP_CATALOG_ROOT_CATEGORY;

export const CATALOG_STATUS_MESSAGE = 1 == process.env.VUE_APP_CATALOG_STATUS_MESSAGE;

export const ADDRESS_TFA = 1 == process.env.VUA_APP_ADDRESS_TFA;

export const USER_MENU_DISPLAY_EDIT_PROFILE = 1 == process.env.VUE_APP_USER_MENU_DISPLAY_EDIT_PROFILE;

export const HOME_PAGE_GROUPING_TYPE = process.env.VUE_APP_HOME_PAGE_GROUPING_TYPE;

export const ORDER_STATUS = {
    DELIVERY_NOT_CONFIRMED: "delivery_not_confirmed",
    ACKNOWLEDGED: "acknowledged",
    CREATED: "created",
    SENT: "sent",
    CONFIRMED: "confirmed",
    NOT_CONFIRMED: "not_confirmed",
    DELIVERED: "delivered",
    DELIVERY_EMAIL: "delivery_email",
    SHIPPED: "shipped",
    DELAYED: "delayed",
    CANCELED: "canceled",
    PREPARING: "preparing",
    PROCESSING: "processing",
    PROCESSED: "processed",
    SUBMITTED: "submitted",
    DELIVERY_RECEIVED: "delivery_received",
    DELIVERY_NOT_RECEIVED: "delivery_not_received",
    RETURNED: "returned",
    REFURBISHED: "refurbished",
    RETURNED_TO_OFFICE: "returned_to_office",
    DISPOSED: "disposed",
    LOST_SHIPMENT: "lost_shipment",
    RETURN_PICKUP_SCHEDULED: "return_pickup_scheduled",
    RETURN_PICKUP_COMPLETED: "return_pickup_completed",
    RETURN_PICKUP_EXCLUDED: "return_pickup_excluded",
    RETURN_PICKUP_SUBMITTED: "return_pickup_submitted",
    RETURN_PICKUP_FINALIZED: "return_pickup_finalized",
    RETURN_TO_EMPLOYER: "return_to_employer",
    DAMAGED: "damaged",
    SHIPMENT_EXCEPTION: "shipment_exception",
    DELIVERY_EXCEPTION:"delivery_exception",
    PENDING_APPROVAL: "pending_approval",
    APPROVED: "approved",
    NOT_APPROVED: "not_approved"
};

export const PRODUCT_TAG_GENERIC = "generic"

export const PRODUCT_CLASS = {
    PRODUCT: "Product",
    LINKED_PRODUCT: "LinkedProduct"
};

export const ORDERS_LIMIT = 50;

export const IMPERSONATION_TOKEN_LIFETIME_HOURS = 24