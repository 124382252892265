export default {
    view: {
        success: {
            header: "Enquête ingediend",
            message: "Bedankt voor je feedback."
        },
        productChoice: {
            cta: "Redden",
            processing: "Besparing"
        },
        addressForm: {
            cta: "Redden",
            processing: "Besparing"
        }
    },
    delivery: {
        title: "Opleveringsonderzoek",
        message: "Heb je alle onderstaande items ontvangen?",
        cta: {
            yes: "Ja",
            no: "Nee",
            processing: "Besparing"
        }
    }
}