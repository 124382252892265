const text = {
    countryAfterComponentsBeforeSelection: "Observe que sua escolha de país será usada como parte de seu endereço de entrega para enviar produtos diretamente para você. Se você não vir seu país na lista, o Gear não está disponível no momento em seu país.",
    countryAfterComponentsAllowedSelection: "Observe que sua escolha de país será usada como parte de seu endereço de entrega para enviar produtos diretamente para você.",
    countryAfterComponentsLimitedSelection: "Gear está atualmente disponível em seu país, mas está abrindo em etapas. A menos que você tenha recebido uma notificação, talvez não consiga acessar o Gear no momento em seu país.",
    disclaimer: "Ao clicar no próximo Botão abaixo, reconheço que sou funcionário da Empresa e autorizo que a Empresa e seu parceiro RemoteRetail armazenem e processem as informações pessoais enviadas neste site para me fornecer o conteúdo solicitado. A RemoteRetail precisa das informações de contato que forneço para que possam entrar em contato comigo com informações relacionadas aos meus pedidos. Fornecedores terceirizados também podem receber minhas informações para enviar produtos para mim.</p><p> Posso cancelar a assinatura dessas comunicações a qualquer momento. Para obter mais informações sobre como cancelar a inscrição, as práticas de privacidade e os compromissos de proteção e respeito à minha privacidade, consulte a <a href='{privacyPolicyUrl}' target='_blank'>Política de Privacidade</a> ."
};

export default {
    title: "Bem-vindo, {userName}!",
    description: {
        default: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`,
        virtualworker: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories that you need to work effectively from home. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`
    },
    text: {
        default: "Para começar, faremos uma série de perguntas.",
        virtualworker: "Para começar, faremos uma série de perguntas para nos ajudar a entender seu espaço de trabalho remoto para ajudar a moldar a direção futura deste programa."
    },
    afterComponents: {
        beforeSelection: `${text.countryAfterComponentsBeforeSelection}`,
        allowedSelection: `${text.countryAfterComponentsAllowedSelection}`,
        limitedSelection: `${text.countryAfterComponentsLimitedSelection}`,
        autoLoginBeforeSelection: `${text.countryAfterComponentsBeforeSelection}<br/><br/>${text.disclaimer}`,
        autoLoginAllowedSelection: `${text.countryAfterComponentsAllowedSelection}<br/><br/>${text.disclaimer}`,
        autoLoginLimitedSelection: `${text.countryAfterComponentsLimitedSelection}<br/><br/>${text.disclaimer}`
    },
    buttons: {
        back: "Voltar",
        next: "Próximo",
        submit: "Enviar"
    },
    entityinfo: {
        title: "Informações da Entidade",
        description: "Para nos ajudar a encaminhar corretamente seu pedido, forneça as seguintes informações:",
    },
    equipmentsurvey: {
        title: "Levantamento de Equipamentos",
        description: "A próxima página irá perguntar sobre o tipo de equipamento que você mais precisa para ajudar a Empresa a entender melhor as prioridades.",
        info: "Observe que o equipamento mostrado é apenas para fins de coleta de informações e não implica que a Empresa fornecerá este equipamento no futuro."
    },
    workplace: {
        title: "Conte-nos mais sobre o seu home office?",
        description: "Para nos ajudar a entender melhor seu espaço de trabalho, selecione uma das opções abaixo",
    },
    priority: {
        title: "Prioridades de equipamento",
        description: "Por favor, selecione suas duas opções principais de equipamentos que você mais precisa"
    },
    "additional-questions": {
        title: "Questões adicionais"
    },
    "equipment-assessment": {
        title: "Avaliação de Equipamentos",
        description: "O conjunto de perguntas a seguir <b>determinará qual equipamento estará disponível para você solicitar no Gear com base em suas respostas.</b>"
    },
    headset: {
        title: "Equipamento atual"
    },
    "office-worker": {
        title: "Escritório"
    },
    "keyboard-mouse": {
        title: "teclado e mouse"
    },
    webcam: {
        title: "Webcam"
    },
    monitor: {
        title: "Monitor"
    },
    usbhub: {
        title: "Hub USB"
    },
    "equipment-list": {
        title: "Equipamento",
        init: "initEquipmentList",
        description: {
            loading: "<span class=\"animate-spin icon-spinner\"></span>",
            error: "Pedido ruim. Um erro ocorreu.",
            allowed: "Com base em suas respostas, você poderá receber os seguintes itens:{items}",
            empty: "Com base em suas respostas, você não será elegível para nenhum item no Gear."
        },
        text: "<ul class=\"text-left\" style=\"width: 300px; margin: 2rem auto 0\">{list}</ul>",
        afterComponents: "<small>Se você precisar fazer alguma correção, clique no botão Voltar para alterar suas respostas.</small>"
    },
    saved: "Salvou"
}