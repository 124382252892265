<template>
    <form @submit.prevent="base_submit('model', 'submit', null)">

        <div class="modals-modal-body pt-4">
            <div class="row">
                <div class="col-12">
                    <form-textbox textbox-class="form-control form-control-sm"
                                  v-model="model.email"
                                  :validator="v$.model.email"
                                  :label="t('model.email.label')"
                                  :placeholder="t('model.email.placeholder')"
                                  :disabled="processing"/>

                    <form-date v-model="model.start_on"
                               :validator="v$.model.start_on"
                               :label="t('model.start_on.label')"
                               :placeholder="t('model.start_on.placeholder')"
                               :min-date="minStartDate"
                               :max-date="maxStartDate"
                               :milliseconds="true"
                               :disabled="processing"/>

                    <form-date v-model="model.end_on"
                               :validator="v$.model.end_on"
                               :label="t('model.end_on.label')"
                               :placeholder="t('model.end_on.placeholder')"
                               :min-date="minEndDate"
                               :milliseconds="true"
                               :disabled="processing"/>

                    <div class="form-group">
                        <div class="small pb-3">
                            {{t('model.accept.tip')}}
                        </div>
                        <form-checkbox v-model="model.accept"
                                       :disabled="processing"
                                       :label="t('model.accept.label')"
                                       :validator="v$.model.accept"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="modals-modal-footer">
            <button type="submit" class="btn btn-primary" :disabled="processing">
                <span v-if="processing" class="icon-spinner animate-spin"></span>
                {{t('buttons.submit.cta')}}
            </button>

            <button type="button" class="btn btn-primary" :disabled="processing" @click="$emit('close')">
                {{t('buttons.close.cta')}}
            </button>
        </div>
    </form>
</template>

<script>

    import {required, email} from "@vuelidate/validators"
    import useVuelidate from "@vuelidate/core";

    import mixin_form_payload from "@/mixins/form/payload";

    import FormTextbox from "@/components/form/FormTextbox";
    import FormCheckbox from "@/components/form/FormCheckbox";
    import FormDate from "@/components/form/FormDate";

    export default {
        name: "ModalApprovalDelegateEditor",
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        mixins: [mixin_form_payload],
        components: {
            FormDate,
            FormCheckbox,
            FormTextbox
        },
        props: {
            delegate: Object
        },
        data() {

            return {
                t_path: "modal.approvalDelegateEditor",

                processing: false,

                model: {
                    email: this.delegate ? this.delegate.delegate_email : null,
                    start_on: this.delegate ? this.delegate.start_on : null,
                    end_on: this.delegate ? this.delegate.end_on : null
                }
            }
        },
        computed: {
            mode() {

                return "undefined" === typeof this.delegate ? "add" : "edit";
            },
            minEndDate() {

                return this.model.start_on ? parseInt(this.model.start_on) + (3600 * 24 * 1000): null;
            },
            minStartDate() {

                return this.delegate && this.delegate.id && this.delegate.start_on
                    ? this.delegate.start_on
                    : new Date();
            },
            maxStartDate() {

                return this.model.end_on ? parseInt(this.model.end_on) - (3600 * 24 * 1000): null;
            }
        },
        validations() {

            return {
                model: {
                    email: {
                        required,
                        email
                    },
                    start_on: {
                        required,
                    },
                    end_on: {},
                    accept: {
                        required
                    }
                }
            }
        },
        methods: {
            submit() {

                this.processing = true;

                const delegate = {
                    delegate_email: this.model.email,
                    start_on: this.form_payload_getDateUtcMilliseconds(this.model.start_on),
                    end_on: this.form_payload_getDateUtcMilliseconds(this.model.end_on)
                };

                const request = "undefined" === typeof this.delegate ? {delegates: [delegate]} : {...delegate};

                return ("undefined" === typeof this.delegate
                    ? this.$order.post(`/users/${this.$store.getters["user/id"]}/delegates`, request)
                    : this.$order.put(`/users/${this.$store.getters["user/id"]}/delegates/${this.delegate.id}`, request))
                    .then(() => {
                        this.$toast.success(this.t("saved"));
                        this.$emit("save");
                    })
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing = false);
            }
        }
    }
</script>