export default {
    login: "Belépés",
    logout: "Kijelentkezés",
    home: "itthon",
    user: {
        profile: {
            edit: "Profil szerkesztése"
        }
    },
    approvals: {
        delegates: "Küldöttek"
    },
    impersonation: "Rendelés a nevében"
}