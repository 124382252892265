export default {
    menu: {
        approvals: "Schválení",
        delegates: "Delegáti"
    },
    approvals: {
        title: "Odsouhlasení",
        error: "Vyskytla se chyba",
        saved: "Schválení předloženo",
        noApprovals: "Nic ke schválení",
        review: "Pokud jsou nesprávné, zkontrolujte a upravte nákladové středisko",
        priorApprovals: "Předchozí schválení",
        noOrders: "Nic ke schválení",
        noPriorApprovals: "Žádná předchozí schválení",
        approved: "Schválený",
        notApproved: "Neschváleno",
        confirm: "Stále existují objednávky, které čekají na schválení. Přejete si pokračovat ve stávajících změnách?",
        manager: "Manažer",
        orders: {
            name: "název",
            date: "Datum objednávky",
            details: "Podrobnosti o objednávce",
            cost: "Náklady",
            costCenter: "Nákladové středisko",
            approval: "Odsouhlasení"
        },
        model: {
            reason: {
                placeholder: "Důvod"
            },
            approve: {
                yes: {
                    label: "Ano"
                },
                no: {
                    label: "Ne"
                }
            }
        },
        buttons: {
            submit: {
                text: "Předložit",
                processing: "zpracovává se"
            }
        }
    },
    delegates: {
        title: "Delegáti",
        error: "Vyskytla se chyba",
        noDelegates: "Žádně věci",
        delegateDelete: "Delegát byl smazán",
        confirm: {
            delete: "Smazat delegáta?"
        },
        delegates: {
            email: "E-mailem",
            startDate: "Datum zahájení",
            endDate: "Datum ukončení",
        },
        buttons: {
            add: {
                text: "Přidat delegáta"
            }
        }
    }
}