export default {
    country: {
        label: "Aufenthaltsland",
        errors: {
            required: "Eine Auswahl ist erforderlich"
        }
    },
    entity: {
        label: "Juristische Person",
        errors: {
            required: "Eine Auswahl ist erforderlich"
        }
    },
    location: {
        label: "Bürostandort",
        errors: {
            required: "Eine Auswahl ist erforderlich"
        }
    },
    workplace: {
        values: {
            "Dedicated Office": {
                title: "Eigenes Büro",
                description: "Home Office mit einem festen Standort als Büro"
            },
            "Semi-Dedicated": {
                title: "Semi-dediziert",
                description: "Temporärer Standort, um den Anforderungen einer Heimarbeitsumgebung gerecht zu werden"
            },
            "Floating": {
                title: "Schwimmend",
                description: "Kein dedizierter Raum für das Heimbüro, sondern wird mit anderen Orten wie einem Esszimmer oder Wohnzimmer geteilt"
            }
        },
        errors: {
            required: "Eine Auswahl ist erforderlich"
        }
    },
    priority: {
        label: "Höchste Priorität",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Docking Station": {
                title: "Dockingstation"
            },
            "Keyboard/Mouse": {
                title: "Tastatur maus"
            },
            "Web Cam": {
                title: "Webcam"
            },
            "Headset": {
                title: "Headset"
            },
            "Office Chair": {
                title: "Bürostuhl"
            },
            "Desk": {
                title: "Schreibtisch"
            },
            "Adapters": {
                title: "Adapter"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Unterbrechungsfreie Stromversorgung (USV)"
            },
            "Something Else": {
                title: "Etwas anderes"
            },
            "Nothing Needed": {
                title: "Nichts benötigt"
            }
        },
        errors: {
            requiredUnless: "Eine Auswahl ist erforderlich",
            requiredIf: "Eine Auswahl ist erforderlich",
            required: "Eine Auswahl ist erforderlich"
        }
    },
    priorityDetails: {
        placeholder: "Stellen Sie Details bereit"
    },
    secondaryPriority: {
        label: "Zweithöchste Priorität",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Second Monitor": {
                title: "Zweiter Monitor"
            },
            "Docking Station": {
                title: "Dockingstation"
            },
            "Keyboard/Mouse": {
                title: "Tastatur maus"
            },
            "Web Cam": {
                title: "Webcam"
            },
            "Headset": {
                title: "Headset"
            },
            "Office Chair": {
                title: "Bürostuhl"
            },
            "Desk": {
                title: "Schreibtisch"
            },
            "Adapters": {
                title: "Adapter"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Unterbrechungsfreie Stromversorgung (USV)"
            },
            "Something Else": {
                title: "Etwas anderes"
            },
            "Nothing Needed": {
                title: "Nichts benötigt"
            }
        },
        errors: {
            requiredUnless: "Eine Auswahl ist erforderlich",
            requiredIf: "Eine Auswahl ist erforderlich",
            required: "Eine Auswahl ist erforderlich"
        }
    },
    secondaryPriorityDetails: {
        placeholder: "Stellen Sie Details bereit"
    },
    improveWorkspace: {
        label: "Wie würden Sie Ihren Arbeitsplatz verbessern?",
        placeholder: "Optional",
    },
    improveRemoteWorking: {
        label: "Abgesehen von der Ausrüstung, was würde Ihnen helfen, bei der Fernarbeit effektiver zu sein?",
        placeholder: "Optional",
    },
    receivedEquipmentHeadset: {
        label: "Haben Sie innerhalb der letzten 24 Monate ein drahtloses Headset oder eine Freisprecheinrichtung vom Unternehmen erhalten?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "NEIN"
            }
        }
    },
    issuesHeadset: {
        label: "Hast du Probleme damit?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "NEIN"
            }
        }
    },
    issuesDescriptionHeadset: {
        placeholder: "Beschreiben Sie Ihre Probleme"
    },
    isOfficeWorker: {
        label: "Arbeiten Sie Vollzeit vom Büro aus?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "NEIN"
            }
        }
    },
    receivedKeyboardMouse: {
        label: "Haben Sie in den letzten 12 Monaten eine Tastatur und Maus vom Unternehmen erhalten?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "NEIN"
            }
        }
    },
    issuesKeyboardMouse: {
        label: "Hast du Probleme damit?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "NEIN"
            }
        }
    },
    issuesDescriptionKeyboardMouse: {
        placeholder: "Beschreiben Sie Ihre Probleme"
    },
    needWebcam: {
        label: "Benötigen Sie eine externe Webcam für die Arbeit (nicht die in Ihrem Laptop?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "NEIN"
            }
        }
    },
    needWebcamExternal: {
        label: "Benötigen Sie eine externe USB-Webcam (nicht die in Ihrem Laptop eingebaute)?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "NEIN"
            }
        }
    },
    needMonitor: {
        label: "Benötigen Sie einen Monitor für die Arbeit?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "NEIN"
            }
        }
    },
    receivedMonitor: {
        label: "Haben Sie in den letzten 4 Jahren einen Monitor vom Unternehmen erhalten?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "NEIN"
            }
        }
    },
    issuesMonitor: {
        label: "Hast du Probleme damit?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "NEIN"
            }
        }
    },
    issuesDescriptionMonitor: {
        placeholder: "Beschreiben Sie Ihre Probleme"
    },
    needUsbhub: {
        label: "Benötigen Sie einen externen USB-Hub für die Arbeit?",
        values: {
            Yes: {
                name: "Ja"
            },
            No: {
                name: "NEIN"
            }
        }
    },
    issuesDescriptionUsbhub: {
        placeholder: "Beschreiben Sie Ihre Probleme"
    }
}