<template>
    <div class="modals-modal-body text-center">
        <p v-if="message" class="pt-3">{{message}}</p>
        <p>{{t('message')}}</p>
        <p>
            <button type="button" class="btn btn-link p-0" @click="undo" :disabled="processing">
                <spinner v-if="processing"/>
                {{t('undo')}}
            </button>
        </p>
    </div>
</template>

<script>

    import Spinner from "@/components/Spinner";

    export default {
        name: "ModalComponentsImpersonationOrdersActionUndo",
        components: {
            Spinner
        },
        props: {
            message: String,
            orderProductId: {
                type: String,
                required: true
            },
            userId: {
                type: Number,
                required: true
            }
        },
        data() {

            return {
                t_path: "modal.components.impersonation.orders.actionUndo",
                processing: false
            }
        },
        methods: {
            undo() {

                this.processing = true;

                return this.$store
                    .dispatch("user/impersonation/orders/undo", {
                        orderItemId: this.orderProductId,
                        userId: this.userId
                    })
                    .then(() => this.$emit("save"))
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing = false);
            }
        }
    }
</script>