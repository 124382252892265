export default {
    400: {
        title: "Špatný požadavek",
        header: "@:views.errors.400.title",
        html: "Špatný požadavek. Prosím zkuste to znovu později."
    },
    401: {
        title: "Při přihlašování došlo k chybě",
        header: "@:views.errors.401.title",
        html: "Kontaktujte prosím <a href='{email}' target='_blank'>podporu</a> ."
    },
    404: {
        title: "Stránka nenalezena",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Přístup není povolen",
        header: "Přístup není povolen",

        contact: "O-I Periferní zařízení je dostupné pouze zaměstnancům. Pokud jste zaměstnanec a vidíte tuto zprávu, ve spolupráci s místními lidmi a kulturou aktualizujte svůj stav.",

        subject: "Zařízení – způsobilost",

        html: "<p class='text-left'>@:views.errors.403.eligibility @:views.errors.403.contact</p>",
        error: {
            disabled: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Momentálně nemáte nárok. Další informace získáte od <a href='#' role='button' data-widget-trigger='{widgetOptions}'>podpory</a> .</p>"
            },
            "not-active": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Momentálně nemáte nárok. Pro více informací pracujte s místními lidmi a kulturou.</p>"
            },
            "not-allowed":{
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I Periferní zařízení je dostupné pouze zaměstnancům. Pokud jste zaměstnanec a vidíte tuto zprávu, ve spolupráci s místními lidmi a kulturou aktualizujte svůj stav.</p>"
            },
            "no-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Ve vašem profilu ServiceNow nemáte aktivního správce. Aktualizujte svého manažera ve spolupráci se svým místním zástupcem People &amp; Culture.</p>"
            },
            "self-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Váš manažer je nastaven jako vy ve vašem profilu ServiceNow. Aktualizujte svého manažera ve spolupráci se svým místním zástupcem People &amp; Culture.</p>"
            },
            "no-location": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Ve svém profilu ServiceNow nemáte uvedenou polohu. Aktualizujte svou polohu ve spolupráci se svým místním zástupcem People &amp; Culture.</p>"
            },
            eligibility: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I Periferní zařízení je dostupné pouze zaměstnancům. Pokud jste zaměstnanec a vidíte tuto zprávu, ve spolupráci s místními lidmi a kulturou aktualizujte svůj stav.</p>",
            },
            "eligibility-location": {
                header: "Vypadá to, že jsi trochu brzy...",
                html: "<p class='text-left'>Děkujeme za váš zájem o O-I Obvodový. Zdá se, že jste se na web dostali dříve, než jste byli přímo povoleni a pozváni. O-I Periferní zařízení je zaváděno postupně v jednotlivých zemích a podle skupin uživatelů. Po aktivaci obdržíte e-mail obsahující osobní pozvánku s pokyny.</p><p class='text-left'> Kliknutím na <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Podpora</a> zobrazíte dotazy nebo problémy s objednávkou nebo webem.</p>",
            },
            "secondary-account": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Právě se pokoušíte získat přístup O-I Periferní zařízení na sekundárním účtu. Zkuste se prosím znovu přihlásit pomocí svého primárního účtu.</p>"
            },
            "empty-core-id": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Váš účet ServiceNow nemá název účtu. Aktualizujte svůj účet ve spolupráci se svým místním zástupcem People &amp; Culture.</p>"
            },
            country: {
                header: "@:views.errors.403.header",
                html: "@:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "no-permissions": {
                header: "Žádný přístup",
                subject: "Žádný přístup",
                html: "K otevření této stránky nemáte oprávnění.",
            },
            "virtual-denied": {
                header: "Vypadá to, že jsi trochu brzy...",
                html: "<p class='text-left'>Děkujeme za váš zájem o O-I Obvodový. Zdá se, že jste se na web dostali dříve, než jste byli přímo povoleni a pozváni. O-I Periferní zařízení je zaváděno postupně v jednotlivých zemích a podle skupin uživatelů. Po aktivaci obdržíte e-mail obsahující osobní pozvánku s pokyny.</p><p class='text-left'> Kliknutím na <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Podpora</a> zobrazíte dotazy nebo problémy s objednávkou nebo webem.</p>",
            }
        }
    },
    500: {
        title: "Neočekávaná chyba",
        header: "@:views.errors.500.title",
        message: "Vyskytla se chyba. Prosím zkuste to znovu později."
    },
    code: {
        title: "Neočekávaná chyba",
        message: "@:views.errors.code.title",
        error: "Chyba {code}."
    }
}