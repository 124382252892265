export default {
    error: "Beim Abrufen von Bestellungen ist ein Problem aufgetreten. Bitte aktualisiere deinen Browser.",
    meta: {
        default: "Aufträge",
        my_orders: "Meine Bestellungen",
        my_gear: "Erhalten"
    },
    loadMore: "Mehr laden",
    noOrders: "Keine Bestellungen",
    order: {
        product: {
            title: "Befehl",
            tracking: "Verfolgung",
            quantity: "Menge",
            control: {
                cancel: {
                    cta: "Klicken Sie hier, um abzubrechen",
                    tooltip: "Klicken Sie, um diesen Artikel zu stornieren",
                    message: "Wir haben Ihre Bestellung storniert."
                },
                cancelRequest: {
                    cta: "Bitte um Stornierung",
                    tooltip: "Klicken Sie hier, um eine Stornierungsanfrage einzureichen"
                },
                received: {
                    cta: "Als erhalten markieren",
                    tooltip: "Klicken Sie hier, um die Lieferung zu bestätigen",
                    message: "Vielen Dank für die Bestätigung, dass Sie Ihr Produkt erhalten haben."
                },
                notReceived: {
                    cta: "Als nicht erhalten markieren",
                    tooltip: "Klicken Sie hier, um die Nichtlieferung zu bestätigen"
                },
                damaged: {
                    cta: "Als beschädigt/kaputt markieren",
                    tooltip: "Klicken Sie hier, um ein Supportticket zu erstellen"
                },
                hide: {
                    cta: "Artikel ausblenden",
                    tooltip: "Klicken Sie, um das Element aus der Liste auszublenden"
                }
            },
            requestToCancelInfo: "Wir werden den Lieferanten kontaktieren, um eine Stornierung zu beantragen. Bitte beachten Sie, dass dies nur eine Anfrage ist und eine Stornierung möglicherweise nicht möglich ist. Sobald wir feststellen können, ob eine Stornierung möglich ist oder nicht, werden wir Sie darüber informieren.",
            cancelOk: "OK",
            reason: "Grund",
            orderedBy: "Bestellt durch",
            model: {
                cancel: {
                    reason: {
                        label: "Gründe für die Stornierung",
                        placeholder: "Gründe für die Stornierung"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Einreichen",
                        processing: "Einreichen"
                    },
                    cancel: {
                        cta: "Stornieren"
                    }
                }
            }
        }
    },
    help: {
        text: "<p>Haben Sie Fragen zu Ihrer Bestellung?<br/> Hilfe finden Sie in unserem <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>Support-Center</b></a></p><p> Benötigen Sie ein Produkt, das nicht aufgeführt ist?<br/> Klicken Sie <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>hier,</b></a> um einen Vorschlag zu senden</p>",
        email: {
            subject: ""
        }
    }
}