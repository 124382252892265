import {DateTime} from "luxon";

export default {
    methods: {
        form_dynamic_getDatepickerMinDate(field) {

            if ("undefined" !== typeof field.min) {

                if ("now" === field.min)  {

                    return DateTime.local().startOf("day").ts;
                }

                if ("object" === typeof field.min) {

                    const now = DateTime.local().startOf("day").plus({day: 2});

                    if ("undefined" !== typeof field.min.days) {

                        return now.plus({day: field.min.days}).ts;
                    }

                    if ("undefined" !== typeof field.min.business_days) {

                        return Array
                            .from(Array(field.min.business_days).keys())
                            .reduce(
                                (luxonDateTime) => {

                                    do {

                                        luxonDateTime = luxonDateTime.plus({day: 1});

                                    } while (luxonDateTime.weekday > 5);

                                    return luxonDateTime;
                                },
                                now.minus({day: 1}) // remove one day because days shift ususally counts with including today
                            )
                            .ts;
                    }
                }
            }

            return null;
        },
        form_dynamic_getDatepickerMaxDate(field) {

            if ("undefined" !== typeof field.max) {

                if ("now" === field.max)  {

                    return DateTime.local().startOf("day").ts;
                }

                if ("object" === typeof field.max) {

                    const now = DateTime.local().startOf("day").plus({day: 1});

                    if ("undefined" !== typeof field.max.days) {

                        return now.plus({day: field.max.days}).ts;
                    }

                    if ("undefined" !== typeof field.max.business_days) {

                        return Array
                            .from(Array(field.max.business_days).keys())
                            .reduce(
                                (luxonDateTime) => {

                                    do {

                                        luxonDateTime = luxonDateTime.plus({day: 1});

                                    } while (luxonDateTime.weekday > 5);

                                    return luxonDateTime;
                                },
                                now.minus({day: 1}) // remove one day because days shift ususally counts with including today
                            )
                            .ts;
                    }
                }
            }

            return null;
        },
        form_dynamic_getDatepickerDisableDates(field) {

            if ("object" === typeof field.max) {

                if ("undefined" !== typeof field.max.business_days) {

                    return [date => 0 === date.getDay() || 6 === date.getDay()];
                }
            }

            return [];
        }
    }
}