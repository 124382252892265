<template>
    <div>
        <div class="modals-modal-body pt-4">

            <div class="row">
                <div class="col-md-10 offset-md-1">

                    <div class="text-center font-weight-bold pb-3">
                        {{t('message')}}
                    </div>

                    <!-- Product items -->
                    <div class="row pt-3" v-for="product in products" :key="product.sku">
                        <div class="col-4">
                            <products-product-thumbnails v-if="0 < product.pictures.length" size="small"
                                                         :value="product.pictures"/>
                        </div>
                        <div class="col-8">
                            {{product.name}}
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="modals-modal-footer">
            <button type="button" class="btn btn-primary" @click="$emit('save')">
                {{t('buttons.ok')}}
            </button>
        </div>
    </div>
</template>

<script>

    import mixin_products from "@/mixins/products";

    import ProductsProductThumbnails from "@/components/products/product/ProductsProductThumbnails";

    export default {
        name: "ModalOrderOutOfStock",
        mixins: [mixin_products],
        components: {
            ProductsProductThumbnails
        },
        props: {
            items: {
                required: true,
                type: Array
            }
        },
        data() {

            return {
                t_path: "modal.orderOutOfStock"
            }
        },
        computed: {
            products() {

                return this.items.map(item => ({
                    ...item,
                    name: this.products_translateProductName(item)
                }));
            }
        }
    }
</script>