import page from "./page";
import list from "./list";
import grouping from "./grouping";
import cart from "./cart";
import limit from "./limit";

export default {
    page,
    list,
    grouping,
    cart,
    limit
}