export default {
    title: "Ordine per conto di",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Compila l'e-mail dell'utente per effettuare l'ordine per conto di",
            errors: {
                myself: "L'e-mail deve essere diversa dalla tua"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Invia",
            processing: "Invio"
        }
    },
    error: {
        userNotExists: "{email} non esiste"
    }
}