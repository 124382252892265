export default {
    message: {
        //     constraints  ordered
        default_everything_everything: "<p>A mai napig mindent megrendelt, ami elérhető a Gearben.</p><p> Ha konkrét kérdése van ezzel kapcsolatban, kérjük, kattintson a fenti <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a> linkre, és küldje el kérdését.</p>",
        default_everything_something: "<p>A korábban megrendelt termékek már nem jelennek meg.</p><p> Ha konkrét kérdése van ezzel kapcsolatban, kérjük, kattintson a fenti <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a> linkre, és küldje el kérdését.</p>",
        default_partial_everything: "<p>Nincsenek további megrendelhető termékek.</p><p> Változott a helyzete ahhoz képest, amikor először válaszolt a felszerelési felmérésére? Kattintson <a href='{equipmentQuizLink}'><b>ide</b></a> , ha meg szeretné tekinteni a berendezés felmérésére adott válaszait.</p>",
        default_partial_something: "<p>A korábban megrendelt termékek már nem jelennek meg.</p><p> Változott a helyzete ahhoz képest, amikor először válaszolt a felszerelési felmérésére? Kattintson <a href='{equipmentQuizLink}'><b>ide</b></a> , ha meg szeretné tekinteni a berendezés felmérésére adott válaszait.</p>",
        default_partial_noitems: "<p>Változott a helyzete ahhoz képest, amikor először válaszolt a felszerelési felmérésére? Kattintson <a href='{equipmentQuizLink}'><b>ide</b></a> , ha meg szeretné tekinteni a berendezés felmérésére adott válaszait.</p>",

        ehs_everything_everything: "<p>A mai napig mindent megrendelt, ami elérhető a Gearben.</p><p> A termékek elérhetősége az EHS felmérésen alapul. Ha további támogatásra van szüksége, forduljon a következőhöz <a href='mailto:{supportEmail}'>: {supportEmail}</a> .</p>",
        ehs_everything_something: "<p>A korábban megrendelt termékek már nem jelennek meg.</p><p> A termékek elérhetősége az EHS felmérésen alapul. Ha további támogatásra van szüksége, forduljon a következőhöz <a href='mailto:{supportEmail}'>: {supportEmail}</a> .</p>",
        ehs_partial_everything: "<p>A mai napig mindent megrendelt, ami elérhető a Gearben.</p><p> A termékek elérhetősége az EHS felmérésen alapul. Ha további támogatásra van szüksége, forduljon a következőhöz <a href='mailto:{supportEmail}'>: {supportEmail}</a> .</p>",
        ehs_partial_something: "<p>A korábban megrendelt termékek már nem jelennek meg.</p><p> A termékek elérhetősége az EHS felmérésen alapul. Ha további támogatásra van szüksége, forduljon a következőhöz <a href='mailto:{supportEmail}'>: {supportEmail}</a> .</p>",
        ehs_partial_noitems: "<p>A termékek elérhetősége az EHS felmérésen alapul. Ha további támogatásra van szüksége, forduljon a következőhöz <a href='mailto:{supportEmail}'>: {supportEmail}</a> .</p>",
    }
}