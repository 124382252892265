export default {
    menu: {
        approvals: "Approbations",
        delegates: "Délégués"
    },
    approvals: {
        title: "Approbation",
        error: "Une erreur s'est produite",
        saved: "Approbations soumises",
        noApprovals: "Rien à approuver",
        review: "Veuillez vérifier et modifier le centre de coûts s'il est incorrect",
        priorApprovals: "Approbations préalables",
        noOrders: "Rien à approuver",
        noPriorApprovals: "Aucune approbation préalable",
        approved: "Approuvé",
        notApproved: "Non approuvé",
        confirm: "Il y a encore des commandes avec des approbations en attente. Souhaitez-vous continuer avec vos modifications actuelles ?",
        manager: "Directeur",
        orders: {
            name: "Nom",
            date: "Date de commande",
            details: "détails de la commande",
            cost: "Coût",
            costCenter: "Centre de coûts",
            approval: "Approbation"
        },
        model: {
            reason: {
                placeholder: "Raison"
            },
            approve: {
                yes: {
                    label: "Oui"
                },
                no: {
                    label: "Non"
                }
            }
        },
        buttons: {
            submit: {
                text: "Soumettre",
                processing: "Traitement"
            }
        }
    },
    delegates: {
        title: "Délégués",
        error: "Une erreur s'est produite",
        noDelegates: "Pas d'objet",
        delegateDelete: "Le délégué a été supprimé",
        confirm: {
            delete: "Supprimer le délégué ?"
        },
        delegates: {
            email: "E-mail",
            startDate: "Date de début",
            endDate: "Date de fin",
        },
        buttons: {
            add: {
                text: "Ajouter un délégué"
            }
        }
    }
}