export default {
    title: "代订",
    model: {
        email: {
            label: "电子邮件",
            placeholder: "请填写用户邮箱进行代购",
            errors: {
                myself: "电子邮件必须与您的不同"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "提交",
            processing: "正在提交"
        }
    },
    error: {
        userNotExists: "{email}不存在"
    }
}