const text = {
    countryAfterComponentsBeforeSelection: "Všimněte si, že vaše volba země bude použita jako součást vaší doručovací adresy pro zasílání produktů přímo vám. Pokud svou zemi v seznamu nevidíte, zařízení Gear momentálně není ve vaší zemi dostupné.",
    countryAfterComponentsAllowedSelection: "Všimněte si, že vaše volba země bude použita jako součást vaší doručovací adresy pro zasílání produktů přímo vám.",
    countryAfterComponentsLimitedSelection: "Gear je aktuálně k dispozici ve vaší zemi, ale otevírá se postupně. Dokud neobdržíte upozornění, je možné, že v tuto chvíli nebudete mít ve své zemi přístup k zařízení Gear.",
    disclaimer: "Kliknutím na další tlačítko níže potvrzuji, že jsem zaměstnancem společnosti a souhlasím s tím, aby společnost a její partner RemoteRetail ukládali a zpracovávali osobní údaje odeslané na této stránce za účelem poskytnutí požadovaného obsahu. RemoteRetail potřebuje kontaktní údaje, které poskytnu, aby mě mohl kontaktovat s informacemi souvisejícími s mými objednávkami. Dodavatelé třetích stran mohou také obdržet mé informace, aby mi mohli zaslat produkty.</p><p> Z odběru těchto sdělení se mohu kdykoli odhlásit. Další informace o tom, jak se mohu odhlásit, o způsobech ochrany osobních údajů a závazcích k ochraně a respektování mého soukromí, naleznete v <a href='{privacyPolicyUrl}' target='_blank'>Zásadách ochrany osobních údajů</a> ."
};

export default {
    title: "Vítejte, {userName}!",
    description: {
        default: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`,
        virtualworker: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories that you need to work effectively from home. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`
    },
    text: {
        default: "Pro začátek vám položíme řadu otázek.",
        virtualworker: "Nejprve vám položíme řadu otázek, které nám pomohou porozumět vašemu pracovnímu prostoru práce z domova, abychom pomohli utvářet budoucí směřování tohoto programu."
    },
    afterComponents: {
        beforeSelection: `${text.countryAfterComponentsBeforeSelection}`,
        allowedSelection: `${text.countryAfterComponentsAllowedSelection}`,
        limitedSelection: `${text.countryAfterComponentsLimitedSelection}`,
        autoLoginBeforeSelection: `${text.countryAfterComponentsBeforeSelection}<br/><br/>${text.disclaimer}`,
        autoLoginAllowedSelection: `${text.countryAfterComponentsAllowedSelection}<br/><br/>${text.disclaimer}`,
        autoLoginLimitedSelection: `${text.countryAfterComponentsLimitedSelection}<br/><br/>${text.disclaimer}`
    },
    buttons: {
        back: "Zadní",
        next: "další",
        submit: "Předložit"
    },
    entityinfo: {
        title: "Informace o entitě",
        description: "Chcete-li nám pomoci správně směrovat vaši objednávku, poskytněte nám prosím následující informace:",
    },
    equipmentsurvey: {
        title: "Průzkum vybavení",
        description: "Na další stránce se vás zeptá na typ vybavení, které nejvíce potřebujete, abyste společnosti pomohli lépe porozumět prioritám.",
        info: "Vezměte prosím na vědomí, že zobrazené vybavení slouží pouze pro účely shromažďování informací a neznamená, že společnost bude toto vybavení poskytovat v budoucnu."
    },
    workplace: {
        title: "Řekněte nám více o své domácí kanceláři?",
        description: "Chcete-li nám pomoci lépe porozumět vašemu pracovnímu prostoru, vyberte jednu z níže uvedených možností",
    },
    priority: {
        title: "Priority vybavení",
        description: "Vyberte prosím své dvě nejlepší možnosti vybavení, které nejvíce potřebujete"
    },
    "additional-questions": {
        title: "Další otázky"
    },
    "equipment-assessment": {
        title: "Posouzení vybavení",
        description: "Následující sada otázek <b>určí, jaké vybavení si budete moci objednat z Gear na základě vašich odpovědí.</b>"
    },
    headset: {
        title: "Současné vybavení"
    },
    "office-worker": {
        title: "Kancelář"
    },
    "keyboard-mouse": {
        title: "Klávesnice a myš"
    },
    webcam: {
        title: "Webová kamera"
    },
    monitor: {
        title: "Monitor"
    },
    usbhub: {
        title: "USB Hub"
    },
    "equipment-list": {
        title: "Zařízení",
        init: "initEquipmentList",
        description: {
            loading: "<span class=\"animate-spin icon-spinner\"></span>",
            error: "Špatný požadavek. Vyskytla se chyba.",
            allowed: "Na základě vašich odpovědí budete mít nárok na následující položky:{items}",
            empty: "Na základě vašich odpovědí aktuálně nebudete mít nárok na žádné položky v Gear."
        },
        text: "<ul class=\"text-left\" style=\"width: 300px; margin: 2rem auto 0\">{list}</ul>",
        afterComponents: "<small>Pokud potřebujete provést nějaké opravy, stiskněte tlačítko Zpět a změňte své odpovědi.</small>"
    },
    saved: "Uložené"
}