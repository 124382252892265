<template>
    <div class="d-flex">
        <!-- Product image -->
        <img class="image" :src="product.item.pictures[0].medium" :alt="name">

        <div class="flex-grow-1 pl-3">

            <!-- Product name -->
            <div class="name">{{name}}</div>

            <!-- Product delivery date -->
            <div v-if="estimatedDeliveryDate" v-html="estimatedDeliveryDate" class="pt-2 font-italic small"></div>

            <!-- Product note -->
            <div v-if="note" class="font-italic small">{{note}}</div>

            <!-- Product value -->

            <form-textbox-group v-if="1 < model.maxValue.$params.max"
                                class="quantity-control"
                                textbox-class="form-control form-control-sm"
                                :model-value="modelValue"
                                :validator="model"
                                :placeholder="t('model.product.placeholder')"
                                :errors="errors"
                                :disabled="disabled"
                                @input="$emit('update:modelValue', $event.target.value)">
                <template #before>
                    <button type="button" class="btn btn-sm btn-outline-secondary"
                            :disabled="disableDecrease"
                            @click="decrease">
                        -
                    </button>
                </template>
                <template #after>
                    <button type="button" class="btn btn-sm btn-outline-secondary"
                            :disabled="disableIncrease"
                            @click="increase">
                        +
                    </button>
                </template>
            </form-textbox-group>

            <button type="button" class="btn btn-link btn-remove"
                    :disabled="disabled"
                    :title="t('button.remove')"
                    @click="cart_remove(product.item)">
                <small>{{t('button.remove')}}</small>
            </button>
        </div>
    </div>
</template>

<script>

    import mixin_cart from "@/mixins/cart";
    import mixin_constraints from "@/mixins/constraints";
    import mixin_products from "@/mixins/products";

    import FormTextbox from "@/components/form/FormTextbox";
    import FormTextboxGroup from "@/components/form/FormTextboxGroup";

    export default {
        mixins: [mixin_cart, mixin_constraints, mixin_products],
        emits: ["update:modelValue"],
        name: "ViewsOrderSubmitProduct",
        components: {
            FormTextboxGroup,
            FormTextbox
        },
        props: {
            modelValue: {},
            product: {
                type: Object,
                required: true
            },
            model: {
                type: Object,
                required: true
            },
            disabled: Boolean
        },
        data() {

            return {
                t_path: "views.order.submit",
            }
        },
        computed: {
            name() {

                return this.products_translateProductName(this.product.item);
            },
            estimatedDeliveryDate() {

                return this.products_translateProductEstimatedShipDate(this.product.item);
            },
            image() {

                return this.product.item.pictures[0].medium;
            },
            note() {

                return this.products_translateProductNote(this.product.item);
            },
            errors() {

                return {
                    maxValue: this.$t("errors.maxValue", {
                        maxValue: this.constraints_getProductAvailableAmount(this.product.item)
                    })
                }
            },
            disableIncrease() {

                return this.modelValue >= this.constraints_getProductAvailableAmount(this.product.item);
            },
            disableDecrease() {

                return this.modelValue <= 1;
            },
        },
        methods: {
            increase() {

                this.$emit('update:modelValue', parseInt(this.modelValue) + 1);
            },
            decrease() {

                this.$emit('update:modelValue', parseInt(this.modelValue) - 1);
            }
        }
    }
</script>