export default {
    country: {
        label: "Paese di residenza",
        errors: {
            required: "È necessaria una selezione"
        }
    },
    entity: {
        label: "Entità",
        errors: {
            required: "È necessaria una selezione"
        }
    },
    location: {
        label: "Posizione dell'ufficio",
        errors: {
            required: "È necessaria una selezione"
        }
    },
    workplace: {
        values: {
            "Dedicated Office": {
                title: "Ufficio dedicato",
                description: "Home office con una sede permanente dedicata ad ufficio"
            },
            "Semi-Dedicated": {
                title: "Semi-dedicato",
                description: "Posizione temporanea per soddisfare le esigenze di un ambiente di lavoro domestico"
            },
            "Floating": {
                title: "Galleggiante",
                description: "Nessuno spazio dedicato per l'home office ma è condiviso con altri luoghi come una sala da pranzo o un soggiorno"
            }
        },
        errors: {
            required: "È necessaria una selezione"
        }
    },
    priority: {
        label: "Massima priorità",
        values: {
            "Monitor": {
                title: "Tenere sotto controllo"
            },
            "Docking Station": {
                title: "Stazione di attracco"
            },
            "Keyboard/Mouse": {
                title: "Tastiera/mouse"
            },
            "Web Cam": {
                title: "Webcam"
            },
            "Headset": {
                title: "Auricolare"
            },
            "Office Chair": {
                title: "Sedia da ufficio"
            },
            "Desk": {
                title: "Scrivania"
            },
            "Adapters": {
                title: "Adattatori"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Gruppo di continuità (UPS)"
            },
            "Something Else": {
                title: "Qualcos'altro"
            },
            "Nothing Needed": {
                title: "Niente di necessario"
            }
        },
        errors: {
            requiredUnless: "È necessaria una selezione",
            requiredIf: "È necessaria una selezione",
            required: "È necessaria una selezione"
        }
    },
    priorityDetails: {
        placeholder: "Fornire dettagli"
    },
    secondaryPriority: {
        label: "Seconda massima priorità",
        values: {
            "Monitor": {
                title: "Tenere sotto controllo"
            },
            "Second Monitor": {
                title: "Secondo Monitor"
            },
            "Docking Station": {
                title: "Stazione di attracco"
            },
            "Keyboard/Mouse": {
                title: "Tastiera/mouse"
            },
            "Web Cam": {
                title: "Webcam"
            },
            "Headset": {
                title: "Auricolare"
            },
            "Office Chair": {
                title: "Sedia da ufficio"
            },
            "Desk": {
                title: "Scrivania"
            },
            "Adapters": {
                title: "Adattatori"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Gruppo di continuità (UPS)"
            },
            "Something Else": {
                title: "Qualcos'altro"
            },
            "Nothing Needed": {
                title: "Niente di necessario"
            }
        },
        errors: {
            requiredUnless: "È necessaria una selezione",
            requiredIf: "È necessaria una selezione",
            required: "È necessaria una selezione"
        }
    },
    secondaryPriorityDetails: {
        placeholder: "Fornire dettagli"
    },
    improveWorkspace: {
        label: "Come miglioreresti il tuo spazio di lavoro?",
        placeholder: "Opzionale",
    },
    improveRemoteWorking: {
        label: "Al di fuori delle attrezzature, cosa ti aiuterebbe a essere più efficace nel lavoro a distanza?",
        placeholder: "Opzionale",
    },
    receivedEquipmentHeadset: {
        label: "Hai ricevuto un auricolare wireless o un vivavoce dalla Società negli ultimi 24 mesi?",
        values: {
            Yes: {
                name: "SÌ"
            },
            No: {
                name: "NO"
            }
        }
    },
    issuesHeadset: {
        label: "Hai problemi con esso?",
        values: {
            Yes: {
                name: "SÌ"
            },
            No: {
                name: "NO"
            }
        }
    },
    issuesDescriptionHeadset: {
        placeholder: "Descrivi i tuoi problemi"
    },
    isOfficeWorker: {
        label: "Lavori dall'ufficio a tempo pieno?",
        values: {
            Yes: {
                name: "SÌ"
            },
            No: {
                name: "NO"
            }
        }
    },
    receivedKeyboardMouse: {
        label: "Hai ricevuto una tastiera e un mouse dalla Società negli ultimi 12 mesi?",
        values: {
            Yes: {
                name: "SÌ"
            },
            No: {
                name: "NO"
            }
        }
    },
    issuesKeyboardMouse: {
        label: "Hai problemi con esso?",
        values: {
            Yes: {
                name: "SÌ"
            },
            No: {
                name: "NO"
            }
        }
    },
    issuesDescriptionKeyboardMouse: {
        placeholder: "Descrivi i tuoi problemi"
    },
    needWebcam: {
        label: "Hai bisogno di una webcam esterna per lavorare (non quella integrata nel tuo laptop?",
        values: {
            Yes: {
                name: "SÌ"
            },
            No: {
                name: "NO"
            }
        }
    },
    needWebcamExternal: {
        label: "Hai bisogno di una webcam USB esterna (non quella integrata nel tuo laptop)?",
        values: {
            Yes: {
                name: "SÌ"
            },
            No: {
                name: "NO"
            }
        }
    },
    needMonitor: {
        label: "Hai bisogno di un monitor per il lavoro?",
        values: {
            Yes: {
                name: "SÌ"
            },
            No: {
                name: "NO"
            }
        }
    },
    receivedMonitor: {
        label: "Hai ricevuto un monitor dalla Società negli ultimi 4 anni?",
        values: {
            Yes: {
                name: "SÌ"
            },
            No: {
                name: "NO"
            }
        }
    },
    issuesMonitor: {
        label: "Hai problemi con esso?",
        values: {
            Yes: {
                name: "SÌ"
            },
            No: {
                name: "NO"
            }
        }
    },
    issuesDescriptionMonitor: {
        placeholder: "Descrivi i tuoi problemi"
    },
    needUsbhub: {
        label: "Hai bisogno di un hub USB esterno per il lavoro?",
        values: {
            Yes: {
                name: "SÌ"
            },
            No: {
                name: "NO"
            }
        }
    },
    issuesDescriptionUsbhub: {
        placeholder: "Descrivi i tuoi problemi"
    }
}