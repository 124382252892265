<template>
    <page-message>
        <template #header>{{t('header')}}</template>
        <template #html>
            <div v-html="message"></div>
        </template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
</template>

<script>

    import mixin_view from "@/mixins/view";

    import PageMessage from "@/components/page/PageMessage";

    export default {
        name: "ViewsErrors400",
        mixins: [mixin_view],
        components: {
            PageMessage
        },
        data() {

            return {
                t_path: "views.errors.400",
                email: process.env.VUE_APP_SUPPORT_EMAIL,
                message: null
            }
        },
        computed: {
            error() {

                return this.$route.params.error;
            },
            subject() {

                const path = this.error ? `views.errors.400.error.${this.error}.subject` : `views.errors.400.subject`;
                const translation = this.$t(path);

                if (translation != path) {

                    return translation;
                }

                return "";
            },
        },
        methods: {
            fetch() {

                const error = this.error;
                const email = `mailto:${this.email}`;
                const subject = this.subject;

                this.message = error
                    ? this.t(`error.${error}.html`, {email, subject})
                    : this.t('html', {email, subject});
            }
        },
        mounted() {

            this.fetch();
        }
    }
</script>