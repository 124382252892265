export default {
    title: "Révision de la commande",
    header: "@:views.order.submitted.title",

    noItems: "Aucun produit sélectionné",

    model: {
        addressId: {
            errors: {
                required: "Veuillez remplir l'adresse",
                noAddress: "Aucune adresse n'est disponible. Veuillez contacter l'assistance."
            }
        },
        product: {
            placeholder: "Veuillez indiquer le montant"
        }
    },

    createNewAddress: "Créer une nouvelle adresse",

    button: {
        submit: {
            cta: "Soumettre la commande",
            processing: "Soumission"
        },
        remove: "Supprimer de la commande"
    },

    errors: {
        itemNotExist: "Article «{name}» n'existe pas au catalogue. Il sera supprimé.",
        requestError: "Un problème est survenu lors de la soumission de votre commande. Veuillez réessayer de soumettre."
    }
}