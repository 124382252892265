export default {
    header: "Potwierdź adres i wyślij zamówienie",
    message: "Czy podany poniżej adres wysyłki jest prawidłowy?",
    tip: "Kliknij <b>TAK</b> , aby potwierdzić adres wysyłki i złożyć zamówienie. Kliknij <b>NIE</b> , aby anulować przesyłanie i wybrać inny adres.",
    info: "Po złożeniu zamówienia nie można go zmienić. Jeśli zamawiany produkt jest niedostępny lub ma późniejszą datę wysyłki i planujesz przeprowadzkę, weź to pod uwagę przy wyborze adresu wysyłki. Dodatkowo, jeśli zbliża się święto lub wakacje i nie będziesz w domu, aby odebrać produkt, prosimy o wstrzymanie się ze składaniem zamówień do czasu, gdy będziesz w domu, aby odebrać produkty.",
    warning: {
        message: "Sprawdź dokładnie, czy Twój {fields} jest poprawna, ponieważ firma spedycyjna może nie być w stanie dostarczyć Twojego zamówienia lub skontaktować się z Tobą w sprawie zamówienia. | Sprawdź dokładnie, czy Twój {fields} są poprawne, ponieważ firma spedycyjna może nie być w stanie dostarczyć Twojego zamówienia lub skontaktować się z Tobą w sprawie zamówienia.",
        name: "Nazwa",
        street: "Ulica",
        phone: "Telefon"
    },
    buttons: {
        yes: "Tak",
        no: "NIE"
    }
}