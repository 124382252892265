export default {
    "India": "India",
    "United States of America": "Amerikai egyesült államok",
    "United Kingdom": "Egyesült Királyság",
    "Philippines": "Fülöp-szigetek",
    "Australia": "Ausztrália",
    "Spain": "Spanyolország",
    "Bulgaria": "Bulgária",
    "Poland": "Lengyelország",
    "Malaysia": "Malaysia",
    "Russian Federation": "Orosz Föderáció",
    "Ukraine": "Ukrajna",
    "Germany": "Németország",
    "Brazil": "Brazília",
    "Canada": "Kanada",
    "Romania": "Románia",
    "France": "Franciaország",
    "China": "Kína",
    "Italy": "Olaszország",
    "Costa Rica": "Costa Rica",
    "Mexico": "Mexikó",
    "Slovakia": "Szlovákia",
    "Vietnam": "Vietnam",
    "Singapore": "Szingapúr",
    "Argentina": "Argentína",
    "Denmark": "Dánia",
    "Japan": "Japán",
    "Belgium": "Belgium",
    "Czech Republic": "Cseh Köztársaság",
    "Netherlands": "Hollandia",
    "Switzerland": "Svájc",
    "Sweden": "Svédország",
    "New Zealand": "Új Zéland",
    "Hungary": "Magyarország",
    "Egypt": "Egyiptom",
    "Lithuania": "Litvánia",
    "United Arab Emirates": "Egyesült Arab Emírségek",
    "Ireland": "Írország",
    "Hong Kong": "Hong Kong",
    "Austria": "Ausztria",
    "Saudi Arabia": "Szaud-Arábia",
    "Portugal": "Portugália",
    "Norway": "Norvégia",
    "Chile": "Chile",
    "Thailand": "Thaiföld",
    "Taiwan": "Tajvan",
    "Tunisia": "Tunézia",
    "Israel": "Izrael",
    "South Africa": "Dél-Afrika",
    "Colombia": "Colombia",
    "Serbia": "Szerbia",
    "Luxembourg": "Luxemburg",
    "Peru": "Peru",
    "Finland": "Finnország",
    "Brunei Darussalam": "Brunei Darussalam",
    "Korea, Republic of": "Koreai Köztársaság",
    "Indonesia": "Indonézia",
    "Jordan": "Jordánia",
    "Fiji": "Fidzsi-szigetek",
    "Morocco": "Marokkó",
    "Nigeria": "Nigéria",
    "Panama": "Panama",
    "Qatar": "Katar",
    "Greece": "Görögország",
    "Turkey": "pulyka",
    "Croatia": "Horvátország",
    "Kazakhstan": "Kazahsztán",
    "Cyprus": "Ciprus"
}