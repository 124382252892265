export default {

    title: "加载中...",

    responses: {
        "200_tfalogin": {
            title: "登录验证",
            header: "@:views.tunnel.service.response.200_tfalogin.title",
            message: "登录已验证。"
        },
        "404_tfalogin": {
            title: "登录验证",
            header: "@:views.tunnel.service.response.404_tfalogin.title",
            message: "未找到有效令牌。您的令牌可能已过期。"
        },
        "200_tfaaddress": {
            title: "地址验证",
            header: "@:views.tunnel.service.response.200_tfaaddress.title",
            message: "感谢您验证您的地址。您现在可以在下订单时选择此地址作为送货地址。"
        },
        "404_tfaaddress": {
            title: "地址验证",
            header: "@:views.tunnel.service.response.404_tfaaddress.title",
            message: "未找到有效令牌。您的令牌可能已过期。"
        },
        "201_delivery": {
            title: "谢谢",
            header: "谢谢",
            message: "感谢您的答复。"
        },
        "201_shipment_received": {
            title: "谢谢",
            header: "谢谢",
            message: "感谢您的答复。"
        },
        "400": {
            title: "错误的请求。发生错误。",
            header: "@:views.tunnel.service.response.400.title",
            message: "错误的请求。发生错误。"
        }
    }
}