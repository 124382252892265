export default {
    view: {
        success: {
            header: "Enquête soumise",
            message: "Merci pour votre avis."
        },
        productChoice: {
            cta: "Sauvegarder",
            processing: "Économie"
        },
        addressForm: {
            cta: "Sauvegarder",
            processing: "Économie"
        }
    },
    delivery: {
        title: "Enquête de livraison",
        message: "Avez-vous reçu tous les articles énumérés ci-dessous ?",
        cta: {
            yes: "Oui",
            no: "Non",
            processing: "Économie"
        }
    }
}