export default {
    title: "Orden en nombre de",
    model: {
        email: {
            label: "Correo electrónico",
            placeholder: "Por favor complete el correo electrónico del usuario para realizar el pedido en nombre de",
            errors: {
                myself: "El correo electrónico debe diferir de su"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Entregar",
            processing: "Sumisión"
        }
    },
    error: {
        userNotExists: "{email} no existe"
    }
}