export default {
    title: "Průzkum portálu",
    header: "@:views.survey.customer.title",
    model: {
        service_rating: {
            label: "Jak byste ohodnotili tuto službu na stupnici od 1 do 10, kde 1 je velmi nespokojen a 10 je velmi spokojen?",
        },
        service_likes: {
            label: "Co jsi měl rád?",
            placeholder: ""
        },
        service_improvements: {
            label: "Co lze zlepšit?",
            placeholder: ""
        },
        service_easy_to_follow: {
            label: "Jak byste ohodnotili komunikaci týkající se tohoto webu na stupnici 1–10, přičemž 1 není vůbec jasné a 10 je snadné sledovat?"
        },
        item: {
            setup_rating: {
                label: "Na stupnici od 1 do 10, kde 1 je velmi obtížné a 10 je velmi snadné, jak obtížné bylo instalaci a nastavení vašeho \"{name}\"?",
            },
            comments: {
                label: "Uveďte prosím nějaké komentáře k {name}?",
                placeholder: ""
            }
        }
    },
    disclaimer: "Vezměte prosím na vědomí, že vaše odpovědi na průzkum budou sdíleny s O-I a můžeme vás kontaktovat ohledně vaší zpětné vazby.",
    button: {
        submit: {
            cta: "Předložit",
            processing: "Odesílání"
        }
    }
}