export default {
    title: "Conferma di consegna",
    header: "@:views.survey.delivery.title",
    result: {
        header: "Grazie",
        message: "Grazie per la vostra risposta."
    },
    status: {
        yes: "Verifica i prodotti selezionati di seguito per confermare la consegna e deseleziona quelli che non hai ricevuto. Quindi premi Invia per confermare.",
        no: "Verifica di non aver ricevuto nessuno dei seguenti prodotti. Se hai ricevuto uno di questi prodotti, seleziona la casella accanto ai prodotti ricevuti e premi Invia per confermare."
    },
    model: {
        checked: {
            label: "Ricevuto"
        }
    },
    button: {
        submit: {
            cta: "Invia",
            processing: "Invio"
        }
    }
}