export default {
    menu: {
        approvals: "Zatwierdzenia",
        delegates: "Delegaci"
    },
    approvals: {
        title: "Aprobata",
        error: "Wystąpił błąd",
        saved: "Zatwierdzenia przesłane",
        noApprovals: "Nic do zatwierdzenia",
        review: "Sprawdź i edytuj centrum kosztów, jeśli jest nieprawidłowe",
        priorApprovals: "Uprzednie aprobaty",
        noOrders: "Nic do zatwierdzenia",
        noPriorApprovals: "Brak uprzednich zezwoleń",
        approved: "Zatwierdzony",
        notApproved: "Niezatwierdzone",
        confirm: "Nadal istnieją zamówienia oczekujące na zatwierdzenie. Czy chcesz kontynuować bieżące zmiany?",
        manager: "Menedżer",
        orders: {
            name: "Nazwa",
            date: "Data zamówienia",
            details: "Szczegóły zamówienia",
            cost: "Koszt",
            costCenter: "Centrum kosztów",
            approval: "Aprobata"
        },
        model: {
            reason: {
                placeholder: "Powód"
            },
            approve: {
                yes: {
                    label: "Tak"
                },
                no: {
                    label: "NIE"
                }
            }
        },
        buttons: {
            submit: {
                text: "Składać",
                processing: "Przetwarzanie"
            }
        }
    },
    delegates: {
        title: "Delegaci",
        error: "Wystąpił błąd",
        noDelegates: "Żadnych przedmiotów",
        delegateDelete: "Pełnomocnik został usunięty",
        confirm: {
            delete: "Usunąć pełnomocnika?"
        },
        delegates: {
            email: "E-mail",
            startDate: "Data rozpoczęcia",
            endDate: "Data końcowa",
        },
        buttons: {
            add: {
                text: "Dodaj delegata"
            }
        }
    }
}