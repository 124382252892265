export default {
    "India": "Índia",
    "United States of America": "Estados Unidos da América",
    "United Kingdom": "Reino Unido",
    "Philippines": "Filipinas",
    "Australia": "Austrália",
    "Spain": "Espanha",
    "Bulgaria": "Bulgária",
    "Poland": "Polônia",
    "Malaysia": "Malásia",
    "Russian Federation": "Federação Russa",
    "Ukraine": "Ucrânia",
    "Germany": "Alemanha",
    "Brazil": "Brasil",
    "Canada": "Canadá",
    "Romania": "Romênia",
    "France": "França",
    "China": "China",
    "Italy": "Itália",
    "Costa Rica": "Costa Rica",
    "Mexico": "México",
    "Slovakia": "Eslováquia",
    "Vietnam": "Vietnã",
    "Singapore": "Cingapura",
    "Argentina": "Argentina",
    "Denmark": "Dinamarca",
    "Japan": "Japão",
    "Belgium": "Bélgica",
    "Czech Republic": "República Checa",
    "Netherlands": "Holanda",
    "Switzerland": "Suíça",
    "Sweden": "Suécia",
    "New Zealand": "Nova Zelândia",
    "Hungary": "Hungria",
    "Egypt": "Egito",
    "Lithuania": "Lituânia",
    "United Arab Emirates": "Emirados Árabes Unidos",
    "Ireland": "Irlanda",
    "Hong Kong": "Hong Kong",
    "Austria": "Áustria",
    "Saudi Arabia": "Arábia Saudita",
    "Portugal": "Portugal",
    "Norway": "Noruega",
    "Chile": "Chile",
    "Thailand": "tailândia",
    "Taiwan": "Taiwan",
    "Tunisia": "Tunísia",
    "Israel": "Israel",
    "South Africa": "África do Sul",
    "Colombia": "Colômbia",
    "Serbia": "Sérvia",
    "Luxembourg": "Luxemburgo",
    "Peru": "Peru",
    "Finland": "Finlândia",
    "Brunei Darussalam": "Brunei Darussalam",
    "Korea, Republic of": "Republica da Coréia",
    "Indonesia": "Indonésia",
    "Jordan": "Jordânia",
    "Fiji": "Fiji",
    "Morocco": "Marrocos",
    "Nigeria": "Nigéria",
    "Panama": "Panamá",
    "Qatar": "Catar",
    "Greece": "Grécia",
    "Turkey": "Peru",
    "Croatia": "Croácia",
    "Kazakhstan": "Cazaquistão",
    "Cyprus": "Chipre"
}