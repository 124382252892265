import {platform} from "@/api/platform";
import {encode} from "@/helpers/base64";
import get from "lodash/get"

export function errorHandler(message, vm, info) {

    const log = {
        source: "mktportal",
        type: "exception",
        details: {
            message: `message: ${get(message, "message", "NOT SET")}\nstack: ${get(message, "stack")}`,
            info,
            url: window.location.href,
            vm_name: get(vm, "$options.name", "NOT SET"),
            vm_props: get(vm, "$props", "NOT SET"),
            referrer: document.referrer
        }
    };

    platform.post(
        "/api/v2/frontend/log",
        encode(JSON.stringify(log)),
        {
            headers: {
                "X-Content-Transfer-Encoding": "base64",
                "Content-Type": "application/json"
            }
        }
    );

    console.error(message);
    console.error(vm);
    console.error(info);
}