export default {
    title: "服务台",
    header: "服务台",
    model: {
        email: {
            label: "用户邮箱",
            placeholder: "请填写用户邮箱"
        }
    },
    cta: "提交",
    processing: "加载中"
}