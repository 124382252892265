export default {
    error: "Probléma lépett fel a rendelések lekérésekor. Kérem frissítse a böngészőjét.",
    meta: {
        default: "Megrendelések",
        my_orders: "rendeléseim",
        my_gear: "Megkapta"
    },
    loadMore: "Load More",
    noOrders: "Nincs rendelés",
    order: {
        product: {
            title: "Rendelés",
            tracking: "Követés",
            quantity: "Mennyiség",
            control: {
                cancel: {
                    cta: "Kattintson a visszavonáshoz",
                    tooltip: "Kattintson az elem törléséhez",
                    message: "Megrendelését töröltük."
                },
                cancelRequest: {
                    cta: "Lemondási kérelem",
                    tooltip: "Kattintson a gombra a törlési kérelem benyújtásához"
                },
                received: {
                    cta: "Megjelölés átvettként",
                    tooltip: "Kattintson a kézbesítés megerősítéséhez",
                    message: "Köszönjük, hogy megerősítette, hogy megkapta termékét."
                },
                notReceived: {
                    cta: "Megjelölés meg nem érkezettként",
                    tooltip: "Kattintson a nem kézbesítés megerősítéséhez"
                },
                damaged: {
                    cta: "Jelölje meg sérültként/töröttként",
                    tooltip: "Kattintson a támogatási jegy létrehozásához"
                },
                hide: {
                    cta: "Elem elrejtése",
                    tooltip: "Kattintson az elem elrejtéséhez a listából"
                }
            },
            requestToCancelInfo: "Felvesszük a kapcsolatot a szállítóval, hogy lemondást kérjünk. Felhívjuk figyelmét, hogy ez csak egy kérés, és előfordulhat, hogy a lemondás nem lehetséges. Amint meg tudjuk állapítani, hogy törölhető-e vagy sem, értesítjük.",
            cancelOk: "Rendben",
            reason: "Ok",
            orderedBy: "Megrendelő",
            model: {
                cancel: {
                    reason: {
                        label: "A lemondás okai",
                        placeholder: "A lemondás okai"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Beküldés",
                        processing: "Beküldés"
                    },
                    cancel: {
                        cta: "Megszünteti"
                    }
                }
            }
        }
    },
    help: {
        text: "<p>Kérdései vannak a rendelésével kapcsolatban?<br/> Segítségért keresse fel <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>Ügyfélszolgálatunkat</b></a></p><p> Olyan termékre van szüksége, amely nem szerepel a listán?<br/> Javaslat küldéséhez kattintson <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>ide</b></a></p>",
        email: {
            subject: ""
        }
    }
}