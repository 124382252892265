export default {
    title: "Potvrzení o doručení",
    header: "@:views.survey.delivery.title",
    result: {
        header: "Děkuji",
        message: "Děkuji za Vaši odpověď."
    },
    status: {
        yes: "Zkontrolujte prosím níže zaškrtnuté produkty a potvrďte doručení a zrušte zaškrtnutí těch, které jste neobdrželi. Poté potvrďte stisknutím Odeslat.",
        no: "Ověřte prosím, že jste neobdrželi žádný z níže uvedených produktů. Pokud jste některý z těchto produktů obdrželi, zaškrtněte políčko vedle přijatých produktů a potvrďte tlačítkem Odeslat."
    },
    model: {
        checked: {
            label: "Přijato"
        }
    },
    button: {
        submit: {
            cta: "Předložit",
            processing: "Odesílání"
        }
    }
}