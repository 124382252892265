export default {
    disclaimer: {
        label_default: "Riconosco e accetto che gli articoli che sto ordinando/ricevendo sono O-I proprietà da utilizzare per O-I attività legate al lavoro, e come tale, O-I si riserva il diritto di richiedere la restituzione dell'immobile alla mia uscita da O-I (o prima, se richiesto da O-I).",
        label_type_1: "Confermo che l'autovalutazione per i compiti da casa è stata completata in modo accurato e in buona fede e che qualsiasi attrezzatura o mobilio fornito da O-I posizione o ordinati tramite Gear verranno utilizzati da me ai fini del mio lavoro con O-I. Riconosco e accetto che gli articoli che sto ordinando/ricevendo sono O-I proprietà da utilizzare per O-I attività legate al lavoro, e come tale, O-I si riserva il diritto di richiedere la restituzione dell'immobile alla mia uscita da O-I (o prima, se richiesto da O-I).",
        errors: {
            required: "Si prega di accettare i termini di utilizzo"
        }
    },
    cost_center: {
        label: "Codice centro di costo",
        placeholder: "Codice centro di costo",
        cost_center_not_found: "I centri di costo non presenti possono richiedere fino a 24 ore per essere visualizzati. Contatta il Global Service Desk se non vedi il centro di costo appropriato.",
        errors: {
            regex: "Codice centro di costo non valido"
        }
    },
    company_code: {
        label: "Codice aziendale"
    },
    cost_center_name: {
        label: "Nome centro di costo"
    },
    order_note: {
        label: "Nota d'ordine",
        placeholder: "Nota facoltativa al manager per l'approvazione",
    }
}