export default {
    title: {
        new: "Aggiungi un nuovo indirizzo",
        complete: "Indirizzo completo",
        edit: "Modifica indirizzo"
    },

    incomplete: "Si prega di compilare i campi dell'indirizzo obbligatori",

    info: {
        "CHN": "Inserisci il tuo indirizzo in cinese.</br> Inserisci il tuo indirizzo in cinese.",
        "BGR": "Inserisci il tuo <b>indirizzo di casa</b> in inglese per ricevere i tuoi articoli.",
        "PRI": "Inserisci il tuo indirizzo di casa e non una casella postale per ricevere i pacchi.",
        "SGP": "Per favore includi il numero della tua unità nel tuo indirizzo.",
        "SVK": "Inserisci il tuo indirizzo in <b>inglese</b>",
        "POL": "Inserisci il tuo indirizzo in <b>inglese</b>"
    },

    model: {
        country: {
            label: "Paese"
        }
    },

    field: {
        default: {
            "name": {
                "label": {
                    "Name": "Nome"
                },
                "placeholder": {
                    "Name": "Nome",
                    "Name in English": "Nome in inglese"
                }
            },
            personalEmail: {
                label: {
                    "Personal Email": "E-mail personale"
                },
                placeholder: {
                    "Personal Email": "E-mail personale"
                }
            },
            managerEmail: {
                label: {
                    "Manager Email": "E-mail del direttore"
                },
                placeholder: {
                    "Manager Email": "E-mail del direttore"
                }
            },
            managerName: {
                label: {"Manager Name": "Nome del direttore"},
                placeholder: {"Manager Name": "Nome del direttore"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Cap",
                    "Post Code": "Codice postale",
                    "Postal Code": "Codice Postale",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "Cap",
                    "Post Code": "Codice postale",
                    "Postal Code": "Codice Postale",
                    "CPA": "CPA"
                }
            },
            "province": {
                "label": {
                    "Province": "Provincia",
                    "Prefecture": "Prefettura",
                    "Province/Region": "Provincia Regione",
                    "Province Code": "Codice provinciale"
                },
                "placeholder": {
                    "Province": "Provincia",
                    "Prefecture": "Prefettura",
                    "Province/Region": "Provincia Regione",
                    "Province Code": "Codice provinciale"
                }
            },
            "phoneNumber": {
                "label": {
                    "Phone Number": "Numero di telefono"
                },
                "placeholder": {
                    "Phone Number": "Numero di telefono"
                }
            },
            "firstStreet": {
                "label": {
                    "Street": "Strada",
                    "Tax ID": "Codice fiscale"
                },
                "placeholder": {
                    "Street": "Strada",
                    "Street in English": "Strada in inglese",
                    "Tax ID (RUT)": "Codice fiscale (RUT)",
                    "Number/Street/Apartment": "Numero/Via/Appartamento"
                }
            },
            "secondStreet": {
                "label": {
                    "District": "Quartiere",
                    "Colonia": "Colonia",
                    "Canton": "Cantone",
                    "Street": "Strada"
                },
                "placeholder": {
                    "District": "Quartiere",
                    "Colonia": "Colonia",
                    "Canton": "Cantone",
                    "Number/Street/Apartment": "Numero/Via/Appartamento"
                }
            },
            "city": {
                "label": {
                    "City": "Città",
                    "Town/City/Locality": "Città/città/località",
                    "Town/City": "Città/città",
                    "City/Municipality/Locality": "Città/Comune/Località",
                    "City/Town/Locality": "Città/Paese/Località",
                    "City/Town": "Città/Paese",
                    "District": "Quartiere",
                    "City/Town/Locallity": "Città/Paese/Località",
                    "Town/City/Sub Locality": "Città/città/sublocalità"
                },
                "placeholder": {
                    "City": "Città",
                    "Town/City": "Città/città",
                    "Town/City/Locality": "Città/città/località",
                    "City/Municipality/Locality": "Città/Comune/Località",
                    "City/Town/Locality": "Città/Paese/Località",
                    "City/Town in English": "Città/Paese in inglese",
                    "District": "Quartiere",
                    "City/Town/Locallity": "Città/Paese/Località",
                    "Town/City/Sub Locality": "Città/città/sublocalità"
                }
            },
            identifier: {
                label: {
                    "identifier": "Identificatore",
                    "Identifier": "Identificatore"
                },
                placeholder: {
                    "identifier": "Identificatore",
                    "Identifier": "Identificatore"
                }
            },
            "region": {
                "label": {
                    "Region": "Regione",
                    "County": "contea",
                    "Locality": "Località"
                },
                "placeholder": {
                    "Region": "Regione",
                    "County": "contea",
                    "Locality": "Località"
                }
            },
            "poBox": {
                "label": {
                    "PO BOX": "CASELLA POSTALE"
                },
                "placeholder": {
                    "PO BOX": "CASELLA POSTALE"
                }
            },
            state: {
                "label": {
                    "State": "Stato",
                    "Province/Region": "Provincia Regione",
                    "Province": "Provincia",
                    "Department": "Dipartimento",
                    "Municipality/Comuna": "Comune/Comuna"
                },
                "placeholder": {
                    "State": "Stato",
                    "Province/Region": "Provincia Regione",
                    "Province": "Provincia",
                    "Department": "Dipartimento",
                    "Municipality/Comuna": "Comune/Comuna"
                },
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alaska",
                    "Arizona": "Arizona",
                    "Arkansas": "Arkansas",
                    "California": "California",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "Distretto della Colombia",
                    "Florida": "Florida",
                    "Georgia": "Georgia",
                    "Hawaii": "Hawaii",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Louisiana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Mississippi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebraska",
                    "Nevada": "Nevada",
                    "New Hampshire": "New Hampshire",
                    "New Jersey": "New Jersey",
                    "New Mexico": "Nuovo Messico",
                    "New York": "New York",
                    "North Carolina": "Carolina del Nord",
                    "North Dakota": "Nord Dakota",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregon",
                    "Pennsylvania": "Pennsylvania",
                    "Rhode Island": "Rhode Island",
                    "South Carolina": "Carolina del Sud",
                    "South Dakota": "Sud Dakota",
                    "Tennessee": "Tennessee",
                    "Texas": "Texas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Virginia",
                    "Washington": "Washington",
                    "West Virginia": "Virginia dell'ovest",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            "deliveryInstructions": {
                "label": {
                    "Delivery Instructions": "Istruzioni per la consegna"
                },
                "placeholder": {
                    "Extra address information, delivery instructions, etc. ": "Ulteriori informazioni sull'indirizzo, istruzioni di consegna, ecc. ",
                    "Extra address information, delivery instructions, etc. in English ": "Ulteriori informazioni sull'indirizzo, istruzioni per la consegna, ecc. in inglese ",
                    "Extra address information, delivery instructions, etc": "Ulteriori informazioni sull'indirizzo, istruzioni di consegna, ecc."
                }
            },
        },
        BRA: {
            name: {
                label: {"Full name": "Nome e cognome"},
                placeholder: {"Full name": "Nome e cognome"}
            },
            firstStreet: {
                label: {"Address 1": "Indirizzo 1"},
                placeholder: {"Address 1": "Via + numero civico/appartamento"}
            },
            secondStreet: {
                label: {
                    "Address 2": "Indirizzo 2",
                    "District": "Quartiere"
                },
                placeholder: {
                    "Address 2": "Via + numero civico/appartamento",
                    "District": "Quartiere"
                }
            },
            city: {
                label: {City: "Città"},
                placeholder: {City: "Città"},
            },
            state: {
                label: {State: "Stato"},
                placeholder: {State: "Stato"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Cap",
                    "Post Code": "Codice postale",
                    "Postal Code": "Codice Postale",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "Cap",
                    "Post Code": "Codice postale",
                    "Postal Code": "Codice Postale",
                    "CPA": "CPA"
                }
            },
            identifier: {
                "label": {
                    "Tax ID": "Codice fiscale"
                },
                "placeholder": {
                    "Tax ID (CDI, CUIT, CUIL, etc)": "Codice fiscale (CDI, CUIT, CUIL, ecc.)"
                }
            }
        }
    },

    errors: {
        required: "{field} è obbligatorio.",
        mask: "{field} è invalido."
    },

    buttons: {
        submit: {
            text: "Salva",
            saving: "Salvataggio"
        },
        cancel: {
            text: "Annulla"
        }
    },

    disclaimer: "L'aggiunta di questo indirizzo richiederà la verifica dell'e-mail. Dopo aver salvato l'indirizzo, controlla la tua email e fai clic sul collegamento all'interno.",

    message: {
        added: "L'indirizzo è stato aggiunto",
        updated: "L'indirizzo è stato aggiornato"
    }
}