export default {
    disclaimer: {
        label_default: "Reconozco y acepto que los artículos que estoy ordenando/recibiendo son O-I propiedad que se utilizará para O-I actividades relacionadas con el trabajo, y como tal, O-I se reserva el derecho de solicitar la devolución de la propiedad a mi salida de O-I (o antes, si lo requiere O-I).",
        label_type_1: "Confirmo que la autoevaluación para el trabajo a domicilio se completó con precisión y de buena fe, y cualquier equipo o mobiliario proporcionado por O-I ubicación o pedido a través de Gear será utilizado por mí para el propósito de mi trabajo con O-I. Reconozco y acepto que los artículos que estoy ordenando/recibiendo son O-I propiedad que se utilizará para O-I actividades relacionadas con el trabajo, y como tal, O-I se reserva el derecho de solicitar la devolución de la propiedad a mi salida de O-I (o antes, si lo requiere O-I).",
        errors: {
            required: "Por favor acepte los términos de uso"
        }
    },
    cost_center: {
        label: "Código del centro de costos",
        placeholder: "Código del centro de costos",
        cost_center_not_found: "Los centros de costes que no están presentes pueden tardar hasta 24 horas en aparecer. Comuníquese con Global Service Desk si no ve el centro de costos apropiado.",
        errors: {
            regex: "Código de centro de costos no válido"
        }
    },
    company_code: {
        label: "Codigo de compañia"
    },
    cost_center_name: {
        label: "Nombre del centro de costos"
    },
    order_note: {
        label: "Nota de pedido",
        placeholder: "Nota opcional al gerente para su aprobación",
    }
}