export default {
    disclaimer: {
        label_default: "Beru na vědomí a souhlasím s tím, že položky, které objednávám / dostávám, jsou O-I majetek, který má být použit pro O-I činnosti související s prací a jako takové O-I si vyhrazuje právo požádat o vrácení nemovitosti při mém odchodu z O-I (nebo dříve, pokud to vyžaduje O-I).",
        label_type_1: "Potvrzuji, že sebehodnocení pro domácí úkoly bylo provedeno přesně a v dobré víře a že veškeré vybavení nebo nábytek poskytl O-I umístění nebo objednané prostřednictvím zařízení Gear použiji pro účely mé práce s O-I. Beru na vědomí a souhlasím s tím, že položky, které objednávám / dostávám, jsou O-I majetek, který má být použit pro O-I činnosti související s prací a jako takové O-I si vyhrazuje právo požádat o vrácení nemovitosti při mém odchodu z O-I (nebo dříve, pokud to vyžaduje O-I).",
        errors: {
            required: "Přijměte prosím podmínky použití"
        }
    },
    cost_center: {
        label: "Kód nákladového střediska",
        placeholder: "Kód nákladového střediska",
        cost_center_not_found: "Nepřítomnost nákladových středisek může trvat až 24 hodin, než se zobrazí. Pokud příslušné nákladové středisko nevidíte, kontaktujte Global Service Desk.",
        errors: {
            regex: "Neplatný kód nákladového střediska"
        }
    },
    company_code: {
        label: "Zákoník společnosti"
    },
    cost_center_name: {
        label: "Název nákladového střediska"
    },
    order_note: {
        label: "Poznámka k objednávce",
        placeholder: "Nepovinná poznámka vedoucímu ke schválení",
    }
}