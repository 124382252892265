<template>
    <div class="impersonation-orders-history-user">
        <div class="d-flex">
            <div class="flex-grow-1 name">{{name}}</div>

            <div class="flex-grow-0 pl-3">
                <button type="button" class="btn-switcher" :disabled="loading" @click="display = !display">
                    <spinner v-if="true === loading"/>
                    <span v-else :class="{'icon-plus':!display,'icon-minus':display}"></span>
                </button>
            </div>
        </div>

        <div v-if="null !== total && true === display" class="pt-3">
            <!-- Orders -->
            <impersonation-orders-history-user-product v-for="product in items"
                                                       class="orders-history-item"
                                                       :user-id="userId"
                                                       :user-email="userEmail"
                                                       :key="product._id"
                                                       :product="product"/>
            <!-- Load more-->
            <div v-if="total > items.length" class="load-more">
                <button type="button"
                        class="btn btn-link p-0"
                        :disabled="loading"
                        @click="load(items.length, true, true)">
                    {{t('loadMore')}}
                    <spinner v-if="loading"/>
                </button>
            </div>
        </div>
    </div>
</template>

<script>

    import emitter, {EVENTS} from "@/api/events";

    import Spinner from "@/components/Spinner";
    import ImpersonationOrdersHistoryUserProduct from "./ImpersonationOrdersHistoryUserProduct";

    export default {
        name: "ImpersonationOrdersHistoryUser",
        components: {
            ImpersonationOrdersHistoryUserProduct,
            Spinner
        },
        props: {
            userId: {
                type: Number,
                required: true
            },
            userEmail: {
                type: String,
                required: true
            },
            name: {
                type: String,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.impersonation.orders.history.user",
                display: false,
                loading: false,
                items: [],
                total: null
            };
        },
        methods: {
            load(skip, append, changeLoadingIndicator, limit) {

                if (changeLoadingIndicator) {

                    this.loading = true;
                }

                this.$store
                    .dispatch("user/impersonation/orders/loadItems", {
                        userId: this.userId,
                        skip,
                        limit: limit ? limit : process.env.VUE_APP_COMPONENT_IMPERSONATION_ORDERS_HISTORY_CHUNK_SIZE
                    })
                    .then(result => {

                        this.total = result.total;
                        this.items = append ? this.items.concat(result.items) : result.items;
                    })
                    .finally(() => this.loading = false);
            },
            refresh() {

                if (null !== this.total) {

                    this.load(
                        0,
                        false,
                        false,
                        this.items.length < process.env.VUE_APP_COMPONENT_IMPERSONATION_ORDERS_HISTORY_CHUNK_SIZE
                            ? process.env.VUE_APP_COMPONENT_IMPERSONATION_ORDERS_HISTORY_CHUNK_SIZE
                            : this.items.length
                    );
                }
            }
        },
        watch: {
            display() {

                if (true === this.display && null === this.total) {

                    this.load(0, false, true, process.env.VUE_APP_COMPONENT_IMPERSONATION_ORDERS_HISTORY_USER_CHUNK_SIZE);
                }
            }
        },
        mounted() {

            emitter.on(EVENTS.USER_IMPERSONATION_ORDER_CANCEL, this.refresh);
            emitter.on(EVENTS.USER_IMPERSONATION_ORDER_RECEIVED, this.refresh);
            emitter.on(EVENTS.USER_IMPERSONATION_ORDER_UNDO, this.refresh);
        },
        beforeUnmount() {

            emitter.off(EVENTS.USER_IMPERSONATION_ORDER_CANCEL, this.refresh);
            emitter.off(EVENTS.USER_IMPERSONATION_ORDER_RECEIVED, this.refresh);
            emitter.off(EVENTS.USER_IMPERSONATION_ORDER_UNDO, this.refresh);
        }
    }
</script>