export default {

    title: "Caricamento...",

    responses: {
        "200_tfalogin": {
            title: "Verifica dell'accesso",
            header: "@:views.tunnel.service.response.200_tfalogin.title",
            message: "L'accesso è stato verificato."
        },
        "404_tfalogin": {
            title: "Verifica dell'accesso",
            header: "@:views.tunnel.service.response.404_tfalogin.title",
            message: "Token valido non trovato. Il tuo token potrebbe essere scaduto."
        },
        "200_tfaaddress": {
            title: "Verifica dell'indirizzo",
            header: "@:views.tunnel.service.response.200_tfaaddress.title",
            message: "Grazie per aver verificato il tuo indirizzo. Ora puoi scegliere questo come indirizzo di spedizione quando effettui un ordine."
        },
        "404_tfaaddress": {
            title: "Verifica dell'indirizzo",
            header: "@:views.tunnel.service.response.404_tfaaddress.title",
            message: "Token valido non trovato. Il tuo token potrebbe essere scaduto."
        },
        "201_delivery": {
            title: "Grazie",
            header: "Grazie",
            message: "Grazie per la vostra risposta."
        },
        "201_shipment_received": {
            title: "Grazie",
            header: "Grazie",
            message: "Grazie per la vostra risposta."
        },
        "400": {
            title: "Brutta richiesta. Si è verificato un errore.",
            header: "@:views.tunnel.service.response.400.title",
            message: "Brutta richiesta. Si è verificato un errore."
        }
    }
}