export default {
    methods: {
        checkout_config_getFields(config, {tags}) {

            tags = "undefined" === typeof tags ? [] : tags;

            return config.filter(
                field => "undefined" === typeof field.tags
                    || 0 < field.tags.filter(fieldTag => -1 !== tags.indexOf(fieldTag)).length
            );
        }
    }
}