export default {
    error: "Beim Abrufen von Bestellungen ist ein Problem aufgetreten. Bitte aktualisiere deinen Browser.",
    title: "Bestellhistorie",
    loadMore: "Mehr laden",
    noOrders: "Keine Bestellungen",
    user: {
        loadMore: "Mehr laden",
        product: {
            title: "Befehl",
            tracking: "Verfolgung",
            quantity: "Menge",
            reason: "Grund",
            control: {
                cancel: {
                    cta: "Klicken Sie hier, um abzubrechen",
                    tooltip: "Klicken Sie, um diesen Artikel zu stornieren",
                    message: "Wir haben Ihre Bestellung storniert."
                },
                received: {
                    cta: "Produkt erhalten",
                    tooltip: "Klicken Sie hier, um die Lieferung zu bestätigen",
                    message: "Vielen Dank für die Bestätigung, dass Sie Ihr Produkt erhalten haben."
                },
                notReceived: {
                    cta: "Produkt nicht erhalten",
                    tooltip: "Klicken Sie hier, um die Nichtlieferung zu bestätigen"
                },
                hide: {
                    cta: "Artikel ausblenden",
                    tooltip: "Klicken Sie, um das Element aus der Liste auszublenden"
                }
            }
        }
    }
}