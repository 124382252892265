export default {
    message: {
        //     constraints  ordered
        default_everything_everything: "<p>Ad oggi, hai ordinato tutto ciò che hai a disposizione in Gear.</p><p> Se hai una domanda specifica al riguardo, fai clic sul link <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a> qui sopra e invia la tua domanda.</p>",
        default_everything_something: "<p>Gli articoli precedentemente ordinati non vengono più visualizzati.</p><p> Se hai una domanda specifica al riguardo, fai clic sul link <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a> qui sopra e invia la tua domanda.</p>",
        default_partial_everything: "<p>Non ci sono altri prodotti da ordinare.</p><p> La tua situazione è cambiata da quando hai risposto per la prima volta al sondaggio sulle attrezzature? Fare clic <a href='{equipmentQuizLink}'><b>qui</b></a> per rivedere le risposte al sondaggio sulle apparecchiature.</p>",
        default_partial_something: "<p>Gli articoli precedentemente ordinati non vengono più visualizzati.</p><p> La tua situazione è cambiata da quando hai risposto per la prima volta al sondaggio sulle attrezzature? Fare clic <a href='{equipmentQuizLink}'><b>qui</b></a> per rivedere le risposte al sondaggio sulle attrezzature.</p>",
        default_partial_noitems: "<p>La tua situazione è cambiata da quando hai risposto per la prima volta al sondaggio sulle attrezzature? Fare clic <a href='{equipmentQuizLink}'><b>qui</b></a> per rivedere le risposte al sondaggio sulle apparecchiature.</p>",

        ehs_everything_everything: "<p>Ad oggi, hai ordinato tutto ciò che hai a disposizione in Gear.</p><p> La disponibilità dei prodotti si basa sul sondaggio EHS. Se hai bisogno di ulteriore supporto, contatta <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_everything_something: "<p>Gli articoli precedentemente ordinati non vengono più visualizzati.</p><p> La disponibilità dei prodotti si basa sul sondaggio EHS. Se hai bisogno di ulteriore supporto, contatta <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_everything: "<p>Ad oggi, hai ordinato tutto ciò che hai a disposizione in Gear.</p><p> La disponibilità dei prodotti si basa sul sondaggio EHS. Se hai bisogno di ulteriore supporto, contatta <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_something: "<p>Gli articoli precedentemente ordinati non vengono più visualizzati.</p><p> La disponibilità dei prodotti si basa sul sondaggio EHS. Se hai bisogno di ulteriore supporto, contatta <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_noitems: "<p>La disponibilità dei prodotti si basa sul sondaggio EHS. Se hai bisogno di ulteriore supporto, contatta <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
    }
}