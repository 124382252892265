export default {
    ordered: {
        type: {
            "ac_router": "Ho già ordinato un router AC diverso",
            "chair": "Ho già ordinato una sedia diversa",
            "default": "Già ordinato",
            "default_multiple": "Ho già ordinato prodotti simili",
            "desk": "Ho già ordinato una scrivania diversa",
            "desktop": "Ho già ordinato un desktop diverso",
            "desktop_riser": "Ho già ordinato diversi montanti per desktop",
            "detector": "Ho già ordinato diversi rilevatori di vapore e rumore",
            "displayport_cable": "Ho già ordinato un cavo DisplayPort diverso",
            "docking station": "Ho già ordinato una docking station diversa",
            "dual_usb_adapter": "Ho già ordinato diversi adattatori USB dual band",
            "ear cushion": "Ho già ordinato diversi cuscinetti auricolari",
            "footrest": "Ho già ordinato un poggiapiedi diverso",
            "hdmi_cable": "Ho già ordinato diversi cavi HDMI",
            "hdmi_displayport_cable": "Ho già ordinato un cavo HDMI-DisplayPort diverso",
            "hdmi_vga_cable": "Ho già ordinato un cavo HDMI-VGA diverso",
            "headset": "Ho già ordinato un dispositivo audio diverso",
            "keyboard": "Ho già ordinato una tastiera diversa",
            "kvm switch": "Ho già ordinato un interruttore KVM diverso",
            "kvm_switch": "Ho già ordinato un interruttore KVM diverso",
            "lamp": "Ho già ordinato una lampada diversa",
            "laptop stand": "Ho già ordinato un supporto per laptop diverso",
            "laptop": "Ho già ordinato un laptop diverso",
            "mesh_router": "Ho già ordinato un router mesh diverso",
            "monitor": "Ho già ordinato un monitor diverso",
            "mouse": "Ho già ordinato diversi topi",
            "patch_cord": "Ho già ordinato un cavo patch cat6 diverso",
            "power_center": "Ho già ordinato diversi Power Center",
            "power_strip": "Ho già ordinato una presa multipla diversa",
            "smart_plug": "Ho già ordinato diversi smart plug mini",
            "smart_power_strip": "Ho già ordinato diverse prese multiple intelligenti",
            "thunderbolt_cable": "Ho già ordinato diversi cavi da USB-C a Thunderbolt",
            "travel_router": "Ho già ordinato un router da viaggio diverso",
            "usb hub": "Ho già ordinato un hub USB diverso",
            "usb_adapter": "Ho già ordinato un adattatore USB diverso",
            "usb_hub": "Ho già ordinato un hub diverso",
            "webcam": "Ho già ordinato una webcam diversa"
        },
        product: "Ordinato"
    },
    request: "Richiesta",
    add: "Aggiungi al carrello",
    remove: "Rimuovi dal carrello",
    added: "Aggiunto al carrello",
}