export default {
    status: {
        acknowledged: "Commandé",
        created: "Créé",
        sent: "Commandé",
        confirmed: "Commandé",
        not_confirmed: "Pas ordonné",
        delivery_email: "Expédié",
        shipped: "Expédié",
        delayed: "Retardé",
        preparing: "En train de préparer",
        processing: "Traitement",
        processed: "Traité",
        submitted: "Commandé",
        delivery_not_received: "Expédié",
        shipment_exception: "Expédié",
        pending_approval: "En attente",
        approved: "Approuvé",
        not_approved: "Non approuvé",
        canceled: "Annulé",
        returned: "Revenu",
        lost_shipment: "Envoi perdu",
        delivery_not_confirmed: "Livré",
        delivered: "Livré",
        delivery_received: "Livré",
        refurbished: "Retour terminé",
        returned_to_office: "Retourné au bureau",
        return_to_employer: "Renvoyé à l'employeur",
        return_pickup_scheduled: "Ramassage prévu",
        return_pickup_completed: "Ramassage terminé",
        return_pickup_excluded: "Ramassage exclu",
        return_pickup_submitted: "Ramassage Soumis",
        return_pickup_finalized: "Ramassage finalisé",
        disposed: "Disposé",
        damaged: "Endommagé"
    }
}