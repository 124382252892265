export default {
    unverified: "Non verificato",
    verificationLink: "Invia nuovamente email di verifica",
    buttons: {
        delete: {
            cta: "Eliminare",
            processing: "Eliminazione"
        }
    },
    addressDeleted: "L'indirizzo è stato cancellato",
    verificationEmailSent: "L'email è stata inviata",
    deleteAddressConfirmation: "Sei sicuro di voler eliminare questo indirizzo?"
}