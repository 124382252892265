<template>
    <page-message v-if="alreadyConfirmed">
        <template #header>{{t('headerAlreadyConfirmed')}}</template>
        <template #message>
            <span v-html="t('messageAlreadyConfirmed')"></span>
        </template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
    <page-message v-else>
        <template #header>{{t('header')}}</template>
        <template #messageLeftAligned>
            <span v-html="t('message')"></span>
            <span class="pl-1" v-html="t('messageBeforeCta')"></span>
            <a href="#" class="pl-1" @click.prevent="resend">
                <spinner v-if="processing"></spinner>
                {{processing ? t('buttons.processing') : t('buttons.cta')}}</a><span class="pl-1"
                                                                                     v-html="t('messageAfterCta')"></span>

            <span class="d-block pt-3" v-html="t('secondMessage', {email, subject})"></span>
        </template>
        <template #cta>

        </template>
    </page-message>
</template>

<script>

    import mixin_view from "@/mixins/view";
    import {retryRequest} from "@/helpers/request";

    import PageMessage from "@/components/page/PageMessage";
    import Spinner from "@/components/Spinner";

    export default {
        name: "ViewsAuthConfirm",
        components: {
            Spinner,
            PageMessage
        },
        mixins: [mixin_view],
        props: {
            token: String
        },
        data() {

            return {
                t_path: "views.auth.confirm",

                alreadyConfirmed: false
            }
        },
        computed: {
            email() {

                return process.env.VUE_APP_SUPPORT_EMAIL;
            },
            subject() {

                return this.t('subject');
            }
        },
        methods: {
            resend() {

                const userId = this.$route.params.userId;
                const token = this.$route.params.token;

                this.processing = true;

                return retryRequest(() => this.$platform.post(`/api/v2/users/${userId}/login/verification/send`, {}, {headers: {Authorization: token}}))
                    .catch(error => {

                        if (error.response && 400 == error.response.status) {

                            return this.alreadyConfirmed = true;
                        }

                        this.base_error(error);
                    })
                    .finally(() => this.processing = false);
            }
        }
    }
</script>