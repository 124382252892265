export default {
    disclaimer: {
        label_default: "我承认并同意我订购/接收的物品是 O-I用于 O-I 的属性与工作相关的活动，因此，O-I保留在我退出 O-I 时要求归还财产的权利（或更快，如果 O-I 要求）。",
        label_type_1: "我确认家庭作业的自我评估已准确且真诚地完成，并且 O-I 提供的任何设备或家具。位置或通过 Gear 订购将被我用于使用 O-I 的目的。我承认并同意我订购/接收的物品是 O-I用于 O-I 的属性与工作相关的活动，因此，O-I保留在我退出 O-I 时要求归还财产的权利（或更快，如果 O-I 要求）。",
        errors: {
            required: "请接受使用条款"
        }
    },
    cost_center: {
        label: "成本中心代码",
        placeholder: "成本中心代码",
        cost_center_not_found: "不存在的成本中心最多可能需要 24 小时才会出现。如果您没有看到合适的成本中心，请联系全球服务台。",
        errors: {
            regex: "成本中心代码无效"
        }
    },
    company_code: {
        label: "公司代码"
    },
    cost_center_name: {
        label: "成本中心名称"
    },
    order_note: {
        label: "订单备注",
        placeholder: "供经理批准的可选备注",
    }
}