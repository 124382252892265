export default {
    title: "Enquête sur le portail",
    header: "@:views.survey.customer.title",
    model: {
        service_rating: {
            label: "Sur une échelle de 1 à 10, où 1 correspond à très insatisfait et 10 à très satisfait, comment évaluez-vous ce service ?",
        },
        service_likes: {
            label: "Qu'est ce que tu aimais?",
            placeholder: ""
        },
        service_improvements: {
            label: "Que peut-on améliorer ?",
            placeholder: ""
        },
        service_easy_to_follow: {
            label: "Sur une échelle de 1 à 10, 1 étant pas clair du tout et 10 étant facile à suivre, comment évaluez-vous les communications concernant ce site ?"
        },
        item: {
            setup_rating: {
                label: "Sur une échelle de 1 à 10, où 1 signifie très difficile et 10, très facile, à quel point votre \"{name}\" a-t-il été difficile à installer et à configurer ?",
            },
            comments: {
                label: "Veuillez fournir des commentaires sur {name} ?",
                placeholder: ""
            }
        }
    },
    disclaimer: "Veuillez noter que vos réponses à l'enquête seront partagées avec O-I et nous pouvons faire un suivi avec vous concernant vos commentaires.",
    button: {
        submit: {
            cta: "Soumettre",
            processing: "Soumission"
        }
    }
}