export default {
    title: "Confirmacion de envio",
    header: "@:views.survey.delivery.title",
    result: {
        header: "Gracias",
        message: "Gracias por su respuesta."
    },
    status: {
        yes: "Verifique los productos marcados a continuación para confirmar la entrega y desmarque los que no ha recibido. Luego presione Enviar para confirmar.",
        no: "Verifique que no recibió ninguno de los productos a continuación. Si recibió alguno de estos productos, marque la casilla junto a los productos recibidos y presione Enviar para confirmar."
    },
    model: {
        checked: {
            label: "Recibió"
        }
    },
    button: {
        submit: {
            cta: "Entregar",
            processing: "Sumisión"
        }
    }
}