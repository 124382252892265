export default {
    header: "Potvrďte adresu a odešlete objednávku",
    message: "Je vaše dodací adresa uvedená níže správná?",
    tip: "Kliknutím <b>na ANO</b> potvrďte dodací adresu a odešlete objednávku. Kliknutím na <b>NE</b> zrušíte odeslání a zvolíte jinou adresu.",
    info: "Jakmile je objednávka zadána, nelze ji změnit. Pokud je produkt, který si objednáváte, doobjednaný nebo má pozdější datum odeslání a plánujete se přestěhovat, vezměte to prosím v úvahu při výběru dodací adresy. Kromě toho, pokud se blíží svátek nebo dovolená a nebudete doma, abyste produkt převzali, žádáme vás, abyste odložili objednávky, dokud nebudete doma a produkty si převezmete.",
    warning: {
        message: "Znovu prosím zkontrolujte, že vaše {fields} je správné, protože přepravní společnost nemusí být schopna doručit vaši objednávku nebo vás kontaktovat ohledně vaší objednávky. | Znovu prosím zkontrolujte, že vaše {fields} jsou správné, protože přepravní společnost nemusí být schopna doručit vaši objednávku nebo vás kontaktovat ohledně vaší objednávky.",
        name: "název",
        street: "ulice",
        phone: "Telefon"
    },
    buttons: {
        yes: "Ano",
        no: "Ne"
    }
}