export default {
    return: {
        title: "Zurückkehren",
        header: "Zurückzugebende Artikel",
        message: "Die folgende Betriebsausstattung ist zurückzugeben. Nach dem Absenden dieses Formulars werden Sie separat kontaktiert, um die Abholung und/oder den Versand zu arrangieren. Ihr Vorgesetzter wird über den Status Ihrer Rücksendung informiert.",

        model: {
            address: {
                country: {
                    label: "Land"
                }
            },
            exclude: {
                label: "Von der Rückgabe ausschließen"
            },
            textarea: {
                placeholder: "Grund: Zustimmung des Managers, zuvor zurückgegeben usw."
            },
            return_note: {
                label: "Hinweise zurückgeben",
                placeholder: "Optional: Geben Sie Anmerkungen zu den zurückzusendenden Artikeln ein."
            },
            additional_disposal: {
                label: "Haben Sie physische DXC-Aufzeichnungen, die sicher entsorgt werden müssen?"
            },
            additional_item: {
                type: {
                    label: "Typ",
                    options: {
                        "chair": "Stuhl",
                        "desk": "Schreibtisch",
                        "desk riser": "Tischerhöhung",
                        "docking station": "Dockingstation",
                        "footrest": "Fußstütze",
                        "headset": "Headset",
                        "hdmi cable": "HDMI Kabel",
                        "keyboard": "Klaviatur",
                        "kvm switch": "KVM-Switch",
                        "lamp": "Lampe",
                        "laptop riser": "Laptop-Riser",
                        "monitor": "Monitor",
                        "mouse": "Maus",
                        "other": "Andere",
                        "speakerphone": "Freisprecheinrichtung",
                        "usb hub": "USB-Hub",
                        "webcam": "Webcam",
                    }
                },
                detail: {
                    label: "Detail",
                    placeholder: "Detail"
                },
                quantity: {
                    label: "Menge",
                    placeholder: "Menge"
                }
            }
        },

        submitted: {
            title: "Rücksendung eingereicht",
            message: "Ihre Rücksendung wurde eingereicht. Sie werden separat bezüglich der Abholung und/oder des Versands des Artikels kontaktiert."
        },
        nothingToReturn: {
            title: "Rücksendeinformationen",
            message: "Derzeit haben Sie keine Artikel, die Sie innerhalb von Gear zurückgeben können."
        },
        notEligible: {
            title: "Rücksendeinformationen",
            message: "Derzeit kann Gear Ihre Rücksendung nicht bearbeiten. Bitte wenden Sie sich an Ihren lokalen Manager oder Ihr HR-Team, um zu erfahren, wie Sie Ihre Artikel zurücksenden können."
        },

        button: {
            add: {
                cta: "Nicht gelistetes Element hinzufügen"
            },
            submit: {
                processing: "Einreichen",
                cta: "Einreichen"
            }
        }
    }
}