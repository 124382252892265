export default {
    namespaced: true,
    state: {
        options: {}
    },
    getters: {
        options: state => state.options
    },
    mutations: {
        setOptions: (state, options) => state.options = options,
        clearOptions: state => state.options = {}
    },
    actions: {
        setOptions: (context, options) => context.commit("setOptions", options),
        clearOptions: (context) => context.commit("clearOptions"),
    }
}