export default {
    400: {
        title: "Ungültige Anforderung",
        header: "@:views.errors.400.title",
        html: "Ungültige Anforderung. Bitte versuchen Sie es später erneut."
    },
    401: {
        title: "Bei der Anmeldung ist ein Fehler aufgetreten",
        header: "@:views.errors.401.title",
        html: "Bitte wenden Sie sich an <a href='{email}' target='_blank'>den Support</a> ."
    },
    404: {
        title: "Seite nicht gefunden",
        header: "@:views.errors.404.title"
    },
    403: {
        title: "Zugriff nicht erlaubt",
        header: "Zugriff nicht erlaubt",

        contact: "O-I Peripherie ist nur für Mitarbeiter verfügbar. Wenn Sie ein Mitarbeiter sind und diese Nachricht sehen, wenden Sie sich bitte an People &amp; Culture vor Ort, um Ihren Status zu aktualisieren.",

        subject: "Ausrüstung - Berechtigung",

        html: "<p class='text-left'>@:views.errors.403.eligibility @:views.errors.403.contact</p>",
        error: {
            disabled: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Sie sind derzeit nicht berechtigt. Bitte kontaktieren Sie <a href='#' role='button' data-widget-trigger='{widgetOptions}'>den Support</a> für weitere Informationen.</p>"
            },
            "not-active": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Sie sind derzeit nicht berechtigt. Bitte arbeiten Sie mit Ihrem lokalen People &amp; Culture für weitere Informationen zusammen.</p>"
            },
            "not-allowed":{
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I Peripherie ist nur für Mitarbeiter verfügbar. Wenn Sie ein Mitarbeiter sind und diese Nachricht sehen, wenden Sie sich bitte an People &amp; Culture vor Ort, um Ihren Status zu aktualisieren.</p>"
            },
            "no-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>In Ihrem ServiceNow-Profil ist kein aktiver Manager aufgeführt. Bitte arbeiten Sie mit Ihrem lokalen People &amp; Culture-Vertreter zusammen, um Ihren Manager auf den neuesten Stand zu bringen.</p>"
            },
            "self-manager": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Ihr Manager ist in Ihrem ServiceNow-Profil als Sie festgelegt. Bitte arbeiten Sie mit Ihrem lokalen People &amp; Culture-Vertreter zusammen, um Ihren Manager auf den neuesten Stand zu bringen.</p>"
            },
            "no-location": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>In Ihrem ServiceNow-Profil ist kein Standort aufgeführt. Bitte wenden Sie sich an Ihren People &amp; Culture-Vertreter vor Ort, um Ihren Standort zu aktualisieren.</p>"
            },
            eligibility: {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>O-I Peripherie ist nur für Mitarbeiter verfügbar. Wenn Sie ein Mitarbeiter sind und diese Nachricht sehen, wenden Sie sich bitte an People &amp; Culture vor Ort, um Ihren Status zu aktualisieren.</p>",
            },
            "eligibility-location": {
                header: "Du scheinst ein bisschen zu früh zu sein...",
                html: "<p class='text-left'>Vielen Dank für Ihr Interesse an O-I Peripherie. Anscheinend haben Sie die Site erreicht, bevor Sie aktiviert und direkt eingeladen wurden. O-I Die Peripherie wird stufenweise von Land zu Land und nach Benutzergruppen eingeführt. Wenn Sie aktiviert sind, erhalten Sie eine E-Mail mit einer persönlichen Einladung mit Anweisungen.</p><p class='text-left'> Klicken Sie bei Fragen oder Problemen mit Ihrer Bestellung oder der Website auf <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Support</a> .</p>",
            },
            "secondary-account": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Sie versuchen gerade, auf O-I zuzugreifen Peripheriegerät auf einem sekundären Konto. Bitte versuchen Sie erneut, sich mit Ihrem primären Konto anzumelden.</p>"
            },
            "empty-core-id": {
                header: "@:views.errors.403.header",
                html: "<p class='text-left'>Ihr ServiceNow-Konto hat keinen Kontonamen. Bitte arbeiten Sie mit Ihrem People &amp; Culture-Vertreter vor Ort zusammen, um Ihr Konto zu aktualisieren.</p>"
            },
            country: {
                header: "@:views.errors.403.header",
                html: "@:views.errors.403.eligibility @:views.errors.403.contact",
            },
            "no-permissions": {
                header: "Kein Zugang",
                subject: "Kein Zugang",
                html: "Sie sind nicht berechtigt, diese Seite zu öffnen.",
            },
            "virtual-denied": {
                header: "Du scheinst ein bisschen zu früh zu sein...",
                html: "<p class='text-left'>Vielen Dank für Ihr Interesse an O-I Peripherie. Anscheinend haben Sie die Site erreicht, bevor Sie aktiviert und direkt eingeladen wurden. O-I Die Peripherie wird stufenweise von Land zu Land und nach Benutzergruppen eingeführt. Wenn Sie aktiviert sind, erhalten Sie eine E-Mail mit einer persönlichen Einladung mit Anweisungen.</p><p class='text-left'> Klicken Sie bei Fragen oder Problemen mit Ihrer Bestellung oder der Website auf <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Support</a> .</p>",
            }
        }
    },
    500: {
        title: "Unerwarteter Fehler",
        header: "@:views.errors.500.title",
        message: "Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut."
    },
    code: {
        title: "Unerwarteter Fehler",
        message: "@:views.errors.code.title",
        error: "Fehler {code}."
    }
}