export default {
    title: "Rendelés áttekintése",
    header: "@:views.order.submitted.title",

    noItems: "Nincsenek kiválasztott termékek",

    model: {
        addressId: {
            errors: {
                required: "Kérjük, töltse ki a címet",
                noAddress: "Nincs elérhető cím. Kérjük, forduljon az ügyfélszolgálathoz."
            }
        },
        product: {
            placeholder: "Kérjük, töltse ki az összeget"
        }
    },

    createNewAddress: "Új cím létrehozása",

    button: {
        submit: {
            cta: "Rendelés leadása",
            processing: "Beküldés"
        },
        remove: "Eltávolítás a rendelésből"
    },

    errors: {
        itemNotExist: "Tétel «{name}» nem létezik a katalógusban. El lesz távolítva.",
        requestError: "Hiba történt a rendelés elküldésekor. Kérjük, próbálja meg újra elküldeni."
    }
}