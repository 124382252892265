export default {
    model: {
        email: {
            label: "E-mail",
            placeholder: "Deleguj e-mail"
        },
        start_on: {
            label: "Data rozpoczęcia",
            placeholder: "Data rozpoczęcia delegowania"
        },
        end_on: {
            label: "Data końcowa",
            placeholder: "Opcjonalny"
        },
        accept: {
            tip: "Delegując swoją odpowiedzialność za zatwierdzanie, oświadczasz, że bierzesz na siebie odpowiedzialność i odpowiedzialność za wszelkie zamówienia zatwierdzone przez Twojego delegata.",
            label: "Akceptuję"
        }
    },
    buttons: {
        submit: {
            cta: "Potwierdzać",
            processing: "Oszczędność"
        },
        close: {
            cta: "Anulować"
        }
    },
    saved: "Zapisano"
}