<template>
    <div class="container">
        <div class="row" v-if="!groupingLoading&&0===groupings.length">
            <div class="col-12 text-center">
                {{t('noAvailableItems')}}
            </div>
        </div>
        <div class="row" v-else-if="groupingLoading&&loading">
            <div class="col-12">
                <loading/>
            </div>
        </div>
        <div class="row" v-else>
            <div class="d-none d-md-block col-md-4">
                <panel>
                    <tree-view :items="groupings" :value="groupingActiveId" @select="navigate"/>
                </panel>
            </div>
            <div class="col-12 col-md-8">

                <loading v-if="loading"/>
                <div v-else>
                    <div class="pb-3 pt-3 pt-md-0">
                        <h1>{{title}}</h1>
                        <p v-html="description"></p>
                    </div>

                    <div v-if="0 === products.length">
                        {{t('noAvailableItems')}}
                    </div>

                    <products-list :value="products"
                                   :grouping="groupingType"
                                   :grouping-id="groupingActiveId"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex";

    import mixin_view from "@/mixins/view";
    import mixin_constraints from "@/mixins/constraints";
    import mixin_products from "@/mixins/products";
    import {groupings} from "@/helpers/plp";
    import {sanitize} from "@/helpers/html";
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";
    import {retryRequest} from "@/helpers/request";

    import TreeView from "@/components/TreeView";
    import ProductsList from "@/components/products/ProductsList";
    import Panel from "@/components/Panel";
    import emitter, {EVENTS} from "@/api/events";

    export default {
        name: "ViewsProducts",
        mixins: [mixin_view, mixin_constraints, mixin_products],
        components: {
            Panel,
            ProductsList,
            TreeView
        },
        data() {

            return {
                t_path: "views.products",
                loading: false,
                category: {},
                products: []
            }
        },
        computed: {
            ...mapGetters({
                groupings: "grouping/items",
                groupingActiveId: "grouping/activeId",
                groupingLoading: "grouping/loading",
                groupingType: "grouping/type"
            }),
            title() {

                return getTranslationValue(this.category, "title", this.$i18n)
            },
            description() {

                return sanitize(getTranslationValue(this.category, "description", this.$i18n))
            }
        },
        methods: {
            navigate(id) {

                this.$store.dispatch("grouping/setActiveId", id);
            },
            fetch() {

                this.loading = true;

                return this.$store
                    .dispatch("grouping/fetch", {
                        type: this.$route.params.grouping,
                        activeId: this.$route.params.grouping_id
                    })
                    .then(() => this.load());
            },
            refresh() {

                this.products_updateBreadcrumbsTree(this.$store.getters["grouping/active"]);

                this.view_setTitle(this.title);
            },
            load(groupingId) {

                groupingId = groupingId ? groupingId : this.$route.params.grouping_id;

                return retryRequest(() => this.$platform.get(`/api/v2/grouping/${this.$route.params.grouping}/${groupingId}`))
                    .then(response => {

                        this.category = response.data.results;

                        this.products = groupings.recommendation == this.$route.params.grouping
                            ? response.data.results.products
                            : this.constraints_filterAvailableProducts(response.data.results.products);
                    })
                    .finally(() => this.loading = false);
            }
        },
        watch: {
            "$i18n.locale": function () {

                this.refresh();
            },
            groupingActiveId(groupingActiveId) {

                if (groupingActiveId && groupingActiveId != this.$route.params.grouping_id) {

                    this.$router.replace({params: {grouping_id: groupingActiveId}});

                    this.load(groupingActiveId);

                    this.refresh();
                }
            },
            category() {

                this.refresh();
            }
        },
        mounted() {

            this.fetch();

            emitter.on(EVENTS.USER_CONSTRAINTS_FETCH, this.load);
        },
        beforeUnmount() {

            emitter.off(EVENTS.USER_CONSTRAINTS_FETCH, this.load);
        }
    }
</script>