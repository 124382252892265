export default {
    menu: {
        approvals: "Approvazioni",
        delegates: "Delegati"
    },
    approvals: {
        title: "Approvazione",
        error: "Si è verificato un errore",
        saved: "Approvazioni presentate",
        noApprovals: "Niente da approvare",
        review: "Si prega di rivedere e modificare il centro di costo se errato",
        priorApprovals: "Approvazioni preventive",
        noOrders: "Niente da approvare",
        noPriorApprovals: "Nessuna approvazione preventiva",
        approved: "Approvato",
        notApproved: "Non approvato",
        confirm: "Ci sono ancora ordini con approvazioni in attesa. Desideri continuare con le modifiche attuali?",
        manager: "Manager",
        orders: {
            name: "Nome",
            date: "Data dell'ordine",
            details: "Dettagli dell'ordine",
            cost: "Costo",
            costCenter: "Centro di costo",
            approval: "Approvazione"
        },
        model: {
            reason: {
                placeholder: "Motivo"
            },
            approve: {
                yes: {
                    label: "SÌ"
                },
                no: {
                    label: "NO"
                }
            }
        },
        buttons: {
            submit: {
                text: "Invia",
                processing: "in lavorazione"
            }
        }
    },
    delegates: {
        title: "Delegati",
        error: "Si è verificato un errore",
        noDelegates: "Nessun oggetto",
        delegateDelete: "Il delegato è stato eliminato",
        confirm: {
            delete: "Eliminare delegato?"
        },
        delegates: {
            email: "E-mail",
            startDate: "Data d'inizio",
            endDate: "Data di fine",
        },
        buttons: {
            add: {
                text: "Aggiungi Delegato"
            }
        }
    }
}