export default {
    title: "Položky nedodané",
    header: "@:views.survey.deliveryfaq.title",
    result: {
        header: "Děkuji",
        message: "Děkuji za Vaši odpověď."
    },
    message: "Uvedli jste, že jste zboží neobdrželi, přestože je kurýr označil jako doručené.",
    cta: "Postupujte podle následujících kroků, které nám pomohou problém vyřešit:",
    items: {
        1: {
            message: "Klikněte na sledovací odkaz:"
        },
        2: {
            message: "Zkontrolujte stav doručení. Zkontrolujte:",
            items: {
                1: "Kdo podepsal balíček",
                2: "Pokud byl vrácen odesílateli",
                3: "Pokud zásilka čeká na vyzvednutí"
            }
        },
        3: {
            message: "Zkontrolujte, zda není doručeno oznámení o tom, kde mohla být položka zanechána."
        },
        4: {
            message: "Informujte se u sousedů, příbuzných a přátel, kteří mohli zásilku převzít."
        },
        5: {
            message: "Zkontrolujte společná místa, kde kurýři nechávají věci ve vaší nepřítomnosti."
        }
    },
    question: "Pomohlo to vyřešit váš dotaz na doručení? Máte nyní položku nebo víte, kdo ji má?",
    buttons: {
        yes: "Ano",
        no: "Ne",
        saving: "Ukládání"
    }
}