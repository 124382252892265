export default {
    types: {
        "ac_router": "AC-Router",
        "chair": "Stuhl",
        "desk": "Schreibtisch",
        "desktop": "Desktop",
        "desktop_riser": "Desktop-Riser",
        "detector": "Dampf- und Geräuschdetektor",
        "displayport_cable": "Displayport-Kabel",
        "docking station": "Dockingstation",
        "dual_usb_adapter": "Dualband-USB-Adapter",
        "ear cushion": "Ohrpolster",
        "footrest": "Fußstütze",
        "hdmi_cable": "HDMI Kabel",
        "hdmi_displayport_cable": "HDMI-zu-DisplayPort-Kabel",
        "hdmi_vga_cable": "HDMI-zu-VGA-Kabel",
        "headset": "Headset",
        "keyboard": "Tastatur",
        "kvm switch": "Kvm-Switch",
        "kvm_switch": "Kvm-Switch",
        "lamp": "Lampe",
        "laptop stand": "Laptopständer",
        "laptop": "Laptop",
        "mesh_router": "Mesh-Router",
        "monitor": "Monitor",
        "mouse": "Maus",
        "patch_cord": "Cat6-Patchkabel",
        "power_center": "Kraftzentrum",
        "power_strip": "Steckdosenleiste",
        "smart_plug": "Smart Plug Mini",
        "smart_power_strip": "Intelligente Steckdosenleiste",
        "thunderbolt_cable": "USB-C zu Thunderbolt",
        "travel_router": "Reiserouter",
        "usb hub": "USB-Hub",
        "usb_adapter": "USB-Adapter",
        "usb_hub": "USB-Hub",
        "webcam": "Webcam"
    }
}