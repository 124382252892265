export default {
    header: "Confirme o endereço e envie o pedido",
    message: "Seu endereço de entrega listado abaixo está correto?",
    tip: "Clique <b>em SIM</b> para confirmar o endereço de entrega e enviar o pedido. Clique em <b>NÃO</b> para cancelar o envio e escolha outro endereço.",
    info: "Depois que um pedido é feito, ele não pode ser alterado. Se o produto que você está pedindo estiver em espera ou tiver uma data de envio posterior e você planeja se mudar, leve isso em consideração ao escolher o endereço de entrega. Além disso, se houver um próximo feriado ou férias e você não estiver em casa para receber o produto, pedimos que aguarde a realização de pedidos até que esteja em casa para receber os produtos.",
    warning: {
        message: "Por favor, verifique se o seu {fields} está correto, pois a empresa de transporte pode não conseguir entregar seu pedido ou entrar em contato com você sobre seu pedido. | Por favor, verifique se o seu {fields} estão corretos, pois a empresa de transporte pode não conseguir entregar seu pedido ou entrar em contato com você sobre seu pedido.",
        name: "Nome",
        street: "Rua",
        phone: "Telefone"
    },
    buttons: {
        yes: "Sim",
        no: "Não"
    }
}