export default {
    type: {
        "ac_router": "Osiągnąłeś limit zamówień routerów AC",
        "chair": "Osiągnąłeś limit zamówień krzeseł",
        "default": "Osiągnąłeś limit zamówień",
        "default_multiple": "Osiągnąłeś limit zamówień podobnych produktów",
        "desk": "Osiągnąłeś limit zamówień biurek",
        "desktop": "Osiągnąłeś limit zamówień komputerów stacjonarnych",
        "desktop_riser": "Osiągnąłeś limit zamówień podstawek do komputerów stacjonarnych",
        "detector": "Osiągnąłeś limit zamówień na detektory waporyzacji i hałasu",
        "displayport_cable": "Osiągnąłeś limit zamówień kabli Displayport",
        "docking station": "Osiągnąłeś limit zamówień stacji dokujących",
        "dual_usb_adapter": "Osiągnąłeś limit zamówień na dwuzakresowe adaptery USB",
        "ear cushion": "Osiągnąłeś limit zamówień poduszek nausznych",
        "footrest": "Osiągnąłeś limit zamówień podnóżków",
        "hdmi_cable": "Osiągnąłeś limit zamówień kabli HDMI",
        "hdmi_displayport_cable": "Osiągnąłeś limit zamówień kabli HDMI na DisplayPort",
        "hdmi_vga_cable": "Osiągnąłeś limit zamówień kabli HDMI na VGA",
        "headset": "Osiągnąłeś limit zamówień urządzeń audio",
        "keyboard": "Osiągnąłeś limit zamówień klawiatur",
        "kvm switch": "Osiągnąłeś limit zamówień przełączników kvm",
        "kvm_switch": "Osiągnąłeś limit zamówień przełączników kvm",
        "lamp": "Osiągnąłeś limit zamówień lamp",
        "laptop stand": "Osiągnąłeś limit zamówień stojaków na laptopy",
        "laptop": "Osiągnąłeś limit zamówień laptopów",
        "mesh_router": "Osiągnąłeś limit zamówień routerów mesh",
        "monitor": "Osiągnąłeś limit zamówień monitorów",
        "mouse": "Osiągnąłeś limit zamawiania myszy",
        "patch_cord": "Osiągnąłeś limit zamówień na kable krosowe Cat6",
        "power_center": "Osiągnąłeś limit zamówień Power Centers",
        "power_strip": "Osiągnąłeś limit zamówień listew zasilających",
        "smart_plug": "Osiągnąłeś limit zamówień inteligentnych wtyczek mini",
        "smart_power_strip": "Osiągnąłeś limit zamówień inteligentnych listew zasilających",
        "thunderbolt_cable": "Osiągnąłeś limit zamówień USB-C na Thunderbolt",
        "travel_router": "Osiągnąłeś limit zamówień routerów podróżnych",
        "usb hub": "Osiągnąłeś limit zamówień koncentratorów USB",
        "usb_adapter": "Osiągnąłeś limit zamówień adapterów USB",
        "usb_hub": "Osiągnąłeś limit zamówień koncentratorów USB",
        "webcam": "Osiągnąłeś limit zamówień kamer internetowych"
    }
}