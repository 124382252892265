export default {
    type: {
        "ac_router": "Ha alcanzado el límite de pedidos de enrutadores de CA",
        "chair": "Has alcanzado el límite de pedidos de sillas.",
        "default": "Has alcanzado el límite de pedidos.",
        "default_multiple": "Has alcanzado el límite de pedidos de productos similares.",
        "desk": "Has alcanzado el límite de pedidos de escritorios.",
        "desktop": "Has alcanzado el límite de pedidos de escritorio.",
        "desktop_riser": "Ha alcanzado el límite de pedidos de soportes verticales para escritorio",
        "detector": "Has alcanzado el límite de pedidos de detectores de ruido y vapeo.",
        "displayport_cable": "Ha alcanzado el límite de pedidos de cables Displayport",
        "docking station": "Ha alcanzado el límite de pedidos de estaciones de acoplamiento",
        "dual_usb_adapter": "Ha alcanzado el límite de pedidos de adaptadores USB de doble banda",
        "ear cushion": "Has alcanzado el límite de pedidos de almohadillas para los oídos.",
        "footrest": "Has alcanzado el límite de pedidos de reposapiés.",
        "hdmi_cable": "Ha alcanzado el límite de pedidos de cables HDMI",
        "hdmi_displayport_cable": "Ha alcanzado el límite de pedidos de cables HDMI a DisplayPort",
        "hdmi_vga_cable": "Ha alcanzado el límite de pedidos de cables HDMI a VGA",
        "headset": "Has alcanzado el límite de pedidos de dispositivos de audio.",
        "keyboard": "Has alcanzado el límite de pedidos de teclados.",
        "kvm switch": "Ha alcanzado el límite de pedidos de conmutadores kvm",
        "kvm_switch": "Ha alcanzado el límite de pedidos de conmutadores kvm",
        "lamp": "Has alcanzado el límite de pedidos de lámparas.",
        "laptop stand": "Ha alcanzado el límite de pedidos de soportes para portátiles",
        "laptop": "Has alcanzado el límite de pedidos de portátiles.",
        "mesh_router": "Ha alcanzado el límite de pedidos de enrutadores de malla",
        "monitor": "Has alcanzado el límite de pedidos de monitores.",
        "mouse": "Has alcanzado el límite de pedidos de ratones.",
        "patch_cord": "Ha alcanzado el límite de pedidos de latiguillos Cat6",
        "power_center": "Has alcanzado el límite de pedidos de Power Centers",
        "power_strip": "Has alcanzado el límite de pedidos de regletas.",
        "smart_plug": "Has alcanzado el límite de pedidos de enchufes inteligentes mini",
        "smart_power_strip": "Ha alcanzado el límite de pedidos de regletas inteligentes",
        "thunderbolt_cable": "Ha alcanzado el límite de pedidos de USB-C a Thunderbolt",
        "travel_router": "Has alcanzado el límite de pedidos de enrutadores de viaje",
        "usb hub": "Has alcanzado el límite de pedidos de concentradores USB",
        "usb_adapter": "Has alcanzado el límite de pedidos de adaptadores USB.",
        "usb_hub": "Has alcanzado el límite de pedidos de concentradores USB",
        "webcam": "Has alcanzado el límite de pedidos de cámaras web."
    }
}