export default {
    title: "Podpora",
    header: "Podpora",
    model: {
        email: {
            label: "E-mail uživatele",
            placeholder: "Vyplňte prosím e-mail uživatele"
        }
    },
    cta: "Předložit",
    processing: "načítání"
}