export default {
    "India": "Indië",
    "United States of America": "Verenigde Staten van Amerika",
    "United Kingdom": "Verenigd Koninkrijk",
    "Philippines": "Filippijnen",
    "Australia": "Australië",
    "Spain": "Spanje",
    "Bulgaria": "Bulgarije",
    "Poland": "Polen",
    "Malaysia": "Maleisië",
    "Russian Federation": "Russische Federatie",
    "Ukraine": "Oekraïne",
    "Germany": "Duitsland",
    "Brazil": "Brazilië",
    "Canada": "Canada",
    "Romania": "Roemenië",
    "France": "Frankrijk",
    "China": "China",
    "Italy": "Italië",
    "Costa Rica": "Costa Rica",
    "Mexico": "Mexico",
    "Slovakia": "Slowakije",
    "Vietnam": "Vietnam",
    "Singapore": "Singapore",
    "Argentina": "Argentinië",
    "Denmark": "Denemarken",
    "Japan": "Japan",
    "Belgium": "België",
    "Czech Republic": "Tsjechische Republiek",
    "Netherlands": "Nederland",
    "Switzerland": "Zwitserland",
    "Sweden": "Zweden",
    "New Zealand": "Nieuw-Zeeland",
    "Hungary": "Hongarije",
    "Egypt": "Egypte",
    "Lithuania": "Litouwen",
    "United Arab Emirates": "Verenigde Arabische Emiraten",
    "Ireland": "Ierland",
    "Hong Kong": "Hongkong",
    "Austria": "Oostenrijk",
    "Saudi Arabia": "Saoedi-Arabië",
    "Portugal": "Portugal",
    "Norway": "Noorwegen",
    "Chile": "Chili",
    "Thailand": "Thailand",
    "Taiwan": "Taiwan",
    "Tunisia": "Tunesië",
    "Israel": "Israël",
    "South Africa": "Zuid-Afrika",
    "Colombia": "Colombia",
    "Serbia": "Servië",
    "Luxembourg": "Luxemburg",
    "Peru": "Peru",
    "Finland": "Finland",
    "Brunei Darussalam": "Brunei Darussalam",
    "Korea, Republic of": "Korea, republiek van",
    "Indonesia": "Indonesië",
    "Jordan": "Jordanië",
    "Fiji": "Fiji",
    "Morocco": "Marokko",
    "Nigeria": "Niger",
    "Panama": "Panama",
    "Qatar": "Katar",
    "Greece": "Griekenland",
    "Turkey": "Kalkoen",
    "Croatia": "Kroatië",
    "Kazakhstan": "Kazachstan",
    "Cyprus": "Cyprus"
}