export default {
    header: "Bevestig het adres en verzend de bestelling",
    message: "Klopt uw verzendadres hieronder?",
    tip: "Klik op <b>JA</b> om het verzendadres te bevestigen en de bestelling te plaatsen. Klik op <b>NEE</b> om de indiening te annuleren en een ander adres te kiezen.",
    info: "Een eenmaal geplaatste bestelling kan niet meer worden gewijzigd. Als het product dat u bestelt een nabestelling heeft of een latere verzenddatum heeft en u van plan bent te verhuizen, houd hier dan rekening mee bij het kiezen van het verzendadres. Bovendien, als er een aankomende feestdag of vakantie is en u niet thuis bent om het product in ontvangst te nemen, vragen we u te wachten met het plaatsen van bestellingen totdat u thuis bent om de producten te ontvangen.",
    warning: {
        message: "Controleer nogmaals of uw {fields} klopt, aangezien het transportbedrijf mogelijk niet in staat is om uw bestelling af te leveren of contact met u op te nemen over uw bestelling. | Controleer nogmaals of uw {fields} correct zijn aangezien het transportbedrijf mogelijk niet in staat is om uw bestelling af te leveren of contact met u op te nemen over uw bestelling.",
        name: "Naam",
        street: "Straat",
        phone: "Telefoon"
    },
    buttons: {
        yes: "Ja",
        no: "Nee"
    }
}