<template>
    <form @submit.prevent="base_submit('model', 'submit', 'processing')">
        <div class="modals-modal-header">
            {{t('title')}}
        </div>
        <div class="modals-modal-body pt-4">
            <div class="row">
                <div class="col-12">
                    <form-textbox v-model="model.email"
                                  :textbox-class="'form-control form-control-sm'"
                                  :validator="v$.model.email"
                                  :label="t('model.email.label')"
                                  :placeholder="t('model.email.placeholder')"
                                  :disabled="true"/>

                    <form-textbox v-model="model.name"
                                  :validator="v$.model.name"
                                  :textbox-class="'form-control form-control-sm'"
                                  :label="t('model.name.label')"
                                  :placeholder="t('model.name.placeholder')"
                                  :disabled="processing"/>

                    <form-select v-model="model.country"
                                 :validator="v$.model.country"
                                 :select-class="'form-control form-control-sm'"
                                 :label="t('model.country.label')"
                                 :options="countries"
                                 :option-value="'value'"
                                 :option-name="'name'"
                                 :disabled="processing"/>
                </div>
            </div>
        </div>

        <div class="modals-modal-footer">
            <button type="submit" class="btn btn-primary" :disabled="processing">
                <span v-if="true === processing">
                    <spinner/>
                    {{t('cta.submit.processing')}}
                </span>
                <span v-else>
                    {{t('cta.submit.text')}}
                </span>
            </button>

            <button type="button" class="btn btn-primary" @click="$emit('close')">{{t('cta.cancel.text')}}</button>
        </div>
    </form>
</template>

<script>

    import useVuelidate from "@vuelidate/core";
    import {email, required} from "@vuelidate/validators";

    import mixin_auth from "@mixins_auth";

    import FormTextbox from "@/components/form/FormTextbox";
    import FormSelect from "@/components/form/FormSelect";
    import Spinner from "@/components/Spinner";

    export default {
        name: "ModalImpersonationUserCreate",
        mixins: [mixin_auth],
        components: {
            Spinner,
            FormTextbox,
            FormSelect
        },
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        props: {
            email: {
                type: String,
                required: true
            }
        },
        data() {

            return {
                t_path: "modal.impersonation.user.create",
                processing: false,
                model: {
                    email: this.email,
                    name: null,
                    country: null
                }
            }
        },
        computed: {
            countries() {

                return this.$store.getters["db/countries/items"].map(country => ({
                    name: country.name,
                    value: country.alpha3
                }));
            }
        },
        validations() {

            return {
                model: {
                    email: {
                        required,
                        email
                    },
                    name: {
                        required
                    },
                    country: {
                        required
                    }
                }
            }
        },
        methods: {
            submit() {

                this.$store
                    .dispatch("user/impersonation/createUser", this.model)
                    .then(user => this.$emit("save", user))
                    .catch(error => this.base_error(error))
                    .finally(() => this.processing = false);
            }
        }
    }
</script>