export default {
    user: {
        create: {
            title: "Nowy użytkownik",
            model: {
                email: {
                    label: "E-mail",
                    placeholder: "Wypełnij e-mail"
                },
                name: {
                    label: "Nazwa",
                    placeholder: "Wypełnij nazwę"
                },
                country: {
                    label: "Kraj",
                    placeholder: "Wypełnij kraj"
                }
            },
            cta: {
                submit: {
                    text: "Składać",
                    processing: "Przedkładający"
                },
                cancel: {
                    text: "Anulować"
                }
            }
        }
    }
}