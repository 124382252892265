export default {
    types: {
        "ac_router": "AC Router",
        "chair": "Chair",
        "desk": "Desk",
        "desktop": "Desktop",
        "desktop_riser": "Desktop Riser",
        "detector": "Vape and noise detector",
        "displayport_cable": "Displayport Cable",
        "docking station": "Docking Station",
        "dual_usb_adapter": "Dual Band USB Adapter",
        "ear cushion": "Ear Cushion",
        "footrest": "Footrest",
        "hdmi_cable": "HDMI Cable",
        "hdmi_displayport_cable": "HDMI to DisplayPort Cable",
        "hdmi_vga_cable": "HDMI to VGA Cable",
        "headset": "Headset",
        "keyboard": "Keyboard",
        "kvm switch": "Kvm Switch",
        "kvm_switch": "Kvm Switch",
        "lamp": "Lamp",
        "laptop stand": "Laptop Stand",
        "laptop": "Laptop",
        "mesh_router": "Mesh router",
        "monitor": "Monitor",
        "mouse": "Mouse",
        "patch_cord": "Cat6 Patch Cord",
        "power_center": "Power Center",
        "power_strip": "Power Strip",
        "smart_plug": "Smart Plug mini",
        "smart_power_strip": "Smart power strip",
        "thunderbolt_cable": "USB-C to Thunderbolt",
        "travel_router": "Travel router",
        "usb hub": "Usb Hub",
        "usb_adapter": "USB adapter",
        "usb_hub": "Usb Hub",
        "webcam": "Webcam"
    }
}