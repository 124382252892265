export default {
    title: "Anmeldebestätigung erforderlich",
    header: "@:views.auth.confirm.title",
    message: "Um fortzufahren, überprüfen Sie bitte Ihre Unternehmens-E-Mail auf einen E-Mail-Bestätigungslink. Klicken Sie auf den Link in der E-Mail, um fortzufahren.",
    messageBeforeCta: "Wenn Sie die E-Mail nicht sehen, klicken Sie auf",
    messageAfterCta: "erneut senden.",
    secondMessage: "Wenn Sie die E-Mail immer noch nicht sehen, überprüfen Sie Ihren Spam-Ordner oder kontaktieren Sie uns unter <a href='mailto:{email}?subject={subject}'>{email}</a> .",
    subject: "Ausrüstung - TFA",
    buttons: {
        cta: "Hier",
        processing: "Hier"
    },
    headerAlreadyConfirmed: "Anmeldebestätigung",
    messageAlreadyConfirmed: "Sie haben Ihre E-Mail bereits bestätigt."
}