export default {
    home: "itthon",
    order: {
        submit: "Megrendelés áttekintése"
    },
    tunnel: {
        loginVerification: "Bejelentkezés ellenőrzése",
        addressVerification: "Cím ellenőrzése"
    },
    catalog: "Katalógus",
    all: "Minden",
    return: {
        return: "Visszatérés"
    },
    errors: {
        404: "@:views.errors.404.title"
    },
    impersonation: "Rendelés a nevében"
}