import components from "./components";
import confirm from "./confirm";
import orderSubmitConfirm from "./orderSubmitConfirm";
import orderOutOfStock from "./orderOutOfStock";
import quizProfile from "./quizProfile";
import approvalDelegateEditor from "./approvalDelegateEditor";
import impersonation from "./impersonation";

export default {
    components,
    confirm,
    orderSubmitConfirm,
    quizProfile,
    orderOutOfStock,
    approvalDelegateEditor,
    impersonation
}