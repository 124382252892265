export default {
    model: {
        email: {
            label: "E-mail",
            placeholder: "E-mail du délégué"
        },
        start_on: {
            label: "Date de début",
            placeholder: "Date de début de la délégation"
        },
        end_on: {
            label: "Date de fin",
            placeholder: "Facultatif"
        },
        accept: {
            tip: "En déléguant votre responsabilité d'approbation, vous certifiez que vous assumez la responsabilité de toute commande approuvée par votre délégué.",
            label: "J'accepte"
        }
    },
    buttons: {
        submit: {
            cta: "Confirmer",
            processing: "Économie"
        },
        close: {
            cta: "Annuler"
        }
    },
    saved: "Enregistré"
}