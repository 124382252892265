export default {
    menu: {
        approvals: "Zulassungen",
        delegates: "Delegierte"
    },
    approvals: {
        title: "Genehmigung",
        error: "Ein Fehler ist aufgetreten",
        saved: "Genehmigungen eingereicht",
        noApprovals: "Nichts zu genehmigen",
        review: "Bitte überprüfen und bearbeiten Sie die Kostenstelle, falls sie falsch ist",
        priorApprovals: "Vorherige Genehmigungen",
        noOrders: "Nichts zu genehmigen",
        noPriorApprovals: "Keine vorherigen Genehmigungen",
        approved: "Genehmigt",
        notApproved: "Nicht bestätigt",
        confirm: "Es gibt noch Bestellungen mit ausstehenden Genehmigungen. Möchten Sie mit Ihren aktuellen Änderungen fortfahren?",
        manager: "Manager",
        orders: {
            name: "Name",
            date: "Auftragsdatum",
            details: "Bestelldetails",
            cost: "Kosten",
            costCenter: "Kostenstelle",
            approval: "Genehmigung"
        },
        model: {
            reason: {
                placeholder: "Grund"
            },
            approve: {
                yes: {
                    label: "Ja"
                },
                no: {
                    label: "NEIN"
                }
            }
        },
        buttons: {
            submit: {
                text: "Einreichen",
                processing: "wird bearbeitet"
            }
        }
    },
    delegates: {
        title: "Delegierte",
        error: "Ein Fehler ist aufgetreten",
        noDelegates: "Keine Gegenstände",
        delegateDelete: "Der Delegierte wurde gelöscht",
        confirm: {
            delete: "Delegierter löschen?"
        },
        delegates: {
            email: "Email",
            startDate: "Startdatum",
            endDate: "Endtermin",
        },
        buttons: {
            add: {
                text: "Delegierten hinzufügen"
            }
        }
    }
}