export default {
    title: "Zamówienie w imieniu",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Proszę wypełnić adres e-mail użytkownika, aby złożyć zamówienie w imieniu",
            errors: {
                myself: "Adres e-mail musi różnić się od Twojego"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Składać",
            processing: "Przedkładający"
        }
    },
    error: {
        userNotExists: "{email} nie istnieje"
    }
}