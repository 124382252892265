export default {
    login: "Conecte-se",
    logout: "Sair",
    home: "Lar",
    user: {
        profile: {
            edit: "Editar Perfil"
        }
    },
    approvals: {
        delegates: "Delegados"
    },
    impersonation: "Encomende em nome de"
}