export default {
    status: {
        acknowledged: "Besteld",
        created: "Gemaakt",
        sent: "Besteld",
        confirmed: "Besteld",
        not_confirmed: "Niet besteld",
        delivery_email: "verzonden",
        shipped: "verzonden",
        delayed: "Vertraagd",
        preparing: "voorbereidingen treffen",
        processing: "Verwerken",
        processed: "Verwerkt",
        submitted: "Besteld",
        delivery_not_received: "verzonden",
        shipment_exception: "verzonden",
        pending_approval: "In behandeling",
        approved: "Goedgekeurd",
        not_approved: "Niet goedgekeurd",
        canceled: "Geannuleerd",
        returned: "Geretourneerd",
        lost_shipment: "Verloren zending",
        delivery_not_confirmed: "Afgeleverd",
        delivered: "Afgeleverd",
        delivery_received: "Afgeleverd",
        refurbished: "Retour voltooid",
        returned_to_office: "Terug naar kantoor",
        return_to_employer: "Teruggestuurd naar werkgever",
        return_pickup_scheduled: "Afhalen gepland",
        return_pickup_completed: "Ophalen voltooid",
        return_pickup_excluded: "Afhalen uitgesloten",
        return_pickup_submitted: "Ophalen ingediend",
        return_pickup_finalized: "Ophalen afgerond",
        disposed: "verwijderd",
        damaged: "Beschadigd"
    }
}