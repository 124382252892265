export default {
    home: "Home",
    order: {
        submit: "Review Order"
    },
    tunnel: {
        loginVerification: "Login Verification",
        addressVerification: "Address Verification"
    },
    catalog: "Catalog",
    all: "All",
    return: {
        return: "Return"
    },
    errors: {
        404: "@:views.errors.404.title"
    },
    impersonation: "Order on behalf of"
}