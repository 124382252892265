export default {
    return: {
        title: "Retornar",
        header: "Itens a serem devolvidos",
        message: "Os seguintes equipamentos da Empresa devem ser devolvidos. Depois de enviar este formulário, você será contatado separadamente para organizar a coleta e/ou remessas. Seu gerente será informado sobre o status de sua devolução.",

        model: {
            address: {
                country: {
                    label: "País"
                }
            },
            exclude: {
                label: "Excluir da devolução"
            },
            textarea: {
                placeholder: "Motivo: aprovação do gerente, devolvido anteriormente etc."
            },
            return_note: {
                label: "Notas de Devolução",
                placeholder: "Opcional: Insira quaisquer observações sobre os itens a serem devolvidos."
            },
            additional_disposal: {
                label: "Você tem algum registro DXC físico que precise ser descartado com segurança?"
            },
            additional_item: {
                type: {
                    label: "Tipo",
                    options: {
                        "chair": "Cadeira",
                        "desk": "Mesa",
                        "desk riser": "Elevador de mesa",
                        "docking station": "Estação de ancoragem",
                        "footrest": "apoio para os pés",
                        "headset": "Fone de ouvido",
                        "hdmi cable": "Cabo HDMI",
                        "keyboard": "Teclado",
                        "kvm switch": "Chave KVM",
                        "lamp": "Lâmpada",
                        "laptop riser": "Suporte para laptop",
                        "monitor": "Monitor",
                        "mouse": "Rato",
                        "other": "Outro",
                        "speakerphone": "Viva voz",
                        "usb hub": "Hub USB",
                        "webcam": "Webcam",
                    }
                },
                detail: {
                    label: "Detalhe",
                    placeholder: "Detalhe"
                },
                quantity: {
                    label: "Quantidade",
                    placeholder: "Quantidade"
                }
            }
        },

        submitted: {
            title: "Devolução Enviada",
            message: "Seu retorno foi enviado. Você será contatado separadamente sobre a coleta e/ou envio do item."
        },
        nothingToReturn: {
            title: "Informações de devolução",
            message: "No momento, você não tem nenhum item para devolver no Gear."
        },
        notEligible: {
            title: "Informações de devolução",
            message: "Neste momento, o Gear não pode processar sua devolução. Entre em contato com seu gerente local ou equipe de RH sobre como devolver seus itens."
        },

        button: {
            add: {
                cta: "Adicionar item não listado"
            },
            submit: {
                processing: "Enviando",
                cta: "Enviar"
            }
        }
    }
}