export default {
    view: {
        success: {
            header: "Průzkum předložen",
            message: "Děkujeme vám za vaši reakci."
        },
        productChoice: {
            cta: "Uložit",
            processing: "Ukládání"
        },
        addressForm: {
            cta: "Uložit",
            processing: "Ukládání"
        }
    },
    delivery: {
        title: "Průzkum doručení",
        message: "Obdrželi jste všechny položky uvedené níže?",
        cta: {
            yes: "Ano",
            no: "Ne",
            processing: "Ukládání"
        }
    }
}