export default {
    title: "Besteloverzicht",
    header: "@:views.order.submitted.title",

    noItems: "Geen geselecteerde producten",

    model: {
        addressId: {
            errors: {
                required: "Vul het adres in",
                noAddress: "Er is geen adres beschikbaar. Neem contact op met de ondersteuning."
            }
        },
        product: {
            placeholder: "Vul het bedrag in"
        }
    },

    createNewAddress: "Nieuw adres aanmaken",

    button: {
        submit: {
            cta: "Bevestig bestelling",
            processing: "Indienen"
        },
        remove: "Verwijderen uit bestelling"
    },

    errors: {
        itemNotExist: "Artikel «{name}» bestaat niet in de catalogus. Het zal worden verwijderd.",
        requestError: "Er is een probleem opgetreden bij het verzenden van uw bestelling. Probeer het opnieuw in te dienen."
    }
}