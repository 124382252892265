export default {
    login: "登录",
    logout: "登出",
    home: "家",
    user: {
        profile: {
            edit: "编辑个人资料"
        }
    },
    approvals: {
        delegates: "代表们"
    },
    impersonation: "代订"
}