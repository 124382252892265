export default {
    title: "Mesa de ayuda",
    header: "Mesa de ayuda",
    model: {
        email: {
            label: "Correo electrónico del usuario",
            placeholder: "Por favor complete el correo electrónico del usuario"
        }
    },
    cta: "Entregar",
    processing: "Cargando"
}