export default {
    error: "There was an issue retrieving orders. Please refresh your browser.",
    meta: {
        default: "Orders",
        my_orders: "My Orders",
        my_gear: "Received"
    },
    loadMore: "Load More",
    noOrders: "No Orders",
    order: {
        product: {
            title: "Order",
            tracking: "Tracking",
            quantity: "Quantity",
            control: {
                cancel: {
                    cta: "Click&nbsp;to&nbsp;cancel",
                    tooltip: "Click to cancel this item",
                    message: "We have cancelled your order."
                },
                cancelRequest: {
                    cta: "Request to cancel",
                    tooltip: "Click to submit a cancellation request"
                },
                received: {
                    cta: "Mark as received",
                    tooltip: "Click to confirm delivery",
                    message: "Thank you for confirming that you received your product."
                },
                notReceived: {
                    cta: "Mark as not received",
                    tooltip: "Click to confirm non-delivery"
                },
                damaged: {
                    cta: "Mark as damaged/broken",
                    tooltip: "Click to create support ticket"
                },
                hide: {
                    cta: "Hide item",
                    tooltip: "Click to hide item from the list"
                }
            },
            requestToCancelInfo: "We will contact the supplier to request a cancellation. Please note that this is a request only and cancellation may not be possible. Once we can determine if it can be canceled or not, we will let you know.",
            cancelOk: "Ok",
            reason: "Reason",
            orderedBy: "Ordered by",
            model: {
                cancel: {
                    reason: {
                        label: "Reasons for cancellation",
                        placeholder: "Reasons for cancellation"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Submit",
                        processing: "Submitting"
                    },
                    cancel: {
                        cta: "Cancel"
                    }
                }
            }
        }
    },
    help: {
        text: "Have questions on your order?<br/>See our <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>Support Center</b></a> for help",
        email: {
            subject: ""
        }
    }
}