export default {
    title: "Artikelen niet bezorgd",
    header: "@:views.survey.deliveryfaq.title",
    result: {
        header: "Bedankt",
        message: "Bedankt voor je reactie."
    },
    message: "U heeft aangegeven dat u uw artikel niet heeft ontvangen ondanks dat de koerier het als bezorgd heeft gemarkeerd.",
    cta: "Volg deze stappen om ons te helpen het probleem op te lossen:",
    items: {
        1: {
            message: "Klik op de trackinglink:"
        },
        2: {
            message: "Controleer de bezorgstatus. Controleren op:",
            items: {
                1: "Wie heeft getekend voor het pakket",
                2: "Indien teruggestuurd naar afzender",
                3: "Als het pakket wacht om opgehaald te worden"
            }
        },
        3: {
            message: "Controleer op een afleverbericht dat aangeeft waar het item mogelijk is achtergelaten."
        },
        4: {
            message: "Neem contact op met buren, familieleden en vrienden die het pakket mogelijk hebben meegenomen."
        },
        5: {
            message: "Controleer gemeenschappelijke plaatsen waar koeriers items achterlaten tijdens uw afwezigheid."
        }
    },
    question: "Heeft dit geholpen om uw leveringsvraag op te lossen? Heb je het item nu of weet je wie het heeft?",
    buttons: {
        yes: "Ja",
        no: "Nee",
        saving: "Besparing"
    }
}