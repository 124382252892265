const text = {
    countryAfterComponentsBeforeSelection: "Notez que votre choix de pays sera utilisé dans le cadre de votre adresse de livraison pour vous envoyer les produits directement. Si vous ne voyez pas votre pays dans la liste, Gear n'est actuellement pas disponible dans votre pays.",
    countryAfterComponentsAllowedSelection: "Notez que votre choix de pays sera utilisé dans le cadre de votre adresse de livraison pour vous envoyer les produits directement.",
    countryAfterComponentsLimitedSelection: "Gear est actuellement disponible dans votre pays, mais il s'ouvre par étapes. À moins que vous n'ayez reçu une notification, vous ne pourrez peut-être pas accéder à Gear pour le moment dans votre pays.",
    disclaimer: "En cliquant sur le bouton suivant ci-dessous, je reconnais que je suis un employé de la Société et j'accepte que la Société et son partenaire RemoteRetail stockent et traitent les informations personnelles soumises sur ce site pour me fournir le contenu demandé. RemoteRetail a besoin des informations de contact que je fournis afin qu'ils puissent me contacter avec des informations relatives à mes commandes. Des fournisseurs tiers peuvent également recevoir mes informations afin de m'expédier des produits.</p><p> Je peux me désinscrire de ces communications à tout moment. Pour plus d'informations sur la façon dont je peux me désinscrire, les pratiques de confidentialité et les engagements de protection et de respect de ma vie privée, veuillez consulter la <a href='{privacyPolicyUrl}' target='_blank'>politique de confidentialité</a> ."
};

export default {
    title: "Bienvenue, {userName} !",
    description: {
        default: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`,
        virtualworker: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories that you need to work effectively from home. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`
    },
    text: {
        default: "Pour commencer, nous allons vous poser une série de questions.",
        virtualworker: "Pour commencer, nous vous poserons une série de questions pour nous aider à comprendre votre espace de travail à domicile afin de façonner l'orientation future de ce programme."
    },
    afterComponents: {
        beforeSelection: `${text.countryAfterComponentsBeforeSelection}`,
        allowedSelection: `${text.countryAfterComponentsAllowedSelection}`,
        limitedSelection: `${text.countryAfterComponentsLimitedSelection}`,
        autoLoginBeforeSelection: `${text.countryAfterComponentsBeforeSelection}<br/><br/>${text.disclaimer}`,
        autoLoginAllowedSelection: `${text.countryAfterComponentsAllowedSelection}<br/><br/>${text.disclaimer}`,
        autoLoginLimitedSelection: `${text.countryAfterComponentsLimitedSelection}<br/><br/>${text.disclaimer}`
    },
    buttons: {
        back: "Dos",
        next: "Suivant",
        submit: "Soumettre"
    },
    entityinfo: {
        title: "Informations sur l'entité",
        description: "Pour nous aider à acheminer correctement votre commande, veuillez nous fournir les informations suivantes :",
    },
    equipmentsurvey: {
        title: "Enquête sur l'équipement",
        description: "La page suivante vous posera des questions sur le type d'équipement dont vous avez le plus besoin pour aider l'entreprise à mieux comprendre les priorités.",
        info: "Veuillez noter que l'équipement montré est uniquement à des fins de collecte d'informations et n'implique pas que la Société fournira cet équipement à l'avenir."
    },
    workplace: {
        title: "Parlez-nous de votre bureau à domicile?",
        description: "Pour nous aider à mieux comprendre votre espace de travail, veuillez sélectionner l'un des choix ci-dessous",
    },
    priority: {
        title: "Priorités d'équipement",
        description: "Veuillez sélectionner vos deux premiers choix d'équipement dont vous avez le plus besoin"
    },
    "additional-questions": {
        title: "Questions supplémentaires"
    },
    "equipment-assessment": {
        title: "Évaluation de l'équipement",
        description: "La série de questions suivante <b>déterminera quel équipement vous pourrez commander dans Gear en fonction de vos réponses.</b>"
    },
    headset: {
        title: "Équipement actuel"
    },
    "office-worker": {
        title: "Bureau"
    },
    "keyboard-mouse": {
        title: "Clavier et Souris"
    },
    webcam: {
        title: "Webcam"
    },
    monitor: {
        title: "Moniteur"
    },
    usbhub: {
        title: "Concentrateur USB"
    },
    "equipment-list": {
        title: "Équipement",
        init: "initEquipmentList",
        description: {
            loading: "<span class=\"animate-spin icon-spinner\"></span>",
            error: "Mauvaise demande. Une erreur s'est produite.",
            allowed: "En fonction de vos réponses, vous serez éligible pour les éléments suivants :{items}",
            empty: "D'après vos réponses, vous ne serez actuellement éligible à aucun article dans Gear."
        },
        text: "<ul class=\"text-left\" style=\"width: 300px; margin: 2rem auto 0\">{list}</ul>",
        afterComponents: "<small>Si vous devez apporter des corrections, veuillez appuyer sur le bouton de retour pour modifier vos réponses.</small>"
    },
    saved: "Enregistré"
}