<template>
    <div class="address-selector">

        <div class="label">{{t('select')}}</div>

        <div class="addresses">

            <div v-for="address in addresses" :key="address._id" class="address">

                <form-radio :value="address._id"
                            :model="modelValue"
                            :name="'radio-address-selector'"
                            :label-class="'form-check-label d-block w-100'"
                            :disabled="disabled||address.tfaNeedAddressVerification"
                            @input="$emit('update:modelValue', $event)">

                    <address-viewer :disabled="disabled"
                                    :show-delete-button="!denyAddressCreation"
                                    :show-tfa-button="true"
                                    :tags="tags"
                                    :value="address"/>
                </form-radio>
            </div>
        </div>
    </div>
</template>

<script>

    import {mapGetters} from "vuex"

    import AddressViewer from "./AddressViewer";
    import AddressForm from "./AddressForm";
    import FormRadio from "../form/FormRadio";

    export default {
        name: "AddressSelector",
        components: {
            FormRadio,
            AddressViewer,
            AddressForm
        },
        props: {
            modelValue: {},
            tags: {
                type: Array,
                default: () => []
            },
            disabled: Boolean
        },
        emits: ["update:modelValue"],
        data() {

            return {
                t_path: "components.address.selector"
            }
        },
        computed: {
            ...mapGetters({
                addresses: "user/address/items",
                denyAddressCreation: "user/address/denyAddressCreation"
            })
        },
        watch: {
            addresses: {
                immediate: true,
                deep: true,
                handler(addresses) {

                    const defaultAddress = addresses.find(a => !a.tfaNeedAddressVerification);

                    if (!this.modelValue && defaultAddress) {

                        this.$emit("update:modelValue", defaultAddress._id);
                    } else if (this.modelValue) {

                        const selectedAddress = addresses.find(a => a._id == this.modelValue);;

                        if (!selectedAddress) {

                            this.$emit("update:modelValue", null);
                        }
                    }
                }
            }
        }
    }
</script>