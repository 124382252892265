import {requiredIf} from "@vuelidate/validators"

import mixin_quiz_conditions from "./conditions.mjs";

export default {
    mixins: [mixin_quiz_conditions],
    methods: {
        quiz_validation_createValidationRules(quiz, pageComponents) {

            return pageComponents.reduce((model, pageComponent) => {

                // By default empty validation rule
                model[pageComponent.name] = {};

                // Get original quiz component from config file
                const component = quiz.components[pageComponent.name];

                if (component.validation) {

                    if (component.validation.required) {

                        // Component is required just in case component is displayed on the page
                        model[pageComponent.name].required = requiredIf(() => this.components.find(c => c.name == pageComponent.name))
                    }

                    if (component.validation.requiredIf) {

                        model[pageComponent.name].required = requiredIf(() => this.quiz_conditions_match(quiz, component.validation.requiredIf));
                    }

                    if (component.validation.requiredUnless) {

                        model[pageComponent.name].required = requiredIf(() => !this.quiz_conditions_match(quiz, component.validation.requiredIf));
                    }

                }

                return model;
            }, {});
        }
    }
}