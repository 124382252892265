export default {
    error: "Ocorreu um problema ao recuperar pedidos. Atualize seu navegador.",
    meta: {
        default: "Pedidos",
        my_orders: "minhas ordens",
        my_gear: "Recebido"
    },
    loadMore: "Carregue mais",
    noOrders: "Sem pedidos",
    order: {
        product: {
            title: "Ordem",
            tracking: "Monitorando",
            quantity: "Quantidade",
            control: {
                cancel: {
                    cta: "Clique para cancelar",
                    tooltip: "Clique para cancelar este item",
                    message: "Cancelamos seu pedido."
                },
                cancelRequest: {
                    cta: "Solicitação de cancelamento",
                    tooltip: "Clique para enviar uma solicitação de cancelamento"
                },
                received: {
                    cta: "Marcar como recebido",
                    tooltip: "Clique para confirmar a entrega",
                    message: "Obrigado por confirmar que recebeu seu produto."
                },
                notReceived: {
                    cta: "Marcar como não recebido",
                    tooltip: "Clique para confirmar a não entrega"
                },
                damaged: {
                    cta: "Marcar como danificado/quebrado",
                    tooltip: "Clique para criar ticket de suporte"
                },
                hide: {
                    cta: "Ocultar item",
                    tooltip: "Clique para ocultar o item da lista"
                }
            },
            requestToCancelInfo: "Entraremos em contato com o fornecedor para solicitar o cancelamento. Observe que esta é apenas uma solicitação e o cancelamento pode não ser possível. Assim que pudermos determinar se ele pode ser cancelado ou não, avisaremos você.",
            cancelOk: "OK",
            reason: "Razão",
            orderedBy: "Ordenado por",
            model: {
                cancel: {
                    reason: {
                        label: "Razões para cancelamento",
                        placeholder: "Razões para cancelamento"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Enviar",
                        processing: "Enviando"
                    },
                    cancel: {
                        cta: "Cancelar"
                    }
                }
            }
        }
    },
    help: {
        text: "<p>Tem dúvidas sobre seu pedido?<br/> Consulte nosso <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>Centro de Suporte</b></a> para obter ajuda</p><p> Você precisa de um produto não listado?<br/> Clique <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>aqui</b></a> para enviar uma sugestão</p>",
        email: {
            subject: ""
        }
    }
}