<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 col-lg-8 order-md-1">
                    <h3>{{t('title')}}</h3>

                    <form @submit.prevent="base_submit('model', 'submit', 'processing')">
                        <form-textbox-group v-model="model.email"
                                            :label="t('model.email.label')"
                                            :placeholder="t('model.email.placeholder')"
                                            :textbox-class="'form-control'"
                                            :disabled="processing"
                                            :errors="{myself:t('model.email.errors.myself')}"
                                            :validator="v$.model.email">
                            <template #after>
                                <button type="submit" class="btn btn-sm btn-primary" :disabled="processing">
                                    <spinner v-if="true === processing"/>
                                    <span v-if="true === processing">{{t('cta.impersonate.processing')}}</span>
                                    <span v-else>{{t('cta.impersonate.cta')}}</span>
                                </button>
                            </template>
                        </form-textbox-group>
                    </form>
                </div>
                <div class="col-12 col-md-6 col-lg-4 order-md-0">
                    <panel>
                        <impersonation-orders-history/>
                    </panel>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    import {email, required} from "@vuelidate/validators";
    import useVuelidate from "@vuelidate/core";
    import get from "lodash/get.js";

    import mixin_view from "@/mixins/view";
    import mixin_auth from "@mixins_auth";
    import {PERMISSIONS, IMPERSONATION_TOKEN_LIFETIME_HOURS} from "@/constants";

    import Spinner from "@/components/Spinner";
    import FormTextboxGroup from "@/components/form/FormTextboxGroup";
    import ModalImpersonationUserCreate from "@/modal/ModalImpersonationUserCreate";
    import ImpersonationOrdersHistory from "@/components/impersonation/ImpersonationOrdersHistory";
    import Panel from "@/components/Panel";

    export default {
        name: "ViewsImpersonation",
        mixins: [mixin_view, mixin_auth],
        components: {
            Panel,
            ImpersonationOrdersHistory,
            FormTextboxGroup,
            Spinner
        },
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        data() {

            return {
                t_path: "views.impersonation",
                loading: false,
                processing: false,
                model: {
                    email: null
                }
            };
        },
        computed: {
            permissions() {

                return {
                    user: {
                        create: this.$store.getters["user/permissions/hasPermission"](PERMISSIONS.CREATE_USER)
                    }
                }
            }
        },
        validations() {

            return {
                model: {
                    email: {
                        required,
                        email,
                        myself: value => true === this.processing || value !== this.$store.getters["user/profile/email"]
                    }
                }
            }
        },
        methods: {
            submit() {

                this.$store
                    .dispatch("user/impersonation/getUser", this.model.email)
                    .then(user => this.impersonate(user.id))
                    .catch(error => {

                        this.processing = false

                        if (404 === get(error, "response.status") && true === this.permissions.user.create) {

                            return this.$modal
                                .show({
                                    component: ModalImpersonationUserCreate,
                                    props: {
                                        email: this.model.email
                                    }
                                })
                                .then(user => this.impersonate(user.id), () => true);
                        }

                        return this.$toast.error(this.t("error.userNotExists", {email: this.model.email}));
                    });
            },
            impersonate(userId) {

                return this.$store
                    .dispatch("user/impersonation/impersonate", userId)
                    .then(
                        token => this.auth_impersonate(
                            token,
                            userId,
                            [],
                            {
                                permissions: [PERMISSIONS.EMPLOYEE],
                                restrictions: []
                            },
                            false,
                            IMPERSONATION_TOKEN_LIFETIME_HOURS * 3600 * 1000 // 1 day
                        )
                    )
                    .catch(error => this.base_error(error));
            }
        }
    }
</script>