export default {
    view: {
        success: {
            header: "Felmérés elküldve",
            message: "Köszönjük a visszajelzést."
        },
        productChoice: {
            cta: "Megment",
            processing: "Megtakarítás"
        },
        addressForm: {
            cta: "Megment",
            processing: "Megtakarítás"
        }
    },
    delivery: {
        title: "Szállítási felmérés",
        message: "Megkaptad az összes alább felsorolt terméket?",
        cta: {
            yes: "Igen",
            no: "Nem",
            processing: "Megtakarítás"
        }
    }
}