export default {
    title: "Bestellung im Namen von",
    model: {
        email: {
            label: "Email",
            placeholder: "Bitte geben Sie die E-Mail-Adresse des Benutzers ein, um im Namen von zu bestellen",
            errors: {
                myself: "Die E-Mail-Adresse muss von Ihrer abweichen"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Einreichen",
            processing: "Einreichen"
        }
    },
    error: {
        userNotExists: "{email} ist nicht vorhanden"
    }
}