export default {
    title: "Lieferbestätigung",
    header: "@:views.survey.delivery.title",
    result: {
        header: "Danke",
        message: "Danke für Ihre Antwort."
    },
    status: {
        yes: "Bitte überprüfen Sie die unten markierten Produkte, um die Lieferung zu bestätigen, und deaktivieren Sie die Produkte, die Sie nicht erhalten haben. Klicken Sie dann zur Bestätigung auf „Senden“.",
        no: "Bitte stellen Sie sicher, dass Sie keines der unten aufgeführten Produkte erhalten haben. Wenn Sie eines dieser Produkte erhalten haben, aktivieren Sie bitte das Kontrollkästchen neben den erhaltenen Produkten und klicken Sie zur Bestätigung auf „Senden“."
    },
    model: {
        checked: {
            label: "Erhalten"
        }
    },
    button: {
        submit: {
            cta: "Einreichen",
            processing: "Einreichen"
        }
    }
}