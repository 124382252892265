import mixin_quiz_values from "./values.mjs";

export default {
    mixins: [mixin_quiz_values],
    methods: {
        quiz_conditions_match(quiz, dependency) {

            if ("boolean" === typeof dependency && true === dependency) {

                return true;
            }

            if (Array.isArray(dependency)) {

                return this._quiz_conditions_matchAnd(quiz, dependency);
            }

            if ("object" === typeof dependency) {

                return this._quiz_conditions_matchOr(quiz, dependency);
            }

            return false;
        },
        _quiz_conditions_matchAnd(quiz, dependency) {

            const total = dependency.length;

            const matched = dependency.reduce((matches, chainDependency) => {

                if ("object" === typeof chainDependency) {

                    if (this._quiz_conditions_matchOr(quiz, chainDependency)) {

                        matches++;
                    }
                }

                return matches;
            }, 0);

            // Check all conditions are TRUE
            return total == matched;
        },
        _quiz_conditions_matchOr(quiz, dependency) {

            return Object.keys(dependency).reduce((result, keyOrComponent) => {

                if (result) {

                    return result;
                }

                if (/^__function:.*/.test(keyOrComponent)) {

                    return this[keyOrComponent.split("__function:")[1]]();
                }

                if (/^__pageDisplayed$/.test(keyOrComponent)) {

                    return (
                        // Convert config value to array
                        "string" === typeof dependency[keyOrComponent]
                            ? [dependency[keyOrComponent]]
                            : dependency[keyOrComponent]
                    ).reduce((result, page) => {

                        if  (result) {

                            return result;
                        }

                        const pageToCheck = quiz.pages.find(p => p.id == page);

                        return pageToCheck && this._quiz_conditions_isPageShouldBeDisplayed(quiz, pageToCheck)
                    }, false);
                }

                if (/^__.*/.test(keyOrComponent)) {

                    return this.quiz_conditions_match(quiz, dependency[keyOrComponent]);
                }

                return result
                    ? result
                    : this._quiz_conditions_isConditionTrue(keyOrComponent, dependency[keyOrComponent]);

            }, false);
        },
        _quiz_conditions_isConditionTrue(component, condition) {

            if ("boolean" === typeof condition) {

                if (true === condition) {

                    return this.quiz_values_doesComponentHaveValue(component);
                }

                return !this.quiz_values_doesComponentHaveValue(component);
            }

            const componentValue = this.quiz_values_getComponentValue(component);

            if ("string" === typeof condition) {

                if (Array.isArray(componentValue)) {

                    return -1 !== componentValue.indexOf(condition);
                }
            }

            if (Array.isArray(condition)) {

                if (Array.isArray(componentValue)) {

                    return componentValue.reduce((result, item) => {

                        if (result) {

                            return result;
                        }

                        return -1 !== condition.indexOf(item);
                    }, false);
                }

                return -1 !== condition.indexOf(componentValue);
            }

            return false;
        },
        _quiz_conditions_isLimitedCountry() {

            return this.$store.getters["user/quiz/countries"].reduce((result, country) => {

                if (result) {

                    return result;
                }

                return this.model.country == country.iso_alpha3 && country.is_limited;
            }, false);
        },
        /**
         * Duplicated from mixinQuiz to avoid recursive import
         * @param quiz
         * @param page
         * @returns {*|boolean}
         * @private
         */
        _quiz_conditions_isPageShouldBeDisplayed(quiz, page) {

            if ("undefined" !== typeof page.display) {

                return this.quiz_conditions_match(quiz, page.display);
            }

            if ("undefined" !== typeof page.hide) {

                return !this.quiz_conditions_match(quiz, page.hide);
            }

            return true;
        },
    }
}