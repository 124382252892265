import emitter, {EVENTS} from "@/api/events";
import {retryRequest} from "@/helpers/request";
import {order} from "@/api/order";
import {platform} from "@/api/platform";

export default {
    namespaced: true,
    actions: {
        loadUsers(context, params) {

            return retryRequest(() => order.get("/orders/users", {params}))
                .then(response => ({
                    items: response.data.results.map(user => ({...user, id: user._id})),
                    total: response.data.pagination.totalCount
                }));
        },
        loadItems(context, params) {

            return retryRequest(() => order.get(`/users/${params.userId}/items/impersonated`, {params}))
                .then(response => ({items: response.data.results, total: response.data.pagination.totalCount}));
        },
        cancel(context, {userId, orderItemId, sendEmail}) {

            return platform
                .post(`/api/v2/users/${this.getters["user/id"]}/impersonate`, {impersonate: userId})
                .then(response => order.put(
                    `/users/${userId}/items/${orderItemId}/cancel`,
                    {id: orderItemId, sendEmail},
                    {headers: {Authorization: response.data.results.token}}
                ))
                .then(() => emitter.emit(EVENTS.USER_IMPERSONATION_ORDER_CANCEL));
        },
        received(context, {userId, orderItemId}) {

            return platform
                .post(`/api/v2/users/${this.getters["user/id"]}/impersonate`, {impersonate: userId})
                .then(response => order.put(
                    `/users/${userId}/items/${orderItemId}/received`,
                    {},
                    {headers: {Authorization: response.data.results.token}}
                ))
                .then(() => emitter.emit(EVENTS.USER_IMPERSONATION_ORDER_RECEIVED));
        },
        undo(context, {userId, orderItemId}) {

            return platform
                .post(`/api/v2/users/${this.getters["user/id"]}/impersonate`, {impersonate: userId})
                .then(response => order.post(
                    `/users/${userId}/items/${orderItemId}/status/undo`,
                    {},
                    {headers: {Authorization: response.data.results.token}}
                ))
                .then(() => emitter.emit(EVENTS.USER_IMPERSONATION_ORDER_UNDO));
        }
    }
}