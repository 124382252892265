export default {
    model: {
        email: {
            label: "E-mail",
            placeholder: "E-mail delegeren"
        },
        start_on: {
            label: "Begin datum",
            placeholder: "Datum waarop de delegatie begint"
        },
        end_on: {
            label: "Einddatum",
            placeholder: "Optioneel"
        },
        accept: {
            tip: "Door uw goedkeuringsverantwoordelijkheid te delegeren, verklaart u dat u de verantwoordelijkheid en verantwoordelijkheid op u neemt voor alle bestellingen die door uw afgevaardigde zijn goedgekeurd.",
            label: "ik aanvaard"
        }
    },
    buttons: {
        submit: {
            cta: "Bevestigen",
            processing: "Besparing"
        },
        close: {
            cta: "Annuleren"
        }
    },
    saved: "Opgeslagen"
}