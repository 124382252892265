import {retryRequest} from "@/helpers/request";
import {platform} from "@/api/platform";

export default {
    namespaced: true,
    state: {
        config: []
    },
    getters: {
        config: state => state.config
    },
    mutations: {
        setConfig: (state, config) => state.config = config,
    },
    actions: {
        fetch(context) {

            return retryRequest(() => platform.get(`/api/v2/user/${this.getters["user/id"]}/approval-config`))
                .then(response => context.commit("setConfig", response.data.results));
        },
        clear: context => context.commit("setConfig", [])
    }
}