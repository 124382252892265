export default {
    "India": "Inde",
    "United States of America": "les états-unis d'Amérique",
    "United Kingdom": "Royaume-Uni",
    "Philippines": "Philippines",
    "Australia": "Australie",
    "Spain": "Espagne",
    "Bulgaria": "Bulgarie",
    "Poland": "Pologne",
    "Malaysia": "Malaisie",
    "Russian Federation": "Fédération Russe",
    "Ukraine": "Ukraine",
    "Germany": "Allemagne",
    "Brazil": "Brésil",
    "Canada": "Canada",
    "Romania": "Roumanie",
    "France": "France",
    "China": "Chine",
    "Italy": "Italie",
    "Costa Rica": "Costa Rica",
    "Mexico": "Mexique",
    "Slovakia": "Slovaquie",
    "Vietnam": "Viêt Nam",
    "Singapore": "Singapour",
    "Argentina": "Argentine",
    "Denmark": "Danemark",
    "Japan": "Japon",
    "Belgium": "Belgique",
    "Czech Republic": "République tchèque",
    "Netherlands": "Pays-Bas",
    "Switzerland": "Suisse",
    "Sweden": "Suède",
    "New Zealand": "Nouvelle-Zélande",
    "Hungary": "Hongrie",
    "Egypt": "Egypte",
    "Lithuania": "Lituanie",
    "United Arab Emirates": "Emirats Arabes Unis",
    "Ireland": "Irlande",
    "Hong Kong": "Hong Kong",
    "Austria": "L'Autriche",
    "Saudi Arabia": "Arabie Saoudite",
    "Portugal": "le Portugal",
    "Norway": "Norvège",
    "Chile": "Chili",
    "Thailand": "Thaïlande",
    "Taiwan": "Taïwan",
    "Tunisia": "Tunisie",
    "Israel": "Israël",
    "South Africa": "Afrique du Sud",
    "Colombia": "Colombie",
    "Serbia": "Serbie",
    "Luxembourg": "Luxembourg",
    "Peru": "Pérou",
    "Finland": "Finlande",
    "Brunei Darussalam": "Brunei Darussalam",
    "Korea, Republic of": "Corée, République de",
    "Indonesia": "Indonésie",
    "Jordan": "Jordan",
    "Fiji": "Fidji",
    "Morocco": "Maroc",
    "Nigeria": "Nigeria",
    "Panama": "Panama",
    "Qatar": "Qatar",
    "Greece": "Grèce",
    "Turkey": "Turquie",
    "Croatia": "Croatie",
    "Kazakhstan": "Kazakhstan",
    "Cyprus": "Chypre"
}