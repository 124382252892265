export default {

    title: "Wird geladen...",

    responses: {
        "200_tfalogin": {
            title: "Anmeldebestätigung",
            header: "@:views.tunnel.service.response.200_tfalogin.title",
            message: "Die Anmeldung wurde bestätigt."
        },
        "404_tfalogin": {
            title: "Anmeldebestätigung",
            header: "@:views.tunnel.service.response.404_tfalogin.title",
            message: "Gültiges Token nicht gefunden. Ihr Token ist möglicherweise abgelaufen."
        },
        "200_tfaaddress": {
            title: "Adressverifikation",
            header: "@:views.tunnel.service.response.200_tfaaddress.title",
            message: "Vielen Dank für die Bestätigung Ihrer Adresse. Sie können diese nun bei Ihrer Bestellung als Lieferadresse auswählen."
        },
        "404_tfaaddress": {
            title: "Adressverifikation",
            header: "@:views.tunnel.service.response.404_tfaaddress.title",
            message: "Gültiges Token nicht gefunden. Ihr Token ist möglicherweise abgelaufen."
        },
        "201_delivery": {
            title: "Danke",
            header: "Danke",
            message: "Danke für Ihre Antwort."
        },
        "201_shipment_received": {
            title: "Danke",
            header: "Danke",
            message: "Danke für Ihre Antwort."
        },
        "400": {
            title: "Ungültige Anforderung. Ein Fehler ist aufgetreten.",
            header: "@:views.tunnel.service.response.400.title",
            message: "Ungültige Anforderung. Ein Fehler ist aufgetreten."
        }
    }
}