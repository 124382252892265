export default {
    disclaimer: {
        label_default: "Ich bestätige und stimme zu, dass die Artikel, die ich bestelle/erhalte, O-I sind Eigenschaft, die für O-I verwendet werden soll arbeitsbezogene Aktivitäten und als solche O-I behält sich das Recht vor, bei meinem Verlassen von O-I die Rückgabe des Eigentums zu verlangen (oder früher, falls erforderlich durch O-I).",
        label_type_1: "Ich bestätige, dass die Selbsteinschätzung für die Hausaufgaben korrekt und in gutem Glauben durchgeführt wurde und dass alle von O-I zur Verfügung gestellten Geräte oder Möbel zur Verfügung gestellt wurden Standort oder über Gear bestellt werden, werden von mir für meine Arbeit mit O-I verwendet. Ich bestätige und stimme zu, dass die Artikel, die ich bestelle/erhalte, O-I sind Eigenschaft, die für O-I verwendet werden soll arbeitsbezogene Aktivitäten und als solche O-I behält sich das Recht vor, bei meinem Verlassen von O-I die Rückgabe des Eigentums zu verlangen (oder früher, falls erforderlich durch O-I).",
        errors: {
            required: "Bitte akzeptieren Sie die Nutzungsbedingungen"
        }
    },
    cost_center: {
        label: "Kostenstellencode",
        placeholder: "Kostenstellencode",
        cost_center_not_found: "Es kann bis zu 24 Stunden dauern, bis Kostenstellen angezeigt werden, wenn sie nicht vorhanden sind. Wenden Sie sich an den Global Service Desk, wenn Sie die entsprechende Kostenstelle nicht sehen.",
        errors: {
            regex: "Ungültiger Kostenstellencode"
        }
    },
    company_code: {
        label: "Firmencode"
    },
    cost_center_name: {
        label: "Name der Kostenstelle"
    },
    order_note: {
        label: "Bestellhinweis",
        placeholder: "Optionaler Hinweis an den Manager zur Genehmigung",
    }
}