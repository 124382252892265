export default {
    title: "Confirmation de livraison",
    header: "@:views.survey.delivery.title",
    result: {
        header: "Merci",
        message: "Merci pour votre réponse."
    },
    status: {
        yes: "Veuillez vérifier les produits cochés ci-dessous pour confirmer la livraison et décochez ceux que vous n'avez pas reçus. Appuyez ensuite sur Soumettre pour confirmer.",
        no: "Veuillez vérifier que vous n'avez reçu aucun des produits ci-dessous. Si vous avez reçu l'un de ces produits, veuillez cocher la case à côté du ou des produits reçus et cliquez sur Soumettre pour confirmer."
    },
    model: {
        checked: {
            label: "Reçu"
        }
    },
    button: {
        submit: {
            cta: "Soumettre",
            processing: "Soumission"
        }
    }
}