export default {
    title: "发货确认",
    header: "@:views.survey.delivery.title",
    result: {
        header: "谢谢",
        message: "感谢您的答复。"
    },
    status: {
        yes: "请核实下方勾选的产品以确认送达，取消勾选您未收到的产品。然后点击提交确认。",
        no: "请确认您没有收到以下任何产品。如果您确实收到了这些产品中的任何一种，请选中已收到产品旁边的框并点击提交以确认。"
    },
    model: {
        checked: {
            label: "已收到"
        }
    },
    button: {
        submit: {
            cta: "提交",
            processing: "提交中"
        }
    }
}