export default {
    title: "Artikel nicht geliefert",
    header: "@:views.survey.deliveryfaq.title",
    result: {
        header: "Danke",
        message: "Danke für Ihre Antwort."
    },
    message: "Sie haben angegeben, dass Sie Ihren Artikel nicht erhalten haben, obwohl der Kurier ihn als geliefert markiert hat.",
    cta: "Bitte befolgen Sie diese Schritte, um uns bei der Behebung des Problems zu helfen:",
    items: {
        1: {
            message: "Klicken Sie auf den Tracking-Link:"
        },
        2: {
            message: "Überprüfen Sie den Lieferstatus. Prüfen Auf:",
            items: {
                1: "Wer hat das Paket unterschrieben?",
                2: "Wurde an den Absender zurückgesendet",
                3: "Wenn das Paket auf Abholung wartet"
            }
        },
        3: {
            message: "Suchen Sie nach einer Lieferbenachrichtigung, aus der hervorgeht, wo der Artikel möglicherweise zurückgelassen wurde."
        },
        4: {
            message: "Erkundigen Sie sich bei Nachbarn, Verwandten und Freunden, die das Paket möglicherweise entgegengenommen haben."
        },
        5: {
            message: "Überprüfen Sie die üblichen Orte, an denen Kuriere in Ihrer Abwesenheit Gegenstände abgeben."
        }
    },
    question: "Hat dies zur Lösung Ihrer Lieferanfrage beigetragen? Haben Sie den Artikel jetzt oder wissen Sie, wer ihn hat?",
    buttons: {
        yes: "Ja",
        no: "NEIN",
        saving: "Sparen"
    }
}