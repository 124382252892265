export default {
    title: "Przedmioty niedostarczone",
    header: "@:views.survey.deliveryfaq.title",
    result: {
        header: "Dziękuję",
        message: "Dziękuję za odpowiedź."
    },
    message: "Wskazałeś, że nie otrzymałeś przedmiotu, mimo że kurier oznaczył go jako dostarczony.",
    cta: "Wykonaj poniższe czynności, aby pomóc nam rozwiązać problem:",
    items: {
        1: {
            message: "Kliknij link śledzenia:"
        },
        2: {
            message: "Sprawdź status dostawy. Sprawdzić:",
            items: {
                1: "Kto podpisał paczkę",
                2: "Jeśli został zwrócony nadawcy",
                3: "Jeśli przesyłka czeka na odbiór"
            }
        },
        3: {
            message: "Sprawdź powiadomienie o dostawie wskazujące, gdzie przedmiot mógł zostać pozostawiony."
        },
        4: {
            message: "Sprawdź sąsiadów, krewnych i znajomych, którzy mogli zabrać paczkę."
        },
        5: {
            message: "Sprawdź wspólne miejsca, w których kurierzy zostawiają przesyłki pod Twoją nieobecność."
        }
    },
    question: "Czy pomogło to w rozwiązaniu problemu z dostawą? Czy masz teraz przedmiot lub wiesz, kto go ma?",
    buttons: {
        yes: "Tak",
        no: "NIE",
        saving: "Oszczędność"
    }
}