export default {
    title: "Vérification de connexion requise",
    header: "@:views.auth.confirm.title",
    message: "Pour continuer, veuillez consulter l'e-mail de votre entreprise pour un lien de vérification d'e-mail. Cliquez sur le lien dans l'e-mail pour continuer.",
    messageBeforeCta: "Si vous ne voyez pas l'e-mail, cliquez sur",
    messageAfterCta: "à renvoyer.",
    secondMessage: "Si vous ne voyez toujours pas l'e-mail, vérifiez votre dossier spam ou contactez-nous à l'adresse <a href='mailto:{email}?subject={subject}'>{email}</a> .",
    subject: "Engrenage - TFA",
    buttons: {
        cta: "ici",
        processing: "ici"
    },
    headerAlreadyConfirmed: "Identification",
    messageAlreadyConfirmed: "Vous avez déjà confirmé votre email."
}