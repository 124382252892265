export default {
    types: {
        "ac_router": "Router CA",
        "chair": "Sedia",
        "desk": "Scrivania",
        "desktop": "Desktop",
        "desktop_riser": "Rialzo da scrivania",
        "detector": "Rilevatore di vapore e rumore",
        "displayport_cable": "Cavo DisplayPort",
        "docking station": "Stazione di attracco",
        "dual_usb_adapter": "Adattatore USB doppia banda",
        "ear cushion": "Cuscino auricolare",
        "footrest": "Poggiapiedi",
        "hdmi_cable": "Cavo HDMI",
        "hdmi_displayport_cable": "Cavo da HDMI a DisplayPort",
        "hdmi_vga_cable": "Cavo da HDMI a VGA",
        "headset": "Cuffia",
        "keyboard": "Tastiera",
        "kvm switch": "Interruttore KVM",
        "kvm_switch": "Interruttore KVM",
        "lamp": "Lampada",
        "laptop stand": "Supporto per laptop",
        "laptop": "Computer portatile",
        "mesh_router": "Router di rete",
        "monitor": "Tenere sotto controllo",
        "mouse": "Topo",
        "patch_cord": "Cavo di connessione Cat6",
        "power_center": "Centro di potere",
        "power_strip": "Ciabatta elettrica",
        "smart_plug": "Presa intelligente mini",
        "smart_power_strip": "Ciabatta intelligente",
        "thunderbolt_cable": "Da USB-C a Thunderbolt",
        "travel_router": "Router da viaggio",
        "usb hub": "Hub USB",
        "usb_adapter": "Adattatore USB",
        "usb_hub": "Hub USB",
        "webcam": "Webcam"
    }
}