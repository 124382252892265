import mixin_quiz_filter from "./filter.mjs";
import mixin_quiz from "../quiz.mjs"

export default {
    mixins: [mixin_quiz, mixin_quiz_filter],
    methods: {
        quiz_components_process(quiz, name) {

            const result = {
                ...JSON.parse(JSON.stringify(quiz.components[name])),
                name
            };

            result.disabled = this.quiz_isComponentDisabled(quiz, result);
            result.wrapperCssClass = this.quiz_css_getComponentWrapperCssClass(quiz, result);
            result.options = this.quiz_components_processOptions(quiz, result);
            result.errors = this.quiz_components_processErrors(result);
            result.label = this.quiz_components_processTranslation(result, "label");
            result.placeholder = this.quiz_components_processTranslation(result, "placeholder");

            this.quiz_components_hydrateModel(quiz, result);

            return result;
        },
        /**
         * Hydrate model value for component
         * @param component
         */
        quiz_components_hydrateModel(quiz, component) {

            let value = null;

            // Handle component model
            if (quiz.types.collection == component.type) {

                value = [];
            }

            if (quiz.types.dropdown == component.type) {

                if (1 === component.options.length) {

                    // Select single option if there is no choice
                    value = component.options[0].value;
                }
            }

            // Set model value
            this.model[component.name] = this.model[component.name]
                ? this.model[component.name]
                : value;
        },
        quiz_components_processTranslation(component, translation) {

            return this.quiz_getComponentText(component.name, translation);
        },
        /**
         * Prepare component validation errors if they are presented
         * @param component
         * @returns {Object|undefined}
         */
        quiz_components_processErrors(component) {

            const errors = {};

            if (component.validation && component.validation.messages) {

                Object.keys(component.validation.messages).forEach(key => {

                    errors[key] = this.quiz_getComponentText(component.name, `errors.${key}`);
                });
            }

            return errors;
        },
        /**
         * Prepare component options if they are presented
         * @param component
         * @returns {Array|undefined}
         */
        quiz_components_processOptions(quiz, component) {

            let options;

            if ("undefined" === typeof component.options) {

                return undefined;
            }

            if (false === Array.isArray(component.options)) {

                if ("store" === component.options.source) {

                    // Options should be pulled from Vuex storage (e.g. country)
                    options = this.$store.getters[component.options.target].map(item => ({
                        value: item[component.options.value],
                        name: this.quiz_getComponentText(component.name, `values.${item[component.options.value]}.${item[component.options.name]}`, item[component.options.name])
                    }));

                    // Check for we have to filter components
                    if (component.options.filter) {

                        if ("function" !== typeof this[component.options.filter]) {

                            console.warn(`Quiz options filter not found: ${component.options.filter}`);

                            return [];
                        }
                        return this[component.options.filter](options);
                    }

                    return options;
                }

                return [];
            }

            options = component.options
                .filter(option => this.quiz_isComponentOptionShouldBeDisplayed(quiz, option))
                .map(option => ({
                    ...option,
                    title: this.quiz_getComponentText(component.name, `values.${option.value}.${option.title}`, option.title),
                    name: this.quiz_getComponentText(component.name, `values.${option.value}.${option.name}`, option.name),
                    description: this.quiz_getComponentText(component.name, `values.${option.value}.${option.description}`, option.description),
                    image: option.image ? this.quiz_getComponentImage(option.image) : null
                }));

            if (1 === options.length && "dropdown" == component.type) {

                // Select single option if there is no choice
                this.model[component.name] = options[0].value;
            }

            return options;
        },
        quiz_components_processOKTA() {

            if (this.components) {

                this.components.forEach(component => {

                    if (!this.model[`OKTA_${component.name}`] || this.model[component.name]) {

                        return true;
                    }

                    if (component.options) {

                        // Set OKTA value just in case this value is allowed to use
                        component.options.forEach(option => {

                            if (option.value == this.model[`OKTA_${component.name}`]) {

                                this.model[component.name] = this.model[`OKTA_${component.name}`];
                            }
                        })
                    } else {

                        // Just set OKTA value
                        this.model[component.name] = this.model[`OKTA_${component.name}`];
                    }
                });
            }
        }
    }
}