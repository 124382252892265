export default {
    unverified: "Niesprawdzony",
    verificationLink: "Ponownie wysłać e-mail weryfikacyjny",
    buttons: {
        delete: {
            cta: "Usuwać",
            processing: "Usuwanie"
        }
    },
    addressDeleted: "Adres został usunięty",
    verificationEmailSent: "Email został wysłany",
    deleteAddressConfirmation: "Czy na pewno chcesz usunąć ten adres?"
}