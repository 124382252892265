export default {
    error: "Si è verificato un problema durante il recupero degli ordini. Per favore, ricarica il browser.",
    meta: {
        default: "Ordini",
        my_orders: "i miei ordini",
        my_gear: "Ricevuto"
    },
    loadMore: "Carica altro",
    noOrders: "Nessun ordine",
    order: {
        product: {
            title: "Ordine",
            tracking: "Monitoraggio",
            quantity: "Quantità",
            control: {
                cancel: {
                    cta: "Fare clic per annullare",
                    tooltip: "Fare clic per annullare questo elemento",
                    message: "Abbiamo annullato il tuo ordine."
                },
                cancelRequest: {
                    cta: "Richiesta di annullamento",
                    tooltip: "Fare clic per inviare una richiesta di cancellazione"
                },
                received: {
                    cta: "Contrassegna come ricevuto",
                    tooltip: "Fare clic per confermare la consegna",
                    message: "Grazie per aver confermato di aver ricevuto il tuo prodotto."
                },
                notReceived: {
                    cta: "Contrassegna come non ricevuto",
                    tooltip: "Fare clic per confermare la mancata consegna"
                },
                damaged: {
                    cta: "Contrassegnare come danneggiato/rotto",
                    tooltip: "Fare clic per creare un ticket di supporto"
                },
                hide: {
                    cta: "Nascondi elemento",
                    tooltip: "Fare clic per nascondere l'elemento dall'elenco"
                }
            },
            requestToCancelInfo: "Contatteremo il fornitore per richiedere la cancellazione. Tieni presente che questa è solo una richiesta e la cancellazione potrebbe non essere possibile. Una volta che avremo stabilito se può essere cancellato o meno, ti faremo sapere.",
            cancelOk: "OK",
            reason: "Motivo",
            orderedBy: "Ordinato da",
            model: {
                cancel: {
                    reason: {
                        label: "Motivi di cancellazione",
                        placeholder: "Motivi di cancellazione"
                    }
                }
            },
            buttons: {
                cancel: {
                    submit: {
                        cta: "Invia",
                        processing: "Invio"
                    },
                    cancel: {
                        cta: "Annulla"
                    }
                }
            }
        }
    },
    help: {
        text: "<p>Hai domande sul tuo ordine?<br/> Consulta il nostro <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>Centro di supporto</b></a> per assistenza</p><p> Hai bisogno di un prodotto non elencato?<br/> Clicca <a href='#' role='button' data-widget-trigger='{widgetOptions}'><b>qui</b></a> per inviare un suggerimento</p>",
        email: {
            subject: ""
        }
    }
}