export default {
    types: {
        "ac_router": "Router prądu przemiennego",
        "chair": "Krzesło",
        "desk": "Biurko",
        "desktop": "Pulpit",
        "desktop_riser": "Podstawka do komputerów stacjonarnych",
        "detector": "Detektor Vape i hałasu",
        "displayport_cable": "Kabel DisplayPort",
        "docking station": "Stacja dokująca",
        "dual_usb_adapter": "Dwuzakresowy adapter USB",
        "ear cushion": "Poduszka na uszy",
        "footrest": "Podnóżek",
        "hdmi_cable": "Kabel HDMI",
        "headset": "Zestaw słuchawkowy",
        "keyboard": "Klawiatura",
        "kvm switch": "Przełącznik Kvm",
        "kvm_switch": "Przełącznik Kvm",
        "lamp": "Lampa",
        "laptop stand": "Stojak na laptopa",
        "laptop": "Laptop",
        "mesh_router": "Ruter siatkowy",
        "monitor": "Monitor",
        "mouse": "Mysz",
        "patch_cord": "Kabel krosowy Cat6",
        "power_strip": "Listwa zasilająca",
        "smart_plug": "Inteligentna wtyczka mini",
        "smart_power_strip": "Inteligentna listwa zasilająca",
        "travel_router": "Router podróżny",
        "usb hub": "Rozdzielacz USB",
        "usb_adapter": "Adapter USB",
        "usb_hub": "Rozdzielacz USB",
        "webcam": "Kamerka internetowa"
    }
}