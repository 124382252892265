export default {
    return: {
        title: "返回",
        header: "退回物品",
        message: "以下公司设备将被归还。提交此表格后，我们将单独联系您安排取件和/或发货。您的经理将被告知您的退货状态。",

        model: {
            address: {
                country: {
                    label: "国家"
                }
            },
            exclude: {
                label: "从退货中排除"
            },
            textarea: {
                placeholder: "原因：经理批准，之前退回等。"
            },
            return_note: {
                label: "退货须知",
                placeholder: "可选：输入有关要退回的项目的任何注释。"
            },
            additional_disposal: {
                label: "您是否有任何需要安全处置的物理 DXC 记录"
            },
            additional_item: {
                type: {
                    label: "类型",
                    options: {
                        "chair": "椅子",
                        "desk": "桌子",
                        "desk riser": "办公桌立管",
                        "docking station": "扩展坞",
                        "footrest": "搁脚板",
                        "headset": "耳机",
                        "hdmi cable": "HDMI线",
                        "keyboard": "键盘",
                        "kvm switch": "多电脑切换器",
                        "lamp": "灯",
                        "laptop riser": "笔记本电脑提升板",
                        "monitor": "监视器",
                        "mouse": "老鼠",
                        "other": "其他",
                        "speakerphone": "扩音器",
                        "usb hub": "USB集线器",
                        "webcam": "摄像头",
                    }
                },
                detail: {
                    label: "细节",
                    placeholder: "细节"
                },
                quantity: {
                    label: "数量",
                    placeholder: "数量"
                }
            }
        },

        submitted: {
            title: "退货已提交",
            message: "您的退货已提交。关于物品取件和/或装运，我们将单独联系您。"
        },
        nothingToReturn: {
            title: "退货信息",
            message: "目前，您在 Gear 中没有任何物品可以归还。"
        },
        notEligible: {
            title: "退货信息",
            message: "此时，Gear 无法处理您的退货。请联系您当地的经理或人力资源团队，了解如何退回您的物品。"
        },

        button: {
            add: {
                cta: "添加未列出的项目"
            },
            submit: {
                processing: "提交中",
                cta: "提交"
            }
        }
    }
}