<template>
    <page-message>
        <template #header>{{t('header')}}</template>
        <template #message>{{t('message')}}</template>
    </page-message>
</template>

<script>

    import mixin_view from "@/mixins/view";

    import PageMessage from "@/components/page/PageMessage";

    export default {
        name: "ViewsErrors500",
        mixins: [mixin_view],
        components: {
            PageMessage
        },
        data() {

            return {
                t_path: "views.errors.500"
            }
        }
    }
</script>