export default {
    country: {
        label: "Země trvalého pobytu",
        errors: {
            required: "Je vyžadován výběr"
        }
    },
    entity: {
        label: "Entita",
        errors: {
            required: "Je vyžadován výběr"
        }
    },
    location: {
        label: "Umístění kanceláře",
        errors: {
            required: "Je vyžadován výběr"
        }
    },
    workplace: {
        values: {
            "Dedicated Office": {
                title: "Vyhrazená kancelář",
                description: "Domácí kancelář se stálým místem vyhrazeným jako kancelář"
            },
            "Semi-Dedicated": {
                title: "Semi-Dedicated",
                description: "Dočasné místo pro uspokojení potřeb domácího pracovního prostředí"
            },
            "Floating": {
                title: "Plovoucí",
                description: "Žádný vyhrazený prostor pro domácí kancelář, ale je sdílen s jinými místy, jako je jídelna nebo obývací pokoj"
            }
        },
        errors: {
            required: "Je vyžadován výběr"
        }
    },
    priority: {
        label: "Nejvyšší prioritou",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Docking Station": {
                title: "Dokovací stanice"
            },
            "Keyboard/Mouse": {
                title: "Klávesnice/myš"
            },
            "Web Cam": {
                title: "Webová kamera"
            },
            "Headset": {
                title: "Sluchátka"
            },
            "Office Chair": {
                title: "Kancelářská židle"
            },
            "Desk": {
                title: "Lavice"
            },
            "Adapters": {
                title: "Adaptéry"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Nepřerušitelný zdroj napájení (UPS)"
            },
            "Something Else": {
                title: "Něco jiného"
            },
            "Nothing Needed": {
                title: "Nic není potřeba"
            }
        },
        errors: {
            requiredUnless: "Je vyžadován výběr",
            requiredIf: "Je vyžadován výběr",
            required: "Je vyžadován výběr"
        }
    },
    priorityDetails: {
        placeholder: "Uveďte podrobnosti"
    },
    secondaryPriority: {
        label: "Druhá nejvyšší priorita",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Second Monitor": {
                title: "Druhý monitor"
            },
            "Docking Station": {
                title: "Dokovací stanice"
            },
            "Keyboard/Mouse": {
                title: "Klávesnice/myš"
            },
            "Web Cam": {
                title: "Webová kamera"
            },
            "Headset": {
                title: "Sluchátka"
            },
            "Office Chair": {
                title: "Kancelářská židle"
            },
            "Desk": {
                title: "Lavice"
            },
            "Adapters": {
                title: "Adaptéry"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Nepřerušitelný zdroj napájení (UPS)"
            },
            "Something Else": {
                title: "Něco jiného"
            },
            "Nothing Needed": {
                title: "Nic není potřeba"
            }
        },
        errors: {
            requiredUnless: "Je vyžadován výběr",
            requiredIf: "Je vyžadován výběr",
            required: "Je vyžadován výběr"
        }
    },
    secondaryPriorityDetails: {
        placeholder: "Uveďte podrobnosti"
    },
    improveWorkspace: {
        label: "Jak byste zlepšili svůj pracovní prostor?",
        placeholder: "Volitelný",
    },
    improveRemoteWorking: {
        label: "Co by vám mimo vybavení pomohlo být efektivnější při práci na dálku?",
        placeholder: "Volitelný",
    },
    receivedEquipmentHeadset: {
        label: "Obdrželi jste během posledních 24 měsíců od společnosti bezdrátovou náhlavní soupravu nebo hlasitý odposlech?",
        values: {
            Yes: {
                name: "Ano"
            },
            No: {
                name: "Ne"
            }
        }
    },
    issuesHeadset: {
        label: "Máte s tím problémy?",
        values: {
            Yes: {
                name: "Ano"
            },
            No: {
                name: "Ne"
            }
        }
    },
    issuesDescriptionHeadset: {
        placeholder: "Popište své problémy"
    },
    isOfficeWorker: {
        label: "Pracujete z kanceláře na plný úvazek?",
        values: {
            Yes: {
                name: "Ano"
            },
            No: {
                name: "Ne"
            }
        }
    },
    receivedKeyboardMouse: {
        label: "Obdrželi jste během posledních 12 měsíců od společnosti klávesnici a myš?",
        values: {
            Yes: {
                name: "Ano"
            },
            No: {
                name: "Ne"
            }
        }
    },
    issuesKeyboardMouse: {
        label: "Máte s tím problémy?",
        values: {
            Yes: {
                name: "Ano"
            },
            No: {
                name: "Ne"
            }
        }
    },
    issuesDescriptionKeyboardMouse: {
        placeholder: "Popište své problémy"
    },
    needWebcam: {
        label: "Potřebujete k práci externí webovou kameru (ne tu, která je vestavěná v notebooku?",
        values: {
            Yes: {
                name: "Ano"
            },
            No: {
                name: "Ne"
            }
        }
    },
    needWebcamExternal: {
        label: "Potřebujete externí webovou kameru USB (ne tu vestavěnou ve vašem notebooku)?",
        values: {
            Yes: {
                name: "Ano"
            },
            No: {
                name: "Ne"
            }
        }
    },
    needMonitor: {
        label: "Potřebujete k práci monitor?",
        values: {
            Yes: {
                name: "Ano"
            },
            No: {
                name: "Ne"
            }
        }
    },
    receivedMonitor: {
        label: "Obdrželi jste od společnosti monitor v posledních 4 letech?",
        values: {
            Yes: {
                name: "Ano"
            },
            No: {
                name: "Ne"
            }
        }
    },
    issuesMonitor: {
        label: "Máte s tím problémy?",
        values: {
            Yes: {
                name: "Ano"
            },
            No: {
                name: "Ne"
            }
        }
    },
    issuesDescriptionMonitor: {
        placeholder: "Popište své problémy"
    },
    needUsbhub: {
        label: "Potřebujete pro práci externí USB hub?",
        values: {
            Yes: {
                name: "Ano"
            },
            No: {
                name: "Ne"
            }
        }
    },
    issuesDescriptionUsbhub: {
        placeholder: "Popište své problémy"
    }
}