export default {
    title: "Bureau d'aide",
    header: "Bureau d'aide",
    model: {
        email: {
            label: "Courriel de l'utilisateur",
            placeholder: "Veuillez remplir l'adresse e-mail de l'utilisateur"
        }
    },
    cta: "Soumettre",
    processing: "Chargement"
}