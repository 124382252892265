export default {
    menu: {
        approvals: "Jóváhagyások",
        delegates: "Küldöttek"
    },
    approvals: {
        title: "Jóváhagyás",
        error: "Hiba lépett fel",
        saved: "A jóváhagyások benyújtva",
        noApprovals: "Nincs mit helyeselni",
        review: "Ha téves, tekintse át és szerkessze a költséghelyet",
        priorApprovals: "Előzetes jóváhagyások",
        noOrders: "Nincs mit helyeselni",
        noPriorApprovals: "Nincs előzetes jóváhagyás",
        approved: "Jóváhagyott",
        notApproved: "Nem engedélyezett",
        confirm: "Vannak még jóváhagyásra váró megrendelések. Szeretné folytatni a jelenlegi változtatásokat?",
        manager: "Menedzser",
        orders: {
            name: "Név",
            date: "Rendelés dátuma",
            details: "Megrendelés részletei",
            cost: "Költség",
            costCenter: "Költségközpont",
            approval: "Jóváhagyás"
        },
        model: {
            reason: {
                placeholder: "Ok"
            },
            approve: {
                yes: {
                    label: "Igen"
                },
                no: {
                    label: "Nem"
                }
            }
        },
        buttons: {
            submit: {
                text: "Beküldés",
                processing: "Feldolgozás"
            }
        }
    },
    delegates: {
        title: "Küldöttek",
        error: "Hiba lépett fel",
        noDelegates: "Nincsenek tárgyak",
        delegateDelete: "A delegált törölve lett",
        confirm: {
            delete: "Törli a delegált?"
        },
        delegates: {
            email: "Email",
            startDate: "Kezdő dátum",
            endDate: "Befejezés dátuma",
        },
        buttons: {
            add: {
                text: "Képviselő hozzáadása"
            }
        }
    }
}