export default {
    title: "Bevestiging van levering",
    header: "@:views.survey.delivery.title",
    result: {
        header: "Bedankt",
        message: "Bedankt voor je reactie."
    },
    status: {
        yes: "Controleer de aangevinkte producten hieronder om de levering te bevestigen en verwijder de vinkjes bij de producten die je niet hebt ontvangen. Druk vervolgens op Verzenden om te bevestigen.",
        no: "Controleer of u geen van de onderstaande producten heeft ontvangen. Als je een van deze producten hebt ontvangen, vink dan het vakje aan naast ontvangen product(en) en klik op Verzenden om te bevestigen."
    },
    model: {
        checked: {
            label: "Ontvangen"
        }
    },
    button: {
        submit: {
            cta: "Indienen",
            processing: "Indienen"
        }
    }
}