export default {
    country: {
        label: "A lakóhely szerinti ország",
        errors: {
            required: "Kiválasztás szükséges"
        }
    },
    entity: {
        label: "Entitás",
        errors: {
            required: "Kiválasztás szükséges"
        }
    },
    location: {
        label: "Iroda helye",
        errors: {
            required: "Kiválasztás szükséges"
        }
    },
    workplace: {
        values: {
            "Dedicated Office": {
                title: "Dedikált iroda",
                description: "Otthoni iroda állandó irodahellyel"
            },
            "Semi-Dedicated": {
                title: "Félig dedikált",
                description: "Ideiglenes helyszín az otthoni munkakörnyezet igényeinek kielégítésére"
            },
            "Floating": {
                title: "Úszó",
                description: "Nincs külön hely az otthoni iroda számára, de megosztva más helyekkel, például étkezővel vagy nappalival"
            }
        },
        errors: {
            required: "Kiválasztás szükséges"
        }
    },
    priority: {
        label: "Legfontosabb",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Docking Station": {
                title: "Dokkoló"
            },
            "Keyboard/Mouse": {
                title: "Billentyűzet/Egér"
            },
            "Web Cam": {
                title: "Webkamera"
            },
            "Headset": {
                title: "Fejhallgató"
            },
            "Office Chair": {
                title: "Irodai szék"
            },
            "Desk": {
                title: "Asztal"
            },
            "Adapters": {
                title: "Adapterek"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Szünetmentes tápegység (UPS)"
            },
            "Something Else": {
                title: "Valami más"
            },
            "Nothing Needed": {
                title: "Semmi szükség"
            }
        },
        errors: {
            requiredUnless: "Kiválasztás szükséges",
            requiredIf: "Kiválasztás szükséges",
            required: "Kiválasztás szükséges"
        }
    },
    priorityDetails: {
        placeholder: "Adja meg a részleteket"
    },
    secondaryPriority: {
        label: "Második legmagasabb prioritás",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Second Monitor": {
                title: "Második monitor"
            },
            "Docking Station": {
                title: "Dokkoló"
            },
            "Keyboard/Mouse": {
                title: "Billentyűzet/Egér"
            },
            "Web Cam": {
                title: "Webkamera"
            },
            "Headset": {
                title: "Fejhallgató"
            },
            "Office Chair": {
                title: "Irodai szék"
            },
            "Desk": {
                title: "Asztal"
            },
            "Adapters": {
                title: "Adapterek"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Szünetmentes tápegység (UPS)"
            },
            "Something Else": {
                title: "Valami más"
            },
            "Nothing Needed": {
                title: "Semmi szükség"
            }
        },
        errors: {
            requiredUnless: "Kiválasztás szükséges",
            requiredIf: "Kiválasztás szükséges",
            required: "Kiválasztás szükséges"
        }
    },
    secondaryPriorityDetails: {
        placeholder: "Adja meg a részleteket"
    },
    improveWorkspace: {
        label: "Hogyan javítaná a munkaterületét?",
        placeholder: "Választható",
    },
    improveRemoteWorking: {
        label: "A berendezéseken kívül mi segítene abban, hogy hatékonyabban dolgozzon a távmunkában?",
        placeholder: "Választható",
    },
    receivedEquipmentHeadset: {
        label: "Kapott-e vezeték nélküli headsetet vagy kihangosítót a vállalattól az elmúlt 24 hónapban?",
        values: {
            Yes: {
                name: "Igen"
            },
            No: {
                name: "Nem"
            }
        }
    },
    issuesHeadset: {
        label: "Problémáid vannak vele?",
        values: {
            Yes: {
                name: "Igen"
            },
            No: {
                name: "Nem"
            }
        }
    },
    issuesDescriptionHeadset: {
        placeholder: "Írja le problémáit"
    },
    isOfficeWorker: {
        label: "Teljes munkaidőben az irodában dolgozik?",
        values: {
            Yes: {
                name: "Igen"
            },
            No: {
                name: "Nem"
            }
        }
    },
    receivedKeyboardMouse: {
        label: "Kapott-e billentyűzetet és egeret a cégtől az elmúlt 12 hónapban?",
        values: {
            Yes: {
                name: "Igen"
            },
            No: {
                name: "Nem"
            }
        }
    },
    issuesKeyboardMouse: {
        label: "Problémáid vannak vele?",
        values: {
            Yes: {
                name: "Igen"
            },
            No: {
                name: "Nem"
            }
        }
    },
    issuesDescriptionKeyboardMouse: {
        placeholder: "Írja le problémáit"
    },
    needWebcam: {
        label: "Szüksége van külső webkamerára a munkához (nem a laptopba építettre?",
        values: {
            Yes: {
                name: "Igen"
            },
            No: {
                name: "Nem"
            }
        }
    },
    needWebcamExternal: {
        label: "Szüksége van egy külső USB-webkamerára (nem a laptopba építettre)?",
        values: {
            Yes: {
                name: "Igen"
            },
            No: {
                name: "Nem"
            }
        }
    },
    needMonitor: {
        label: "Szüksége van monitorra a munkához?",
        values: {
            Yes: {
                name: "Igen"
            },
            No: {
                name: "Nem"
            }
        }
    },
    receivedMonitor: {
        label: "Kapott-e monitort a Társaságtól az elmúlt 4 évben?",
        values: {
            Yes: {
                name: "Igen"
            },
            No: {
                name: "Nem"
            }
        }
    },
    issuesMonitor: {
        label: "Problémáid vannak vele?",
        values: {
            Yes: {
                name: "Igen"
            },
            No: {
                name: "Nem"
            }
        }
    },
    issuesDescriptionMonitor: {
        placeholder: "Ismertesse problémáit"
    },
    needUsbhub: {
        label: "Szüksége van egy külső USB-elosztóra a munkához?",
        values: {
            Yes: {
                name: "Igen"
            },
            No: {
                name: "Nem"
            }
        }
    },
    issuesDescriptionUsbhub: {
        placeholder: "Ismertesse problémáit"
    }
}