export default {
    title: "Objednávejte jménem",
    model: {
        email: {
            label: "E-mailem",
            placeholder: "Pro objednání jménem vyplňte prosím e-mail uživatele",
            errors: {
                myself: "E-mail se musí lišit od vašeho"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Předložit",
            processing: "Odesílání"
        }
    },
    error: {
        userNotExists: "{email} neexistuje"
    }
}