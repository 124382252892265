export default {
    return: {
        title: "Vrátit se",
        header: "Položky k vrácení",
        message: "Je třeba vrátit následující vybavení společnosti. Po odeslání tohoto formuláře budete samostatně kontaktováni, abychom se domluvili na vyzvednutí a/nebo odeslání. Váš manažer bude informován o stavu vašeho vrácení.",

        model: {
            address: {
                country: {
                    label: "Země"
                }
            },
            exclude: {
                label: "Vyloučit z vrácení"
            },
            textarea: {
                placeholder: "Důvod: souhlas manažera, vrácené dříve atd."
            },
            return_note: {
                label: "Zpětné poznámky",
                placeholder: "Volitelné: Zadejte poznámky týkající se položek, které mají být vráceny."
            },
            additional_disposal: {
                label: "Máte nějaké fyzické záznamy DXC, které je třeba bezpečně zlikvidovat?"
            },
            additional_item: {
                type: {
                    label: "Typ",
                    options: {
                        "chair": "Židle",
                        "desk": "Lavice",
                        "desk riser": "Stůl na stůl",
                        "docking station": "Dokovací stanice",
                        "footrest": "Opěrka nohou",
                        "headset": "Sluchátka",
                        "hdmi cable": "HDMI kabel",
                        "keyboard": "Klávesnice",
                        "kvm switch": "Přepínač KVM",
                        "lamp": "Svítilna",
                        "laptop riser": "Stojan na notebook",
                        "monitor": "Monitor",
                        "mouse": "Myš",
                        "other": "jiný",
                        "speakerphone": "Hlasitý telefon",
                        "usb hub": "USB Hub",
                        "webcam": "Webová kamera",
                    }
                },
                detail: {
                    label: "Detail",
                    placeholder: "Detail"
                },
                quantity: {
                    label: "Množství",
                    placeholder: "Množství"
                }
            }
        },

        submitted: {
            title: "Vrácení odesláno",
            message: "Vaše vrácení bylo odesláno. Ohledně vyzvednutí a/nebo odeslání položky budete kontaktováni samostatně."
        },
        nothingToReturn: {
            title: "Informace o vrácení",
            message: "V tuto chvíli nemáte v rámci Gear žádné položky, které byste mohli vrátit."
        },
        notEligible: {
            title: "Informace o vrácení",
            message: "V tuto chvíli Gear nemůže zpracovat váš návrat. Obraťte se na místního manažera nebo personální tým ohledně způsobu vrácení vašich položek."
        },

        button: {
            add: {
                cta: "Přidat neuvedenou položku"
            },
            submit: {
                processing: "Odesílání",
                cta: "Předložit"
            }
        }
    }
}