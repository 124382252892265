export default {
    title: "Articles non livrés",
    header: "@:views.survey.deliveryfaq.title",
    result: {
        header: "Merci",
        message: "Merci pour votre réponse."
    },
    message: "Vous avez indiqué que vous n'avez pas reçu votre article bien que le coursier l'ait marqué comme livré.",
    cta: "Veuillez suivre ces étapes pour nous aider à résoudre le problème :",
    items: {
        1: {
            message: "Cliquez sur le lien de suivi :"
        },
        2: {
            message: "Vérifiez l'état de la livraison. Vérifier:",
            items: {
                1: "Qui a signé pour le colis",
                2: "Si a été renvoyé à l'expéditeur",
                3: "Si le colis est en attente de retrait"
            }
        },
        3: {
            message: "Vérifiez s'il y a un avis de livraison indiquant où l'article a pu être laissé."
        },
        4: {
            message: "Renseignez-vous auprès des voisins, parents et amis qui pourraient avoir pris le colis."
        },
        5: {
            message: "Vérifiez les lieux communs où les coursiers laissent des objets en votre absence."
        }
    },
    question: "Cela a-t-il aidé à résoudre votre problème de livraison ? Avez-vous maintenant l'article ou savez-vous qui l'a?",
    buttons: {
        yes: "Oui",
        no: "Non",
        saving: "Économie"
    }
}