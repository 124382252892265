export default {
    login: "Anmeldung",
    logout: "Ausloggen",
    home: "Heim",
    user: {
        profile: {
            edit: "Profil bearbeiten"
        }
    },
    approvals: {
        delegates: "Delegierte"
    },
    impersonation: "Bestellung im Namen von"
}