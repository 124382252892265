export default {
    unverified: "Neověřeno",
    verificationLink: "Opětovné zaslání ověřovacího e-mailu",
    buttons: {
        delete: {
            cta: "Vymazat",
            processing: "Mazání"
        }
    },
    addressDeleted: "Adresa byla smazána",
    verificationEmailSent: "E-mail byl odeslán",
    deleteAddressConfirmation: "Opravdu chcete smazat tuto adresu?"
}