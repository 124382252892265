export default {
    country: {
        label: "居住国家",
        errors: {
            required: "需要选择"
        }
    },
    entity: {
        label: "实体",
        errors: {
            required: "需要选择"
        }
    },
    location: {
        label: "办公地点",
        errors: {
            required: "需要选择"
        }
    },
    workplace: {
        values: {
            "Dedicated Office": {
                title: "专用办公室",
                description: "家庭办公室，有一个专门用作办公室的永久位置"
            },
            "Semi-Dedicated": {
                title: "半专用",
                description: "满足家庭工作环境需求的临时位置"
            },
            "Floating": {
                title: "漂浮的",
                description: "没有专门的家庭办公室空间，但与餐厅或客厅等其他地点共享"
            }
        },
        errors: {
            required: "需要选择"
        }
    },
    priority: {
        label: "最高优先级",
        values: {
            "Monitor": {
                title: "监视器"
            },
            "Docking Station": {
                title: "扩展坞"
            },
            "Keyboard/Mouse": {
                title: "键盘/鼠标"
            },
            "Web Cam": {
                title: "摄像头"
            },
            "Headset": {
                title: "耳机"
            },
            "Office Chair": {
                title: "办公椅"
            },
            "Desk": {
                title: "桌子"
            },
            "Adapters": {
                title: "适配器"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "不间断电源 (UPS)"
            },
            "Something Else": {
                title: "别的东西"
            },
            "Nothing Needed": {
                title: "什么都不需要"
            }
        },
        errors: {
            requiredUnless: "需要选择",
            requiredIf: "需要选择",
            required: "需要选择"
        }
    },
    priorityDetails: {
        placeholder: "提供详情"
    },
    secondaryPriority: {
        label: "第二优先级",
        values: {
            "Monitor": {
                title: "监视器"
            },
            "Second Monitor": {
                title: "第二台显示器"
            },
            "Docking Station": {
                title: "扩展坞"
            },
            "Keyboard/Mouse": {
                title: "键盘/鼠标"
            },
            "Web Cam": {
                title: "摄像头"
            },
            "Headset": {
                title: "耳机"
            },
            "Office Chair": {
                title: "办公椅"
            },
            "Desk": {
                title: "桌子"
            },
            "Adapters": {
                title: "适配器"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "不间断电源 (UPS)"
            },
            "Something Else": {
                title: "别的东西"
            },
            "Nothing Needed": {
                title: "什么都不需要"
            }
        },
        errors: {
            requiredUnless: "需要选择",
            requiredIf: "需要选择",
            required: "需要选择"
        }
    },
    secondaryPriorityDetails: {
        placeholder: "提供详情"
    },
    improveWorkspace: {
        label: "你会如何改善你的工作空间？",
        placeholder: "选修的",
    },
    improveRemoteWorking: {
        label: "在设备之外，什么可以帮助您更有效地进行远程工作？",
        placeholder: "选修的",
    },
    receivedEquipmentHeadset: {
        label: "您在过去 24 个月内是否收到过公司的无线耳机或免提电话？",
        values: {
            Yes: {
                name: "是的"
            },
            No: {
                name: "不"
            }
        }
    },
    issuesHeadset: {
        label: "你有问题吗？",
        values: {
            Yes: {
                name: "是的"
            },
            No: {
                name: "不"
            }
        }
    },
    issuesDescriptionHeadset: {
        placeholder: "描述您的问题"
    },
    isOfficeWorker: {
        label: "你在办公室全职工作吗？",
        values: {
            Yes: {
                name: "是的"
            },
            No: {
                name: "不"
            }
        }
    },
    receivedKeyboardMouse: {
        label: "您在过去 12 个月内是否收到过公司的键盘和鼠标？",
        values: {
            Yes: {
                name: "是的"
            },
            No: {
                name: "不"
            }
        }
    },
    issuesKeyboardMouse: {
        label: "你有问题吗？",
        values: {
            Yes: {
                name: "是的"
            },
            No: {
                name: "不"
            }
        }
    },
    issuesDescriptionKeyboardMouse: {
        placeholder: "描述您的问题"
    },
    needWebcam: {
        label: "您是否需要一个外部网络摄像头来工作（而不是笔记本电脑内置的网络摄像头？",
        values: {
            Yes: {
                name: "是的"
            },
            No: {
                name: "不"
            }
        }
    },
    needWebcamExternal: {
        label: "您需要外部 USB 网络摄像头（不是笔记本电脑内置的网络摄像头）吗？",
        values: {
            Yes: {
                name: "是的"
            },
            No: {
                name: "不"
            }
        }
    },
    needMonitor: {
        label: "你需要显示器来工作吗？",
        values: {
            Yes: {
                name: "是的"
            },
            No: {
                name: "不"
            }
        }
    },
    receivedMonitor: {
        label: "您在过去 4 年内是否从公司收到过显示器？",
        values: {
            Yes: {
                name: "是的"
            },
            No: {
                name: "不"
            }
        }
    },
    issuesMonitor: {
        label: "你有问题吗？",
        values: {
            Yes: {
                name: "是的"
            },
            No: {
                name: "不"
            }
        }
    },
    issuesDescriptionMonitor: {
        placeholder: "描述您的问题"
    },
    needUsbhub: {
        label: "您需要外部 USB 集线器才能工作吗？",
        values: {
            Yes: {
                name: "是的"
            },
            No: {
                name: "不"
            }
        }
    },
    issuesDescriptionUsbhub: {
        placeholder: "描述您的问题"
    }
}