export default {
    title: "Helpdesk",
    header: "Helpdesk",
    model: {
        email: {
            label: "E-mailadres gebruiker",
            placeholder: "Vul het e-mailadres van de gebruiker in"
        }
    },
    cta: "Indienen",
    processing: "Bezig met laden"
}