export default {
    title: "Helpdesk",
    header: "Helpdesk",
    model: {
        email: {
            label: "E-mail dell'utente",
            placeholder: "Si prega di compilare l'e-mail dell'utente"
        }
    },
    cta: "Invia",
    processing: "Caricamento"
}