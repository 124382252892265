import mixin_validator from "@/helpers/validator";
import mixin_form_payload from "@/mixins/form/payload";
import mixin_form_dynamic from "@/mixins/form/dynamic";

const t_path = "components.address.form";

export default {
    mixins: [mixin_validator, mixin_form_payload, mixin_form_dynamic],
    methods: {
        address_form_getAddressFields(config, {tags}) {

            tags = "undefined" === typeof tags ? [] : tags;

            return config
                .filter(
                    field => "undefined" === typeof field.tags
                        || 0 < field.tags.filter(fieldTag => -1 !== tags.indexOf(fieldTag)).length
                )
                .map(field => {

                    const result = Object.assign({}, field);

                    if (field.mask && Array.isArray(field.mask) && 1 === field.mask.length) {

                        field.mask = field.mask[0];
                    }

                    return result;
                });
        },
        address_form_getAddressFieldTranslation(field, target, country) {

            if ("undefined" === typeof field.display) {

                return null;
            }

            const fieldName = field.field.trim(), fieldTarget = field.display[target].trim().replace(/\.$/, "");
            let path;

            if (country && "string" === typeof country) {

                path = `${t_path}.field.${country}.${fieldName}.${target}.${fieldTarget}`;

                if (this.$te(path)) {

                    return this.$t(path);
                }
            }

            path = `${t_path}.field.default.${fieldName}.${target}.${fieldTarget}`;

            return this.$te(path) ? this.$t(path) : field.display[target];
        },
        address_form_getAddressFieldOptions(field) {

            const options = field.options;

            return options.map(option => {

                const path = `${t_path}.field.default.${field.field}.options.${option.name}`;
                const translation = this.$t(path);

                return {
                    value: option.id,
                    name: translation === path ? option.name : translation
                }
            })
        },
        address_form_getAddressFieldOption(field, value) {

            return field.options.reduce(
                (result, option) => {

                    if (null === result && option.id === value) {

                        const path = `${t_path}.field.default.${field.field}.options.${option.name}`;
                        const translation = this.$t(path);

                        return {
                            value: option.id,
                            name: translation === path ? option.name : translation
                        };
                    }

                    return result;
                },
                null
            );
        },
        address_form_getAddressFieldDatepickerMinDate(field) {

            return this.form_dynamic_getDatepickerMinDate(field);
        },
        address_form_getAddressFieldDatepickerMaxDate(field) {

            return this.form_dynamic_getDatepickerMaxDate(field);
        },
        address_form_getAddressFieldDatepickerDisableDates(field) {

            return this.form_dynamic_getDatepickerDisableDates(field);
        },
        address_form_createValidationRules(fields) {

            const model = {
                country: {}
            };

            fields.forEach(field => {

                model[field.field] = this.validator_createDynamicValidationRules(field.validation, {});
                model[field.field] = this.validator_applyDynamicMask(field.mask, model[field.field]);
            });

            return {model};
        },
        address_form_getErrorMessages(fields, country) {

            return fields.reduce((errors, field) => {

                return {
                    ...errors,
                    [field.field]: {
                        required: this.$t(`components.address.form.errors.required`, {
                            field: this.address_form_getAddressFieldTranslation(field, "label", country)
                        }),
                        mask: this.$t(`components.address.form.errors.mask`, {
                            field: this.address_form_getAddressFieldTranslation(field, "label", country)
                        })
                    }
                };
            }, {});
        },
        address_form_getPayload(fields, model) {

            return Object.keys(model).reduce(
                (payload, fieldKey) => {

                    const field = fields.find(field => field.field === fieldKey);

                    payload[fieldKey] = model[fieldKey];

                    // Some model fields do not exist in address config
                    if ("undefined" !== typeof field) {

                        if ("datepicker" === field.type) {

                            payload[fieldKey] = this.form_payload_getDateUtcMilliseconds(model[fieldKey]);
                        }
                    }

                    return payload
                },
                {}
            );
        }
    }
}