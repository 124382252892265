import mixin_quiz_conditions from "./conditions.mjs";

export default {
    mixins: [mixin_quiz_conditions],
    methods: {
        /**
         * Handles CSS classes that needed to be shown for component
         * @param quiz
         * @param component
         * @returns {*|string|null|Array}
         */
        quiz_css_getComponentWrapperCssClass(quiz, component) {

            const cssClasses = component.wrapperCssClass;

            // Check class is set
            if ("undefined" === typeof cssClasses) {

                return null;
            }

            // Check for we need to return class as is
            if ("string" === typeof cssClasses || Array.isArray(cssClasses)) {

                return cssClasses;
            }

            /**
             * Go through css classes and their conditions
             * {
             *     ...
             *     wrapperCssClass: {
             *         "some-css-class": true, // Always display
             *
             *         // Conditional displaying (OR condition)
             *         // CSS class is attached if one of conditions match
             *         "some-css-class": {
             *             <component_name>: true // Works if component has any value
             *             <component_name>: ["value1", "value2"] // Works if component has one of specified values
             *         },
             *
             *         // Conditional displaying (AND condition)
             *         // CSS class is attached if all conditions match
             *         "some-css-class": [
             *             {
             *                 // OR condition structure
             *                 <component_name>: true,
             *                 <component_name>: ["value1", "value2"],
             *             }
             *         ]
             *     }
             *     ...
             * }
             */
            return Object.keys(cssClasses).reduce((result, cssClass) => {

                const condition = cssClasses[cssClass];

                if (this.quiz_conditions_match(quiz, condition)) {

                    result.push(cssClass);
                }

                return result;
            }, []);
        }
    }
}