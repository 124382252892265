export default {
    country: {
        label: "País de residencia",
        errors: {
            required: "Se requiere una selección"
        }
    },
    entity: {
        label: "Entidad",
        errors: {
            required: "Se requiere una selección"
        }
    },
    location: {
        label: "Localización de la oficina",
        errors: {
            required: "Se requiere una selección"
        }
    },
    workplace: {
        values: {
            "Dedicated Office": {
                title: "Oficina dedicada",
                description: "Oficina en casa con una ubicación permanente dedicada como oficina"
            },
            "Semi-Dedicated": {
                title: "Semi-Dedicado",
                description: "Ubicación temporal para adaptarse a las necesidades de un entorno de trabajo en el hogar"
            },
            "Floating": {
                title: "Flotante",
                description: "No hay espacio dedicado para la oficina en el hogar, pero se comparte con otros lugares, como un comedor o una sala de estar."
            }
        },
        errors: {
            required: "Se requiere una selección"
        }
    },
    priority: {
        label: "Más alta prioridad",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Docking Station": {
                title: "Estación de acoplamiento"
            },
            "Keyboard/Mouse": {
                title: "Teclado/Ratón"
            },
            "Web Cam": {
                title: "Cámara web"
            },
            "Headset": {
                title: "Auriculares"
            },
            "Office Chair": {
                title: "Silla de oficina"
            },
            "Desk": {
                title: "Escritorio"
            },
            "Adapters": {
                title: "Adaptadores"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Fuente de alimentación ininterrumpida (UPS)"
            },
            "Something Else": {
                title: "Algo más"
            },
            "Nothing Needed": {
                title: "nada necesario"
            }
        },
        errors: {
            requiredUnless: "Se requiere una selección",
            requiredIf: "Se requiere una selección",
            required: "Se requiere una selección"
        }
    },
    priorityDetails: {
        placeholder: "Proporcionar detalles"
    },
    secondaryPriority: {
        label: "Segunda prioridad más alta",
        values: {
            "Monitor": {
                title: "Monitor"
            },
            "Second Monitor": {
                title: "segundo monitor"
            },
            "Docking Station": {
                title: "Estación de acoplamiento"
            },
            "Keyboard/Mouse": {
                title: "Teclado/Ratón"
            },
            "Web Cam": {
                title: "Cámara web"
            },
            "Headset": {
                title: "Auriculares"
            },
            "Office Chair": {
                title: "Silla de oficina"
            },
            "Desk": {
                title: "Escritorio"
            },
            "Adapters": {
                title: "Adaptadores"
            },
            "Uninterruptible Power Supply (UPS)": {
                title: "Fuente de alimentación ininterrumpida (UPS)"
            },
            "Something Else": {
                title: "Algo más"
            },
            "Nothing Needed": {
                title: "nada necesario"
            }
        },
        errors: {
            requiredUnless: "Se requiere una selección",
            requiredIf: "Se requiere una selección",
            required: "Se requiere una selección"
        }
    },
    secondaryPriorityDetails: {
        placeholder: "Proporcionar detalles"
    },
    improveWorkspace: {
        label: "¿Cómo mejorarías tu espacio de trabajo?",
        placeholder: "Opcional",
    },
    improveRemoteWorking: {
        label: "Fuera del equipo, ¿qué le ayudaría a ser más eficaz en el trabajo remoto?",
        placeholder: "Opcional",
    },
    receivedEquipmentHeadset: {
        label: "¿Ha recibido un auricular inalámbrico o un altavoz de la empresa en los últimos 24 meses?",
        values: {
            Yes: {
                name: "Sí"
            },
            No: {
                name: "No"
            }
        }
    },
    issuesHeadset: {
        label: "¿Tienes problemas con eso?",
        values: {
            Yes: {
                name: "Sí"
            },
            No: {
                name: "No"
            }
        }
    },
    issuesDescriptionHeadset: {
        placeholder: "Describa sus problemas"
    },
    isOfficeWorker: {
        label: "¿Trabajas desde la oficina a tiempo completo?",
        values: {
            Yes: {
                name: "Sí"
            },
            No: {
                name: "No"
            }
        }
    },
    receivedKeyboardMouse: {
        label: "¿Ha recibido un teclado y un mouse de la empresa en los últimos 12 meses?",
        values: {
            Yes: {
                name: "Sí"
            },
            No: {
                name: "No"
            }
        }
    },
    issuesKeyboardMouse: {
        label: "¿Tienes problemas con eso?",
        values: {
            Yes: {
                name: "Sí"
            },
            No: {
                name: "No"
            }
        }
    },
    issuesDescriptionKeyboardMouse: {
        placeholder: "Describa sus problemas"
    },
    needWebcam: {
        label: "¿Necesita una cámara web externa para el trabajo (no la integrada en su computadora portátil?",
        values: {
            Yes: {
                name: "Sí"
            },
            No: {
                name: "No"
            }
        }
    },
    needWebcamExternal: {
        label: "¿Necesita una cámara web USB externa (no la integrada en su computadora portátil)?",
        values: {
            Yes: {
                name: "Sí"
            },
            No: {
                name: "No"
            }
        }
    },
    needMonitor: {
        label: "¿Necesitas un monitor para el trabajo?",
        values: {
            Yes: {
                name: "Sí"
            },
            No: {
                name: "No"
            }
        }
    },
    receivedMonitor: {
        label: "¿Ha recibido un monitor de la Compañía en los últimos 4 años?",
        values: {
            Yes: {
                name: "Sí"
            },
            No: {
                name: "No"
            }
        }
    },
    issuesMonitor: {
        label: "¿Tienes problemas con eso?",
        values: {
            Yes: {
                name: "Sí"
            },
            No: {
                name: "No"
            }
        }
    },
    issuesDescriptionMonitor: {
        placeholder: "Describa sus problemas"
    },
    needUsbhub: {
        label: "¿Necesita un concentrador USB externo para trabajar?",
        values: {
            Yes: {
                name: "Sí"
            },
            No: {
                name: "No"
            }
        }
    },
    issuesDescriptionUsbhub: {
        placeholder: "Describa sus problemas"
    }
}