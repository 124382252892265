export default {
    title: "Przegląd zamówienia",
    header: "@:views.order.submitted.title",

    noItems: "Brak wybranych produktów",

    model: {
        addressId: {
            errors: {
                required: "Proszę wypełnić adres",
                noAddress: "Żaden adres nie jest dostępny. Skontaktuj się z pomocą techniczną."
            }
        },
        product: {
            placeholder: "Proszę wypełnić kwotę"
        }
    },

    createNewAddress: "Utwórz nowy adres",

    button: {
        submit: {
            cta: "Prześlij zamówienie",
            processing: "Przedkładający"
        },
        remove: "Usuń z zamówienia"
    },

    errors: {
        itemNotExist: "Artykuł «{name}» nie istnieje w katalogu. Zostanie usunięty.",
        requestError: "Wystąpił problem ze złożeniem zamówienia. Spróbuj przesłać ponownie."
    }
}