export default {
    return: {
        title: "Retour",
        header: "Articles à retourner",
        message: "L'équipement suivant de la Société doit être retourné. Après avoir soumis ce formulaire, vous serez contacté séparément pour organiser le ramassage et/ou les expéditions. Votre gestionnaire sera informé de l'état de votre retour.",

        model: {
            address: {
                country: {
                    label: "Pays"
                }
            },
            exclude: {
                label: "Exclure du retour"
            },
            textarea: {
                placeholder: "Raison : approbation du responsable, retour précédent, etc."
            },
            return_note: {
                label: "Notes de retour",
                placeholder: "Facultatif : saisissez des remarques concernant les articles à retourner."
            },
            additional_disposal: {
                label: "Avez-vous des enregistrements DXC physiques qui doivent être éliminés en toute sécurité"
            },
            additional_item: {
                type: {
                    label: "Taper",
                    options: {
                        "chair": "Chaise",
                        "desk": "Bureau",
                        "desk riser": "Rehausseur de bureau",
                        "docking station": "Station d'accueil",
                        "footrest": "Repose pieds",
                        "headset": "Casque",
                        "hdmi cable": "Câble HDMI",
                        "keyboard": "Clavier",
                        "kvm switch": "Commutateur KVM",
                        "lamp": "Lampe",
                        "laptop riser": "Support pour ordinateur portable",
                        "monitor": "Moniteur",
                        "mouse": "Souris",
                        "other": "Autre",
                        "speakerphone": "Haut-parleur",
                        "usb hub": "Concentrateur USB",
                        "webcam": "Webcam",
                    }
                },
                detail: {
                    label: "Détail",
                    placeholder: "Détail"
                },
                quantity: {
                    label: "Quantité",
                    placeholder: "Quantité"
                }
            }
        },

        submitted: {
            title: "Retour soumis",
            message: "Votre retour a été soumis. Vous serez contacté séparément concernant le ramassage et/ou l'expédition de l'article."
        },
        nothingToReturn: {
            title: "Informations de retour",
            message: "Pour le moment, vous n'avez aucun article à retourner dans Gear."
        },
        notEligible: {
            title: "Informations de retour",
            message: "Pour le moment, Gear ne peut pas traiter votre retour. Veuillez contacter votre responsable local ou votre équipe RH pour savoir comment retourner vos articles."
        },

        button: {
            add: {
                cta: "Ajouter un article non répertorié"
            },
            submit: {
                processing: "Soumission",
                cta: "Soumettre"
            }
        }
    }
}