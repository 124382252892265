import mixin_quiz_conditions from "./quiz/conditions.mjs"

export default {
    mixins: [mixin_quiz_conditions],
    computed: {
        quiz_translationParameters() {

            return {
                userName: this.$store.getters["user/profile/firstName"],
                privacyPolicyUrl: this.$router.resolve({name: "privacy"}).fullPath
            };
        }
    },
    methods: {
        quiz_getComponentImage(url) {

            return /^(http|https):\/\//.test(url) ? url : `${process.env.BASE_URL}${url}`;
        },
        quiz_getComponentText(component, key, defaultValue = null) {

            const path = `quiz.${component}.${key}`;

            if (this.$te(path)) {

                return this.$t(path, this.quiz_translationParameters);
            }

            return defaultValue;
        },
        quiz_getPageText(key, original) {

            if (!this.$te(`views.quiz.${key}`)) {

                return original;
            }

            return this.$t(`views.quiz.${key}`, this.quiz_translationParameters)
        },
        quiz_componentHasValue(name) {

            if (!this.model[name]) {

                return false;
            }

            if (Array.isArray(this.model[name])) {

                return 0 < this.model[name].length
            }

            return !!this.model[name];
        },
        quiz_getComponentValue(name) {

            return this.model[name]
        },
        quiz_isComponentOptionShouldBeDisplayed(quiz, option) {

            if ("undefined" !== typeof option.display) {

                return this.quiz_conditions_match(quiz, option.display)
            }

            if ("undefined" !== typeof option.hide) {

                return !this.quiz_conditions_match(quiz, option.hide)
            }

            return true;
        },
        quiz_isComponentDisabled(quiz, component) {

            if ("undefined" !== typeof component.disable) {

                return this.quiz_conditions_match(quiz, component.disable)
            }

            return false;
        },
        quiz_isPageShouldBeDisplayed(quiz, page) {

            if ("undefined" !== typeof page.display) {

                return this.quiz_conditions_match(quiz, page.display);
            }

            if ("undefined" !== typeof page.hide) {

                return !this.quiz_conditions_match(quiz, page.hide);
            }

            return true;
        },
        quiz_isComponentShouldBeDisplayed(quiz, component) {

            if ("undefined" !== typeof component.display) {

                return this.quiz_conditions_match(quiz, component.display);
            }

            if ("undefined" !== typeof component.hide) {

                return !this.quiz_conditions_match(quiz, component.hide);
            }

            return true;
        },
        quiz_getComponentsToDisplay(quiz, components) {

            const result = [];

            components.forEach(component => {

                if (this.quiz_isComponentShouldBeDisplayed(quiz, component)) {

                    // Check component has options and any option is available
                    const options = quiz.components[component.name].options;

                    if (options && Array.isArray(options)) {

                        // Look for all available options for current conditions
                        const totalAvailable = options.filter(option => this.quiz_isComponentOptionShouldBeDisplayed(quiz, option));

                        if (0 < totalAvailable.length) {

                            result.push(component);
                        }

                    } else {

                        result.push(component);
                    }
                }
            });

            return result;
        }
    }
}