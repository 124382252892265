import {WebStorage} from './storage/WebStorage';
import MemoryStorage from './storage/MemoryStorage';

const _global = (typeof window !== 'undefined' ? window : global || {});

export const USER_TOKEN_STORAGE_KEY = process.env.VUE_APP_USER_TOKEN_STORAGE_KEY || "token";
export const USER_IMPERSONATOR_TOKEN_STORAGE_KEY = "impersonator_token"

let store, ls;

export function getStorage() {

    return ls;
}

export default {
    install(app, options = {}) {

        const _options = Object.assign({}, options, {
            storage: options.storage || 'local',
            name: options.name || 'ls',
        });

        switch (_options.storage) { // eslint-disable-line
            case 'local':
                store = 'localStorage' in _global
                    ? _global.localStorage
                    : null
                ;
                break;

            case 'session':
                store = 'sessionStorage' in _global
                    ? _global.sessionStorage
                    : null
                ;
                break;
            case 'memory':
                store = MemoryStorage;
                break;
        }

        if (!store) {

            // Use memory storage by default
            store = MemoryStorage;
        }

        ls = new WebStorage(store);

        ls.setOptions(Object.assign(ls.options, {
            namespace: "mp__",
        }, _options || {}));

        ls.getExpire = name => {

            const value = ls.get(name);

            return null !== value
                ? JSON.parse(ls.storage.getItem(ls.options.namespace + name)).expire
                : null;
        }

        app.provide("$storage", ls);
        app.config.globalProperties.$storage = ls;
    }
}