export default {
    title: "Bestel namens",
    model: {
        email: {
            label: "E-mail",
            placeholder: "Vul het e-mailadres van de gebruiker in om namens te bestellen",
            errors: {
                myself: "E-mail moet verschillen van uw"
            }
        }
    },
    cta: {
        impersonate: {
            cta: "Indienen",
            processing: "Indienen"
        }
    },
    error: {
        userNotExists: "{email} bestaat niet"
    }
}