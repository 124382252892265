export default {
    required: "Um valor é obrigatório",
    email: "O valor não é um endereço de e-mail válido",
    mask: "O valor é inválido",
    maxValue: "O valor máximo é {maxValue}",
    regex: "O valor é inválido",
    server: {
        DELEGATES_NOT_ENABLED: "Os delegados não estão habilitados para este parceiro.",
        DELEGATE_DOES_NOT_EXIST: "Delegado não existe",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "Não é possível atualizar um delegado que não pertence a você.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Não é possível excluir um delegado que não pertence a você.",
        DELEGATE_ALREADY_EXISTS: "Já existe uma regra de delegação que corresponde a estes critérios.",
        DELEGATE_CANNOT_BE_SELF: "Você não pode se adicionar como delegado."
    }
}