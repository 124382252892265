export default {
    user: {
        create: {
            title: "Nuevo Usuario",
            model: {
                email: {
                    label: "Correo electrónico",
                    placeholder: "Llenar correo electrónico"
                },
                name: {
                    label: "Nombre",
                    placeholder: "Nombre de relleno"
                },
                country: {
                    label: "País",
                    placeholder: "Llenar país"
                }
            },
            cta: {
                submit: {
                    text: "Entregar",
                    processing: "Sumisión"
                },
                cancel: {
                    text: "Cancelar"
                }
            }
        }
    }
}