export default {
    model: {
        email: {
            label: "Correo electrónico",
            placeholder: "Delegado de correo electrónico"
        },
        start_on: {
            label: "Fecha de inicio",
            placeholder: "Fecha de inicio de delegación"
        },
        end_on: {
            label: "Fecha final",
            placeholder: "Opcional"
        },
        accept: {
            tip: "Al delegar su responsabilidad de aprobación, usted certifica que asume la responsabilidad por cualquier pedido aprobado por su delegado.",
            label: "Acepto"
        }
    },
    buttons: {
        submit: {
            cta: "Confirmar",
            processing: "Ahorro"
        },
        close: {
            cta: "Cancelar"
        }
    },
    saved: "Salvado"
}