const text = {
    countryAfterComponentsBeforeSelection: "Houd er rekening mee dat uw landkeuze wordt gebruikt als onderdeel van uw verzendadres om producten rechtstreeks naar u te verzenden. Als u uw land niet in de lijst ziet, is Gear momenteel niet beschikbaar in uw land.",
    countryAfterComponentsAllowedSelection: "Houd er rekening mee dat uw landkeuze wordt gebruikt als onderdeel van uw verzendadres om producten rechtstreeks naar u te verzenden.",
    countryAfterComponentsLimitedSelection: "Uitrusting is momenteel beschikbaar in jouw land, maar wordt in fases geopend. Tenzij u een melding heeft ontvangen, heeft u op dit moment mogelijk geen toegang tot Gear in uw land.",
    disclaimer: "Door op de volgende knop hieronder te klikken, erken ik dat ik een medewerker van het bedrijf ben en geef ik toestemming aan het bedrijf en zijn partner RemoteRetail om de persoonlijke informatie die op deze site is ingediend op te slaan en te verwerken om mij de gevraagde inhoud te bieden. RemoteRetail heeft de contactgegevens nodig die ik verstrek, zodat zij contact met mij kunnen opnemen met informatie over mijn bestellingen. Externe leveranciers kunnen mijn informatie ook ontvangen om producten naar mij te verzenden.</p><p> Ik kan me op elk moment afmelden voor deze communicatie. Raadpleeg het <a href='{privacyPolicyUrl}' target='_blank'>privacybeleid</a> voor meer informatie over hoe ik me kan afmelden, de privacypraktijken en toezeggingen om mijn privacy te beschermen en te respecteren."
};

export default {
    title: "Welkom, {userName}!",
    description: {
        default: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`,
        virtualworker: `We are proud to introduce Gear: <i>the</i> source for Company IT accessories that you need to work effectively from home. Gear allows you to order equipment shipped directly to your door without third party procurement systems or subsequent approvals.`
    },
    text: {
        default: "Om te beginnen stellen we je een aantal vragen.",
        virtualworker: "Om te beginnen zullen we u een reeks vragen stellen om ons te helpen uw thuiswerkruimte te begrijpen om de toekomstige richting van dit programma vorm te geven."
    },
    afterComponents: {
        beforeSelection: `${text.countryAfterComponentsBeforeSelection}`,
        allowedSelection: `${text.countryAfterComponentsAllowedSelection}`,
        limitedSelection: `${text.countryAfterComponentsLimitedSelection}`,
        autoLoginBeforeSelection: `${text.countryAfterComponentsBeforeSelection}<br/><br/>${text.disclaimer}`,
        autoLoginAllowedSelection: `${text.countryAfterComponentsAllowedSelection}<br/><br/>${text.disclaimer}`,
        autoLoginLimitedSelection: `${text.countryAfterComponentsLimitedSelection}<br/><br/>${text.disclaimer}`
    },
    buttons: {
        back: "Rug",
        next: "Volgende",
        submit: "Indienen"
    },
    entityinfo: {
        title: "entiteit informatie",
        description: "Om ons te helpen uw bestelling correct te routeren, verzoeken wij u ons de volgende informatie te verstrekken:",
    },
    equipmentsurvey: {
        title: "Apparatuur onderzoek",
        description: "Op de volgende pagina wordt u gevraagd welk type apparatuur u het meest nodig heeft om het bedrijf te helpen de prioriteiten beter te begrijpen.",
        info: "Houd er rekening mee dat de getoonde apparatuur alleen bedoeld is voor het verzamelen van informatie en niet impliceert dat het bedrijf deze apparatuur in de toekomst zal leveren."
    },
    workplace: {
        title: "Vertel eens meer over je thuiskantoor?",
        description: "Selecteer een van de onderstaande keuzes om ons een beter inzicht te geven in uw werkruimte",
    },
    priority: {
        title: "Apparatuur prioriteiten",
        description: "Selecteer uw top twee keuzes van apparatuur die u het meest nodig heeft"
    },
    "additional-questions": {
        title: "Bijkomende vragen"
    },
    "equipment-assessment": {
        title: "Beoordeling van apparatuur",
        description: "De volgende reeks vragen <b>bepaalt op basis van uw antwoorden welke apparatuur voor u beschikbaar zal zijn om te bestellen vanuit Gear.</b>"
    },
    headset: {
        title: "Huidige uitrusting"
    },
    "office-worker": {
        title: "Kantoor"
    },
    "keyboard-mouse": {
        title: "Toetsenbord en muis"
    },
    webcam: {
        title: "Webcam"
    },
    monitor: {
        title: "Monitor"
    },
    usbhub: {
        title: "USB-hub"
    },
    "equipment-list": {
        title: "Apparatuur",
        init: "initEquipmentLijst",
        description: {
            loading: "<span class=\"animate-spin icon-spinner\"></span>",
            error: "Foute aanvraag. Er is een fout opgetreden.",
            allowed: "Op basis van je antwoorden kom je in aanmerking voor de volgende items:{items}",
            empty: "Op basis van je antwoorden kom je momenteel niet in aanmerking voor items in Gear."
        },
        text: "<ul class=\"text-left\" style=\"width: 300px; margin: 2rem auto 0\">{list}</ul>",
        afterComponents: "<small>Als u correcties moet aanbrengen, drukt u op de terugknop om uw antwoorden te wijzigen.</small>"
    },
    saved: "opgeslagen"
}