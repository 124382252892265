export default {
    type: {
        "ac_router": "You have reached the ordering limit of AC routers",
        "chair": "You have reached the ordering limit of chairs",
        "default": "You have reached the ordering limit",
        "default_multiple": "You have reached the ordering limit of similar products",
        "desk": "You have reached the ordering limit of desks",
        "desktop": "You have reached the ordering limit of desktop",
        "desktop_riser": "You have reached the ordering limit of desktop risers",
        "detector": "You have reached the ordering limit of vape and noise detectors",
        "displayport_cable": "You have reached the ordering limit of displayport cables",
        "docking station": "You have reached the ordering limit of docking stations",
        "dual_usb_adapter": "You have reached the ordering limit of dual band USB adapters",
        "ear cushion": "You have reached the ordering limit of ear cushions",
        "footrest": "You have reached the ordering limit of footrests",
        "hdmi_cable": "You have reached the ordering limit of HDMI Cables",
        "hdmi_displayport_cable": "You have reached the ordering limit of HDMI to DisplayPort cables",
        "hdmi_vga_cable": "You have reached the ordering limit of HDMI to VGA cables",
        "headset": "You have reached the ordering limit of audio devices",
        "keyboard": "You have reached the ordering limit of keyboards",
        "kvm switch": "You have reached the ordering limit of kvm switches",
        "kvm_switch": "You have reached the ordering limit of kvm switches",
        "lamp": "You have reached the ordering limit of lamps",
        "laptop stand": "You have reached the ordering limit of laptop stands",
        "laptop": "You have reached the ordering limit of laptops",
        "mesh_router": "You have reached the ordering limit of mesh routers",
        "monitor": "You have reached the ordering limit of monitors",
        "mouse": "You have reached the ordering limit of mice",
        "patch_cord": "You have reached the ordering limit of Cat6 patch cords",
        "power_center": "You have reached the ordering limit of Power Centers",
        "power_strip": "You have reached the ordering limit of power strips",
        "smart_plug": "You have reached the ordering limit of smart plugs mini",
        "smart_power_strip": "You have reached the ordering limit of smart power strips",
        "thunderbolt_cable": "You have reached the ordering limit of USB-C to Thunderbolt",
        "travel_router": "You have reached the ordering limit of travel routers",
        "usb hub": "You have reached the ordering limit of usb hubs",
        "usb_adapter": "You have reached the ordering limit of usb adapters",
        "usb_hub": "You have reached the ordering limit of usb hubs",
        "webcam": "You have reached the ordering limit of webcams"
    }
}