export default {
    return: {
        title: "Ritorno",
        header: "Articoli da restituire",
        message: "La seguente attrezzatura aziendale deve essere restituita. Dopo aver inviato questo modulo, verrai contattato separatamente per organizzare il ritiro e/o le spedizioni. Il tuo manager sarà informato dello stato del tuo reso.",

        model: {
            address: {
                country: {
                    label: "Paese"
                }
            },
            exclude: {
                label: "Escludi dal reso"
            },
            textarea: {
                placeholder: "Motivo: approvazione del manager, restituito in precedenza ecc."
            },
            return_note: {
                label: "Note di ritorno",
                placeholder: "Facoltativo: inserire eventuali note relative agli articoli da restituire."
            },
            additional_disposal: {
                label: "Hai record DXC fisici che devono essere smaltiti in modo sicuro?"
            },
            additional_item: {
                type: {
                    label: "Tipo",
                    options: {
                        "chair": "Sedia",
                        "desk": "Scrivania",
                        "desk riser": "Alzata da scrivania",
                        "docking station": "Stazione di attracco",
                        "footrest": "Poggiapiedi",
                        "headset": "Auricolare",
                        "hdmi cable": "Cavo HDMI",
                        "keyboard": "Tastiera",
                        "kvm switch": "Commutatore KVM",
                        "lamp": "Lampada",
                        "laptop riser": "Supporto per computer portatile",
                        "monitor": "Tenere sotto controllo",
                        "mouse": "Topo",
                        "other": "Altro",
                        "speakerphone": "Vivavoce",
                        "usb hub": "Hub USB",
                        "webcam": "Webcam",
                    }
                },
                detail: {
                    label: "Dettaglio",
                    placeholder: "Dettaglio"
                },
                quantity: {
                    label: "Quantità",
                    placeholder: "Quantità"
                }
            }
        },

        submitted: {
            title: "Ritorno inviato",
            message: "Il reso è stato inviato. Sarai contattato separatamente per il ritiro e/o la spedizione dell'articolo."
        },
        nothingToReturn: {
            title: "Informazioni sul reso",
            message: "Al momento, non hai articoli da restituire all'interno di Gear."
        },
        notEligible: {
            title: "Informazioni sul reso",
            message: "Al momento, Gear non può elaborare il tuo reso. Contatta il tuo responsabile locale o il team delle risorse umane su come restituire i tuoi articoli."
        },

        button: {
            add: {
                cta: "Aggiungi articolo non in elenco"
            },
            submit: {
                processing: "Invio",
                cta: "Invia"
            }
        }
    }
}