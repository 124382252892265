<template>
    <form @submit.prevent="base_submit('model', 'submit', null)">

        <div v-for="(field, index) in address_form_getAddressFields(address.config)" :key="field.field">

            <form-textbox v-if="fieldTypes.text == field.type"
                          textbox-class="form-control form-control-sm"
                          v-model="model[field.field]"
                          :validator="v$.model[field.field]"
                          :label="address_form_getAddressFieldTranslation(field, 'label', address.country)"
                          :placeholder="address_form_getAddressFieldTranslation(field, 'placeholder', address.country)"
                          :focus="0==index"
                          :errors="errors[field.field]"
                          :mask="field.mask"
                          :maxlength="field.maxlength"
                          :max-characters="'undefined'===typeof field.mask?field.maxlength:null"
                          :disabled="disabled"
            ></form-textbox>

            <form-textarea v-if="fieldTypes.textarea == field.type"
                           textarea-class="form-control form-control-sm"
                           v-model="model[field.field]"
                           :validator="v$.model[field.field]"
                           :label="address_form_getAddressFieldTranslation(field, 'label', address.country)"
                           :placeholder="address_form_getAddressFieldTranslation(field, 'placeholder', address.country)"
                           :focus="0==index"
                           :errors="errors[field.field]"
                           :maxlength="field.maxlength"
                           :rows="field.rows"
                           :max-characters="'undefined'===typeof field.mask?field.maxlength:null"
                           :disabled="disabled"
            ></form-textarea>

            <form-select v-if="fieldTypes.dropdown == field.type"
                         select-class="form-control form-control-sm"
                         v-model="model[field.field]"
                         :validator="v$.model[field.field]"
                         :label="address_form_getAddressFieldTranslation(field, 'label', address.country)"
                         :options="address_form_getAddressFieldOptions(field)"
                         :focus="0==index"
                         :errors="errors[field.field]"
                         :disabled="disabled"></form-select>

        </div>

        <div class="form-group text-center">
            <button type="submit" class="btn btn-primary" :disabled="disabled">
                <spinner v-if="disabled"></spinner>
                {{disabled ? t('processing') : t('cta')}}
            </button>
        </div>
    </form>
</template>

<script>

    import useVuelidate from "@vuelidate/core";
    import {mapGetters} from "vuex"

    import mixin_address_form from "@/mixins/address/form";

    import FormTextbox from "@/components/form/FormTextbox";
    import FormSelect from "@/components/form/FormSelect";
    import FormTextarea from "@/components/form/FormTextarea";
    import Spinner from "@/components/Spinner";

    const fieldTypes = {
        text: "text",
        textarea: "textarea",
        dropdown: "dropdown"
    };

    const model = {};

    export default {
        name: "ViewsSurveysViewAddressForm",
        setup() {
            return {
                v$: useVuelidate()
            }
        },
        mixins: [mixin_address_form],
        components: {
            Spinner,
            FormTextbox,
            FormSelect,
            FormTextarea,
        },
        props: {
            address: {
                type: Object,
                required: true
            },
            disabled: Boolean
        },
        data() {

            return {
                t_path: "views.surveys.view.addressForm",
                fieldTypes,
                model,
            };
        },
        computed: {
            ...mapGetters({
                countries: "db/countries/items",
            }),
            errors() {

                return this.address_form_getErrorMessages(
                    this.address_form_getAddressFields(this.address.config),
                    this.address.country
                );
            }
        },
        validations() {

            return this.address_form_createValidationRules(
                this.address_form_getAddressFields(this.address.config)
            );
        },
        methods: {
            submit() {

                this.$emit("process", this.model);
            },
            init() {

                const fields = this.address_form_getAddressFields(this.address.config);

                fields.forEach(field => this.model[field.field] = null);
            }
        },
        mounted() {

            this.init();
        }
    }
</script>