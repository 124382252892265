export default {
    title: "Je vyžadováno ověření přihlášení",
    header: "@:views.auth.confirm.title",
    message: "Chcete-li pokračovat, zkontrolujte e-mail vaší společnosti, zda neobsahuje odkaz pro ověření e-mailu. Pokračujte kliknutím na odkaz v e-mailu.",
    messageBeforeCta: "Pokud e-mail nevidíte, klikněte",
    messageAfterCta: "znovu odeslat.",
    secondMessage: "Pokud e-mail stále nevidíte, znovu zkontrolujte složku se spamem nebo nás kontaktujte na adrese <a href='mailto:{email}?subject={subject}'>{email}</a> .",
    subject: "Výbava - TFA",
    buttons: {
        cta: "tady",
        processing: "tady"
    },
    headerAlreadyConfirmed: "Ověření přihlášení",
    messageAlreadyConfirmed: "Již jste potvrdili svůj e-mail."
}