export default {
    header: "Confirmar dirección y enviar pedido",
    message: "¿La dirección de envío que aparece a continuación es correcta?",
    tip: "Haga clic <b>en SÍ</b> para confirmar la dirección de envío y enviar el pedido. Haga clic en <b>NO</b> para cancelar el envío y elija otra dirección.",
    info: "Una vez que se realiza un pedido, no se puede cambiar. Si el producto que está solicitando está pendiente o tiene una fecha de envío posterior y planea mudarse, tenga esto en cuenta al elegir la dirección de envío. Además, si se avecinan días festivos o vacaciones y no estará en casa para aceptar el producto, le pedimos que espere a realizar cualquier pedido hasta que esté en casa para recibir los productos.",
    warning: {
        message: "Vuelva a verificar que su {fields} es correcto ya que es posible que la compañía de envío no pueda entregar su pedido o comunicarse con usted acerca de su pedido. | Vuelva a verificar que su {fields} son correctos ya que es posible que la compañía de envío no pueda entregar su pedido o comunicarse con usted acerca de su pedido.",
        name: "Nombre",
        street: "Calle",
        phone: "Teléfono"
    },
    buttons: {
        yes: "Sí",
        no: "No"
    }
}