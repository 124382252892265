export default {
    login: "Log in",
    logout: "Uitloggen",
    home: "Thuis",
    user: {
        profile: {
            edit: "Bewerk profiel"
        }
    },
    approvals: {
        delegates: "Afgevaardigden"
    },
    impersonation: "Bestel namens"
}