export default {
    title: "Ügyfélszolgálat",
    header: "Ügyfélszolgálat",
    model: {
        email: {
            label: "Felhasználói e-mail",
            placeholder: "Kérjük, töltse ki a felhasználói e-mail címet"
        }
    },
    cta: "Beküldés",
    processing: "Betöltés"
}