export function getTranslations(item, i18n) {

    const locale = i18n.locale;
    const fallback = i18n.fallbackLocale[i18n.locale];

    const translations = item.lang_overrides || {};

    if ("undefined" !== typeof translations[locale]) {

        return translations[locale];
    }

    return fallback.reduce((result, fallbackLocale) => {

        if (!result) {

            if ("undefined" !== typeof translations[fallbackLocale]) {

                return translations[fallbackLocale];
            }
        }

        return result;
    }, null);
}

export function getTranslationValue(source, key, i18n) {

    const translations = getTranslations(source, i18n);

    if ("string" === typeof translations) {

        // source object is something like {[key]: String, lang_overrides: {[lang]: String}}
        return translations;
    }

    if ("string" === typeof key) {

        // source object is something like {[key]: String, lang_overrides: {[lang]: {[key]: String}}}
        return translations && translations[key] ? translations[key] : source[key];
    }

    const translation = key.reduce((result, key) => result ? result : (translations && translations[key] ? translations[key] : result), null);

    return translation
        ? translation
        : key.reduce((result, key) => result ? result : (source && source[key] ? source[key] : result), null);
}