export default {
    ordered: {
        type: {
            "ac_router": "Ordered different AC Router already",
            "chair": "Ordered different chair already",
            "default": "Ordered already",
            "default_multiple": "Ordered similar products already",
            "desk": "Ordered different desk already",
            "desktop": "Ordered different desktop already",
            "desktop_riser": "Ordered different desktop riser already",
            "detector": "Ordered different vape and noise detector already",
            "displayport_cable": "Ordered different displayport cable already",
            "docking station": "Ordered different docking station already",
            "dual_usb_adapter": "Ordered different dual band USB adapter already",
            "ear cushion": "Ordered different ear cushion already",
            "footrest": "Ordered different footrest already",
            "hdmi_cable": "Ordered different HDMI cables already",
            "hdmi_displayport_cable": "Ordered different HDMI to DisplayPort cable already",
            "hdmi_vga_cable": "Ordered different HDMI to VGA cable already",
            "headset": "Ordered different audio device already",
            "keyboard": "Ordered different keyboard already",
            "kvm switch": "Ordered different kvm switch already",
            "kvm_switch": "Ordered different kvm switch already",
            "lamp": "Ordered different lamp already",
            "laptop stand": "Ordered different laptop stand already",
            "laptop": "Ordered different laptop already",
            "mesh_router": "Ordered different mesh router already",
            "monitor": "Ordered different monitor already",
            "mouse": "Ordered different mice already",
            "patch_cord": "Ordered different cat6 patch cord already",
            "power_center": "Ordered different Power Center already",
            "power_strip": "Ordered different power strip already",
            "smart_plug": "Ordered different smart plug mini already",
            "smart_power_strip": "Ordered different smart power strip already",
            "thunderbolt_cable": "Ordered different USB-C to Thunderbolt cable already",
            "travel_router": "Ordered different travel router already",
            "usb hub": "Ordered different usb hub already",
            "usb_adapter": "Ordered different usb adapter already",
            "usb_hub": "Ordered different hub already",
            "webcam": "Ordered different webcam already"
        },
        product: "Ordered"
    },
    request: "Request",
    add: "Add to cart",
    remove: "Remove From Cart",
    added: "Added to Cart",
}