export default {
    return: {
        title: "Devolver",
        header: "Artículos a devolver",
        message: "El siguiente equipo de la Compañía debe ser devuelto. Después de enviar este formulario, lo contactaremos por separado para coordinar la recolección y/o los envíos. Su gerente será informado del estado de su devolución.",

        model: {
            address: {
                country: {
                    label: "País"
                }
            },
            exclude: {
                label: "Excluir de la devolución"
            },
            textarea: {
                placeholder: "Motivo: aprobación del gerente, devuelto anteriormente, etc."
            },
            return_note: {
                label: "Notas de devolución",
                placeholder: "Opcional: Ingrese cualquier nota con respecto a los artículos que se devolverán."
            },
            additional_disposal: {
                label: "¿Tiene algún registro DXC físico que deba desecharse de forma segura?"
            },
            additional_item: {
                type: {
                    label: "Tipo",
                    options: {
                        "chair": "Silla",
                        "desk": "Escritorio",
                        "desk riser": "Elevador de escritorio",
                        "docking station": "Estación de acoplamiento",
                        "footrest": "Reposapiés",
                        "headset": "Auriculares",
                        "hdmi cable": "Cable HDMI",
                        "keyboard": "Teclado",
                        "kvm switch": "Conmutador KVM",
                        "lamp": "Lámpara",
                        "laptop riser": "Elevador de portátil",
                        "monitor": "Monitor",
                        "mouse": "Ratón",
                        "other": "Otro",
                        "speakerphone": "altavoz",
                        "usb hub": "Concentrador USB",
                        "webcam": "Cámara web",
                    }
                },
                detail: {
                    label: "Detalle",
                    placeholder: "Detalle"
                },
                quantity: {
                    label: "Cantidad",
                    placeholder: "Cantidad"
                }
            }
        },

        submitted: {
            title: "Declaración enviada",
            message: "Su devolución ha sido enviada. Se le contactará por separado con respecto a la recogida y/o el envío del artículo."
        },
        nothingToReturn: {
            title: "Información de devolución",
            message: "En este momento, no tiene ningún artículo para devolver dentro de Gear."
        },
        notEligible: {
            title: "Información de devolución",
            message: "En este momento, Gear no puede procesar su devolución. Comuníquese con su gerente local o equipo de recursos humanos para saber cómo devolver sus artículos."
        },

        button: {
            add: {
                cta: "Agregar artículo no listado"
            },
            submit: {
                processing: "Sumisión",
                cta: "Entregar"
            }
        }
    }
}