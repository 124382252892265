export default {
    status: {
        acknowledged: "Bestellt",
        created: "Erstellt",
        sent: "Bestellt",
        confirmed: "Bestellt",
        not_confirmed: "Nicht bestellt",
        delivery_email: "Ausgeliefert",
        shipped: "Ausgeliefert",
        delayed: "Verzögert",
        preparing: "Vorbereiten",
        processing: "wird bearbeitet",
        processed: "Verarbeitet",
        submitted: "Bestellt",
        delivery_not_received: "Ausgeliefert",
        shipment_exception: "Ausgeliefert",
        pending_approval: "Ausstehend",
        approved: "Genehmigt",
        not_approved: "Nicht bestätigt",
        canceled: "Abgesagt",
        returned: "Ist zurückgekommen",
        lost_shipment: "Verlorene Sendung",
        delivery_not_confirmed: "Geliefert",
        delivered: "Geliefert",
        delivery_received: "Geliefert",
        refurbished: "Rückkehr abgeschlossen",
        returned_to_office: "Ins Amt zurückgekehrt",
        return_to_employer: "Zum Arbeitgeber zurückgekehrt",
        return_pickup_scheduled: "Abholung geplant",
        return_pickup_completed: "Abholung abgeschlossen",
        return_pickup_excluded: "Abholung ausgeschlossen",
        return_pickup_submitted: "Abholung eingereicht",
        return_pickup_finalized: "Abholung abgeschlossen",
        disposed: "Entsorgt",
        damaged: "Beschädigt"
    }
}