export default {
    view: {
        success: {
            header: "Encuesta enviada",
            message: "Gracias por tus comentarios."
        },
        productChoice: {
            cta: "Ahorrar",
            processing: "Ahorro"
        },
        addressForm: {
            cta: "Ahorrar",
            processing: "Ahorro"
        }
    },
    delivery: {
        title: "encuesta de entrega",
        message: "¿Ha recibido todos los artículos enumerados a continuación?",
        cta: {
            yes: "Sí",
            no: "No",
            processing: "Ahorro"
        }
    }
}