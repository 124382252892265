export default {
    required: "egy érték megadása szükséges",
    email: "Az érték nem érvényes e-mail cím",
    mask: "Az érték érvénytelen",
    maxValue: "A maximális érték {maxValue}",
    regex: "Az érték érvénytelen",
    server: {
        DELEGATES_NOT_ENABLED: "A küldöttek nem engedélyezettek ennél a partnernél.",
        DELEGATE_DOES_NOT_EXIST: "A delegált nem létezik",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "A nem Önhöz tartozó meghatalmazott nem frissíthető.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Nem törölhető a nem Önhöz tartozó meghatalmazott.",
        DELEGATE_ALREADY_EXISTS: "Már létezik egy, a feltételeknek megfelelő felhatalmazási szabály.",
        DELEGATE_CANNOT_BE_SELF: "Nem veheti fel magát meghatalmazottként."
    }
}