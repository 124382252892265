export default {
    message: {
        //     constraints  ordered
        default_everything_everything: "<p>Do tej pory zamówiłeś wszystko, co było dostępne w Gear.</p><p> Jeśli masz konkretne pytanie na ten temat, kliknij łącze <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a> powyżej i prześlij swoje pytanie.</p>",
        default_everything_something: "<p>Wcześniej zamówione pozycje nie są już wyświetlane.</p><p> Jeśli masz konkretne pytanie na ten temat, kliknij łącze <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a> powyżej i prześlij swoje pytanie.</p>",
        default_partial_everything: "<p>Nie ma dodatkowych produktów do zamówienia.</p><p> Czy Twoja sytuacja zmieniła się od czasu, gdy po raz pierwszy odpowiedziałeś na ankietę dotyczącą sprzętu? Kliknij <a href='{equipmentQuizLink}'><b>tutaj</b></a> , aby przejrzeć odpowiedzi w ankiecie dotyczącej sprzętu.</p>",
        default_partial_something: "<p>Wcześniej zamówione pozycje nie są już wyświetlane.</p><p> Czy Twoja sytuacja zmieniła się od czasu, gdy po raz pierwszy odpowiedziałeś na ankietę dotyczącą sprzętu? Kliknij <a href='{equipmentQuizLink}'><b>tutaj</b></a> , aby przejrzeć odpowiedzi w ankiecie dotyczącej sprzętu.</p>",
        default_partial_noitems: "<p>Czy Twoja sytuacja zmieniła się od czasu, gdy po raz pierwszy odpowiedziałeś na ankietę dotyczącą sprzętu? Kliknij <a href='{equipmentQuizLink}'><b>tutaj</b></a> , aby przejrzeć odpowiedzi w ankiecie dotyczącej sprzętu.</p>",

        ehs_everything_everything: "<p>Do tej pory zamówiłeś wszystko, co było dostępne w Gear.</p><p> Dostępność produktów ustalana jest na podstawie ankiety BHP. Jeśli potrzebujesz dodatkowej pomocy, skontaktuj się <a href='mailto:{supportEmail}'>z {supportEmail}</a> .</p>",
        ehs_everything_something: "<p>Wcześniej zamówione pozycje nie są już wyświetlane.</p><p> Dostępność produktów ustalana jest na podstawie ankiety BHP. Jeśli potrzebujesz dodatkowej pomocy, skontaktuj się <a href='mailto:{supportEmail}'>z {supportEmail}</a> .</p>",
        ehs_partial_everything: "<p>Do tej pory zamówiłeś wszystko, co było dostępne w Gear.</p><p> Dostępność produktów ustalana jest na podstawie ankiety BHP. Jeśli potrzebujesz dodatkowej pomocy, skontaktuj się <a href='mailto:{supportEmail}'>z {supportEmail}</a> .</p>",
        ehs_partial_something: "<p>Wcześniej zamówione pozycje nie są już wyświetlane.</p><p> Dostępność produktów ustalana jest na podstawie ankiety BHP. Jeśli potrzebujesz dodatkowej pomocy, skontaktuj się <a href='mailto:{supportEmail}'>z {supportEmail}</a> .</p>",
        ehs_partial_noitems: "<p>Dostępność produktów ustalana jest na podstawie ankiety BHP. Jeśli potrzebujesz dodatkowej pomocy, skontaktuj się <a href='mailto:{supportEmail}'>z {supportEmail}</a> .</p>",
    }
}