<template>
    <div class="products-list">
        <div class="row">
            <div v-for="product in value" :key="product.id" class="col-12 col-lg-6 col-xl-4 pb-3">
                <products-list-product class="h-100"
                                       :value="product"
                                       :grouping="grouping"
                                       :grouping-id="groupingId"/>
            </div>
        </div>
    </div>
</template>

<script>

    import ProductsListProduct from "./ProductsListProduct";

    export default {
        name: "ProductsList",
        components: {
            ProductsListProduct
        },
        props: {
            value: {
                type: Array,
                required: true
            },
            grouping: {
                type: String,
                required: true,
            },
            groupingId: {}
        },
        data() {

            return {
                t_path: "components.products.list"
            }
        }
    }
</script>