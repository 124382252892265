export default {
    header: "确认地址并提交订单",
    message: "您在下面列出的送货地址是否正确？",
    tip: "单击<b>是</b>确认送货地址并提交订单。单击<b>“否”</b>取消提交并选择其他地址。",
    info: "一旦下订单，就不能更改。如果您订购的产品缺货或有较晚的发货日期，而您打算搬家，请在选择送货地址时考虑到这一点。此外，如果即将到来的假期或休假，而您不会在家接收产品，我们要求您在回家接收产品之前暂缓下订单。",
    warning: {
        message: "请仔细检查您的 {fields}是正确的，因为运输公司可能无法交付您的订单或就您的订单与您联系。 |请仔细检查您的 {fields}是正确的，因为运输公司可能无法交付您的订单或就您的订单与您联系。",
        name: "姓名",
        street: "街道",
        phone: "电话"
    },
    buttons: {
        yes: "是的",
        no: "不"
    }
}