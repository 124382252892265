export default {
    required: "Een waarde is verplicht",
    email: "Waarde is geen geldig e-mailadres",
    mask: "De waarde is ongeldig",
    maxValue: "De maximale waarde is {maxValue}",
    regex: "De waarde is ongeldig",
    server: {
        DELEGATES_NOT_ENABLED: "Gemachtigden zijn niet ingeschakeld voor deze partner.",
        DELEGATE_DOES_NOT_EXIST: "Gedelegeerde bestaat niet",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "Kan een gemachtigde die niet van u is, niet bijwerken.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Kan een gemachtigde die niet van u is, niet verwijderen.",
        DELEGATE_ALREADY_EXISTS: "Er bestaat al een bestaande delegatieregel die aan deze criteria voldoet.",
        DELEGATE_CANNOT_BE_SELF: "U kunt uzelf niet toevoegen als gemachtigde."
    }
}