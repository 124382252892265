export default {
    "India": "印度",
    "United States of America": "美国",
    "United Kingdom": "英国",
    "Philippines": "菲律宾",
    "Australia": "澳大利亚",
    "Spain": "西班牙",
    "Bulgaria": "保加利亚",
    "Poland": "波兰",
    "Malaysia": "马来西亚",
    "Russian Federation": "俄罗斯联邦",
    "Ukraine": "乌克兰",
    "Germany": "德国",
    "Brazil": "巴西",
    "Canada": "加拿大",
    "Romania": "罗马尼亚",
    "France": "法国",
    "China": "中国",
    "Italy": "意大利",
    "Costa Rica": "哥斯达黎加",
    "Mexico": "墨西哥",
    "Slovakia": "斯洛伐克",
    "Vietnam": "越南",
    "Singapore": "新加坡",
    "Argentina": "阿根廷",
    "Denmark": "丹麦",
    "Japan": "日本",
    "Belgium": "比利时",
    "Czech Republic": "捷克共和国",
    "Netherlands": "荷兰",
    "Switzerland": "瑞士",
    "Sweden": "瑞典",
    "New Zealand": "新西兰",
    "Hungary": "匈牙利",
    "Egypt": "埃及",
    "Lithuania": "立陶宛",
    "United Arab Emirates": "阿拉伯联合酋长国",
    "Ireland": "爱尔兰",
    "Hong Kong": "香港",
    "Austria": "奥地利",
    "Saudi Arabia": "沙特阿拉伯",
    "Portugal": "葡萄牙",
    "Norway": "挪威",
    "Chile": "智利",
    "Thailand": "泰国",
    "Taiwan": "台湾",
    "Tunisia": "突尼斯",
    "Israel": "以色列",
    "South Africa": "南非",
    "Colombia": "哥伦比亚",
    "Serbia": "塞尔维亚",
    "Luxembourg": "卢森堡",
    "Peru": "秘鲁",
    "Finland": "芬兰",
    "Brunei Darussalam": "文莱达鲁萨兰国",
    "Korea, Republic of": "大韩民国",
    "Indonesia": "印度尼西亚",
    "Jordan": "约旦",
    "Fiji": "斐济",
    "Morocco": "摩洛哥",
    "Nigeria": "尼日利亚",
    "Panama": "巴拿马",
    "Qatar": "卡塔尔",
    "Greece": "希腊",
    "Turkey": "火鸡",
    "Croatia": "克罗地亚",
    "Kazakhstan": "哈萨克斯坦",
    "Cyprus": "塞浦路斯"
}