<template>
    <div class="products-page panel">
        <div class="row">

            <div class="col-12">

                <div class="d-flex pb-2">
                    <div class="flex-grow-1 h3">{{name}}</div>
                    <div class="d-none d-md-block flex-grow-0 pl-4 products-page-cart text-right">
                        <products-product-cart class="btn-cart" :value="value" :display-add-toast="true"/>
                    </div>
                </div>

                <div v-if="estimatedDeliveryDate" v-html="estimatedDeliveryDate" class="estimated-delivery-date"></div>

                <div v-if="note" class="note" v-html="note"></div>
            </div>

            <div v-if="0 < value.pictures.length" class="col-12 offset-md-3 col-md-6 py-3">
                <products-product-thumbnails size="large" :value="value.pictures"/>
            </div>

            <div class="col-12 d-md-none d-flex justify-content-center pb-3">
                <products-product-cart class="btn-cart" :value="value" :display-add-toast="true"/>
            </div>

            <div v-if="description" class="col-12">
                <h4>{{t('description')}}</h4>

                <p v-html="description"></p>
            </div>

            <!-- Widget item photo -->
            <img v-if="0 < value.pictures.length" :src="value.pictures[0].medium" :alt="name"
                 class="d-none"
                 :data-widget-item="widgetItem"/>
        </div>
    </div>
</template>

<script>

    import mixin_products from "@/mixins/products";
    import {getTranslationValue} from "@/i18n/utils/lang_overrides";

    import ProductsProductThumbnails from "./ProductsProductThumbnails";
    import ProductsProductCart from "@/components/products/ProductsProductCart";
    import ProductsProductLimit from "../ProductsProductLimit";

    export default {
        name: "ProductsProductPage",
        mixins: [mixin_products],
        components: {
            ProductsProductLimit,
            ProductsProductCart,
            ProductsProductThumbnails
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {

            return {
                t_path: "components.products.page"
            }
        },
        computed: {
            name() {

                return this.products_translateProductName(this.value);
            },
            description() {

                return this.products_translateProductDescription(this.value);
            },
            note() {

                return this.products_translateProductNote(this.value);
            },
            widgetItem() {

                return JSON.stringify({
                    item: this.value.sku
                })
            },
            estimatedDeliveryDate() {

                return this.products_translateProductEstimatedShipDate(this.value);
            }
        }
    }
</script>