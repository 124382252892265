export default {
    unverified: "Non vérifié",
    verificationLink: "Renvoyer l'e-mail de vérification",
    buttons: {
        delete: {
            cta: "Supprimer",
            processing: "Suppression"
        }
    },
    addressDeleted: "L'adresse a été supprimée",
    verificationEmailSent: "L'email a été envoyé",
    deleteAddressConfirmation: "Voulez-vous vraiment supprimer cette adresse ?"
}