export default {
    user: {
        create: {
            title: "Nieuwe gebruiker",
            model: {
                email: {
                    label: "E-mail",
                    placeholder: "E-mail invullen"
                },
                name: {
                    label: "Naam",
                    placeholder: "Naam invullen"
                },
                country: {
                    label: "Land",
                    placeholder: "Vul land"
                }
            },
            cta: {
                submit: {
                    text: "Indienen",
                    processing: "Indienen"
                },
                cancel: {
                    text: "Annuleren"
                }
            }
        }
    }
}