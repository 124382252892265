<template>
    <page-message>
        <template #header>{{t('error', {code: code})}}</template>
        <template #message>{{message}}</template>
    </page-message>
</template>

<script>

    import mixin_view from "@/mixins/view";

    import PageMessage from "@/components/page/PageMessage";

    export default {
        name: "ViewsErrorsCode",
        mixins: [mixin_view],
        components: {
            PageMessage
        },
        data() {

            return {
                t_path: "views.errors.code"
            }
        },
        computed: {
            message() {

                if (this.$route.query.message) {

                    return this.$route.query.message;
                }

                return this.t('message');
            },
            code() {

                return this.$route.params.code;
            }
        }
    }
</script>