import widget from "@/mixins/widget";

widget.computed.widget_optionsModels = function () {

    return {
        widgetOptions: this.widget_widgetOptions,
        widgetOptionsHelp: JSON.stringify({
            anchorMargin: "60px 15px 0 0",
            token: this.$store.getters["user/token"],
            selection: [{
                component: "category",
                value: "Product Suggestion"
            }]
        })
    }
}

export default widget;