export default {
    message: {
        //     constraints  ordered
        default_everything_everything: "<p>À ce jour, vous avez commandé tout ce qui vous était disponible dans Gear.</p><p> Si vous avez une question spécifique à ce sujet, veuillez cliquer sur le lien <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a> ci-dessus et soumettre votre question.</p>",
        default_everything_something: "<p>Les articles précédemment commandés ne sont plus affichés.</p><p> Si vous avez une question spécifique à ce sujet, veuillez cliquer sur le lien <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support</a> ci-dessus et soumettre votre question.</p>",
        default_partial_everything: "<p>Il n'y a pas de produits supplémentaires à commander.</p><p> Votre situation a-t-elle changé depuis que vous avez répondu pour la première fois à votre enquête sur l'équipement ? Cliquez <a href='{equipmentQuizLink}'><b>ici</b></a> pour consulter vos réponses au sondage sur l'équipement.</p>",
        default_partial_something: "<p>Les articles précédemment commandés ne sont plus affichés.</p><p> Votre situation a-t-elle changé depuis que vous avez répondu pour la première fois à votre enquête sur l'équipement ? Cliquez <a href='{equipmentQuizLink}'><b>ici</b></a> pour consulter vos réponses au sondage sur l'équipement.</p>",
        default_partial_noitems: "<p>Votre situation a-t-elle changé depuis que vous avez répondu pour la première fois à votre enquête sur l'équipement ? Cliquez <a href='{equipmentQuizLink}'><b>ici</b></a> pour consulter vos réponses au sondage sur l'équipement.</p>",

        ehs_everything_everything: "<p>À ce jour, vous avez commandé tout ce qui vous était disponible dans Gear.</p><p> La disponibilité des produits est basée sur l'enquête EHS. Si vous avez besoin d'une assistance supplémentaire, veuillez contacter <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_everything_something: "<p>Les articles précédemment commandés ne sont plus affichés.</p><p> La disponibilité des produits est basée sur l'enquête EHS. Si vous avez besoin d'une assistance supplémentaire, veuillez contacter <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_everything: "<p>À ce jour, vous avez commandé tout ce qui vous était disponible dans Gear.</p><p> La disponibilité des produits est basée sur l'enquête EHS. Si vous avez besoin d'une assistance supplémentaire, veuillez contacter <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_something: "<p>Les articles précédemment commandés ne sont plus affichés.</p><p> La disponibilité des produits est basée sur l'enquête EHS. Si vous avez besoin d'une assistance supplémentaire, veuillez contacter <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_noitems: "<p>La disponibilité des produits est basée sur l'enquête EHS. Si vous avez besoin d'une assistance supplémentaire, veuillez contacter <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
    }
}