export default {
    title: {
        new: "Neue Adresse hinzufügen",
        complete: "Vollständige Adresse",
        edit: "Adresse bearbeiten"
    },

    incomplete: "Bitte füllen Sie die erforderlichen Adressfelder aus",

    info: {
        "CHN": "Bitte geben Sie Ihre Adresse auf Chinesisch ein.</br> Bitte geben Sie Ihre Adresse auf Chinesisch ein.",
        "BGR": "Geben Sie Ihre <b>Privatadresse</b> auf Englisch ein, um Ihre Artikel zu erhalten.",
        "PRI": "Bitte geben Sie zum Empfangen von Paketen Ihre Privatadresse und kein Postfach ein.",
        "SGP": "Bitte geben Sie in Ihrer Adresse Ihre Einheitennummer an.",
        "SVK": "Bitte geben Sie Ihre Adresse auf <b>Englisch</b> ein",
        "POL": "Bitte geben Sie Ihre Adresse auf <b>Englisch</b> ein"
    },

    model: {
        country: {
            label: "Land"
        }
    },

    field: {
        default: {
            "name": {
                "label": {
                    "Name": "Name"
                },
                "placeholder": {
                    "Name": "Name",
                    "Name in English": "Name auf Englisch"
                }
            },
            personalEmail: {
                label: {
                    "Personal Email": "Persönliche E-Mail"
                },
                placeholder: {
                    "Personal Email": "Persönliche E-Mail"
                }
            },
            managerEmail: {
                label: {
                    "Manager Email": "E-Mail des Managers"
                },
                placeholder: {
                    "Manager Email": "E-Mail des Managers"
                }
            },
            managerName: {
                label: {"Manager Name": "Name des Managers"},
                placeholder: {"Manager Name": "Name des Managers"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "PLZ",
                    "Post Code": "Postleitzahl",
                    "Postal Code": "Postleitzahl",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "PLZ",
                    "Post Code": "Postleitzahl",
                    "Postal Code": "Postleitzahl",
                    "CPA": "CPA"
                }
            },
            "province": {
                "label": {
                    "Province": "Provinz",
                    "Prefecture": "Präfektur",
                    "Province/Region": "Provinz/Region",
                    "Province Code": "Provinzcode"
                },
                "placeholder": {
                    "Province": "Provinz",
                    "Prefecture": "Präfektur",
                    "Province/Region": "Provinz/Region",
                    "Province Code": "Provinzcode"
                }
            },
            "phoneNumber": {
                "label": {
                    "Phone Number": "Telefonnummer"
                },
                "placeholder": {
                    "Phone Number": "Telefonnummer"
                }
            },
            "firstStreet": {
                "label": {
                    "Street": "Straße",
                    "Tax ID": "Steuer ID"
                },
                "placeholder": {
                    "Street": "Straße",
                    "Street in English": "Straße auf Englisch",
                    "Tax ID (RUT)": "Steuernummer (RUT)",
                    "Number/Street/Apartment": "Hausnummer/Straße/Wohnung"
                }
            },
            "secondStreet": {
                "label": {
                    "District": "Bezirk",
                    "Colonia": "Köln",
                    "Canton": "Kanton",
                    "Street": "Straße"
                },
                "placeholder": {
                    "District": "Bezirk",
                    "Colonia": "Köln",
                    "Canton": "Kanton",
                    "Number/Street/Apartment": "Hausnummer/Straße/Wohnung"
                }
            },
            "city": {
                "label": {
                    "City": "Stadt",
                    "Town/City/Locality": "Stadt/Ort",
                    "Town/City": "Ort",
                    "City/Municipality/Locality": "Stadt/Gemeinde/Ort",
                    "City/Town/Locality": "Stadt/Ort/Ortschaft",
                    "City/Town": "Ort",
                    "District": "Bezirk",
                    "City/Town/Locallity": "Stadt/Ort/Ortschaft",
                    "Town/City/Sub Locality": "Ort/Stadt/Unterort"
                },
                "placeholder": {
                    "City": "Stadt",
                    "Town/City": "Ort",
                    "Town/City/Locality": "Stadt/Ort",
                    "City/Municipality/Locality": "Stadt/Gemeinde/Ort",
                    "City/Town/Locality": "Stadt/Ort/Ortschaft",
                    "City/Town in English": "Stadt/Ort auf Englisch",
                    "District": "Bezirk",
                    "City/Town/Locallity": "Stadt/Ort/Gemeinde",
                    "Town/City/Sub Locality": "Ort/Stadt/Unterort"
                }
            },
            identifier: {
                label: {
                    "identifier": "Kennung",
                    "Identifier": "Kennung"
                },
                placeholder: {
                    "identifier": "Kennung",
                    "Identifier": "Kennung"
                }
            },
            "region": {
                "label": {
                    "Region": "Region",
                    "County": "Bezirk",
                    "Locality": "Lokalität"
                },
                "placeholder": {
                    "Region": "Region",
                    "County": "Bezirk",
                    "Locality": "Lokalität"
                }
            },
            "poBox": {
                "label": {
                    "PO BOX": "POSTFACH"
                },
                "placeholder": {
                    "PO BOX": "POSTFACH"
                }
            },
            state: {
                "label": {
                    "State": "Zustand",
                    "Province/Region": "Provinz/Region",
                    "Province": "Provinz",
                    "Department": "Abteilung",
                    "Municipality/Comuna": "Gemeinde/Comuna"
                },
                "placeholder": {
                    "State": "Zustand",
                    "Province/Region": "Provinz/Region",
                    "Province": "Provinz",
                    "Department": "Abteilung",
                    "Municipality/Comuna": "Gemeinde/Comuna"
                },
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alaska",
                    "Arizona": "Arizona",
                    "Arkansas": "Arkansas",
                    "California": "Kalifornien",
                    "Colorado": "Colorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "District von Columbia",
                    "Florida": "Florida",
                    "Georgia": "Georgia",
                    "Hawaii": "Hawaii",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Louisiana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Mississippi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebraska",
                    "Nevada": "Nevada",
                    "New Hampshire": "New Hampshire",
                    "New Jersey": "New Jersey",
                    "New Mexico": "New-Mexiko",
                    "New York": "New York",
                    "North Carolina": "North Carolina",
                    "North Dakota": "Norddakota",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregon",
                    "Pennsylvania": "Pennsylvania",
                    "Rhode Island": "Rhode Island",
                    "South Carolina": "South Carolina",
                    "South Dakota": "Süddakota",
                    "Tennessee": "Tennessee",
                    "Texas": "Texas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Virginia",
                    "Washington": "Washington",
                    "West Virginia": "West Virginia",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            "deliveryInstructions": {
                "label": {
                    "Delivery Instructions": "Lieferanweisungen"
                },
                "placeholder": {
                    "Extra address information, delivery instructions, etc. ": "Zusätzliche Adressinformationen, Lieferanweisungen usw. ",
                    "Extra address information, delivery instructions, etc. in English ": "Zusätzliche Adressinformationen, Lieferanweisungen usw. auf Englisch ",
                    "Extra address information, delivery instructions, etc": "Zusätzliche Adressinformationen, Lieferanweisungen usw."
                }
            },
        },
        BRA: {
            name: {
                label: {"Full name": "Vollständiger Name"},
                placeholder: {"Full name": "Vollständiger Name"}
            },
            firstStreet: {
                label: {"Address 1": "Adresse 1"},
                placeholder: {"Address 1": "Straße + Haus-/Wohnungsnummer"}
            },
            secondStreet: {
                label: {
                    "Address 2": "Adresse 2",
                    "District": "Bezirk"
                },
                placeholder: {
                    "Address 2": "Straße + Haus-/Wohnungsnummer",
                    "District": "Bezirk"
                }
            },
            city: {
                label: {City: "Stadt"},
                placeholder: {City: "Stadt"},
            },
            state: {
                label: {State: "Zustand"},
                placeholder: {State: "Zustand"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "PLZ",
                    "Post Code": "Postleitzahl",
                    "Postal Code": "Postleitzahl",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "PLZ",
                    "Post Code": "Postleitzahl",
                    "Postal Code": "Postleitzahl",
                    "CPA": "CPA"
                }
            },
            identifier: {
                "label": {
                    "Tax ID": "Steuer ID"
                },
                "placeholder": {
                    "Tax ID (CDI, CUIT, CUIL, etc)": "Steuernummer (CDI, CUIT, CUIL usw.)"
                }
            }
        }
    },

    errors: {
        required: "{field} ist erforderlich.",
        mask: "{field} ist ungültig."
    },

    buttons: {
        submit: {
            text: "Speichern",
            saving: "Speichern"
        },
        cancel: {
            text: "Stornieren"
        }
    },

    disclaimer: "Zum Hinzufügen dieser Adresse ist eine E-Mail-Bestätigung erforderlich. Überprüfen Sie nach dem Speichern der Adresse Ihre E-Mails und klicken Sie auf den darin enthaltenen Link.",

    message: {
        added: "Adresse wurde hinzugefügt",
        updated: "Adresse wurde aktualisiert"
    }
}