<template>
    <div :class="class">

        <label v-if="label" :for="labelFor">{{label}}<span v-if="_required" class="label-required">*</span></label>

        <div class="form-autocomplete" :class="[{'is-invalid':isInvalid,'is-valid':isValid}]">
            <input v-maska="mask"
                   :value="currentValue"
                   :type="type"
                   :id="labelFor"
                   :placeholder="placeholder"
                   :autocomplete="autocomplete"
                   :class="[{'is-invalid':isInvalid,'is-valid':isValid}, textboxClass]"
                   :disabled="disabled"
                   :tabindex="tabindex"
                   :pattern="pattern"
                   :min="min"
                   :max="max"
                   :maxlength="maxlength?maxlength:maxCharacters"
                   :minlength="minlength"
                   @input="input"
                   @keydown.enter="select($event)"
                   @keydown.down="next"
                   @keydown.up="prev"
                   @keydown.esc="clear($event)"
                   @blur.prevent="blur"
                   ref="element"/>

            <div class="result-items" v-if="0 < result.length">
                <div v-for="item in result" class="item" :key="item.key" :class="{'selected':item.selected}"
                     @click="select($event, item)">
                    <span v-if="'string' === typeof item.name">{{item.name}}</span>
                    <span v-else>
                        <span v-for="name in item.name" class="pr-1"
                              :class="{'text-muted': 'object' === typeof name && 'secondary' === name.type}">
                            {{'string' === typeof name ? name : name.name}}
                        </span>
                    </span>
                </div>
                <infinite-loading v-if="infinityScroll && all.length > result.length" @infinite="renderMore" />
            </div>
            <div v-else-if="info" class="result-items p-3 small">
                <small>{{info}}</small>
            </div>
        </div>

        <div v-if="showCharacterCounter&&modelValue" class="text-right small">
            <small>{{modelValue.length}}/{{maxCharacters}}</small>
        </div>

        <slot name="tip"></slot>

        <div v-for="error in _errors" :key="error" class="invalid-feedback">
            <span class="icon-attention-circled"></span>
            {{error}}
        </div>

    </div>
</template>

<script>

    import debounce from "lodash/debounce"

    import form from "@/mixins/form";
    import character_counter from "@/mixins/character_counter";

    import InfiniteLoading from "v3-infinite-loading";

    export default {
        name: "FormAutocomplete",
        mixins: [form, character_counter],
        components: {
            InfiniteLoading
        },
        props: {
            modelValue: {},
            label: {
                type: String
            },
            type: {
                type: String,
                default: "text"
            },
            autocomplete: {
                type: String
            },
            pattern: {
                type: String
            },
            min: {
                type: Number
            },
            max: {
                type: Number
            },
            minlength: {
                type: Number
            },
            maxlength: {
                type: Number
            },
            textboxClass: {
                type: String,
                default: "form-control"
            },
            class: {
                type: String,
                default: "form-group"
            },
            mask: {
                type: [String, Array]
            },
            displayPath: String,
            limit: {
                type: Number,
                default: 10
            },
            infinityScroll: Boolean,
            messageNotFound: String,
            strict: Boolean
        },
        emits: ["update:modelValue", "search"],
        data() {

            return {
                escape: false,
                selected: false,
                all: [],
                result: [],
                source: null,
                info: null,
                currentValue: null
            }
        },
        methods: {
            input(event) {

                this.currentValue = event.target.value;

                this.updateModelValue();
            },
            highlight(index) {

                if ("undefined" !== this.result[index]) {

                    this.result.forEach((item, rIndex) => this.result[rIndex].selected = index == rIndex);
                }
            },
            updateModelValue() {

                if (!this.strict) {

                    return this.$emit("update:modelValue", this.currentValue);
                }

                this.result.forEach(item => {

                    if (this.currentValue == item.value) {

                        this.$emit("update:modelValue", this.currentValue);
                    }
                });
            },
            renderMore() {

                if (this.all.length > this.result.length) {

                    const start = this.result.length;
                    const finish = this.result.length + this.limit;

                    for (let i = start; i < finish; i ++) {

                        if (this.all[i]) {

                            this.result.push(this.all[i]);
                        }
                    }
                }
            },
            render(result) {

                if (this.currentValue) {

                    this.all = result.map(item => ({
                        key: Math.random(),
                        selected: false,
                        value: item.value,
                        name: item.name
                    }));

                    this.result = this.all.slice(0, this.limit).map(item => ({
                        key: Math.random(),
                        selected: false,
                        value: item.value,
                        name: item.name
                    }));

                    this.updateModelValue();

                    if (0 === this.result.length && this.messageNotFound) {

                        this.info = this.messageNotFound;
                    }
                }
            },
            clear(event) {

                if (!this.escape && this.model) {

                    this.hide();

                    event.preventDefault();
                    event.stopPropagation();

                    this.escape = true;
                }
            },
            hide() {

                this.all = [];
                this.result = [];

                if (this.strict && this.currentValue != this.modelValue) {

                    if (!this.currentValue) {

                        this.$emit("update:modelValue", null);
                    } else {

                        this.currentValue = this.modelValue;
                    }
                }
            },
            next() {

                let index = this.result.findIndex(item => item && item.selected);

                index = -1 === index
                    ? 0
                    : "undefined" === typeof this.result[index + 1] ? 0 : index + 1;

                this.highlight(index);
            },
            prev() {

                let index = this.result.findIndex(item => item && item.selected);

                index = -1 === index
                    ? this.result.length - 1
                    : "undefined" === typeof this.result[index - 1] ? this.result[this.result.length - 1] : index - 1;

                this.highlight(index);
            },
            search(self, value) {

                if (!value) {

                    self.all = [];
                    return self.result = [];
                }

                self.$emit("search", value, (result) => {

                    if (self.currentValue == value) {

                        self.render(result);
                    }
                });

                self.escape = false;
            },
            blur() {

                setTimeout(() => this.hide(), 250);
            },
            select(event, value) {

                event.preventDefault();
                event.stopPropagation();

                if (value) {

                    this.selected = true;

                    this.currentValue = value.value;
                    this.$emit("update:modelValue", value.value);
                } else if (0 < this.result.length) {

                    const item = this.result.find(item => item && item.selected);

                    if (item) {

                        this.selected = true;

                        this.$emit("update:modelValue", item.value);
                    }
                }

                setTimeout(() => this.hide(), 100);
            },
            searchDeferred: debounce((self, value) => self.search(self, value), 250)
        },
        watch: {
            modelValue: {
                immediate: true,
                handler(value) {

                    this.currentValue = value;
                }
            },
            currentValue(value) {

                this.info = null;

                if (!this.selected && value) {

                    this.searchDeferred(this, value);
                }

                this.selected = false;
            }
        }
    }
</script>