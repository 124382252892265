export default {
    unverified: "Ellenőrizetlen",
    verificationLink: "Igazoló e-mail újraküldése",
    buttons: {
        delete: {
            cta: "Töröl",
            processing: "Törlés"
        }
    },
    addressDeleted: "A cím törölve lett",
    verificationEmailSent: "Az e-mail el lett küldve",
    deleteAddressConfirmation: "Biztosan törli ezt a címet?"
}