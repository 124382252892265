export default {
    required: "Une valeur est requise",
    email: "La valeur n'est pas une adresse e-mail valide",
    mask: "La valeur n'est pas valide",
    maxValue: "La valeur maximale est {maxValue}",
    regex: "La valeur n'est pas valide",
    server: {
        DELEGATES_NOT_ENABLED: "Les délégués ne sont pas activés pour ce partenaire.",
        DELEGATE_DOES_NOT_EXIST: "Le délégué n'existe pas",
        DELEGATE_UPDATE_PERMISSIONS_MISMATCH: "Impossible de mettre à jour un délégué qui ne vous appartient pas.",
        DELEGATE_DELETE_PERMISSIONS_MISMATCH: "Impossible de supprimer un délégué qui ne vous appartient pas.",
        DELEGATE_ALREADY_EXISTS: "Une règle de délégué existante correspondant à ces critères existe déjà.",
        DELEGATE_CANNOT_BE_SELF: "Vous ne pouvez pas vous ajouter en tant que délégué."
    }
}