export default {
    message: {
        //     constraints  ordered
        default_everything_everything: "<p>Tot nu toe heb je alles besteld wat beschikbaar is in Gear.</p><p> Als je hier een specifieke vraag over hebt, klik dan op de <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support-</a> link hierboven en stel je vraag.</p>",
        default_everything_something: "<p>Eerder bestelde artikelen worden niet meer getoond.</p><p> Als je hier een specifieke vraag over hebt, klik dan op de <a href='#' role='button' data-widget-trigger='{widgetOptions}'>Gear Support-</a> link hierboven en stel je vraag.</p>",
        default_partial_everything: "<p>Er zijn geen extra producten om te bestellen.</p><p> Is uw situatie veranderd sinds u voor het eerst uw apparatuurenquête beantwoordde? Klik <a href='{equipmentQuizLink}'><b>hier</b></a> om de antwoorden op uw apparatuurenquête te bekijken.</p>",
        default_partial_something: "<p>Eerder bestelde artikelen worden niet meer getoond.</p><p> Is uw situatie veranderd sinds u voor het eerst uw apparatuurenquête beantwoordde? Klik <a href='{equipmentQuizLink}'><b>hier</b></a> om de antwoorden op uw apparatuurenquête te bekijken.</p>",
        default_partial_noitems: "<p>Is uw situatie veranderd sinds u voor het eerst uw apparatuurenquête beantwoordde? Klik <a href='{equipmentQuizLink}'><b>hier</b></a> om de antwoorden op uw apparatuurenquête te bekijken.</p>",

        ehs_everything_everything: "<p>Tot nu toe heb je alles besteld wat beschikbaar is in Gear.</p><p> Beschikbaarheid van producten is gebaseerd op het EHS-onderzoek. Als je meer ondersteuning nodig hebt, neem dan contact op met <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_everything_something: "<p>Eerder bestelde artikelen worden niet meer getoond.</p><p> Beschikbaarheid van producten is gebaseerd op het EHS-onderzoek. Als je meer ondersteuning nodig hebt, neem dan contact op met <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_everything: "<p>Tot nu toe heb je alles besteld wat beschikbaar is in Gear.</p><p> Beschikbaarheid van producten is gebaseerd op het EHS-onderzoek. Als je meer ondersteuning nodig hebt, neem dan contact op met <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_something: "<p>Eerder bestelde artikelen worden niet meer getoond.</p><p> Beschikbaarheid van producten is gebaseerd op het EHS-onderzoek. Als je meer ondersteuning nodig hebt, neem dan contact op met <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
        ehs_partial_noitems: "<p>Beschikbaarheid van producten is gebaseerd op het EHS-onderzoek. Als je meer ondersteuning nodig hebt, neem dan contact op met <a href='mailto:{supportEmail}'>{supportEmail}</a> .</p>",
    }
}