export default {
    title: "Nem szállított tételek",
    header: "@:views.survey.deliveryfaq.title",
    result: {
        header: "Köszönöm",
        message: "Köszönjük a választ."
    },
    message: "Ön jelezte, hogy a futár kézbesítettnek jelölte ellenére nem kapta meg a terméket.",
    cta: "Kérjük, kövesse az alábbi lépéseket a probléma elhárításához:",
    items: {
        1: {
            message: "Kattintson a nyomkövető linkre:"
        },
        2: {
            message: "Tekintse át a szállítás állapotát. Ellenőrizni:",
            items: {
                1: "Aki aláírta a csomagot",
                2: "Ha visszaküldték a feladónak",
                3: "Ha a csomag átvételre vár"
            }
        },
        3: {
            message: "Keresse meg a szállítási értesítőt, amely jelzi, hogy hol hagyhatta a tételt."
        },
        4: {
            message: "Érdeklődjön a szomszédokkal, rokonokkal és barátokkal, akik átvették a csomagot."
        },
        5: {
            message: "Ellenőrizze a gyakori helyeket, ahol a futárok küldeményeket hagynak az Ön távollétében."
        }
    },
    question: "Segített ez a kézbesítési kérdés megoldásában? Most már megvan a tétel, vagy tudja, kinél van?",
    buttons: {
        yes: "Igen",
        no: "Nem",
        saving: "Megtakarítás"
    }
}