export default {
    status: {
        acknowledged: "Objednáno",
        created: "Vytvořeno",
        sent: "Objednáno",
        confirmed: "Objednáno",
        not_confirmed: "Není objednáno",
        delivery_email: "Odesláno",
        shipped: "Odesláno",
        delayed: "Zpožděno",
        preparing: "Připravuje se",
        processing: "zpracovává se",
        processed: "Zpracováno",
        submitted: "Objednáno",
        delivery_not_received: "Odesláno",
        shipment_exception: "Odesláno",
        pending_approval: "čekající",
        approved: "Schválený",
        not_approved: "Neschváleno",
        canceled: "Zrušeno",
        returned: "Vrátil",
        lost_shipment: "Ztracená zásilka",
        delivery_not_confirmed: "Doručeno",
        delivered: "Doručeno",
        delivery_received: "Doručeno",
        refurbished: "Návrat dokončen",
        returned_to_office: "Vrátil se do kanceláře",
        return_to_employer: "Vráceno zaměstnavateli",
        return_pickup_scheduled: "Vyzvednutí naplánováno",
        return_pickup_completed: "Vyzvednutí dokončeno",
        return_pickup_excluded: "Vyzvednutí vyloučeno",
        return_pickup_submitted: "Vyzvednutí odesláno",
        return_pickup_finalized: "Vyzvednutí dokončeno",
        disposed: "Zlikvidován",
        damaged: "Poškozený"
    }
}