export default {
    title: {
        new: "Dodaj nowy adres",
        complete: "Pełny adres",
        edit: "Edytuj adres"
    },

    incomplete: "Proszę wypełnić wymagane pola adresowe",

    info: {
        "CHN": "Proszę wpisać swój adres w języku chińskim.</br> Proszę wpisać swój adres w języku chińskim.",
        "BGR": "Wpisz swój <b>adres domowy</b> w języku angielskim, aby otrzymać swoje produkty.",
        "PRI": "Aby otrzymać paczkę, wpisz swój adres domowy, a nie skrzynkę pocztową.",
        "SGP": "Proszę podać numer jednostki w swoim adresie.",
        "SVK": "Proszę wpisać swój adres w <b>języku angielskim</b>",
        "POL": "Proszę wpisać swój adres w <b>języku angielskim</b>"
    },

    model: {
        country: {
            label: "Kraj"
        }
    },

    field: {
        default: {
            "name": {
                "label": {
                    "Name": "Nazwa"
                },
                "placeholder": {
                    "Name": "Nazwa",
                    "Name in English": "Imię w języku angielskim"
                }
            },
            personalEmail: {
                label: {
                    "Personal Email": "Osobisty e-mail"
                },
                placeholder: {
                    "Personal Email": "Osobisty e-mail"
                }
            },
            managerEmail: {
                label: {
                    "Manager Email": "Adres e-mail menedżera"
                },
                placeholder: {
                    "Manager Email": "Adres e-mail menedżera"
                }
            },
            managerName: {
                label: {"Manager Name": "Imię menedżera"},
                placeholder: {"Manager Name": "Imię menedżera"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Kod pocztowy",
                    "Post Code": "Kod pocztowy",
                    "Postal Code": "kod pocztowy",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "Kod pocztowy",
                    "Post Code": "Kod pocztowy",
                    "Postal Code": "kod pocztowy",
                    "CPA": "CPA"
                }
            },
            "province": {
                "label": {
                    "Province": "Województwo",
                    "Prefecture": "Prefektura",
                    "Province/Region": "Prowincja/region",
                    "Province Code": "Kodeks prowincji"
                },
                "placeholder": {
                    "Province": "Województwo",
                    "Prefecture": "Prefektura",
                    "Province/Region": "Prowincja/region",
                    "Province Code": "Kodeks prowincji"
                }
            },
            "phoneNumber": {
                "label": {
                    "Phone Number": "Numer telefonu"
                },
                "placeholder": {
                    "Phone Number": "Numer telefonu"
                }
            },
            "firstStreet": {
                "label": {
                    "Street": "Ulica",
                    "Tax ID": "Identyfikator podatkowy"
                },
                "placeholder": {
                    "Street": "Ulica",
                    "Street in English": "Ulica w języku angielskim",
                    "Tax ID (RUT)": "Identyfikator podatkowy (RUT)",
                    "Number/Street/Apartment": "Numer/ulica/mieszkanie"
                }
            },
            "secondStreet": {
                "label": {
                    "District": "Dzielnica",
                    "Colonia": "Kolonia",
                    "Canton": "Kanton",
                    "Street": "Ulica"
                },
                "placeholder": {
                    "District": "Dzielnica",
                    "Colonia": "Kolonia",
                    "Canton": "Kanton",
                    "Number/Street/Apartment": "Numer/ulica/mieszkanie"
                }
            },
            "city": {
                "label": {
                    "City": "Miasto",
                    "Town/City/Locality": "Miasto/miasto/okolica",
                    "Town/City": "Miasto",
                    "City/Municipality/Locality": "Miasto/gmina/okolica",
                    "City/Town/Locality": "Miasto/miasto/okolica",
                    "City/Town": "Miasto",
                    "District": "Dzielnica",
                    "City/Town/Locallity": "Miasto/miasto/okolica",
                    "Town/City/Sub Locality": "Miasto/miasto/podrejon"
                },
                "placeholder": {
                    "City": "Miasto",
                    "Town/City": "Miasto",
                    "Town/City/Locality": "Miasto/miasto/okolica",
                    "City/Municipality/Locality": "Miasto/gmina/okolica",
                    "City/Town/Locality": "Miasto/miasto/okolica",
                    "City/Town in English": "Miasto/miasto w języku angielskim",
                    "District": "Dzielnica",
                    "City/Town/Locallity": "Miasto/miasto/okolica",
                    "Town/City/Sub Locality": "Miasto/miasto/podrejon"
                }
            },
            identifier: {
                label: {
                    "identifier": "Identyfikator",
                    "Identifier": "Identyfikator"
                },
                placeholder: {
                    "identifier": "Identyfikator",
                    "Identifier": "Identyfikator"
                }
            },
            "region": {
                "label": {
                    "Region": "Region",
                    "County": "Hrabstwo",
                    "Locality": "Miejscowość"
                },
                "placeholder": {
                    "Region": "Region",
                    "County": "Hrabstwo",
                    "Locality": "Miejscowość"
                }
            },
            "poBox": {
                "label": {
                    "PO BOX": "SKRYTKA POCZTOWA"
                },
                "placeholder": {
                    "PO BOX": "SKRYTKA POCZTOWA"
                }
            },
            state: {
                "label": {
                    "State": "Państwo",
                    "Province/Region": "Prowincja/region",
                    "Province": "Województwo",
                    "Department": "Dział",
                    "Municipality/Comuna": "Gmina/Gmina"
                },
                "placeholder": {
                    "State": "Państwo",
                    "Province/Region": "Prowincja/region",
                    "Province": "Województwo",
                    "Department": "Dział",
                    "Municipality/Comuna": "Gmina/Gmina"
                },
                options: {
                    "Alabama": "Alabama",
                    "Alaska": "Alaska",
                    "Arizona": "Arizona",
                    "Arkansas": "Arkansas",
                    "California": "Kalifornia",
                    "Colorado": "Kolorado",
                    "Connecticut": "Connecticut",
                    "Delaware": "Delaware",
                    "District Of Columbia": "Dystrykt Kolumbii",
                    "Florida": "Floryda",
                    "Georgia": "Gruzja",
                    "Hawaii": "Hawaje",
                    "Idaho": "Idaho",
                    "Illinois": "Illinois",
                    "Indiana": "Indiana",
                    "Iowa": "Iowa",
                    "Kansas": "Kansas",
                    "Kentucky": "Kentucky",
                    "Louisiana": "Luizjana",
                    "Maine": "Maine",
                    "Maryland": "Maryland",
                    "Massachusetts": "Massachusetts",
                    "Michigan": "Michigan",
                    "Minnesota": "Minnesota",
                    "Mississippi": "Missisipi",
                    "Missouri": "Missouri",
                    "Montana": "Montana",
                    "Nebraska": "Nebraska",
                    "Nevada": "Nevada",
                    "New Hampshire": "New Hampshire",
                    "New Jersey": "New Jersey",
                    "New Mexico": "Nowy Meksyk",
                    "New York": "Nowy Jork",
                    "North Carolina": "Karolina Północna",
                    "North Dakota": "Północna Dakota",
                    "Ohio": "Ohio",
                    "Oklahoma": "Oklahoma",
                    "Oregon": "Oregon",
                    "Pennsylvania": "Pensylwania",
                    "Rhode Island": "Rhode Island",
                    "South Carolina": "Karolina Południowa",
                    "South Dakota": "Południowa Dakota",
                    "Tennessee": "Tennessee",
                    "Texas": "Teksas",
                    "Utah": "Utah",
                    "Vermont": "Vermont",
                    "Virginia": "Wirginia",
                    "Washington": "Waszyngton",
                    "West Virginia": "Wirginia Zachodnia",
                    "Wisconsin": "Wisconsin",
                    "Wyoming": "Wyoming"
                }
            },
            "deliveryInstructions": {
                "label": {
                    "Delivery Instructions": "Instrukcje dostawy"
                },
                "placeholder": {
                    "Extra address information, delivery instructions, etc. ": "Dodatkowe informacje adresowe, instrukcje dotyczące dostawy itp. ",
                    "Extra address information, delivery instructions, etc. in English ": "Dodatkowe informacje adresowe, instrukcje dotyczące dostawy itp. w języku angielskim ",
                    "Extra address information, delivery instructions, etc": "Dodatkowe informacje adresowe, instrukcje dotyczące dostawy itp."
                }
            },
        },
        BRA: {
            name: {
                label: {"Full name": "Pełne imię i nazwisko"},
                placeholder: {"Full name": "Pełne imię i nazwisko"}
            },
            firstStreet: {
                label: {"Address 1": "Adres 1"},
                placeholder: {"Address 1": "Ulica + numer domu/lokalu"}
            },
            secondStreet: {
                label: {
                    "Address 2": "adres 2",
                    "District": "Dzielnica"
                },
                placeholder: {
                    "Address 2": "Ulica + numer domu/lokalu",
                    "District": "Dzielnica"
                }
            },
            city: {
                label: {City: "Miasto"},
                placeholder: {City: "Miasto"},
            },
            state: {
                label: {State: "Państwo"},
                placeholder: {State: "Państwo"}
            },
            "postalCode": {
                "label": {
                    "Zipcode": "Kod pocztowy",
                    "Post Code": "Kod pocztowy",
                    "Postal Code": "kod pocztowy",
                    "CPA": "CPA"
                },
                "placeholder": {
                    "Zipcode": "Kod pocztowy",
                    "Post Code": "Kod pocztowy",
                    "Postal Code": "kod pocztowy",
                    "CPA": "CPA"
                }
            },
            identifier: {
                "label": {
                    "Tax ID": "Identyfikator podatkowy"
                },
                "placeholder": {
                    "Tax ID (CDI, CUIT, CUIL, etc)": "Identyfikator podatkowy (CDI, CUIT, CUIL itp.)"
                }
            }
        }
    },

    errors: {
        required: "{field} jest wymagane.",
        mask: "{field} jest nieważny."
    },

    buttons: {
        submit: {
            text: "Ratować",
            saving: "Oszczędność"
        },
        cancel: {
            text: "Anulować"
        }
    },

    disclaimer: "Dodanie tego adresu będzie wymagało weryfikacji e-mailowej. Po zapisaniu adresu sprawdź swoją skrzynkę e-mail i kliknij znajdujący się w niej link.",

    message: {
        added: "Adres został dodany",
        updated: "Adres został zaktualizowany"
    }
}