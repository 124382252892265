<template>
    <page-message>
        <template #header>{{t('header')}}</template>
        <template #message>
            {{t('message')}}
        </template>
        <template #cta>
            <router-link :to="{name:'home'}" class="btn btn-primary">{{$t('navigation.home')}}</router-link>
        </template>
    </page-message>
</template>

<script>

    import mixin_view from "@/mixins/view";

    import PageMessage from "@/components/page/PageMessage";

    export default {
        name: "ViewsSurveySubmitted",
        components: {
            PageMessage
        },
        mixins: [mixin_view],
        data() {

            return {
                t_path: "views.survey.submitted",
            }
        }
    }
</script>