export default {
    title: "Beratungsstelle",
    header: "Beratungsstelle",
    model: {
        email: {
            label: "Benutzer Email",
            placeholder: "Bitte füllen Sie die E-Mail-Adresse des Benutzers aus"
        }
    },
    cta: "Einreichen",
    processing: "Wird geladen"
}