export default {

    title: "Chargement...",

    responses: {
        "200_tfalogin": {
            title: "Identification",
            header: "@:views.tunnel.service.response.200_tfalogin.title",
            message: "La connexion a été vérifiée."
        },
        "404_tfalogin": {
            title: "Identification",
            header: "@:views.tunnel.service.response.404_tfalogin.title",
            message: "Jeton valide introuvable. Votre jeton a peut-être expiré."
        },
        "200_tfaaddress": {
            title: "Vérification d'adresse",
            header: "@:views.tunnel.service.response.200_tfaaddress.title",
            message: "Merci d'avoir vérifié votre adresse. Vous pouvez désormais la choisir comme adresse de livraison lorsque vous passez une commande."
        },
        "404_tfaaddress": {
            title: "Vérification d'adresse",
            header: "@:views.tunnel.service.response.404_tfaaddress.title",
            message: "Jeton valide introuvable. Votre jeton a peut-être expiré."
        },
        "201_delivery": {
            title: "Merci",
            header: "Merci",
            message: "Merci pour votre réponse."
        },
        "201_shipment_received": {
            title: "Merci",
            header: "Merci",
            message: "Merci pour votre réponse."
        },
        "400": {
            title: "Mauvaise demande. Une erreur s'est produite.",
            header: "@:views.tunnel.service.response.400.title",
            message: "Mauvaise demande. Une erreur s'est produite."
        }
    }
}