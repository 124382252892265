export default {
    home: "Dom",
    order: {
        submit: "Przegląd zamówienia"
    },
    tunnel: {
        loginVerification: "Weryfikacja logowania",
        addressVerification: "Weryfikacja adresu"
    },
    catalog: "Katalog",
    all: "Wszystko",
    return: {
        return: "Powrót"
    },
    errors: {
        404: "@:views.errors.404.title"
    },
    impersonation: "Zamówienie w imieniu"
}